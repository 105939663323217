import React, { FC, useState, useCallback } from 'react';
import { makeStyles, Theme, createStyles, Stepper, Step, StepLabel, Button, Typography, Grid } from '@material-ui/core';
import axios, { CancelTokenSource } from 'axios';
import { CUSTOMER_BASE_URL, USER_BASE_URL } from 'constants/url';
import StepCustomer from './StepCustomer';
import StepAddresses from './StepAddresses';
import StepContact from './StepContact';
import { isValidEmail } from 'utils';
import { isStrongPassword } from '../../../../../utils';

interface Props {
  customers: CustomerModel[];
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenCreateForm: React.Dispatch<React.SetStateAction<boolean>>;
  setCustomers: React.Dispatch<React.SetStateAction<CustomerModel[]>>;
  handleSnackBar: (open: boolean, variant: 'success' | 'error', message: string) => void;
}

interface ErrorHandling {
  error: boolean;
  message: string;
}

interface Error {
  [keys: string]: ErrorHandling;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%'
    },
    button: {
      marginRight: theme.spacing(1)
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1)
    },
    showLoading: {
      display: 'block',
      fontStyle: 'italic',
      color: '#888686'
    },
    showNone: {
      display: 'none'
    },
    titleWizard: {
      fontSize: '1.3rem',
      '& span':{
        fontSize:'15px'
      }
    }
  })
);

const dummyAddress = [
  {
    id: 0,
    postalCode: '',
    address: '',
    floorNo: '',
    unitNo: ''
  }
];

const dummyContactPerson = [
  {
    id: 0,
    name: '',
    contactEmail: '',
    contactNumber: ''
  }
];

const defaultAddressError = [
  {
    postalCode: { error: false, message: '' },
    address: { error: false, message: '' },
    floorNo: { error: false, message: '' },
    unitNo: { error: false, message: '' }
  }
];

const defaultContactError = [
  {
    name: { error: false, message: '' },
    contactEmail: { error: false, message: '' },
    contactNumber: { error: false, message: '' }
  }
];

const defaultCustomerError = {
  firstName: { error: false, message: '' },
  lastName: { error: false, message: '' },
  email: { error: false, message: '' },
  password: { error: false, message: '' },
  confirmPassword: { error: false, message: '' },
  contactNumber: { error: false, message: '' },
  privilegeDiscount: { error: false, message: '' }
};

const CustomerWizard: FC<Props> = props => {
  const classes = useStyles();
  const steps: string[] = ['Customer Details', 'Collection & Delivery Address', 'Password'];

  const [collectionAddress, setCollectionAddress] = useState<CollectionAddressModel[]>(dummyAddress);
  const [serviceAddress, setServiceAddress] = useState<ServiceAddressModel[]>(dummyAddress);
  const [contactPerson, setContactPerson] = useState<ContactPersonModel[]>(dummyContactPerson);

  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [remark, setRemark] = useState<string>('');
  const [privilegeDiscount, setPrivilegeDiscount] = useState<number>(0);
  const [contactNumber, setContactNumber] = useState<string>('');
  const [smsNotification, setSmsNotification] = useState<boolean>(false);

  const [activeStep, setActiveStep] = useState<number>(0);
  const [collectingAddressError, setCollectingAddressError] = useState<Error[]>(defaultAddressError);
  const [serviceAddressError, setServiceAddressError] = useState<Error[]>(defaultAddressError);
  const [contactPersonError, setContactPersonError] = useState<Error[]>(defaultContactError);
  const [customerError, setCustomerError] = useState<Error>(defaultCustomerError);

  const [isSubmit, setSubmit] = useState<boolean>(false);
  const [isSame, setIsSame] = useState<boolean>(false);
  const [isValidFirstStep, setValidFirstStep] = useState<boolean>(false);
  const [isValidSecondStep, setValidSecondStep] = useState<boolean>(false);
  const [isValidThirdStep, setValidThirdStep] = useState<boolean>(false);
  const [isEmailError, setEmailError] = useState<boolean>(false);
  const [isContactError, setContactError] = useState<boolean>(false);
  const [isDuplicatedCollection, setIsDupplicatedCollection] = useState<boolean>(false);
  const [isDuplicatedDelivery, setIsDupplicatedDelivery] = useState<boolean>(false);
  const [isLoadingEmail, setLoadingEmail] = useState<boolean>(false);
  const [isLoadingContact, setLoadingContact] = useState<boolean>(false);

  const { customers, setCustomers, setOpen, setOpenCreateForm, handleSnackBar } = props;

  const handleNext = () => {
    setCustomerError(defaultCustomerError);
    setCollectingAddressError(defaultAddressError);
    setServiceAddressError(defaultAddressError);
    setContactPersonError(defaultContactError);
    switch (activeStep) {
      case 0:
        if (!isValidFirstStep) {
          return;
        } else if (!handleCustomerError()) {
          return;
        } else if (isEmailError || isContactError) {
          return;
        }

        break;
      case 1:
        if (!isValidSecondStep) {
          return;
        } else if (handleErrorCollectionAddress() || handleErrorServiceAddress()) {
          return;
        }

        break;
      default:
        if (!isValidThirdStep) {
          return;
        } else if (handleContactPasswordError()) {
          return;
        }

        handleOnSubmit();
        break;
    }
    setActiveStep(activeStep <= steps.length - 1 ? prevActiveStep => prevActiveStep + 1 : steps.length - 1);
  };

  const handleBack = () => {
    setCustomerError(defaultCustomerError);
    setCollectingAddressError(defaultAddressError);
    setServiceAddressError(defaultAddressError);
    setContactPersonError(defaultContactError);
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleCollectionAddress = useCallback(
    (type: string, index?: number, payload?: CollectionAddressModel) => {
      switch (type) {
        case 'ADD_COLLECTION':
          setCollectionAddress([...collectionAddress, dummyAddress[0]]);
          setCollectingAddressError([...collectingAddressError, defaultAddressError[0]]);
          handleSecondStepValid();
          break;
        case 'DELETE_COLLECTION':
          setCollectionAddress(collectionAddress.filter((val, idx) => idx !== index));
          setCollectingAddressError(collectingAddressError.filter((val, idx) => idx !== index));
          handleSecondStepValid();
          break;
        default:
          setCollectionAddress(
            collectionAddress.map((val, idx) => {
              return idx === index! ? payload! : val;
            })
          );
          break;
      }
    },
    [collectionAddress, collectingAddressError]
  );

  const handleServiceAddress = useCallback(
    (type: string, index?: number, payload?: ServiceAddressModel) => {
      switch (type) {
        case 'ADD_SERVICE':
          setServiceAddress([...serviceAddress, dummyAddress[0]]);
          setServiceAddressError([...serviceAddressError, defaultAddressError[0]]);
          handleSecondStepValid();
          break;
        case 'DELETE_SERVICE':
          setServiceAddress(serviceAddress.filter((val, idx) => idx !== index));
          setServiceAddressError(serviceAddressError.filter((val, idx) => idx !== index));
          handleSecondStepValid();
          break;
        default:
          setServiceAddress(
            serviceAddress.map((val, idx) => {
              return idx === index! ? payload! : val;
            })
          );
          break;
      }
    },
    [serviceAddress, serviceAddressError]
  );

  const handleContactPerson = useCallback(
    (type: string, index?: number, payload?: ContactPersonModel) => {
      switch (type) {
        case 'ADD_CONTACT':
          setContactPerson([...contactPerson, dummyContactPerson[0]]);
          setContactPersonError([...contactPersonError, defaultContactError[0]]);
          break;
        case 'DELETE_CONTACT':
          setContactPerson(contactPerson.filter((val, idx) => idx !== index));
          setContactPersonError(contactPersonError.filter((val, idx) => idx !== index));
          break;
        default:
          setContactPerson(
            contactPerson.map((val, idx) => {
              return idx === index! ? payload! : val;
            })
          );
          break;
      }
    },
    [contactPerson, contactPersonError]
  );

  const handleEmailValidate = async () => {
    let returnErrorValidate = false;
    if (email) {
      setCustomerError({ ...customerError, email: { error: false, message: '' } });

      if (!isValidEmail(email)) {
        setCustomerError({ ...customerError, email: { error: true, message: 'Invalid email address.' } });
        setValidFirstStep(false);
        returnErrorValidate = true;
      } else {
        setLoadingEmail(true);
        await handleCheckUser(email, 'email');
        setLoadingEmail(false);
      }
    } else {
      setCustomerError({ ...customerError, email: { error: true, message: 'E-mail can not be empty.' } });
      setValidFirstStep(false);
      returnErrorValidate = true;
    }

    return returnErrorValidate;
  };

  const handleContactNumberValidate = async () => {
    let returnErrorValidate = false;
    if (contactNumber) {
      setCustomerError({ ...customerError, contactNumber: { error: false, message: '' } });
      if (contactNumber.length !== 8) {
        setCustomerError({ ...customerError, contactNumber: { error: true, message: 'Contact Number must be 8 digits.' } });
        setValidFirstStep(false);
        returnErrorValidate = true;
      } else {
        setLoadingContact(true);
        await handleCheckUser(contactNumber, 'contactNumber');
        setLoadingContact(false);
      }
    } else {
      setCustomerError({ ...customerError, contactNumber: { error: true, message: 'Contact Number can not be empty.' } });
      setValidFirstStep(false);
      returnErrorValidate = true;
    }

    return returnErrorValidate;
  };

  const handleFirstStepValid = () => {
    if (firstName && customerError.firstName.error) {
      setCustomerError({ ...customerError, firstName: { error: false, message: '' } });
    }

    if (lastName && customerError.lastName.error) {
      setCustomerError({ ...customerError, lastName: { error: false, message: '' } });
    }

    if (privilegeDiscount && customerError.privilegeDiscount.error) {
      setCustomerError({ ...customerError, privilegeDiscount: { error: false, message: '' } });
    }

    if (firstName && lastName && contactNumber && email) {
      handleCustomerError();
      if (isEmailError || isContactError) {
        setValidFirstStep(false);
      }
    } else {
      setValidFirstStep(false);
    }
  };

  const handleSecondStepValid = () => {
    setValidSecondStep(true);
  };

  const handleThirdStepValid = () => {
    let resultHandler = false
    if (password && confirmPassword) {
      resultHandler = !handleContactPasswordError();
    }

    setValidThirdStep(resultHandler);
  };

  const handleCustomerError = (): boolean => {
    let customerErrorTemp = defaultCustomerError;
    let returnHandle = true;
    if (firstName === '') {
      customerErrorTemp = { ...customerErrorTemp, firstName: { error: true, message: 'First name can not be empty.' } };
      returnHandle = false;
    } else if (lastName === '') {
      customerErrorTemp = { ...customerErrorTemp, lastName: { error: true, message: 'Last name can not be empty.' } };
      returnHandle = false;
    } else if (email === '') {
      customerErrorTemp = { ...customerErrorTemp, email: { error: true, message: 'Email can not be empty' } };
      returnHandle = false;
    } else if (contactNumber === '') {
      customerErrorTemp = { ...customerErrorTemp, contactNumber: { error: true, message: 'Contact Number can not be empty.' } };
      returnHandle = false;
    } else if (!isValidEmail(email)) {
      customerErrorTemp = { ...customerErrorTemp, email: { error: true, message: 'Invalid email address.' } };
      returnHandle = false;
    } else if (contactNumber.length !== 8) {
      customerErrorTemp = { ...customerErrorTemp, contactNumber: { error: true, message: 'Contact Number must be 8 digits.' } };
      returnHandle = false;
    } else if (privilegeDiscount && privilegeDiscount > 100) {
      customerErrorTemp = { ...customerErrorTemp, privilegeDiscount: { error: true, message: 'Discount cannot be more than 100%.' } };
      returnHandle = false;
    }

    setValidFirstStep(returnHandle);
    setCustomerError(customerErrorTemp);
    return returnHandle;
  };

  const handleErrorCollectionAddress = (): boolean => {
    let i = 0;
    const newErr: any = [];
    collectionAddress.map((value, index) => {
      const thisAddress = `${value.address.replace(/\s/g, '').toLowerCase()},${value.floorNo},${value.unitNo},${value.postalCode}`;

      const findFirst = collectionAddress.find((val, idx) => {
        const checkAddress = `${val.address.replace(/\s/g, '').toLowerCase()},${val.floorNo},${val.unitNo},${val.postalCode}`;
        return idx !== index ? checkAddress === thisAddress : null;
      });

      const newValue: { [keys: string]: any } = {};
      for (const [keys, val] of Object.entries(value)) {
        if (keys === 'postalCode') {
          newValue[keys] = { error: val.length !== 6, message: val.length !== 6 ? 'Postal Code must be 6 digits' : '' };
          if (val.length !== 6) i++;
        } else {
          newValue[keys] = { error: val === '', message: val === '' ? 'This field can not be empty.' : '' };
          if (val === '') i++;
        }
      }

      if (findFirst) {
        setIsDupplicatedCollection(true);
        newValue['address'] = { error: true, message: 'This address is duplicated.'};
        i++;
      }
      
      newErr.push(newValue);
      return value;
    });

    const resultErrorHandle = i > 0 ? true : false;

    setCollectingAddressError(newErr);
    setValidSecondStep(!resultErrorHandle);

    return resultErrorHandle;
  };

  const handleErrorServiceAddress = (): boolean => {
    let i = 0;
    const newErr: any = [];
    serviceAddress.map((value, index) => {
      const thisAddress = `${value.address.replace(/\s/g, '').toLowerCase()},${value.floorNo},${value.unitNo},${value.postalCode}`;

      const findFirst = serviceAddress.find((val, idx) => {
        const checkAddress = `${val.address.replace(/\s/g, '').toLowerCase()},${val.floorNo},${val.unitNo},${val.postalCode}`;
        return idx !== index ? checkAddress === thisAddress : null;
      });

      const newValue: { [keys: string]: any } = {};
      for (const [keys, val] of Object.entries(value)) {
        if (keys === 'postalCode') {
          newValue[keys] = { error: val.length !== 6, message: val.length !== 6 ? 'Postal Code must be 6 digits' : '' };
          if (val.length !== 6) i++;
        } else {
          newValue[keys] = { error: val === '', message: val === '' ? 'Cannot be empty' : '' };
          if (val === '') i++;
        }
      }

      if (findFirst) {
        setIsDupplicatedDelivery(true);
        newValue['address'] = { error: true, message: 'This address is duplicated.'};
        i++;
      }

      newErr.push(newValue);
      return value;
    });
    
    const resultErrorHandle = i > 0 ? true : false;
    
    setServiceAddressError(newErr);
    setValidSecondStep(!resultErrorHandle);

    return resultErrorHandle;
  };

  const handleContactErrorPerson = (): boolean => {
    let i = 0;
    const newErr: any = [];
    contactPerson.map(value => {
      const newValue: { [keys: string]: any } = {};
      for (const [keys, val] of Object.entries(value)) {
        if (keys === 'contactEmail') {
          newValue[keys] = {
            error: val === '' || !isValidEmail(val),
            message: (val === '' && 'Can not be empty.') || (!isValidEmail(val) && ' Email not valid')
          };
          if (val === '' || !isValidEmail(val)) i++;
        } else if (keys === 'contactNumber') {
          newValue[keys] = {
            error: val === '' || contactNumber.length !== 8,
            message: (val === '' && 'Can not be empty.') || (contactNumber.length !== 8 && ' Contact Number must be 8 digits')
          };
          if (val === '' || contactNumber.length !== 8) i++;
        } else {
          newValue[keys] = {
            error: val === '',
            message: val === '' ? 'Can not be empty.' : ''
          };
          if (val === '') i++;
        }
      }
      newErr.push(newValue);
      return value;
    });
    setContactPersonError(newErr);
    return i > 0 ? true : false;
  };

  const handleContactPasswordError = (): boolean => {
    setCustomerError(defaultCustomerError);
    let customerContactErrorTemp = defaultCustomerError;
    let ret = false;

    if (!password || password === '') {
      customerContactErrorTemp = { ...customerContactErrorTemp, password: { error: true, message: 'Password can not be empty' } };
      ret = true;
    } else if (password.length < 8) {
      customerContactErrorTemp = { ...customerContactErrorTemp, password: { error: true, message: 'Please enter password at least 8 characters' } };
      ret = true;
    } else if (!isStrongPassword(password)) {
      customerContactErrorTemp = {
        ...customerContactErrorTemp,
        password: { error: true, message: 'Please enter password at least containing 1 uppercase letter, 1 number and 1 symbol' }
      };
      ret = true;
    } else if (!confirmPassword || confirmPassword === '' || confirmPassword !== password) {
      customerContactErrorTemp = {
        ...customerContactErrorTemp,
        confirmPassword: {
          error: confirmPassword === '' || confirmPassword !== password,
          message: (confirmPassword === '' ? 'Can not be empty' : '') || (confirmPassword !== password ? 'Password not match.' : '')
        }
      };
      ret = true;
    }

    setCustomerError(customerContactErrorTemp);
    return ret;
  };

  const handleCheckUser = useCallback(async (value: string, attribute: string) => {
    if (attribute === 'email') {
      setEmailError(false);
    } else {
      setContactError(false);
    }

    try {
      const params = new URLSearchParams();
      params.append('params', value);
      params.append('role', 'CUSTOMER');
      const result = await axios.get(`${CUSTOMER_BASE_URL}/check?${params.toString()}`);
      const { isExist } = result.data;
      if (isExist > 0) {
        setValidFirstStep(false);
        if (attribute === 'email') {
          setEmailError(true);
        } else {
          setContactError(true);
        }

        setCustomerError({
          ...customerError,
          [attribute]: {
            error: true,
            message: attribute === 'email' ? 'E-mail address has been registered.' : 'Contact number has been registered.'
          }
        });
      } else {
        if (attribute === 'email') {
          setEmailError(false);
          if (contactNumber && !isContactError) {
            setValidFirstStep(true);
          }
        } else {
          setContactError(false);
          if (email && !isEmailError) {
            setValidFirstStep(true);
          }
        }
        
        setCustomerError({
          ...customerError,
          [attribute]: { error: false, message: '' }
        });
      }
    } catch (error) {
      console.log('error', error);
    }
  }, [email, customerError]);

  const handleOnSubmit = async () => {
    setSubmit(true);
    if (!isSubmit) {
      const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();
      try {
        const result = await axios.post(
          CUSTOMER_BASE_URL,
          {
            firstName,
            lastName,
            email,
            remark,
            password,
            privilegeDiscount,
            smsNotification: false,
            contactNumber: contactNumber.replace(/\s/g, '').trim(),
            collectionAddress: collectionAddress,
            serviceAddress: serviceAddress,
            contactPerson: contactPerson
          },
          { cancelToken: cancelTokenSource.token }
        );
        handleSnackBar(true, 'success', 'Customer has been added successfully.');
        setOpen(false);
        setOpenCreateForm(false);
        setSubmit(false);
        setCustomers([{ ...result.data, new: true }, ...customers]);
      } catch (error) {
        console.log('err : ', error);
        handleSnackBar(true, 'error', 'The customer has failed to add.');
        setSubmit(false);
      }
    }
  };

  const handleOnCancel = () => {
    setOpen(false);
    setOpenCreateForm(false);
  };

  const getStepContent = (step: number) => {
    switch (step) {
      case 0:
        return (
          <StepCustomer
            firstName={firstName}
            lastName={lastName}
            remark={remark}
            email={email}
            privilegeDiscount={privilegeDiscount}
            contactNumber={contactNumber}
            smsNotification={smsNotification}
            customerError={customerError}
            setCustomerError={setCustomerError}
            setFirstName={setFirstName}
            setLastName={setLastName}
            setRemark={setRemark}
            setPrivilegeDiscount={setPrivilegeDiscount}
            setEmail={setEmail}
            setContactNumber={setContactNumber}
            setSmsNotification={setSmsNotification}
            isLoadingEmail={isLoadingEmail}
            setLoadingEmail={setLoadingEmail}
            isLoadingContact={isLoadingContact}
            setLoadingContact={setLoadingContact}
            setValidFirstStep={setValidFirstStep}
            handleEmailValidate={handleEmailValidate}
            handleContactNumberValidate={handleContactNumberValidate}
            handleCheckUser={handleCheckUser}
            handleCustomerError={handleCustomerError}
            handleFirstStepValid={handleFirstStepValid}
          />
        );
      case 1:
        return (
          <StepAddresses
            collectionAddress={collectionAddress}
            serviceAddress={serviceAddress}
            setCollectionAddress={setCollectionAddress}
            setServiceAddress={setServiceAddress}
            collectingAddressError={collectingAddressError}
            serviceAddressError={serviceAddressError}
            setCollectingAddressError={setCollectingAddressError}
            setServiceAddressError={setServiceAddressError}
            isDuplicatedCollection={isDuplicatedCollection}
            setIsDupplicatedCollection={setIsDupplicatedCollection}
            isDuplicatedDelivery={isDuplicatedDelivery}
            setIsDupplicatedDelivery={setIsDupplicatedDelivery}
            handleCollectionAddress={handleCollectionAddress}
            handleServiceAddress={handleServiceAddress}
            handleSecondStepValid={handleSecondStepValid}
            handleErrorCollectionAddress={handleErrorCollectionAddress}
            handleErrorServiceAddress={handleErrorServiceAddress}
          />
        );
      default:
        return (
          <StepContact
            contactPerson={contactPerson}
            firstName={firstName}
            lastName={lastName}
            email={email}
            contactNumber={contactNumber}
            password={password}
            confirmPassword={confirmPassword}
            contactPersonError={contactPersonError}
            customerError={customerError}
            isSame={isSame}
            setIsSame={setIsSame}
            handleContactPerson={handleContactPerson}
            setPassword={setPassword}
            setConfirmPassword={setConfirmPassword}
            setCustomerError={setCustomerError}
            setValidThirdStep={setValidThirdStep}
            handleThirdStepValid={handleThirdStepValid}
          />
        );
    }
  };

  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep}>
        {steps.map(label => {
          const stepProps: { completed?: boolean } = {};
          const labelProps: { optional?: React.ReactNode } = {};
          return (
            <Step key={label} {...stepProps}>
              <StepLabel classes={{ labelContainer: classes.titleWizard }} {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <form>{getStepContent(activeStep)}</form>

      <Grid container item lg={12} md={12} sm={12} xs={12} justify='flex-end'>
        <Typography variant='h6' className={isSubmit ? classes.showLoading : classes.showNone}>
          Loading....
        </Typography>
        <Button size='small' disabled={isSubmit} onClick={activeStep === 0 ? handleOnCancel : handleBack} className={classes.button}>
          {activeStep === 0 ? 'Cancel' : 'Back'}
        </Button>
        {activeStep == 0 ? (
          <Button size='small' disabled={isSubmit || !isValidFirstStep} variant='contained' color='primary' onClick={handleNext} className={classes.button}>
            Next
          </Button>
        ) : (activeStep == 1 ? (
          <Button size='small' disabled={isSubmit || !isValidSecondStep} variant='contained' color='primary' onClick={handleNext} className={classes.button}>
            Next
          </Button>
        ) : (
          <Button size='small' disabled={isSubmit || !isValidThirdStep} variant='contained' color='primary' onClick={handleNext} className={classes.button}>
            Finish
          </Button>
        ))}
      </Grid>
    </div>
  );
};

export default CustomerWizard;
