import React, { useEffect, useState } from 'react';
import useRouter from 'hooks/useRouter';
import axios from 'axios';
import { PayPalButton } from 'react-paypal-button-v2';
import { Container, makeStyles, Theme, Paper, Typography } from '@material-ui/core';
import { StandardConfirmationDialog } from 'components/AppDialog';
import { GET_SERVICE_INVOICE_CUSTOMER_URL, GET_PAYMENT_INVOICE_CUSTOMER_URL } from 'constants/url';
import logo from 'images/loundrybutler_logo.png';

const useStyles = makeStyles((theme: Theme) => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white
    }
  },
  root: {
    marginTop: theme.spacing(8),
    padding: theme.spacing(3, 5)
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  logoContainer: {
    textAlign: 'center'
  },
  logo: {
    width: '60%',
    marginBottom: theme.spacing(4)
  },
  subHeader: {
    textAlign: 'center',
    color: '#adadad',
    marginTop: theme.spacing(1)
  },
  linkButton: {
    textAlign: 'center',
    padding: theme.spacing(1),
    paddingTop: theme.spacing(5)
  }
}));

const PaypalPage: React.FC = () => {
  const classes = useStyles();
  const { match } = useRouter();
  const serviceId = match.params.id;
  const cancelTokenSource = axios.CancelToken.source();
  const jwtToken = new URLSearchParams(window.location.search).get('t');

  const [serviceOrder, setServiceOrder] = useState<ServiceModel>();
  const [totalPrice, setTotalPrice] = useState<number>(0);
  const [amount, setAmount] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [snackbarVarient, setSnackbarVarient] = useState<'success' | 'error'>('success');

  const CLIENT_ID = process.env.REACT_APP_PAYPAL_CLIENT;

  useEffect(() => {
    if (!serviceId) {
      setMessage('Data not found.');
      return;
    }

    setMessage('Loading...');
    const fetchData = async () => {
      try {
        const { data } = await axios.get(`${GET_SERVICE_INVOICE_CUSTOMER_URL(Number(serviceId))}?jwtParam=${jwtToken}`);
        if (data) {
          const { totalPrice } = data;
          setServiceOrder(data);
          setTotalPrice(totalPrice);
          setAmount(Number(totalPrice).toFixed(2));
          setMessage('');
        } else {
          setMessage('Data not found.');
        }
      } catch (error) {
        console.log('error:', error);
        setMessage('Failed get data.');
      }
      return;
    };
    fetchData();
  }, [serviceId]);

  const handleOpenSnackbar = () => {
    setOpenSnackbar(true);
  };
  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleSetMessage = (message: string) => {
    setMessage(message);
    handleOpenSnackbar();
  };

  return (
    <Container component='main' maxWidth='xs'>
      <Paper className={classes.root}>
        <div className={classes.paper}>
          <div className={classes.logoContainer}>
            <img src={logo} alt='' className={classes.logo} />
          </div>
          <div>
            {!serviceOrder || loading ? (
              <Typography variant='h3'>{message}</Typography>
            ) : (
              <PayPalButton
                amount={amount}
                options={{
                  clientId: CLIENT_ID,
                  locale: 'en_SG',
                  currency: 'SGD'
                }}
                onApprove={(data: any, actions: any) => {
                  setLoading(true);
                  setMessage('Loading...');
                }}
                onSuccess={async (details: any, data: any) => {
                  const { payerID } = data;
                  const { purchase_units } = details;
                  const { payments } = purchase_units[0];
                  const { captures } = payments;
                  const { id } = captures[0];
                  
                  setLoading(true);
                  setMessage('Loading...');
                  await axios.put(
                    `${GET_PAYMENT_INVOICE_CUSTOMER_URL(Number(serviceId))}`,
                    {
                      jwtParam: jwtToken,
                      paymentType: 'PAYPAL',
                      paymentStatus: 'PAID',
                      transactionIdPaypal: id,
                      totalCollected: Number(totalPrice),
                      totalChanges: 0
                    },
                    { cancelToken: cancelTokenSource.token }
                  );
                  setLoading(false);
                  setMessage('');
                  setSnackbarVarient('success');
                  handleSetMessage('Payment with paypal successfully.');
                  // @ts-ignore
                  window.Android.handlePayment(true);
                  return;
                }}
                onError={(err: object) => {
                  if (err) {
                    console.log('err >> ', err);
                  }
                  setSnackbarVarient('error');
                  handleSetMessage('Payment with paypal failed.');
                  // @ts-ignore
                  window.Android.handlePayment(false);
                }}
              />
            )}
          </div>
        </div>
      </Paper>

      <StandardConfirmationDialog
        variant={snackbarVarient}
        titleMessage={snackbarVarient === 'success' ? 'Success!' : 'Error!'}
        message={message}
        open={openSnackbar}
        handleClose={handleCloseSnackbar}
        onConfirm={handleCloseSnackbar}
        noCancelButton={true}
      />
    </Container>
  );
};

export default PaypalPage;
