import React, { FC, Fragment, useState } from 'react';
import { IconButton, RadioGroup, Radio, FormControl, FormLabel, FormControlLabel, Menu, MenuItem, Tooltip } from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';

interface Props {
  filter: { [keys: string]: string };
  setFilter: React.Dispatch<React.SetStateAction<{ [keys: string]: string }>>;
}

const TableFilter: FC<Props> = props => {
  const { filter, setFilter } = props;
  const [filterEl, setFilterEL] = useState<null | HTMLElement>(null);

  return (
    <Fragment>
      <Tooltip title='Filter by'>
        <IconButton area-label='filter' aria-controls='filter-menu' aria-haspopup='true' onClick={event => setFilterEL(event.currentTarget)}>
          <FilterListIcon />
        </IconButton>
      </Tooltip>

      <Menu id='promotion-menu' anchorEl={filterEl} keepMounted open={Boolean(filterEl)} onClose={event => setFilterEL(null)}>
        <MenuItem>
          <FormControl component='div'>
            <FormLabel component='label'>Role</FormLabel>
            <RadioGroup
              row
              aria-label='role'
              value={filter.role}
              onChange={event => setFilter({ ...filter, role: (event.currentTarget as HTMLInputElement).value })}
            >
              <FormControlLabel value='ADMIN' control={<Radio size='small' />} label='Admin' />
              <FormControlLabel value='DRIVER' control={<Radio size='small' />} label='Driver' />
            </RadioGroup>
          </FormControl>
        </MenuItem>

        <MenuItem>
          <FormControl component='div'>
            <FormLabel component='label'>Status Active</FormLabel>
            <RadioGroup
              row
              aria-label='active'
              value={filter.active}
              onChange={event => setFilter({ ...filter, active: (event.currentTarget as HTMLInputElement).value })}
            >
              <FormControlLabel value='true' control={<Radio size='small' />} label='Active' />
              <FormControlLabel value='false' control={<Radio size='small' />} label='Inactive' />
            </RadioGroup>
          </FormControl>
        </MenuItem>
      </Menu>
    </Fragment>
  );
};

export default TableFilter;
