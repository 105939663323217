import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Grid, makeStyles, Theme, Paper, Typography } from '@material-ui/core';
import logo from 'images/loundrybutler_logo.png';

const useStyles = makeStyles((theme: Theme) => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white
    }
  },
  root: {
    marginTop: theme.spacing(8),
    padding: theme.spacing(3, 5)
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  logoContainer: {
    textAlign: 'center'
  },
  logo: {
    width: '60%',
    marginBottom: theme.spacing(4)
  },
  subHeader: {
    textAlign: 'center',
    color: '#adadad',
    marginTop: theme.spacing(1)
  },
  linkButton: {
    textAlign: 'center',
    padding: theme.spacing(1),
    paddingTop: theme.spacing(5)
  }
}));

const ThankYouPage: React.FC = () => {
  const classes = useStyles();

  return (
    <Container component='main' maxWidth='xs'>
      <Paper className={classes.root}>
        <div className={classes.paper}>
          <div className={classes.logoContainer}>
            <img src={logo} alt='' className={classes.logo} />
          </div>
          <Typography component='h1' variant='h3'>
            Thank you!
          </Typography>
          <Typography component='h1' variant='h6' className={classes.subHeader}>
            Please check your email for reset with your new password!
          </Typography>
          <Grid container>
            <Grid item xs className={classes.linkButton}>
              <Link to='/' style={{ textDecoration: 'none' }}>
                Back to login page
              </Link>
            </Grid>
          </Grid>
        </div>
      </Paper>
    </Container>
  );
};

export default ThankYouPage;
