import React, { FC, Fragment, useState, useEffect, useCallback } from 'react';
import { TextField, Button, Grid, IconButton, makeStyles, Menu, MenuItem, Tab, Tabs, Theme, Tooltip, CircularProgress } from '@material-ui/core';
import axios, { CancelTokenSource } from 'axios';
import FilterListIcon from '@material-ui/icons/FilterList';
import DeleteIcon from '@material-ui/icons/Delete';
import SearchInput from 'components/SearchInput';
import useDebounce from 'hooks/useDebounce';
import ServiceItemTemplateTable from './components/ServiceItemTemplateTable';
import { StandardConfirmationDialog } from 'components/AppDialog';
import { SERVICE_ITEM_TEMPLATE_BASE_URL, BULK_DELETE_SERVICE_ITEM_TEMPLATE_URL, CATEGORY_BASE_URL } from 'constants/url';
import Category from '../Category';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { CategoryDummy } from 'constants/DummyData';

const useStyles = makeStyles((theme: Theme) => ({
  addButton: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1)
  },
  icon: {
    fontSize: 25,
    color: '#FF0909'
  },
  deleteText: {
    color: '#FF0909',
    paddingTop: 5,
    paddingLeft: 10
  },
  root: {
    flexGrow: 1,
    display: 'flex',
    width: '100%'
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`
  },
  panel: {
    width: '100%',
    paddingLeft: 10
  },
  searchCategory: {
    minWidth: 200
  }
}));

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;
  const classes = useStyles();

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
      className={classes.panel}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
};

const a11yProps = (index: number) => {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`
  };
};

const defaultCategory = CategoryDummy;

const ServiceItemTemplatePage: FC = () => {
  const classes = useStyles();

  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [snackbarVarient, setSnackbarVarient] = useState<'success' | 'error'>('success');
  const [messageSuccess, setMessageSuccess] = useState<string>('');
  const [messageError, setMessageError] = useState<string>('');

  const [query, setQuery] = useState<string>('');
  const [queryString, setQueryString] = useState<string>();
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [isSearchingServiceItemTemplate, setSearchingServiceItemTemplate] = useState<boolean>(false);
  const [serviceItemTemplates, setServiceItemTemplates] = useState<ServiceItemTemplatesResponseModel[]>([]);
  const [count, setCount] = useState<number>(0);

  const [order, setOrder] = useState<'asc' | 'desc'>('desc');
  const [orderBy, setOrderBy] = useState<string>('createdAt');
  const [checked, setChecked] = useState<number[]>([]);
  const [openConfirmationBulk, setOpenConfirmationBulk] = useState<boolean>(false);
  const [openCreateServiceItemTemplate, setOpenCreateServiceItemTemplate] = useState<boolean>(false);
  const [openEditServiceItemTemplate, setOpenEditServiceItemTemplate] = useState<boolean>(false);
  const [currentEditingServiceTemplateIndex, setCurrentEditingServiceTemplateIndex] = useState<number>(0);
  const [category, setCategory] = useState<CategoryResponseModel>(defaultCategory);
  const [categories, setCategories] = useState<CategoryResponseModel[]>([defaultCategory]);
  const [filterEl, setFilterEL] = useState<null | HTMLElement>(null);
  const [filter, setFilter] = useState<number>();
  const [openSearchCategory, setOpenSearchCategory] = useState<boolean>(false);
  const countChecked = checked.length;
  const isLoading = openSearchCategory && categories.length === 0;

  // Search Service Item Template whenever rowsPerPage, currentPage, queryString changes
  const fetchData = useCallback(() => {
    const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();

    const getQueryParams = () => {
      const params = new URLSearchParams();

      if (queryString) {
        params.append('q', queryString);
      }

      if (orderBy) {
        params.append('orderBy', orderBy);
      }

      if (order) {
        params.append('order', order.toUpperCase());
      }

      if (filter) {
        params.append('filter', filter.toString());
      }

      params.append('s', (currentPage * rowsPerPage).toString());
      params.append('l', rowsPerPage.toString());

      return params.toString();
    };

    const searchServiceItemTemplate = async () => {
      setSearchingServiceItemTemplate(true);
      try {
        const url = `${SERVICE_ITEM_TEMPLATE_BASE_URL}?${getQueryParams()}`;
        const { data } = await axios.get(url, { cancelToken: cancelTokenSource.token });
        setCount(data.count);
        setServiceItemTemplates(data.serviceItemTemplates);
      } catch (err) {
        console.log(err);
      }
      setSearchingServiceItemTemplate(false);
    };

    searchServiceItemTemplate();
    return () => {
      cancelTokenSource.cancel();
    };
  }, [rowsPerPage, currentPage, queryString, order, orderBy, filter]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const performActionAndRevertPage = (action: React.Dispatch<React.SetStateAction<any>>, actionParam: any) => {
    setCurrentPage(0);
    action(actionParam);
  };

  const handleSearch = useCallback((searchQuery: string) => {
    performActionAndRevertPage(setQueryString, searchQuery);
  }, []);

  const debouncedSearchTerm = useDebounce(query, 500);
  // Load service item data to populate on search list
  useEffect(() => {
    if (debouncedSearchTerm.length >= 3) {
      handleSearch(debouncedSearchTerm);
    } else if (debouncedSearchTerm.length === 0) {
      handleSearch(debouncedSearchTerm);
    }
  }, [debouncedSearchTerm, handleSearch]);

  const handleOpenCreateServiceItemTemplate = () => {
    setOpenEditServiceItemTemplate(false);
    setOpenCreateServiceItemTemplate(!openCreateServiceItemTemplate);
  };

  const handleCancelCreateServiceItemTemplate = () => {
    setOpenCreateServiceItemTemplate(false);
  };

  const handleOpenEditServiceItemTemplate = (serviceItemTemplateIndex: number): React.MouseEventHandler => () => {
    setCurrentEditingServiceTemplateIndex(serviceItemTemplateIndex);
    setOpenCreateServiceItemTemplate(false);
    setOpenEditServiceItemTemplate(true);
  };

  const handleCancelEditServiceItemTemplate = () => {
    setOpenEditServiceItemTemplate(false);
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleSetMessageSuccess = (message: string) => {
    setMessageSuccess(message);
  };

  const handleSetMessageError = (message: string) => {
    setMessageError(message);
  };

  const addNewServiceItemTemplate = (serviceItemTemplate: ServiceItemTemplatesResponseModel) => {
    serviceItemTemplate.new = true;
    serviceItemTemplates.unshift(serviceItemTemplate);
    setServiceItemTemplates([...serviceItemTemplates]);
    setCount(c => c + 1);
  };

  const updateIndividualServiceItemTemplate = (updatedServiceItemTemplateProperties: Partial<ServiceItemTemplatesResponseModel>) => {
    setServiceItemTemplates(
      serviceItemTemplates!.map((serviceItemTemplate, index) => {
        if (index !== currentEditingServiceTemplateIndex) {
          return serviceItemTemplate;
        }
        return Object.assign({}, serviceItemTemplate, updatedServiceItemTemplateProperties);
      })
    );
  };

  const deleteIndividualServiceItemTemplate = (serviceItemTemplateIndex: number) => {
    serviceItemTemplates.splice(serviceItemTemplateIndex, 1);
    setServiceItemTemplates([...serviceItemTemplates]);
    setCount(c => c - 1);
  };

  const handleCloseConfirmationBulk = () => {
    setOpenConfirmationBulk(false);
  };

  const handleOpenConfirmationBulk = () => {
    setOpenConfirmationBulk(true);
  };

  const bulkDeleteServiceItemTemplate = async () => {
    setOpenConfirmationBulk(false);

    const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();

    try {
      await axios.post(
        `${BULK_DELETE_SERVICE_ITEM_TEMPLATE_URL}`,
        {
          id: checked
        },
        { cancelToken: cancelTokenSource.token }
      );
      setSnackbarVarient('success');
      setOpenSnackbar(true);
      handleSetMessageSuccess('Successfully to bulk delete service item');
      setChecked([]);
      fetchData();
    } catch (err) {
      console.log(err);
      setSnackbarVarient('error');
      setOpenSnackbar(true);
      handleSetMessageError('Failed to bulk delete service item');
    }
  };

  const handleSearchCategory = async (value: string) => {
    try {
      const params = new URLSearchParams();
      params.append('q', value);
      params.append('orderBy', 'name');
      params.append('order', 'ASC');
      params.append('l', '10');
      const { data } = await axios.get(`${CATEGORY_BASE_URL}?${params}`);
      setCategories(data.Categories);
    } catch (err) {
      console.log('err : ', err);
    }
  };

  const renderDelete = () => {
    return (
      countChecked !== 0 && (
        <IconButton size='small'>
          <DeleteIcon className={classes.icon} onClick={handleOpenConfirmationBulk} />
        </IconButton>
      )
    );
  };

  const renderMenu = () => (
    <Grid item>
      <Tooltip title='Filter by'>
        <IconButton area-label='filter' aria-controls='filter-menu' aria-haspopup='true' onClick={event => setFilterEL(event.currentTarget)}>
          <FilterListIcon />
        </IconButton>
      </Tooltip>

      <Menu id='menuCategory' anchorEl={filterEl} keepMounted open={Boolean(filterEl)} onClose={event => setFilterEL(null)}>
        <MenuItem>
          <Autocomplete
            fullWidth
            id='searchCategory'
            size='small'
            value={category}
            options={categories}
            getOptionLabel={option => option.nameCategory}
            onChange={(event: any, value: any) => {
              setCategory(value);
              setFilter(value ? value.idCategory : 0);
            }}
            onOpen={() => {
              handleSearchCategory('');
              setOpenSearchCategory(!openSearchCategory);
            }}
            renderInput={params => (
              <TextField
                {...params}
                onChange={event => handleSearchCategory(event.target.value)}
                margin='dense'
                label='Category'
                variant='outlined'
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <Fragment>
                      {isLoading ? <CircularProgress color='inherit' size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </Fragment>
                  )
                }}
                className={classes.searchCategory}
              />
            )}
          />
        </MenuItem>
      </Menu>

      {renderDelete()}
    </Grid>
  );

  return (
    <Fragment>
      <Grid container direction='row' justify='space-between'>
        <Grid container direction='row' item lg={6} sm={6} md={6} justify='flex-start' alignItems='center'>
          <Grid item>
            <SearchInput
              withBorder
              withTransition={false}
              width={150}
              placeHolder='Search Service...'
              iconColor='#989898'
              tableSearchValue={query}
              setTableSearchValue={setQuery}
            />
          </Grid>

          {renderMenu()}
        </Grid>

        <Grid container direction='row' item lg={6} sm={6} md={6} justify='flex-end' alignItems='center'>
          <Grid item>
            <Button
              color='primary'
              size='medium'
              variant='contained'
              disabled={openEditServiceItemTemplate}
              className={classes.addButton}
              onClick={() => {
                handleOpenCreateServiceItemTemplate();
              }}
            >
              New Service Item
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <StandardConfirmationDialog
        variant={'danger'}
        titleMessage={'Delete'}
        message={'Are you sure want to delete this data ?'}
        open={openConfirmationBulk}
        handleClose={handleCloseConfirmationBulk}
        onConfirm={bulkDeleteServiceItemTemplate}
      />

      <StandardConfirmationDialog
        variant={snackbarVarient}
        titleMessage={snackbarVarient === 'success' ? 'Success!' : 'Error!'}
        message={snackbarVarient === 'success' ? messageSuccess : messageError}
        open={openSnackbar}
        handleClose={handleCloseSnackbar}
        onConfirm={handleCloseSnackbar}
        noCancelButton={true}
      />
      <Grid lg={12} item>
        <ServiceItemTemplateTable
          isLoadingData={isSearchingServiceItemTemplate}
          serviceItemTemplates={serviceItemTemplates}
          count={count}
          setOpenSnackbar={setOpenSnackbar}
          setSnackbarVarient={setSnackbarVarient}
          handleSetMessageSuccess={handleSetMessageSuccess}
          handleSetMessageError={handleSetMessageError}
          currentPage={currentPage}
          rowsPerPage={rowsPerPage}
          order={order}
          orderBy={orderBy}
          setOrder={setOrder}
          setOrderBy={setOrderBy}
          handleChangePage={(event, page) => setCurrentPage(page)}
          handleChangeRowsPerPage={event => performActionAndRevertPage(setRowsPerPage, +event.target.value)}
          openCreateServiceItemTemplate={openCreateServiceItemTemplate}
          handleCancelCreateServiceItemTemplate={handleCancelCreateServiceItemTemplate}
          addNewServiceItemTemplate={addNewServiceItemTemplate}
          deleteIndividualServiceItemTemplate={deleteIndividualServiceItemTemplate}
          openEditServiceItemTemplate={openEditServiceItemTemplate}
          serviceItemTemplate={serviceItemTemplates[currentEditingServiceTemplateIndex]}
          currentEditingServiceTemplateIndex={currentEditingServiceTemplateIndex}
          handleOpenEditServiceItemTemplate={handleOpenEditServiceItemTemplate}
          handleCancelEditServiceItemTemplate={handleCancelEditServiceItemTemplate}
          updateIndividualServiceItemTemplate={updateIndividualServiceItemTemplate}
          checked={checked}
          setChecked={setChecked}
        />
      </Grid>
    </Fragment>
  );
};

const Product: FC = () => {
  const classes = useStyles();
  const [value, setValue] = useState<number>(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <Tabs orientation='vertical' variant='scrollable' value={value} onChange={handleChange} aria-label='Service' className={classes.tabs}>
        <Tab label='Category' {...a11yProps(0)} />
        <Tab label='Items' {...a11yProps(1)} />
      </Tabs>
      <TabPanel value={value} index={0}>
        <Category />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <ServiceItemTemplatePage />
      </TabPanel>
    </div>
  );
};

export default Product;
