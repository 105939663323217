import React, { FC, Fragment, useState, useEffect, useCallback } from 'react';
import { Button, Grid, IconButton, makeStyles, Theme } from '@material-ui/core';
import axios, { CancelTokenSource } from 'axios';
import aws from 'aws-sdk';
import DeleteIcon from '@material-ui/icons/Delete';
import useCurrentPageTitleUpdater from 'hooks/useCurrentPageTitleUpdater';
import useDebounce from 'hooks/useDebounce';
import SearchInput from 'components/SearchInput';
import CategoryTable from './components/CategoryTable';
import { StandardConfirmationDialog } from 'components/AppDialog';
import { CATEGORY_BASE_URL, BULK_DELETE_CATEGORY_URL } from 'constants/url';
import { CategoryDummy } from 'constants/DummyData';

const useStyles = makeStyles((theme: Theme) => ({
  addButton: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1)
  },
  icon: {
    fontSize: 25,
    color: '#FF0909'
  },
  deleteText: {
    color: '#FF0909',
    paddingTop: 5,
    paddingLeft: 10
  }
}));

const dummyCategories: CategoryResponseModel[] = [CategoryDummy];

const { REACT_APP_AWS_REGION, REACT_APP_AWS_ACCESS_KEY, REACT_APP_AWS_SECRET_KEY, REACT_APP_S3_BUCKET_NAME } = process.env;

aws.config.update({
  accessKeyId: REACT_APP_AWS_ACCESS_KEY,
  secretAccessKey: REACT_APP_AWS_SECRET_KEY,
  region: REACT_APP_AWS_REGION
});
const s3 = new aws.S3();

const Category: FC = () => {
  useCurrentPageTitleUpdater('SERVICES');
  const classes = useStyles();
  const [isLoadingData, setIsLoadingData] = useState<boolean>(false);
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [snackbarVariant, setSnackbarVariant] = useState<'success' | 'error'>('success');
  const [message, setMessage] = useState<string>('');
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [openCreateForm, setOpenCreateForm] = useState<boolean>(false);
  const [openEditForm, setOpenEditForm] = useState<boolean>(false);
  const [openBulkDelete, setOpenBulkDelete] = useState<boolean>(false);
  const [count, setCount] = useState<number>(0);
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [categories, setCategories] = useState<CategoryResponseModel[]>(dummyCategories);
  const [checked, setChecked] = useState<number[]>([]);
  const [query, setQuery] = useState<string>('');
  const [queryString, setQueryString] = useState<string>();
  const [order, setOrder] = useState<'asc' | 'desc'>('desc');
  const [orderBy, setOrderBy] = useState<string>('createdAt');

  /** Fetch Data */
  const fetchData = useCallback(async () => {
    const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();
    setIsLoadingData(true);

    const getQueryParams = () => {
      const params = new URLSearchParams();
      if (queryString) {
        params.append('q', queryString);
      }

      if (orderBy) {
        params.append('orderBy', orderBy);
      }

      if (order) {
        params.append('order', order.toUpperCase());
      }

      params.append('s', (currentPage * rowsPerPage).toString());
      params.append('l', rowsPerPage.toString());

      return params.toString();
    };

    try {
      const { data } = await axios.get(`${CATEGORY_BASE_URL}?${getQueryParams()}`, { cancelToken: cancelTokenSource.token });
      setCategories(data.Categories);
      setCount(data.count);
      setIsLoadingData(false);
    } catch (err) {
      console.error('err: ', err);
      setIsLoadingData(false);
    }
    return () => cancelTokenSource.cancel();
  }, [rowsPerPage, currentPage, queryString, order, orderBy]);

  const performActionAndRevertPage = (action: React.Dispatch<React.SetStateAction<any>>, actionParam: any) => {
    setCurrentPage(0);
    action(actionParam);
  };

  const handleSearch = useCallback((searchQuery: string) => {
    performActionAndRevertPage(setQueryString, searchQuery);
  }, []);

  const debouncedSearchTerm = useDebounce(query, 500);

  useEffect(() => {
    if (debouncedSearchTerm.length >= 3) {
      handleSearch(debouncedSearchTerm);
    } else if (debouncedSearchTerm.length === 0) {
      handleSearch(debouncedSearchTerm);
    }
  }, [debouncedSearchTerm, handleSearch]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleSnackBar = (open: boolean, variant: 'success' | 'error', message: string) => {
    setSnackbarVariant(variant);
    setOpenSnackbar(open);
    setOpenCreateForm(false);
    setOpenEditForm(false);
    setMessage(message);
  };

  const handleClickDeleteCategory = (index: number) => {
    categories.splice(index, 1);
    setCategories([...categories]);
    setCount(count => count - 1);
    handleSnackBar(true, 'success', 'Category has been deleted successfully.');
  };

  const handleBulkDelete = async () => {
    const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();
    try {
      await axios.post(BULK_DELETE_CATEGORY_URL, { id: checked }, { cancelToken: cancelTokenSource.token });

      await Promise.all([
        checked.map(async (id) => {
          const findFirst = categories.find(category => category.idCategory === id);

          if (findFirst && findFirst.imageKey) {
            const object = { Key: `assets/${findFirst.imageKey}` };
            const options = {
              Bucket: REACT_APP_S3_BUCKET_NAME!,
              Delete: { Objects: [object] }
            };

            await s3.deleteObjects(options, (err: any, data: any) => {
              if (err) {
                console.log(err, err.stack, data);
              }
            });
          }
        })
      ]);

      setChecked([]);
      fetchData();
      handleSnackBar(true, 'success', 'Category has been deleted successfully.');
    } catch (err) {
      console.log(err);
      handleSnackBar(true, 'error', 'The category has failed to delete.');
    }
    setOpenBulkDelete(false);
  };

  const renderDelete = () => {
    return (
      checked.length !== 0 && (
        <IconButton size='small'>
          <DeleteIcon className={classes.icon} onClick={() => setOpenBulkDelete(true)} />
        </IconButton>
      )
    );
  };

  return (
    <Fragment>
      <Grid container direction='row' justify='space-between'>
        <Grid container item lg={6} sm={6} md={6} justify='flex-start' alignItems='center'>
          <Grid item>
            <SearchInput
              withBorder
              withTransition={false}
              width={150}
              placeHolder='Search Category...'
              iconColor='#989898'
              tableSearchValue={query}
              setTableSearchValue={setQuery}
            />
          </Grid>
          <Grid item>{renderDelete()}</Grid>
        </Grid>

        <Grid container item lg={6} sm={6} md={6} justify='flex-end' alignItems='center'>
          <Grid item>
            <Button
              color='primary'
              size='medium'
              variant='contained'
              disabled={openEditForm}
              className={classes.addButton}
              onClick={event => setOpenCreateForm(!openCreateForm)}
            >
              Add New Category
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <Grid item lg={12} sm={12} xs={12} md={12}>
        <CategoryTable
          isLoadingData={isLoadingData}
          setIsLoadingData={setIsLoadingData}
          count={count}
          order={order}
          orderBy={orderBy}
          setOrder={setOrder}
          setOrderBy={setOrderBy}
          checked={checked}
          currentPage={currentPage}
          rowsPerPage={rowsPerPage}
          categories={categories}
          openCreateForm={openCreateForm}
          openEditForm={openEditForm}
          currentIndex={currentIndex}
          setCategories={setCategories}
          setCurrentIndex={setCurrentIndex}
          setOpenCreateForm={setOpenCreateForm}
          setOpenEditForm={setOpenEditForm}
          handleChangePage={(event, page) => setCurrentPage(page)}
          handleChangeRowsPerPage={event => performActionAndRevertPage(setRowsPerPage, +event.target.value)}
          handleSnackBar={handleSnackBar}
          handleClickDeleteCategory={handleClickDeleteCategory}
          setChecked={setChecked}
        />
      </Grid>

      <StandardConfirmationDialog
        variant={'danger'}
        titleMessage={'Delete'}
        message={'Are you sure want to delete this data?'}
        open={openBulkDelete}
        handleClose={() => setOpenBulkDelete(false)}
        onConfirm={handleBulkDelete}
      />

      <StandardConfirmationDialog
        variant={snackbarVariant}
        titleMessage={snackbarVariant.charAt(0).toUpperCase() + snackbarVariant.slice(1) + '!'}
        message={message}
        open={openSnackbar}
        handleClose={() => setOpenSnackbar(false)}
        onConfirm={() => setOpenSnackbar(false)}
        noCancelButton={true}
      />
    </Fragment>
  );
};

export default Category;
