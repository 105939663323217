import React, { FC, useState, useCallback, useEffect, useContext, useRef } from 'react';
import clsx from 'clsx';
import {
  Container,
  makeStyles,
  createStyles,
  Theme,
  Typography,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  TextField,
  RadioGroup,
  Radio,
  FormControl,
  FormLabel,
  FormControlLabel,
  Snackbar
} from '@material-ui/core';
import axios, { CancelTokenSource } from 'axios';
import useDebounce from 'hooks/useDebounce';
import useCurrentPageTitleUpdater from 'hooks/useCurrentPageTitleUpdater';
import InvoicesTable from './components/InvoicesTable';
import DetailInvoiceModal from './components/DetailInvoiceModal';
import CollectPaymentModal from './components/CollectPaymentModal';
import SearchInput from 'components/SearchInput';
import { StandardConfirmationDialog } from 'components/AppDialog';
import { CurrentJobTabContext } from 'contexts/CurrentJobTabContext';
import { Restore } from '@material-ui/icons';
import FilterListIcon from '@material-ui/icons/FilterList';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { CSVLink } from 'react-csv';
import { INVOICE_BASE_URL, BULK_PAID_INVOICE_URL } from 'constants/url';
import { format } from 'date-fns';
import GetAppIcon from '@material-ui/icons/GetApp';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4)
    },
    container: {
      '& > :nth-child(n+2)': {
        marginTop: theme.spacing(2)
      }
    },
    divider: {
      marginBottom: theme.spacing(4)
    },
    paper: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(2),
      margin: 'auto'
    },
    subMenuGrid: {
      borderRight: '1px solid #dcdcdc',
      maxWidth: theme.spacing(15)
    },
    content: {
      paddingLeft: theme.spacing(2),
      paddingTop: theme.spacing(0.2)
    },
    headerSubMenuTitleContainer: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      paddingLeft: theme.spacing(0)
    },
    headerPageTitleContainer: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(4),
      paddingLeft: theme.spacing(2)
    },
    contentContainer: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(0),
      paddingBottom: theme.spacing(2)
    }
  })
);

const dummyInvoices: InvoiceModel[] = [
  {
    id: 0,
    invoiceNumber: '',
    totalPrice: 0,
    paymentStatus: '',
    paymentType: '',
    cardNumber: '',
    cardExpired: '',
    cardName: '',
    createdAt: ''
  }
];

const InvoicesPage: FC = () => {
  useCurrentPageTitleUpdater('INVOICES');
  const classes = useStyles();
  const { setCurrentJobTab } = useContext(CurrentJobTabContext);
  setCurrentJobTab(1);

  const [isLoadingData, setIsLoadingData] = useState<boolean>(false);
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [openSnackbarAlert, setOpenSnackbarAlert] = useState<boolean>(false);
  const [snackbarVarient, setSnackbarVarient] = useState<'success' | 'error'>('success');
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  const [messageSuccess, setMessageSuccess] = useState<string>('');
  const [messageError, setMessageError] = useState<string>('');
  const [isReset, setReset] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [count, setCount] = useState<number>(0);
  const [query, setQuery] = useState<string>('');
  const [queryString, setQueryString] = useState<string>();
  const [filter, setFilter] = useState<string>('');
  const [order, setOrder] = useState<'asc' | 'desc'>('desc');
  const [orderBy, setOrderBy] = useState<string>('createdAt');
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [snackbarVariant, setSnackbarVariant] = useState<'success' | 'error'>('success');
  const [openFilter, setOpenFilter] = useState<null | HTMLElement>(null);
  const [currentDetailInvoiceIndex, setCurrentDetailInvoiceIndex] = useState<number>(-1);
  const [openDetailInvoice, setOpenDetailInvoice] = useState<boolean>(false);
  const [currentCollectIndex, setCurrentCollectIndex] = useState<number>(-1);
  const [openCollectPayment, setOpenCollectPayment] = useState<boolean>(false);

  const [invoices, setInvoices] = useState<InvoiceModel[]>(dummyInvoices);

  const [openConfirmationBulk, setOpenConfirmationBulk] = useState<boolean>(false);
  const [checked, setChecked] = useState<number[]>([]);
  const [csv, setCsv] = useState<CSVInvoiceModel[]>([]);
  const [csvDownload, setCsvDownload] = useState<boolean>(false);
  const csvInstance = useRef<any | null>(null);

  useEffect(() => {
    if (csvDownload && csv.length > 0 && csvInstance.current && csvInstance.current.link) {
      setTimeout(() => {
        csvInstance.current.link.click();
        setCsv([]);
        setCsvDownload(false);
      });
    }
  }, [csv, csvDownload]);

  const countChecked = checked.length;

  const updateIndividualInvoice = (updatedInvoiceProperties: Partial<InvoiceModel>, invoiceIndex?: number) => {
    let currentDetailIndex: number;
    if (invoiceIndex === undefined) {
      currentDetailIndex = currentDetailInvoiceIndex;
    } else {
      currentDetailIndex = invoiceIndex;
    }
    setInvoices(
      invoices!.map((invoice, index) => {
        if (index !== currentDetailIndex) {
          return invoice;
        }

        return Object.assign({}, invoice, updatedInvoiceProperties);
      })
    );
  };

  const handleOpenCollectPayment = (invoiceIndex: number): React.MouseEventHandler => () => {
    handleSnackBarServiceClose();
    setCurrentCollectIndex(invoiceIndex);
    setOpenCollectPayment(true);
  };

  const handleOpenDetailInvoice = (invoiceIndex: number): React.MouseEventHandler => () => {
    setCurrentDetailInvoiceIndex(invoiceIndex);
    setOpenDetailInvoice(true);
  };

  const handleCloseCollect = () => {
    setOpenCollectPayment(false);
    setCurrentCollectIndex(-1);
  };

  const handleCancelDetail = () => {
    fetchData();
    setOpenDetailInvoice(false);
    setCurrentDetailInvoiceIndex(-1);
  };

  const handleSnackBar = (open: boolean, variant: 'success' | 'error', message: string) => {
    setSnackbarVarient(variant);
    setOpenSnackbarAlert(open);
    setSnackbarMessage(message);
  };

  const handleSnackBarServiceClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbarAlert(false);
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleSetMessageSuccess = (message: string) => {
    setMessageSuccess(message);
  };

  const handleSetMessageError = (message: string) => {
    setMessageError(message);
  };

  const getQueryParams = (noLimit?: boolean) => {
    const params = new URLSearchParams();
    if (queryString) {
      params.append('q', queryString);
    }

    if (orderBy) {
      params.append('orderBy', orderBy);
    }

    if (order) {
      params.append('order', order.toUpperCase());
    }

    if (filter) {
      params.append('filter', filter);
      setReset(true);
    }

    if (startDate || endDate) {
      params.append('startDate', startDate !== null ? format(startDate, 'yyyy/MM/dd') : '');
      params.append('endDate', endDate !== null ? format(endDate, 'yyyy/MM/dd') : '');
      setReset(true);
    }

    if (!noLimit) {
      params.append('s', (currentPage * rowsPerPage).toString());
      params.append('l', rowsPerPage.toString());
    }

    return params.toString();
  };

  const fetchData = useCallback(async () => {
    const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();
    setIsLoadingData(true);

    try {
      const result = await axios.get(`${INVOICE_BASE_URL}?${getQueryParams()}`, { cancelToken: cancelTokenSource.token });
      setInvoices(result.data.invoices);
      setCount(result.data.count);
      setIsLoadingData(false);
    } catch (err) {
      console.error('err: ', err);
      setIsLoadingData(false);
    }

    return () => cancelTokenSource.cancel();
  }, [rowsPerPage, currentPage, queryString, order, orderBy, filter, endDate, startDate]);

  const performActionAndRevertPage = (action: React.Dispatch<React.SetStateAction<any>>, actionParam: any) => {
    setCurrentPage(0);
    action(actionParam);
  };

  const handleSearch = useCallback((searchQuery: string) => {
    performActionAndRevertPage(setQueryString, searchQuery);
  }, []);

  const debouncedSearchTerm = useDebounce(query, 500);

  const handleValueFilter = (value: string) => {
    setFilter(value);
  };

  const handleCloseConfirmationBulk = () => {
    setOpenConfirmationBulk(false);
  };

  const handleOpenConfirmationBulk = () => {
    setOpenConfirmationBulk(true);
  };

  const bulkPaidInvoice = async () => {
    setOpenConfirmationBulk(false);

    const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();

    try {
      await axios.post(
        `${BULK_PAID_INVOICE_URL}`,
        {
          id: checked
        },
        { cancelToken: cancelTokenSource.token }
      );
      setSnackbarVarient('success');
      setOpenSnackbar(true);
      handleSetMessageSuccess('Successfully to bulk delete service item');
      setChecked([]);
      fetchData();
    } catch (err) {
      console.log(err);
      setSnackbarVarient('error');
      setOpenSnackbar(true);
      handleSetMessageError('Failed to bulk delete service item');
    }
  };

  const handleClickCsv = async () => {
    const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();
    const { data } = await axios.get(`${INVOICE_BASE_URL}?${getQueryParams(true)}`, { cancelToken: cancelTokenSource.token });

    const csvData: CSVInvoiceModel[] = [];
    data.invoices.map((value: InvoiceModel) => {
      const { invoiceNumber, paymentStatus, totalPrice, createdAt, paymentType, Service } = value;
      let fullName = '-';
      let driverName = '-';

      if (Service) {
        fullName = Service.Customer!.firstName + ' ' + Service.Customer!.lastName;
      }

      if (Service && Service.Job && Service!.Job!.UserId) {
        driverName = Service!.Job!.User!.displayName;
      }

      csvData.push({
        invoiceNumber,
        invoiceDate: createdAt ? format(new Date(createdAt), 'dd/MM/yyyy') : '',
        customerName: fullName,
        driverName,
        totalPrice: parseFloat(String(totalPrice)).toFixed(2),
        paymentType,
        paymentStatus
      });
      return value;
    });

    setCsv(csvData);
    setCsvDownload(true);
  };

  const Alert = (props: AlertProps) => {
    return <MuiAlert elevation={6} variant='filled' {...props} />;
  };

  const handleResetFilter = () => {
    setFilter('');
    setReset(false);
    setStartDate(null);
    setEndDate(null);
  };

  useEffect(() => {
    if (debouncedSearchTerm.length >= 3) {
      handleSearch(debouncedSearchTerm);
    } else if (debouncedSearchTerm.length === 0) {
      handleSearch(debouncedSearchTerm);
    }
  }, [debouncedSearchTerm, handleSearch]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <Container maxWidth='lg' className={clsx(classes.root, classes.container)}>
      <Grid container direction='row' justify='space-between'>
        <Grid item xs={8} sm={8} md={8} lg={8} alignItems='center'>
          <Typography variant='h6' component='h6' gutterBottom>
            List of all invoices.
          </Typography>
        </Grid>
      </Grid>

      <Grid container direction='row' justify='space-between'>
        <Grid container lg={6} sm={6} md={6} justify='flex-start' alignItems='center'>
          <Grid item>
            <SearchInput
              withBorder
              withTransition={false}
              width={150}
              placeHolder='Search Invoice...'
              iconColor='#989898'
              tableSearchValue={query}
              setTableSearchValue={setQuery}
            />
          </Grid>
        </Grid>

        <Grid container lg={6} sm={6} md={6} justify='flex-end' alignItems='center'>
          <Grid item>
            <Tooltip title='Filter'>
              <IconButton area-label='Filter' aria-controls='filter-menu' aria-haspopup='true' onClick={event => setOpenFilter(event.currentTarget)}>
                <FilterListIcon />
              </IconButton>
            </Tooltip>

            <Menu id='filter' anchorEl={openFilter} keepMounted open={Boolean(openFilter)} onClose={event => setOpenFilter(null)}>
              <MenuItem>
                <FormControl component='div'>
                  <FormLabel component='label'>Payment Status</FormLabel>
                  <RadioGroup
                    row
                    name='status-active'
                    aria-label='status-active'
                    value={filter}
                    onChange={event => setFilter((event.currentTarget as HTMLInputElement).value)}
                  >
                    <FormControlLabel value='PAID' control={<Radio size='small' />} label='Paid' />
                    <FormControlLabel value='UNPAID' control={<Radio size='small' />} label='Unpaid' />
                    <FormControlLabel value='CANCELLED' control={<Radio size='small' />} label='Cancelled' />
                  </RadioGroup>
                </FormControl>
              </MenuItem>
              <MenuItem>
                <Grid container direction='row' spacing={1}>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <TextField
                      id='startDate'
                      fullWidth
                      variant='outlined'
                      margin='dense'
                      label='Start Date'
                      type='date'
                      value={startDate && format(startDate, 'yyyy-MM-dd')}
                      onChange={event => setStartDate(new Date(event.target.value))}
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  </Grid>

                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <TextField
                      id='endDate'
                      fullWidth
                      variant='outlined'
                      margin='dense'
                      label='End Date'
                      type='date'
                      value={endDate && format(endDate, 'yyyy-MM-dd')}
                      onChange={event => setEndDate(new Date(event.target.value))}
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  </Grid>
                </Grid>
              </MenuItem>
            </Menu>

            <Tooltip title='Export CSV'>
              {csv.length > 0 ? (
                <CSVLink
                  headers={[
                    { label: 'Invoice Number', key: 'invoiceNumber' },
                    { label: 'Invoice Date', key: 'invoiceDate' },
                    { label: 'Customer', key: 'customerName' },
                    { label: 'Driver', key: 'driverName' },
                    { label: 'Amount', key: 'totalPrice' },
                    { label: 'Payment Method', key: 'paymentType' },
                    { label: 'Payment Status', key: 'paymentStatus' }
                  ]}
                  filename={`Invoices-${format(new Date(), 'd-m-Y')}.csv`}
                  asyncOnClick={true}
                  onClick={(event: any) => {
                    setCsvDownload(true);
                    return false;
                  }}
                  ref={csvInstance}
                  data={csv}
                >
                  <IconButton>
                    <GetAppIcon />
                  </IconButton>
                </CSVLink>
              ) : (
                <IconButton onClick={handleClickCsv}>
                  <GetAppIcon />
                </IconButton>
              )}
            </Tooltip>

            {isReset && (
              <Tooltip title='Reset Filter'>
                <IconButton area-label='reset' onClick={handleResetFilter}>
                  <Restore />
                </IconButton>
              </Tooltip>
            )}
          </Grid>
        </Grid>
      </Grid>

      <Grid container direction='row'>
        <InvoicesTable
          isLoadingData={isLoadingData}
          count={count}
          checked={checked}
          setChecked={setChecked}
          order={order}
          orderBy={orderBy}
          setOrder={setOrder}
          setOrderBy={setOrderBy}
          updateIndividualInvoice={updateIndividualInvoice}
          setOpenSnackbar={setOpenSnackbar}
          setSnackbarVarient={setSnackbarVarient}
          handleSetMessageSuccess={handleSetMessageSuccess}
          handleSetMessageError={handleSetMessageError}
          currentPage={currentPage}
          rowsPerPage={rowsPerPage}
          invoices={invoices}
          handleOpenCollectPayment={handleOpenCollectPayment}
          handleOpenDetailInvoice={handleOpenDetailInvoice}
          handleChangePage={(event, page) => setCurrentPage(page)}
          handleChangeRowsPerPage={event => performActionAndRevertPage(setRowsPerPage, +event.target.value)}
        />
      </Grid>

      <DetailInvoiceModal
        invoice={invoices[currentDetailInvoiceIndex]}
        open={openDetailInvoice}
        handleCancel={handleCancelDetail}
        setOpenSnackbar={setOpenSnackbar}
        setSnackbarVarient={setSnackbarVarient}
        handleSetMessageSuccess={handleSetMessageSuccess}
        handleSetMessageError={handleSetMessageError}
        handleSnackBar={handleSnackBar}
      />

      <CollectPaymentModal
        index={currentCollectIndex}
        invoice={invoices[currentCollectIndex]}
        updateIndividualInvoice={updateIndividualInvoice}
        handleClose={handleCloseCollect}
        handleSnackBar={handleSnackBar}
        open={openCollectPayment}
      />

      <StandardConfirmationDialog
        variant={'success'}
        titleMessage={'Paid'}
        message={'Are you sure want to set paid this data?'}
        open={openConfirmationBulk}
        handleClose={handleCloseConfirmationBulk}
        onConfirm={bulkPaidInvoice}
      />

      <StandardConfirmationDialog
        variant={snackbarVarient}
        titleMessage={snackbarVarient === 'success' ? 'Success!' : 'Error!'}
        message={snackbarVarient === 'success' ? messageSuccess : messageError}
        open={openSnackbar}
        handleClose={handleCloseSnackbar}
        onConfirm={handleCloseSnackbar}
        noCancelButton={true}
      />

      <Snackbar
        autoHideDuration={4000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={openSnackbarAlert}
        onClose={handleSnackBarServiceClose}
      >
        <Alert severity={snackbarVarient} onClose={handleSnackBarServiceClose}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default InvoicesPage;
