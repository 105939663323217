import React, { useEffect, useCallback, useState } from 'react';
import { TextField, Theme, TableRow, IconButton, CircularProgress, Tooltip, makeStyles, createStyles, FormControlLabel } from '@material-ui/core';
import BodyCell from 'components/BodyCell';
import { AntSwitch } from 'components/CustomSwitch';
import { MuiPickersUtilsProvider, MaterialUiPickersDate, KeyboardDateTimePicker } from '@material-ui/pickers';

import SaveIcon from '@material-ui/icons/Save';
import RevertIcon from '@material-ui/icons/NotInterestedOutlined';
import DateFnsUtils from '@date-io/date-fns';
import { format } from 'date-fns';

interface Props {
  startDate: Date | null;
  endDate: Date | null;
  startTime: Date | null;
  endTime: Date | null;
  isHoliday: boolean | false;
  isActive: boolean | false;
  isSubmitting: boolean;
  index: number;
  isStartDateError: boolean;
  isEndDateError: boolean;
  isStartTimeError: boolean;
  isEndTimeError: boolean;
  startDateMessage: string;
  endDateMessage: string;
  startTimeMessage: string;
  endTimeMessage: string;
  setStartDate: React.Dispatch<React.SetStateAction<Date | null>>;
  setEndDate: React.Dispatch<React.SetStateAction<Date | null>>;
  setStartTime: React.Dispatch<React.SetStateAction<Date | null>>;
  setEndTime: React.Dispatch<React.SetStateAction<Date | null>>;
  setIsHoliday: React.Dispatch<React.SetStateAction<boolean>>;
  setIsActive: React.Dispatch<React.SetStateAction<boolean>>;
  setStartDateError: React.Dispatch<React.SetStateAction<boolean>>;
  setEndDateError: React.Dispatch<React.SetStateAction<boolean>>;
  setStartDateMessage: React.Dispatch<React.SetStateAction<string>>;
  setEndDateMessage: React.Dispatch<React.SetStateAction<string>>;
  onSubmit: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onCancel: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonContainer: {
      display: 'flex'
    },
    textFieldActive: {
      color: '#000000',
      background: '#FFFFFF'
    },
    textFieldDisabled: {
      color: '#000000',
      background: '#F9F9FB'
    },
    label: {
      '& .MuiFormLabel-root': {
        color: '#000000'
      },
      '&$focusedLabel': {
        color: '#000000'
      },
      '&$disabledLabel': {
        color: '#000000'
      }
    },
    focusedLabel: {},
    disabledLabel: {}
  })
);

const BlockedDateFormField: React.FC<Props> = (props: Props) => {
  const classes = useStyles();

  /** PROPS */
  const {
    startDate,
    endDate,
    startTime,
    endTime,
    isHoliday,
    isActive,
    isSubmitting,
    index,
    isStartDateError,
    isEndDateError,
    isStartTimeError,
    isEndTimeError,
    startDateMessage,
    endDateMessage,
    startTimeMessage,
    endTimeMessage,
    setStartDate,
    setEndDate,
    setStartTime,
    setEndTime,
    setIsHoliday,
    setIsActive,
    setStartDateError,
    setEndDateError,
    setStartDateMessage,
    setEndDateMessage,
    onSubmit,
    onCancel
  } = props;

  const [currentIndex, setCurrentIndex] = useState<number>(-1);
  const defaultStartTime = '00:00';
  const defaultEndTime = '23:00';

  const handleStartDateChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setStartDate(new Date(event.target.value));
  };

  const handleEndDateChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setEndDate(new Date(event.target.value));
  };

  const handleStartTime = (date: MaterialUiPickersDate) => {
    setStartTime(date);
  };

  const handleEndTime = (date: MaterialUiPickersDate) => {
    setEndTime(date);
  };

  const handleStartDate = (date: MaterialUiPickersDate | null, value?: string | null) => {
    if (date) {
      const currentDate = new Date(date);
      currentDate.setMinutes(0);

      setStartDate(date);
      setStartTime(currentDate);
    }

    setStartDateError(false);
    setStartDateMessage('');
    if (date && endDate) {
      const end = new Date(endDate);
      if (date > end) {
        setStartDateError(true);
        setStartDateMessage('Start Date can not be later than end date');
      } else {
        setEndDateError(false);
        setEndDateMessage('');
      }

      if (isHoliday === true) {
        const defaultStartDate = format(date ? new Date(date) : new Date(), 'yyyy-MM-dd');
        setStartTime(new Date(`${defaultStartDate} ${defaultStartTime}`));
      }
    }
  };

  const handleEndDate = (date: MaterialUiPickersDate | null, value?: string | null) => {
    if (date) {
      const currentDate = new Date(date);
      currentDate.setMinutes(0);

      setEndDate(date);
      setEndTime(currentDate);
    }

    setEndDateError(false);
    setEndDateMessage('');
    if (date && startDate) {
      const start = new Date(startDate);
      if (date < start) {
        setEndDateError(true);
        setEndDateMessage('End Date can not be earlier than start date');
      } else {
        setStartDateError(false);
        setStartDateMessage('');
      }

      if (isHoliday === true) {
        setDefaultTimeHoliday();
      }
    }
  };

  const handleStatusFormChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsActive(!isActive);
  };

  const handleHolidayFormChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsHoliday(!isHoliday);

    if (!isHoliday === true) {
      setDefaultTimeHoliday();
    }
  };

  const setDefaultTimeHoliday = () => {
    if (startDate) {
      const defaultStartDate = format(startDate ? new Date(startDate) : new Date(), 'yyyy-MM-dd');
      setStartTime(new Date(`${defaultStartDate} ${defaultStartTime}`));
    }

    if (endDate) {
      const defaultEndDate = format(endDate ? new Date(endDate) : new Date(), 'yyyy-MM-dd');
      setEndTime(new Date(`${defaultEndDate} ${defaultEndTime}`));
    }
  };

  useEffect(useCallback(() => setCurrentIndex(isSubmitting ? index : -1), [index, isSubmitting]), [index, isSubmitting]);

  const TextFieldComponentDateTime = (props: any) => {
    return <TextField {...props} readonly={true} placeholder='Please click button to select date time' />;
  };

  return (
    <TableRow>
      <BodyCell isComponent={true}>{isSubmitting && index === currentIndex && <CircularProgress size={20} />}</BodyCell>

      <BodyCell isComponent={true}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDateTimePicker
            autoOk
            ampm={false}
            views={['date', 'hours']}
            minutesStep={60}
            label='Start Date'
            value={startDate && startTime ? `${format(new Date(startDate), 'yyyy-MM-dd')} ${format(new Date(startTime), 'HH:mm')}` : null}
            onChange={handleStartDate}
            format='dd/MM/yyyy HH:00'
            inputVariant='outlined'
            margin='dense'
            TextFieldComponent={TextFieldComponentDateTime}
            disablePast={true}
            fullWidth
            InputProps={{
              classes: {
                root: classes.textFieldActive,
                disabled: classes.textFieldActive
              }
            }}
            InputLabelProps={{
              classes: {
                root: classes.label,
                disabled: classes.focusedLabel,
                focused: classes.disabledLabel
              }
            }}
            error={isStartDateError}
            helperText={startDateMessage}
          />
        </MuiPickersUtilsProvider>
      </BodyCell>

      <BodyCell isComponent={true}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDateTimePicker
            autoOk
            ampm={false}
            views={['date', 'hours']}
            minutesStep={60}
            label='End Date'
            value={endDate && endTime ? `${format(new Date(endDate), 'yyyy-MM-dd')} ${format(new Date(endTime), 'HH:mm')}` : null}
            onChange={handleEndDate}
            format='dd/MM/yyyy HH:00'
            inputVariant='outlined'
            margin='dense'
            TextFieldComponent={TextFieldComponentDateTime}
            disablePast={true}
            fullWidth
            InputProps={{
              classes: {
                root: classes.textFieldActive,
                disabled: classes.textFieldActive
              }
            }}
            InputLabelProps={{
              classes: {
                root: classes.label,
                disabled: classes.focusedLabel,
                focused: classes.disabledLabel
              }
            }}
            error={isEndDateError}
            helperText={endDateMessage}
          />
        </MuiPickersUtilsProvider>
      </BodyCell>

      <BodyCell isComponent={true}>
        <FormControlLabel
          control={<AntSwitch checked={isHoliday ? true : false} onChange={handleHolidayFormChange} color='primary' />}
          label={isHoliday ? 'Yes' : 'No'}
        />
      </BodyCell>

      <BodyCell isComponent={true}>
        <FormControlLabel
          control={<AntSwitch checked={isActive ? true : false} onChange={handleStatusFormChange} color='primary' />}
          label={isActive ? 'Active' : 'Inactive'}
        />
      </BodyCell>

      <BodyCell cellWidth='10%'>
        <div className={classes.buttonContainer}>
          <Tooltip title={'Save'}>
            <IconButton disabled={isSubmitting || isStartDateError || isEndDateError} aria-label='done' onClick={onSubmit}>
              <SaveIcon color='primary' fontSize='small' />
            </IconButton>
          </Tooltip>
          <Tooltip title={'Cancel'}>
            <IconButton aria-label='revert' onClick={onCancel}>
              <RevertIcon color='disabled' fontSize='small' />
            </IconButton>
          </Tooltip>
        </div>
      </BodyCell>
    </TableRow>
  );
};

export default BlockedDateFormField;
