import React, { FC, useState, useEffect, useCallback } from 'react';
import clsx from 'clsx';
import { green } from '@material-ui/core/colors';
import axios, { CancelTokenSource } from 'axios';

import useDebounce from 'hooks/useDebounce';
import useCurrentPageTitleUpdater from 'hooks/useCurrentPageTitleUpdater';
import { Container, makeStyles, Theme } from '@material-ui/core';
import AppLogsTable from './components/AppLogsTable';
import { APPLOG_BASE_URL } from 'constants/url';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingBottom: theme.spacing(4),
    paddingLeft: 0,
    paddingRight: 0
  },
  contentContainer: {
    '& > :nth-child(n+2)': {
      marginTop: theme.spacing(2)
    }
  },
  tableRoot: {
    width: '100%',
    overflowX: 'auto'
  },
  success: {
    backgroundColor: green[600]
  },
  error: {
    backgroundColor: theme.palette.error.dark
  },
  addButton: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1)
  },
  extendedIcon: {
    paddingRight: theme.spacing(1)
  }
}));

const AppLogsPage: FC = () => {
  useCurrentPageTitleUpdater('APPLICATION LOGS');

  const classes = useStyles();

  const [query, setQuery] = useState<string>('');
  const [queryString, setQueryString] = useState<string>();
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(5);
  const [isSearchingLog, setSearchingLog] = useState<boolean>(false);
  const [isSearchLogError, setSearchLogError] = useState<boolean>(false);
  const [appLogs, setAppLogs] = useState<AppLogModel[]>([]);
  const [count, setCount] = useState<number>(0);

  // Loading all the roles to populate the tabs once

  // Search Log whenever rowsPerPage, currentPage, queryString
  useEffect(() => {
    const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();

    const getQueryParams = () => {
      const params = new URLSearchParams();
      if (queryString) {
        params.append('q', queryString);
      }

      params.append('s', (currentPage * rowsPerPage).toString());
      params.append('l', rowsPerPage.toString());

      return params.toString();
    };

    const searchLog = async () => {
      setSearchingLog(true);
      setSearchLogError(false);

      try {
        const url = `${APPLOG_BASE_URL}?${getQueryParams()}`;
        const { data } = await axios.get(url, { cancelToken: cancelTokenSource.token });
        setCount(data.count);
        setAppLogs(data.appLogs);
      } catch (err) {
        setSearchLogError(true);
      }

      setSearchingLog(false);
    };

    searchLog();

    return () => {
      cancelTokenSource.cancel();
    };
  }, [rowsPerPage, currentPage, queryString]);

  const performActionAndRevertPage = (action: React.Dispatch<React.SetStateAction<any>>, actionParam: any) => {
    setCurrentPage(0);
    action(actionParam);
  };

  const handleSearch = useCallback((searchQuery: string) => {
    performActionAndRevertPage(setQueryString, searchQuery);
  }, []);

  const debouncedSearchTerm = useDebounce(query, 500);
  // Load client data to populate on search list
  useEffect(() => {
    if (debouncedSearchTerm.length >= 3) {
      handleSearch(debouncedSearchTerm);
    } else if (debouncedSearchTerm.length === 0) {
      handleSearch(debouncedSearchTerm);
    }
  }, [debouncedSearchTerm, handleSearch]);

  // Should not render until role response came back
  return (
    <Container maxWidth='lg' className={clsx(classes.root, classes.contentContainer)}>
      <AppLogsTable
        isLoadingData={isSearchingLog}
        logs={appLogs}
        count={count}
        currentPage={currentPage}
        rowsPerPage={rowsPerPage}
        handleChangePage={(event, page) => setCurrentPage(page)}
        handleChangeRowsPerPage={event => performActionAndRevertPage(setRowsPerPage, +event.target.value)}
      />
    </Container>
  );
};

export default AppLogsPage;
