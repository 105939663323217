import React, { FC, Fragment, useState, useEffect } from 'react';
import { TextField, Grid, Button, IconButton, Tooltip, Checkbox, FormControlLabel, InputAdornment, Typography } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import RandomPassword from '../../../../../typings/RandomPassword';
import { isStrongPassword } from '../../../../../utils';

interface Props {
  firstName: string;
  lastName: string;
  email: string;
  contactNumber: string;
  contactPerson: ContactPersonModel[];
  isSame: boolean;
  password: string;
  confirmPassword: string;
  contactPersonError: Error[];
  customerError: Error;
  setCustomerError: React.Dispatch<React.SetStateAction<Error>>;
  setPassword: React.Dispatch<React.SetStateAction<string>>;
  setConfirmPassword: React.Dispatch<React.SetStateAction<string>>;
  setIsSame: React.Dispatch<React.SetStateAction<boolean>>;
  setValidThirdStep: React.Dispatch<React.SetStateAction<boolean>>;
  handleContactPerson: (type: string, index?: number, payload?: ContactPersonModel) => void;
  handleThirdStepValid: () => void;
}

interface ErrorHandling {
  error: boolean;
  message: string;
}

interface Error {
  [keys: string]: ErrorHandling;
}

const StepContact: FC<Props> = props => {
  const {
    contactPerson,
    password,
    confirmPassword,
    contactPersonError,
    customerError,
    firstName,
    lastName,
    email,
    contactNumber,
    isSame,
    handleContactPerson,
    setPassword,
    setConfirmPassword,
    setIsSame,
    setCustomerError,
    setValidThirdStep,
    handleThirdStepValid
  } = props;

  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);

  const handleChecked = () => {
    if (isSame === false) {
      handleContactPerson('SET_VALUE', 0, { id: 0, name: `${firstName} ${lastName}`, contactEmail: email, contactNumber: contactNumber });
    } else {
      handleContactPerson('SET_VALUE', 0, { id: 0, name: '', contactEmail: '', contactNumber: '' });
    }
    setIsSame(!isSame);
  };

  const generatePassword = () => {
    let pwd = new RandomPassword()
      .setLength(8)
      .setLowerCase(true)
      .setUpperCase(true)
      .setNumberCase(true)
      .setSymbol(true)
      .generate();

    setPassword(pwd);
    setConfirmPassword(pwd);
    setValidThirdStep(true);
    setCustomerError({ 
      password: { error: false, message: '' },
      confirmPassword: { error: false, message: '' }
    });
  };

  const handlePasswordValidate = (): boolean => {
    let resultHandler = true;
    if (password) {
      setCustomerError({ ...customerError, password: { error: false, message: '' } });
      resultHandler = false;

      if (password.length < 8) {
        setCustomerError({ ...customerError, password: { error: true, message: 'Please enter password at least 8 characters' } });
        resultHandler = false;
      } else if (!isStrongPassword(password)) {
        setCustomerError({
          ...customerError,
          password: { error: true, message: 'Please enter password at least containing 1 uppercase letter, 1 number and 1 symbol' }
        });
        resultHandler = false;
      }
    } else {
      setCustomerError({ ...customerError, password: { error: true, message: 'Password can not be empty.' } });
      resultHandler = false;
    }

    handleThirdStepValid();
    return resultHandler;
  };

  const handleConfirmPasswordValidate = (): boolean => {
    let resultHandler = true;

    if (confirmPassword) {
      setCustomerError({ ...customerError, confirmPassword: { error: false, message: '' } });
      resultHandler = false;

      if (confirmPassword !== password) {
        setCustomerError({ ...customerError, confirmPassword: { error: true, message: 'Confirm Password not match.' } });
        resultHandler = false;
      }
    } else {
      setCustomerError({ ...customerError, confirmPassword: { error: true, message: 'Confirm Password can not be empty.' } });
      resultHandler = false;
    }

    handleThirdStepValid();
    return resultHandler;
  };

  return (
    <Fragment>
      <Grid container spacing={1} item lg={12} md={12} sm={12} xs={12}>
        <Grid item component='div' lg={6} md={6} sm={6} xs={6}>
          <TextField
            id='password'
            label='Password'
            placeholder='******'
            variant='outlined'
            margin='dense'
            fullWidth
            required
            value={password}
            type={showPassword ? 'text' : 'password'}
            error={customerError.password.error}
            helperText={customerError.password.message}
            autoComplete='on'
            onChange={event => setPassword(event.target.value)}
            onBlur={handlePasswordValidate}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton edge='end' aria-label='toggle password visibility' onClick={event => setShowPassword(!showPassword)}>
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
          <Button variant='text' size='small' onClick={generatePassword}>
            <Typography gutterBottom> Generate Password</Typography>
          </Button>
        </Grid>

        <Grid item component='div' lg={6} md={6} sm={6} xs={6}>
          <TextField
            id='confirmPassword'
            label='Confirm Password'
            placeholder='******'
            variant='outlined'
            margin='dense'
            fullWidth
            required
            value={confirmPassword}
            type={showConfirmPassword ? 'text' : 'password'}
            error={customerError.confirmPassword.error}
            helperText={customerError.confirmPassword.message}
            autoComplete='on'
            onChange={event => setConfirmPassword(event.target.value)}
            onBlur={handleConfirmPasswordValidate}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton edge='end' aria-label='toggle password visibility' onClick={event => setShowConfirmPassword(!showConfirmPassword)}>
                    {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default StepContact;
