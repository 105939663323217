enum StatusType {
  UNASSIGNED = 'UNASSIGNED',
  ASSIGNED_FOR_COLLECTION = 'ASSIGNED_FOR_COLLECTION',
  COLLECTING = 'COLLECTING',
  COLLECTED = 'COLLECTED',
  CHECKING = 'CHECKING',
  PACKING = 'PACKING',
  ASSIGNED_FOR_DELIVERY = 'ASSIGNED_FOR_DELIVERY',
  DELIVERING = 'DELIVERING',
  DELIVERED = 'DELIVERED',
  CANCELLED = 'CANCELLED'
}

export default StatusType;
