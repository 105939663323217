import React, { FC, ComponentType } from 'react';
import { SvgIconProps } from '@material-ui/core/SvgIcon';
import { ListItem, ListItemIcon, ListItemText, Tooltip, Typography, Box } from '@material-ui/core';

import useRouter from 'hooks/useRouter';

interface Props {
  Icon: ComponentType<SvgIconProps>;
  path: string;
  label: string;
  menuActive: string;
  setMenuActive: React.Dispatch<React.SetStateAction<string>>;
}

const DrawerItem: FC<Props> = props => {
  const { history } = useRouter();
  const { Icon, path, label, menuActive, setMenuActive } = props;

  const onClickHandler: React.MouseEventHandler = event => {
    event.preventDefault();
    setMenuActive(label);
    history.push(path);
  };

  return (
    <Tooltip title={label} placement='right'>
      <ListItem button onClick={onClickHandler}>
        <ListItemIcon>
          <Icon color={menuActive === label ? 'primary' : 'inherit'} />
        </ListItemIcon>
        <ListItemText
          primary={
            <Typography style={{ fontSize: '14px', fontWeight: 'bold' }} color={menuActive === label ? 'primary' : 'initial'}>
              {label}
            </Typography>
          }
        />
      </ListItem>
    </Tooltip>
  );
};

export default DrawerItem;
