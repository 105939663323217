import 'date-fns';
import React, { Fragment, useEffect, useState } from 'react';
import { Button, makeStyles, MenuItem, TableRow, TextField, Theme, Tooltip, Switch, withStyles, IconButton } from '@material-ui/core';

import NumberFormat from 'react-number-format';
import BodyCell from 'components/BodyCell';
import NumberFormatCustom from 'components/NumberFormatCustom';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { CancelRounded, CheckBoxRounded } from '@material-ui/icons';
import { green } from '@material-ui/core/colors';
import { CategoryDummy } from 'constants/DummyData';

interface Props {
  categories: CategoryResponseModel[];
  categoryId: number;
  categoryType: string;
  serviceItemTemplateId: number;
  serviceTypes: Select[];
  serviceItemTemplates: ServiceItemTemplatesModel[];
  quantity: number;
  unitPrice: number;
  totalPrice: number;
  lengthSqFt: number;
  widthSqFt: number;
  serviceTypeCode: string;
  inputValueCategory: string;
  inputValueServiceType: string;
  inputValueServiceItem: string;

  setCategoryId: React.Dispatch<React.SetStateAction<number>>;
  setCategoryType: React.Dispatch<React.SetStateAction<string>>;
  setServiceItemTemplateId: React.Dispatch<React.SetStateAction<number>>;
  setQuantity: React.Dispatch<React.SetStateAction<number>>;
  setUnitPrice: React.Dispatch<React.SetStateAction<number>>;
  setTotalPrice: React.Dispatch<React.SetStateAction<number>>;
  setLengthSqFt: React.Dispatch<React.SetStateAction<number>>;
  setWidthSqFt: React.Dispatch<React.SetStateAction<number>>;
  setServiceTypeCode: React.Dispatch<React.SetStateAction<string>>;
  setInputValueCategory: React.Dispatch<React.SetStateAction<string>>;
  setInputValueServiceType: React.Dispatch<React.SetStateAction<string>>;
  setInputValueServiceItem: React.Dispatch<React.SetStateAction<string>>;

  onSubmit: React.FormEventHandler;
  onCancel: React.FormEventHandler;
  onReset: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  serviceItemTemplateForm: {
    height: 64
  },
  textFieldFont: {
    fontSize: '13px',
    height: 18
  },
  buttonContainer: {
    display: 'flex'
  },
  cancelButton: {
    marginRight: theme.spacing(1)
  }
}));

const CreateEditAdditionalItemForm: React.FC<Props> = props => {
  const classes = useStyles(props);

  const { categories, serviceTypes, onSubmit, onCancel, onReset, serviceItemTemplates } = props;

  const { categoryId, setCategoryId } = props;
  const { categoryType, setCategoryType } = props;
  const { serviceItemTemplateId, setServiceItemTemplateId } = props;
  const { quantity, setQuantity } = props;
  const { totalPrice, setTotalPrice } = props;
  const { unitPrice, setUnitPrice } = props;
  const { lengthSqFt, setLengthSqFt } = props;
  const { widthSqFt, setWidthSqFt } = props;
  const { serviceTypeCode, setServiceTypeCode } = props;

  const { inputValueCategory, setInputValueCategory } = props;
  const { inputValueServiceType, setInputValueServiceType } = props;
  const { inputValueServiceItem, setInputValueServiceItem } = props;

  const [isNormal, setIsNormal] = useState<boolean>(true);
  const [isNAItem, setNAItem] = useState<boolean>(false);
  const [optionServiceItemTemplates, setOptionServiceItemTemplates] = useState<ServiceItemTemplatesModel[]>(serviceItemTemplates);

  useEffect(() => {
    if (!categoryId && !serviceTypeCode) {
      return;
    }

    if (categoryType === 'LA_DC') {
      const filteredTemplate = serviceItemTemplates.filter(value => {
        if (serviceTypeCode == 'LA' && value.priceLA) {
          return value;
        } else if (serviceTypeCode == 'DC' && value.priceDC) {
          return value;
        }
      });

      setOptionServiceItemTemplates(filteredTemplate);
    }

  }, [categoryId, serviceTypeCode]);

  useEffect(() => {
    if (
      serviceItemTemplateId &&
      serviceItemTemplates.filter(value => value.id === serviceItemTemplateId).length &&
      serviceItemTemplates.filter(value => value.id === serviceItemTemplateId)[0].type === 'MEASURE_ON_SITE'
    ) {
      
      setIsNormal(false);
      if (serviceItemTemplates.filter(value => value.id === serviceItemTemplateId)[0].unit === 'KG') {
        setIsNormal(true);
      }

      if (serviceItemTemplates.filter(value => value.id === serviceItemTemplateId)[0].unit === 'NA') {
        setNAItem(true);
      } else {
        setNAItem(false);
      }
    } else {
      setIsNormal(true);
      setNAItem(false);
    }
  }, [serviceItemTemplateId]);

  return (
    <TableRow className={classes.serviceItemTemplateForm}>
      <BodyCell cellWidth='5%' pL='10px' pR='10px' isComponent={true}>
        <Autocomplete
          id='serviceCategory'
          size='small'
          options={categories}
          autoHighlight={true}
          value={categories.filter(value => value.idCategory === categoryId).length ? categories.filter(value => value.idCategory === categoryId)[0] : CategoryDummy}
          onChange={(event, value) => {
            if (value) {
              setCategoryType(value.typeCategory);
              setCategoryId(value.idCategory);
              return;
            }
            onReset();
          }}
          getOptionLabel={option => option.nameCategory}
          renderInput={params => (
            <TextField
              {...params}
              label='Category*'
              margin='dense'
              variant='outlined'
              placeholder='Item'
              InputLabelProps={{
                className: classes.textFieldFont
              }}
            />
          )}
          renderOption={option => <React.Fragment>{option.nameCategory}</React.Fragment>}
        />
      </BodyCell>
      <BodyCell cellWidth='20%' pL='10px' pR='10px' isComponent={true}>
        <Autocomplete
          id='laundryType'
          size='small'
          options={serviceTypes}
          autoHighlight={true}
          value={serviceTypes.filter(value => value.id === serviceTypeCode).length ? serviceTypes.filter(value => value.id === serviceTypeCode)[0] : {id: '', name: ''}}
          inputValue={inputValueServiceType}
          onInputChange={(event, newInputValue) => {
            return setInputValueServiceType(newInputValue);
          }}
          onChange={(event, value) => {
            if (value) {
              setServiceTypeCode(String(value.id));
            }

            setInputValueServiceItem('');
            setServiceItemTemplateId(0);
            setUnitPrice(0);
            setQuantity(0);
            setTotalPrice(0);
            setLengthSqFt(0);
            setWidthSqFt(0);
          }}
          getOptionLabel={option => option.name}
          renderInput={params => (
            <TextField
              {...params}
              label='Laundry Type*'
              margin='dense'
              variant='outlined'
              placeholder='Item'
              InputLabelProps={{
                className: classes.textFieldFont
              }}
            />
          )}
          renderOption={option => <React.Fragment>{option.name}</React.Fragment>}
        />
      </BodyCell>
      <BodyCell cellWidth='15%' pL='10px' pR='10px' isComponent={true}>
        <Autocomplete
          id='item'
          size='small'
          options={optionServiceItemTemplates}
          autoHighlight={true}
          inputValue={inputValueServiceItem}
          getOptionSelected={value => {
            if (value.id === serviceItemTemplateId) {
              return true;
            }

            return false;
          }}
          onInputChange={(event, newInputValue) => {
            return setInputValueServiceItem(newInputValue);
          }}
          onChange={(event, value) => {
            if (value) {
              setServiceItemTemplateId(value.id);
              setUnitPrice(value.priceDC ? value.priceDC : 0);
              if (serviceTypeCode == 'LA' || categoryType == 'OTHER_SERVICE') {
                setUnitPrice(value.priceLA ? value.priceLA : 0);
              }
            }
          }}
          getOptionLabel={option => option.name}
          renderInput={params => (
            <TextField
              {...params}
              label='Item*'
              margin='dense'
              variant='outlined'
              placeholder='Item'
              InputLabelProps={{
                className: classes.textFieldFont
              }}
            />
          )}
          renderOption={option => <React.Fragment>{option.name}</React.Fragment>}
        />
      </BodyCell>
      <BodyCell cellWidth='15%' pL='10px' pR='10px' isComponent={true}>
        {isNAItem ? (
          <NumberFormat value={0} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} fixedDecimalScale={true} />
        ) : isNormal ? (
          <TextField
            margin='dense'
            fullWidth
            id='qty'
            label='Quantity'
            value={quantity}
            onChange={event => {
              setQuantity(+event.target.value);
              setTotalPrice(unitPrice * +event.target.value);
            }}
            variant='outlined'
            autoComplete='off'
            InputProps={{
              classes: {
                input: classes.textFieldFont
              },
              inputComponent: NumberFormatCustom as any,
              inputProps: {
                thousandSeparator: true,
                decimalScale: 0,
                fixedDecimalScale: true
              }
            }}
            InputLabelProps={{
              className: classes.textFieldFont
            }}
          />
        ) : (
          <Fragment>
            <TextField
              margin='dense'
              fullWidth
              variant='outlined'
              id='length'
              label='length'
              autoComplete='off'
              value={lengthSqFt}
              onChange={event => {
                setLengthSqFt(+event.target.value);
                const newTotalUnitPrice: number = Number(+event.target.value * (widthSqFt > 0 ? widthSqFt : 1)) * unitPrice;
                setQuantity(Number(+event.target.value * (widthSqFt > 0 ? widthSqFt : 1)));
                setTotalPrice(newTotalUnitPrice);
              }}
              InputProps={{
                classes: {
                  input: classes.textFieldFont
                },
                inputComponent: NumberFormatCustom as any,
                inputProps: {
                  thousandSeparator: true,
                  decimalScale: 2,
                  fixedDecimalScale: true
                }
              }}
              InputLabelProps={{
                className: classes.textFieldFont
              }}
            />
            <TextField
              margin='dense'
              fullWidth
              variant='outlined'
              id='width'
              label='Width'
              autoComplete='off'
              value={widthSqFt}
              onChange={event => {
                setWidthSqFt(+event.target.value);
                const newTotalUnitPrice: number = +event.target.value * (lengthSqFt > 0 ? lengthSqFt : 1) * unitPrice;
                setQuantity(Number(+event.target.value * (lengthSqFt > 0 ? lengthSqFt : 1)));
                setTotalPrice(newTotalUnitPrice);
              }}
              InputProps={{
                classes: {
                  input: classes.textFieldFont
                },
                inputComponent: NumberFormatCustom as any,
                inputProps: {
                  thousandSeparator: true,
                  decimalScale: 2,
                  fixedDecimalScale: true
                }
              }}
              InputLabelProps={{
                className: classes.textFieldFont
              }}
            />
          </Fragment>
        )}
      </BodyCell>
      <BodyCell cellWidth='15%' isComponent={true}>
        <NumberFormat value={unitPrice} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} fixedDecimalScale={true} />
      </BodyCell>
      <BodyCell cellWidth='15%' isComponent={true}>
        {!isNAItem ? (
          <NumberFormat value={totalPrice} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} fixedDecimalScale={true} />
        ) : (
          <TextField
            margin='dense'
            fullWidth
            variant='outlined'
            id='amount'
            label='Amount'
            autoComplete='off'
            value={totalPrice}
            onChange={event => {
              setTotalPrice(+event.target.value);
            }}
            InputProps={{
              classes: {
                input: classes.textFieldFont
              },
              inputComponent: NumberFormatCustom as any,
              inputProps: {
                prefix: '$',
                thousandSeparator: true,
                decimalScale: 2,
                fixedDecimalScale: true
              }
            }}
            InputLabelProps={{
              className: classes.textFieldFont
            }}
          />
        )}
      </BodyCell>
      <BodyCell cellWidth='15%' isComponent={true}>
        <Fragment>
          <Tooltip title='Save Changes'>
            <IconButton size='small' onClick={onSubmit}>
              <CheckBoxRounded style={{ color: green[500] }} />
            </IconButton>
          </Tooltip>

          <Tooltip title='Cancel' onClick={onCancel}>
            <IconButton size='small'>
              <CancelRounded />
            </IconButton>
          </Tooltip>
        </Fragment>
      </BodyCell>
    </TableRow>
  );
};

export default CreateEditAdditionalItemForm;
