import React, { FC, useState, useEffect } from 'react';
import clsx from 'clsx';
import { Container, makeStyles, createStyles, Theme, Typography, Grid } from '@material-ui/core';
import axios from 'axios';
import { CUSTOMER_BASE_URL } from 'constants/url';
import { StandardConfirmationDialog } from 'components/AppDialog';
import useRouter from 'hooks/useRouter';
import CustomerDetail from './components/CustomerDetail';
import CollectingAddress from './components/CollectingAddress';
import ServiceAddress from './components/ServiceAddress';
import ContactPerson from './components/ContactPerson';
import SingleContactPerson from './components/SingleContactPerson';

import useCurrentPageTitleUpdater from 'hooks/useCurrentPageTitleUpdater';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4)
    },
    container: {
      '& > :nth-child(n+2)': {
        marginTop: theme.spacing(2)
      }
    }
  })
);

const defaultCustomer = {
  id: 0,
  firstName: '',
  lastName: '',
  contactNumber: '',
  contactPersonName: '',
  contactPersonEmail: '',
  contactPersonNumber: '',
  primaryCollectionAddress: '',
  primaryCollectionAddressDetail: '',
  primaryServiceAddress: '',
  primaryServiceAddressDetail: '',
  email: ''
};

const defaultAddress = [
  {
    id: 0,
    postalCode: '',
    address: '',
    floorNo: '',
    unitNo: ''
  }
];

const defaultContactPerson = [
  {
    id: 0,
    name: '',
    contactEmail: '',
    contactNumber: ''
  }
];

const CustomerDetailPage: FC = () => {
  const classes = useStyles();
  useCurrentPageTitleUpdater('CUSTOMERS');

  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [snackbarVariant, setSnackbarVariant] = useState<'success' | 'error'>('success');
  const [message, setMessage] = useState<string>('');
  const [customer, setCustomer] = useState<CustomerModel>(defaultCustomer);
  const [collectionAddress, setCollectionAddress] = useState<CollectionAddressModel[]>(defaultAddress);
  const [serviceAddress, setServiceAddress] = useState<ServiceAddressModel[]>(defaultAddress);
  const { match } = useRouter();
  const params = match.params.id;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await axios.get(`${CUSTOMER_BASE_URL}/${params}`);
        setCustomer(result.data);
        setCollectionAddress(result.data.CollectionAddresses);
        setServiceAddress(result.data.ServiceAddresses);
      } catch (error) {
        console.log('error:', error);
      }
    };
    fetchData();
  }, [params]);

  const handleSnackBar = (open: boolean, variant: 'success' | 'error', message: string) => {
    setSnackbarVariant(variant);
    setOpenSnackbar(open);
    setMessage(message);
  };

  return (
    <Container maxWidth='lg' className={clsx(classes.root, classes.container)}>
      <Grid container direction='row' justify='space-between'>
        <Grid container item xs={8} sm={8} md={8} lg={8} alignItems='center'>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Typography variant='h4' gutterBottom>
              Customer Profile
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid direction='row' container spacing={1}>
        <Grid item lg={4} md={4} sm={12} xs={12}>
          <CustomerDetail customer={customer} setCustomer={setCustomer} handleSnackBar={handleSnackBar} />
        </Grid>
        
        <Grid item lg={4} md={4} sm={12} xs={12}>
          <CollectingAddress
            collectionAddress={collectionAddress}
            customerId={customer.id}
            setCollectionAddress={setCollectionAddress}
            handleSnackBar={handleSnackBar}
          />
        </Grid>
        
        <Grid item lg={4} md={4} sm={12} xs={12}>
          <ServiceAddress
            serviceAddress={serviceAddress}
            customerId={customer.id}
            setServiceAddress={setServiceAddress}
            handleSnackBar={handleSnackBar}
          />
        </Grid>
      </Grid>

      <StandardConfirmationDialog
        variant={snackbarVariant}
        titleMessage={snackbarVariant.charAt(0).toUpperCase() + snackbarVariant.slice(1) + '!'}
        message={message}
        open={openSnackbar}
        handleClose={() => setOpenSnackbar(false)}
        onConfirm={() => setOpenSnackbar(false)}
        noCancelButton={true}
      />
    </Container>
  );
};

export default CustomerDetailPage;
