import React, { useState, useEffect } from 'react';
import { IconButton, Theme, makeStyles, Modal, Typography, Grid } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import DetailDeliveryOrder from './components/DetailDeliveryOrder';
import DetailServiceItems from './components/DetailServiceItems';
import { ServiceItemDummy } from 'constants/DummyData';

interface Props {
  service?: ServiceModel;
  open: boolean;
  handleCancel(): void;
  setOpenSnackbar: React.Dispatch<React.SetStateAction<boolean>>;
  setSnackbarVariant: React.Dispatch<React.SetStateAction<'success' | 'error'>>;
  handleSetMessageSuccess: (message: string) => void;
  handleSetMessageError: (message: string) => void;
}

const dummyService: ServiceModel = {
  id: 0,
  serviceNumber: '',
  description: '',
  time: '',
  CustomerId: 0,
  totalAmount: 0,
  deliveryType: '',
  promoCode: '',
  typePromo: '',
  discountType: '',
  discountAmount: 0
};

const dummyCollectionAddress: CollectionAddressModel = {
  id: 0,
  address: '',
  postalCode: '',
  floorNo: '',
  unitNo: ''
};

const dummyServiceAddress: ServiceAddressModel = {
  id: 0,
  address: '',
  postalCode: '',
  floorNo: '',
  unitNo: ''
};

const dummyCustomer: CustomerModel = {
  id: 0,
  firstName: '',
  lastName: '',
  contactNumber: '',
  contactPersonName: '',
  contactPersonEmail: '',
  contactPersonNumber: '',
  primaryCollectionAddress: '',
  primaryCollectionAddressDetail: '',
  primaryServiceAddress: '',
  primaryServiceAddressDetail: '',
  email: ''
};

const dummyServiceItem: ServiceItemModel[] = [ServiceItemDummy];

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    position: 'absolute',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    outline: 'none',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: 4
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  },
  title: {
    marginBottom: theme.spacing(2)
  },
  informationContainer: {
    paddingRight: '10px !important'
  },
  itemsContainer: {
    paddingLeft: '10px !important'
  }
}));

const DetailDeliveryOrderModal: React.FC<Props> = props => {
  const classes = useStyles();

  const { service, open, handleCancel } = props;

  const [serviceId, setServiceId] = useState<number>(0);
  const [serviceNumber, setServiceNumber] = useState<string>('');
  const [discountAmount, setDiscountAmount] = useState<number>(0);
  const [totalAmount, setTotalAmount] = useState<number>(0);
  const [discountType, setDiscountType] = useState<string>('');
  const [deliveryType, setDeliveryType] = useState<string>('');
  const [customerData, setCustomerData] = useState<CustomerModel>(dummyCustomer);
  const [collectionAddressData, setCollectionAddressData] = useState<CollectionAddressModel>(dummyCollectionAddress);
  const [serviceAddressData, setServiceAddressData] = useState<ServiceAddressModel>(dummyServiceAddress);
  const [serviceItemsData, setServiceItemsData] = useState<ServiceItemModel[]>(dummyServiceItem);
  const [invoiceData, setInvoiceData] = useState<InvoiceModel>();
  const [jobData, setJobData] = useState<JobModel>();

  const [customerFirstName, setFirstName] = useState<string>('');
  const [customerLastName, setLastName] = useState<string>('');

  const [thisInvoiceNumber, setThisInvoiceNumber] = useState<string>('');
  const [invoiceCreated, setInvoiceCreated] = useState<string>('');
  const [invoicePaymentStatus, setInvoicePaymentStatus] = useState<string>('');
  const [invoiceTotalPrice, setInvoiceTotalPrice] = useState<number>(0);
  const [invoiceTotalCollected, setInvoiceTotalCollected] = useState<number>(0);
  const [invoiceTotalChanges, setInvoiceTotalChanges] = useState<number>(0);

  const [invoicePaymentMethod, setInvoicePaymentMethod] = useState<string>('');
  const [jobCollectionDate, setJobCollectionDate] = useState<Date | null>(null);
  const [jobCollectionTime, setJobCollectionTime] = useState<string>('');
  const [jobDeliveryDate, setJobDeliveryDate] = useState<Date | null>(null);
  const [jobDeliveryTime, setJobDeliveryTime] = useState<string>('');
  const [jobSignature, setJobSignature] = useState<string>('');

  const [collectionAddress, setCollectionAddress] = useState<string>('');
  const [collectionUnitNo, setCollectionUnitNo] = useState<string>('');
  const [collectionFloorNo, setCollectionFloorNo] = useState<string>('');
  const [collectionPostalCode, setCollectionPostalCode] = useState<string>('');

  const [serviceAddress, setServiceAddress] = useState<string>('');
  const [serviceUnitNo, setServiceUnitNo] = useState<string>('');
  const [serviceFloorNo, setServiceFloorNo] = useState<string>('');
  const [servicePostalCode, setServicePostalCode] = useState<string>('');

  const handleClose = () => {
    handleCancel();
  };

  useEffect(() => {
    if (!service) {
      return;
    }

    const {
      id,
      serviceNumber,
      discountType,
      discountAmount,
      totalAmount,
      deliveryType,
      Customer,
      CollectionAddress,
      ServiceAddress,
      ServiceItems,
      Invoice,
      Job
    } = service;

    setServiceId(id);
    setServiceNumber(serviceNumber);
    setDiscountAmount(discountAmount);
    setDiscountType(discountType);
    setTotalAmount(totalAmount);
    setDeliveryType(deliveryType);
    setCustomerData(!Customer ? dummyCustomer : Customer);
    setCollectionAddressData(!CollectionAddress ? dummyCollectionAddress : CollectionAddress);
    setServiceAddressData(!ServiceAddress ? dummyServiceAddress : ServiceAddress);
    setServiceItemsData(!ServiceItems ? dummyServiceItem : ServiceItems);
    setInvoiceData(Invoice);
    setJobData(Job);
  }, [service]);

  useEffect(() => {
    if (!customerData) {
      return;
    }

    const { firstName, lastName } = customerData;
    setFirstName(firstName);
    setLastName(lastName);
  }, [customerData]);

  useEffect(() => {
    if (!collectionAddressData) {
      return;
    }

    const { address, unitNo, floorNo, postalCode } = collectionAddressData;
    setCollectionAddress(address);
    setCollectionUnitNo(unitNo);
    setCollectionFloorNo(floorNo);
    setCollectionPostalCode(postalCode);
  }, [collectionAddressData]);

  useEffect(() => {
    if (!serviceAddressData) {
      return;
    }

    const { address, unitNo, floorNo, postalCode } = serviceAddressData;
    setServiceAddress(address);
    setServiceUnitNo(unitNo);
    setServiceFloorNo(floorNo);
    setServicePostalCode(postalCode);
  }, [serviceAddressData]);

  useEffect(() => {
    if (!invoiceData) {
      return;
    }

    const { invoiceNumber, paymentStatus, totalPrice, totalCollected, totalChanges, paymentType, createdAt } = invoiceData;
    setInvoicePaymentStatus(paymentStatus);
    setInvoiceTotalPrice(totalPrice);
    setInvoiceTotalCollected(totalCollected || 0);
    setInvoiceTotalChanges(totalChanges || 0);
    setInvoicePaymentMethod(paymentType);
    setThisInvoiceNumber(invoiceNumber);
    setInvoiceCreated(createdAt);
  }, [invoiceData]);

  useEffect(() => {
    if (!jobData) {
      return;
    }

    const { collectionDate, collectionTime, deliveryDate, deliveryTime, clientSignature } = jobData;
    setJobCollectionDate(collectionDate);
    setJobCollectionTime(collectionTime);
    setJobDeliveryDate(deliveryDate);
    setJobDeliveryTime(deliveryTime);
    setJobSignature(clientSignature || '');
    
  }, [jobData]);

  return (
    <Modal aria-labelledby='modal-title' open={open} disableBackdropClick={true}>
      <Grid container item xs={10} sm={10} md={10} lg={10} xl={10} spacing={3} direction='column' className={classes.paper}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6} className={classes.informationContainer}>
            <Typography variant='h4' id='modal-title' className={classes.title}>
              Information Details
            </Typography>

            <DetailDeliveryOrder
              serviceId={serviceId}
              serviceNumber={serviceNumber}
              customerFirstName={customerFirstName}
              customerLastName={customerLastName}
              collectionAddress={collectionAddress}
              collectionUnitNo={collectionUnitNo}
              collectionFloorNo={collectionFloorNo}
              collectionPostalCode={collectionPostalCode}
              collectionDate={jobCollectionDate}
              collectionTime={jobCollectionTime}
              serviceAddress={serviceAddress}
              serviceUnitNo={serviceUnitNo}
              serviceFloorNo={serviceFloorNo}
              servicePostalCode={servicePostalCode}
              deliveryDate={jobDeliveryDate}
              deliveryTime={jobDeliveryTime}
              clientSignature={jobSignature}
              invoiceNumber={thisInvoiceNumber}
              invoiceCreated={invoiceCreated}
              invoicePaymentStatus={invoicePaymentStatus}
              invoiceTotalPrice={invoiceTotalPrice}
              invoiceTotalCollected={invoiceTotalCollected}
              invoiceTotalChanges={invoiceTotalChanges}
              invoicePaymentMethod={invoicePaymentMethod}
              discountAmount={discountAmount}
              discountType={discountType}
              totalAmount={totalAmount}
              deliveryType={deliveryType}
              serviceItems={serviceItemsData}
              handleClose={handleClose}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6} xl={6} className={classes.itemsContainer}>
            <Typography variant='h4' id='modal-title' className={classes.title}>
              Service Details
            </Typography>

            <DetailServiceItems serviceItems={serviceItemsData} />
          </Grid>
        </Grid>

        <IconButton size='small' className={classes.closeButton} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </Grid>
    </Modal>
  );
};

export default DetailDeliveryOrderModal;
