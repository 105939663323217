const BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const LOGIN_URL = `${BASE_URL}/login`;
export const LOGOUT_URL = `${BASE_URL}/logout`;
export const FORGOT_PASSWORD_URL = `${BASE_URL}/forgotpassword`;
export const ACTIVATION_URL = `${BASE_URL}/activation`;
export const RESET_PASSWORD_URL = `${BASE_URL}/resetpassword`;
export const CHECK_TOKEN_RESET_PASSWORD_URL = `${BASE_URL}/checkresettoken`;
export const CHANGE_PASSWORD_URL = `${BASE_URL}/changepassword`;
export const CHECK_USER_FORGOT_PASSWORD_URL = `${BASE_URL}/checkuserforgot`;

export const APPLOG_BASE_URL = `${BASE_URL}/applog`;

export const USER_BASE_URL = `${BASE_URL}/users`;
export const GET_CURRENT_USER_URL = `${USER_BASE_URL}/current`;
export const GET_CURRENT_USER_PASSWORD_URL = `${USER_BASE_URL}/currentpassword`;
export const GET_EDIT_USER_URL = (userId: number) => `${USER_BASE_URL}/${userId}`;
export const GET_DEACTIVATE_USER_URL = (userId: number) => `${USER_BASE_URL}/${userId}/unactivate`;
export const GET_ACTIVATE_USER_URL = (userId: number) => `${USER_BASE_URL}/${userId}/activate`;
export const GET_USER_BY_ROLE_URL = `${USER_BASE_URL}/search`;
export const UPDATE_TOKEN = `${USER_BASE_URL}/account/device`;
export const DELETE_USER_URL = (userId: number[]) => `${USER_BASE_URL}/${userId}`;

export const SERVICE_ITEM_TEMPLATE_BASE_URL = `${BASE_URL}/serviceitemtemplates`;
export const GET_EDIT_SERVICE_ITEM_TEMPLATE_URL = (serviceItemTemplateId: number) => `${SERVICE_ITEM_TEMPLATE_BASE_URL}/${serviceItemTemplateId}`;
export const GET_DELETE_SERVICE_ITEM_TEMPLATE_URL = (serviceItemTemplateId: number) => `${SERVICE_ITEM_TEMPLATE_BASE_URL}/${serviceItemTemplateId}`;
export const BULK_DELETE_SERVICE_ITEM_TEMPLATE_URL = `${SERVICE_ITEM_TEMPLATE_BASE_URL}/bulkDelete`;

export const SERVICE_ITEM_BASE_URL = `${BASE_URL}/serviceitems`;
export const GET_EDIT_SERVICE_ITEM_URL = (serviceItemId: number) => `${SERVICE_ITEM_BASE_URL}/${serviceItemId}`;
export const GET_DELETE_SERVICE_ITEM_URL = (serviceItemId: number) => `${SERVICE_ITEM_BASE_URL}/${serviceItemId}`;

export const CATEGORY_BASE_URL = `${BASE_URL}/category`;
export const GET_EDIT_CATEGORY_URL = (id: number) => `${CATEGORY_BASE_URL}/${id}`;
export const GET_DELETE_CATEGORY_URL = (id: number) => `${CATEGORY_BASE_URL}/${id}`;
export const GET_IMAGE_CATEGORY_URL = (imageKey: string) => `${CATEGORY_BASE_URL}/getimage/${imageKey}`;
export const BULK_DELETE_CATEGORY_URL = `${CATEGORY_BASE_URL}/bulkDelete`;

export const PROMOTION_BASE_URL = `${BASE_URL}/promotions`;
export const GET_IMAGE_PROMOTION_URL = (imageKey: string) => `${PROMOTION_BASE_URL}/getimage/${imageKey}`;
export const GET_EDIT_PROMOTION_URL = (id: number) => `${PROMOTION_BASE_URL}/${id}`;
export const GET_DELETE_PROMOTION_URL = (id: number) => `${PROMOTION_BASE_URL}/${id}`;
export const BULK_DELETE_PROMOTION_URL = `${PROMOTION_BASE_URL}/bulkDelete`;
export const VALIDATION_PROMOTION_CODE_URL = `${PROMOTION_BASE_URL}/validcode`;

export const CUSTOMER_BASE_URL = `${BASE_URL}/customers`;
export const GET_EDIT_CUSTOMER_URL = (id: number) => `${CUSTOMER_BASE_URL}/${id}`;
export const GET_DELETE_CUSTOMER_URL = (id: number) => `${CUSTOMER_BASE_URL}/${id}`;
export const BULK_DELETE_CUSTOMER_URL = `${PROMOTION_BASE_URL}/bulkDelete`;

export const COLLECTION_ADDRESS_BASE_URL = `${BASE_URL}/collectionaddresses`;
export const GET_EDIT_COLLECTION_ADDRESS_URL = (id: number) => `${COLLECTION_ADDRESS_BASE_URL}/${id}`;
export const GET_DELETE_COLLECTION_ADDRESS_URL = (id: number) => `${COLLECTION_ADDRESS_BASE_URL}/${id}`;

export const SERVICE_ADDRESS_BASE_URL = `${BASE_URL}/serviceaddresses`;
export const GET_EDIT_SERVICE_ADDRESS_URL = (id: number) => `${SERVICE_ADDRESS_BASE_URL}/${id}`;
export const GET_DELETE_SERVICE_ADDRESS_URL = (id: number) => `${SERVICE_ADDRESS_BASE_URL}/${id}`;

export const CONTACT_PERSON_BASE_URL = `${BASE_URL}/contact-person`;
export const GET_EDIT_CONTACT_PERSON_URL = (id: number) => `${CONTACT_PERSON_BASE_URL}/${id}`;
export const GET_DELETE_CONTACT_PERSON_URL = (id: number) => `${CONTACT_PERSON_BASE_URL}/${id}`;

export const BLOCKED_DATE_BASE_URL = `${BASE_URL}/blocked-date`;
export const GET_EDIT_BLOCKED_DATE_URL = (id: number) => `${BLOCKED_DATE_BASE_URL}/${id}`;
export const GET_DELETE_BLOCKED_DATE_URL = (id: number) => `${BLOCKED_DATE_BASE_URL}/${id}`;
export const BULK_DELETE_BLOCKED_DATE_URL = `${BLOCKED_DATE_BASE_URL}/bulkDelete`;

export const JOBS_BASE_URL = `${BASE_URL}/jobs`;
export const GET_DETAIL_JOBS_URL = (id: number) => `${JOBS_BASE_URL}/${id}`;
export const GET_DELETE_JOBS_URL = (id: number) => `${JOBS_BASE_URL}/${id}`;
export const GET_UPDATE_STATUS_JOBS_URL = (id: number) => `${JOBS_BASE_URL}/status/${id}`;
export const GET_IMAGE_JOB_URL = (imageKey: string) => `${JOBS_BASE_URL}/getimage/${imageKey}`;
export const GET_EXPORT_JOBS_URL = `${JOBS_BASE_URL}/export`;

export const JOB_NOTE_BASE_URL = `${BASE_URL}/job-note`;
export const GET_IMAGE_JOB_NOTE_URL = (imageKey: string) => `${JOB_NOTE_BASE_URL}/getimage/${imageKey}`;
export const GET_EDIT_JOB_NOTE_URL = (id: number) => `${JOB_NOTE_BASE_URL}/${id}`;
export const GET_DELETE_JOB_NOTE_URL = (id: number) => `${JOB_NOTE_BASE_URL}/${id}`;
export const GET_UPDATE_STATUS_JOB_NOTE_URL = (id: number) => `${JOB_NOTE_BASE_URL}/status/${id}`;

export const INVOICE_BASE_URL = `${BASE_URL}/invoice`;
export const GET_DETAIL_INVOICE_URL = (id: number) => `${INVOICE_BASE_URL}/${id}`;
export const GET_DETAIL_INVOICE_BY_SERVICE_URL = (id: number) => `${INVOICE_BASE_URL}/service/${id}`;
export const GET_EDIT_INVOICE_BY_SERVICE_URL = (id: number) => `${INVOICE_BASE_URL}/${id}`;
export const GET_PAID_INVOICE_URL = (id: number) => `${INVOICE_BASE_URL}/${id}`;
export const GET_EXPORT_PDF_INVOICE_URL = `${INVOICE_BASE_URL}/exportPDF`;
export const BULK_PAID_INVOICE_URL = `${INVOICE_BASE_URL}/bulkDelete`;
export const GET_SERVICE_INVOICE_CUSTOMER_URL = (serviceId: number) => `${INVOICE_BASE_URL}/${serviceId}/customer`;
export const GET_PAYMENT_INVOICE_CUSTOMER_URL = (serviceId: number) => `${INVOICE_BASE_URL}/${serviceId}/payment-customer`;
export const GET_PAYMENT_INVOICE_URL = (serviceId: number) => `${INVOICE_BASE_URL}/${serviceId}/payment`;
export const GET_UPDATE_INVOICE_URL = (id: number) => `${INVOICE_BASE_URL}/${id}/collect`;

export const DELIVERY_ORDER_BASE_URL = `${BASE_URL}/deliveryorder`;
export const GET_DELIVERY_ORDER_DETAIL_URL = (serviceId: number) => `${DELIVERY_ORDER_BASE_URL}/${serviceId}`;
export const GET_EXPORT_PDF_DELIVERY_ORDER_URL = `${DELIVERY_ORDER_BASE_URL}/exportPDF`;
export const GET_EXPORT_PDF_RECEIPT_URL = `${DELIVERY_ORDER_BASE_URL}/exportReceiptPDF`;

export const SERVICE_BASE_URL = `${BASE_URL}/service`;
export const GET_EDIT_SERVICE_URL = (id: number) => `${SERVICE_BASE_URL}/${id}`;
