import React, { FC, Fragment, useState, useCallback } from 'react';
import { Typography, createStyles, makeStyles, Theme, Button, IconButton, Grid } from '@material-ui/core';
import axios, { CancelTokenSource } from 'axios';
import useRouter from 'hooks/useRouter';
import { StandardConfirmationDialog } from 'components/AppDialog';
import JobNoteForm from './components/JobNoteForm';
import JobNoteRow from './components/JobNoteRow';
import JobNoteDetailImage from './components/JobNoteDetailImage';
import { JOB_NOTE_BASE_URL } from 'constants/url';
import { GET_EDIT_JOB_NOTE_URL, GET_DELETE_JOB_NOTE_URL, GET_UPDATE_STATUS_JOB_NOTE_URL } from 'constants/url';
import AddCircleIcon from '@material-ui/icons/AddCircle';

interface Error {
  note: boolean;
  noteMessage: string;
}

interface Props {
  jobId?: string;
  jobNotes: JobNoteModel[];
  isLoading: boolean;
  setJobNotes: React.Dispatch<React.SetStateAction<JobNoteModel[]>>;
  handleSnackBar: (open: boolean, variant: 'success' | 'error', message: string) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    createLabel: {
      padding: 5,
      borderRadius: 8,
      background: '#CCCCCC',
      color: '#FFFFFF'
    },
    button: {
      marginRight: theme.spacing(1)
    }
  })
);

const defaultError: Error = {
  note: false,
  noteMessage: ''
};

const JobNotesList: FC<Props> = props => {
  const classes = useStyles();

  const { jobId, jobNotes, isLoading, setJobNotes, handleSnackBar } = props;
  const [currentIndex, setCurrentIndex] = useState<number>(-1);
  const [isSwitch, setSwitch] = useState<boolean>(false);
  const [isOpenDialog, setOpenDialog] = useState<boolean>(false);

  const { location } = useRouter();
  const [id, setId] = useState<number>(0);
  const [note, setNote] = useState<string>('');
  const [image, setImage] = useState<string>('');
  const [imageView, setImageView] = useState<string>('');
  const [currentDetailImage, setCurrentDetailImage] = useState<string>('');
  const [openDetailImageJobNote, setOpenDetailImageJobNote] = useState<boolean>(false);
  const [openCreateForm, setOpenCreateForm] = useState<boolean>(false);
  const [openEditForm, setOpenEditForm] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [error, setError] = useState<Error>(defaultError);

  const getCurrentJobNote = useCallback(
    (index: number) => {
      const thisJobNote = jobNotes[index];
      setId(thisJobNote.id);
      setNote(thisJobNote.notes);
    },
    [jobNotes]
  );

  const handleSwitch = async (index: number, id: number, isHide: boolean) => {
    const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();
    setCurrentIndex(index);
    setSwitch(true);

    const timer = setTimeout(() => {
      clearTimeout(timer);
      setSwitch(false);
      handleSnackBar(true, 'error', `Request time out.`);
    }, 2000);

    try {
      const { data } = await axios.put(GET_UPDATE_STATUS_JOB_NOTE_URL(id), { isHide }, { cancelToken: cancelTokenSource.token });
      setJobNotes(jobNotes.map((val, i) => (index === i ? data : val)));
      handleSnackBar(true, 'success', `Job note ${data.isHide ? `hide in pdf` : `show in pdf`}.`);
      clearTimeout(timer);
    } catch (err) {
      console.log('err:', err);
      handleSnackBar(true, 'error', `oops something wrong.`);
    }
    setSwitch(false);
  };

  const handleOpenCreate = () => {
    resetNote();
    setOpenCreateForm(!openCreateForm);
  };

  const handleClickEdit = (index: number) => {
    setCurrentIndex(index);
    getCurrentJobNote(index);
    setOpenEditForm(true);
    setIsSubmitting(false);
  };

  const handleClickDelete = (index: number) => {
    setCurrentIndex(index);
    setOpenDialog(true);
  };

  const handleOnDelete = async () => {
    try {
      await axios.delete(GET_DELETE_JOB_NOTE_URL(jobNotes[currentIndex].id));
      handleSnackBar(true, 'success', 'Job Note has been delete successfully.');
      setJobNotes(jobNotes.filter((val, i) => i !== currentIndex));
    } catch (err) {
      console.log('err : ', err);
      handleSnackBar(true, 'error', 'Job Note has failed to delete.');
    }
    setOpenDialog(false);
  };

  const onSubmit = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (note === '' && !note) {
      setError({ ...error, note: true, noteMessage: 'Note can not be empty.' });
      return;
    }

    if (openCreateForm) {
      if (jobId) {
        try {
          setIsSubmitting(true);
          setError({ ...error, noteMessage: 'Loading...' });
  
          const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();
          const formData = new FormData();
  
          formData.append('JobId', jobId);
          formData.append('notes', note);
          formData.append('image', image);
  
          const config = {
            headers: {
              'Content-Type': 'multipart/form-data'
            },
            cancelToken: cancelTokenSource.token
          };
  
          const result = await axios.post(JOB_NOTE_BASE_URL, formData, config);
  
          setJobNotes([...jobNotes, { notes: result.data.notes, image: result.data.image, id: result.data.id, isHide: result.data.isHide }]);
          resetNote();
          setIsSubmitting(false);
          setOpenCreateForm(false);
          setError(defaultError);
          handleSnackBar(true, 'success', 'Job Note has been added successfully.');
        } catch (error) {
          console.log('err: ', error);
          setIsSubmitting(false);
          setOpenCreateForm(true);
          setError({ ...error, note: false, noteMessage: '' });
          handleSnackBar(true, 'error', 'The job note has failed to add.');
        }
      } else {
        console.log('jobId empty >', jobId);
      }
    }

    if (openEditForm) {
      try {
        setIsSubmitting(true);
        setError({ ...error, noteMessage: 'Loading...' });

        const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();
        const formData = new FormData();

        formData.append('notes', note);
        formData.append('image', image);

        const config = {
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          cancelToken: cancelTokenSource.token
        };

        const result = await axios.put(GET_EDIT_JOB_NOTE_URL(id), formData, config);
        jobNotes[currentIndex].notes = result.data.notes;
        jobNotes[currentIndex].image = result.data.image;
        setJobNotes(jobNotes);
        resetNote();
        setIsSubmitting(false);
        setOpenEditForm(false);
        setError(defaultError);
        handleSnackBar(true, 'success', 'Job Note has been edited successfully.');
      } catch (error) {
        console.log('err: ', error);
        setIsSubmitting(false);
        setOpenEditForm(true);
        setError({ ...error, note: false, noteMessage: '' });
        handleSnackBar(true, 'error', 'The job note has failed to edit.');
      }
    }
  };

  const resetNote = () => {
    setNote('');
    setImage('');
    setImageView('');
  };

  const handleOpenDetail = (currentDetail: string) => {
    setOpenDetailImageJobNote(true);
    setCurrentDetailImage(currentDetail);
  };

  const handleCloseDetailImageJobNote = () => {
    setOpenDetailImageJobNote(false);
  };

  return (
    <Fragment>
      <Typography variant='h5' gutterBottom>
        Job Notes
      </Typography>
      {isLoading ? (
        <Typography variant='overline'>Loading....</Typography>
      ) : (
        <Fragment>
          {jobNotes && jobNotes.length > 0 ? (
            jobNotes.map((value, index) =>
              openEditForm && currentIndex === index ? (
                <JobNoteForm
                  error={error}
                  setError={setError}
                  note={note}
                  setNote={setNote}
                  image={image}
                  setImage={setImage}
                  imageView={imageView}
                  setImageView={setImageView}
                  isSubmitting={isSubmitting}
                  openEditForm={openEditForm}
                  setOpenEditForm={setOpenEditForm}
                  onSubmit={onSubmit}
                />
              ) : (
                <JobNoteRow
                  key={index + 1}
                  job={value}
                  id={value.id}
                  image={value.image}
                  notes={value.notes}
                  isHide={value.isHide}
                  index={index}
                  currentIndex={currentIndex}
                  setImage={setImage}
                  setImageView={setImageView}
                  isLoading={isLoading}
                  isSwitch={isSwitch}
                  openCreateForm={openCreateForm}
                  openEditForm={openEditForm}
                  handleSwitch={handleSwitch}
                  handleClickEdit={handleClickEdit}
                  handleOpenDetail={handleOpenDetail}
                  handleClickDelete={handleClickDelete}
                />
              )
            )
          ) : (
            <Grid container justify='center'>
              <Typography align='center' variant='overline'>
                Job Note data not available.
              </Typography>
            </Grid>
          )}

          {openCreateForm && (
            <JobNoteForm
              error={error}
              setError={setError}
              note={note}
              setNote={setNote}
              image={image}
              setImage={setImage}
              imageView={imageView}
              setImageView={setImageView}
              isSubmitting={isSubmitting}
              openEditForm={openEditForm}
              setOpenEditForm={setOpenEditForm}
              onSubmit={onSubmit}
            />
          )}

          <Grid container item lg={12} md={12} sm={12} xs={12} justify='flex-end'>
            {openCreateForm ? (
              <Fragment>
                <Button size='small' className={classes.button} disabled={isSubmitting} onClick={event => setOpenCreateForm(!openCreateForm)}>
                  Cancel
                </Button>
                <Button size='small' color='primary' variant='contained' onClick={onSubmit}>
                  Save
                </Button>
              </Fragment>
            ) : (
              <div>
                <span className={classes.createLabel}>Add New Job Notes</span>
                <IconButton disabled={openEditForm} onClick={handleOpenCreate}>
                  <AddCircleIcon fontSize='large' color='primary' />
                </IconButton>
              </div>
            )}
          </Grid>
        </Fragment>
      )}

      <StandardConfirmationDialog
        variant={'danger'}
        titleMessage={'Delete'}
        message={'Are you sure want to delete this data?'}
        open={isOpenDialog}
        handleClose={() => setOpenDialog(false)}
        onConfirm={handleOnDelete}
      />

      <JobNoteDetailImage imageUrl={currentDetailImage} open={openDetailImageJobNote} handleClose={handleCloseDetailImageJobNote} />
    </Fragment>
  );
};

export default JobNotesList;
