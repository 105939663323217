import React, { Fragment, FC, useState, useEffect } from 'react';
import { Table, TableBody, TableHead, TableFooter, TableRow, TableCell, Paper } from '@material-ui/core';

import HeaderRow from 'components/HeaderRow';
import TablePagination from 'components/TablePagination';
import BodyRow from './components/BodyRow';
import axios, { CancelTokenSource } from 'axios';
import { GET_EDIT_PROMOTION_URL } from 'constants/url';
import { PromotionDummy } from 'constants/DummyData';

interface Props {
  isLoadingData: boolean;
  count: number;
  checked: number[];
  currentPage: number;
  rowsPerPage: number;
  promotions: PromotionModel[];
  promotion: PromotionModel;
  openEditForm: boolean;
  order: 'asc' | 'desc';
  orderBy: string;
  setOrder: React.Dispatch<React.SetStateAction<'asc' | 'desc'>>;
  setOrderBy: React.Dispatch<React.SetStateAction<string>>;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setPromotions: React.Dispatch<React.SetStateAction<PromotionModel[]>>;
  setCurrentIndex: React.Dispatch<React.SetStateAction<number>>;
  setOpenEditForm: React.Dispatch<React.SetStateAction<boolean>>;
  setChecked: React.Dispatch<React.SetStateAction<number[]>>;
  handleChangeRowsPerPage: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  handleChangePage: (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => void;
  handleClickDeletePromotion: (index: number) => void;
  handleSnackBar: (open: boolean, variant: 'success' | 'error', message: string) => void;
}

const dummyPromotion = PromotionDummy;

const PromotionTable: FC<Props> = props => {
  const {
    isLoadingData,
    count,
    checked,
    currentPage,
    rowsPerPage,
    promotions,
    openEditForm,
    setOpen,
    order,
    setOrder,
    orderBy,
    setOrderBy,
    setPromotions,
    setOpenEditForm,
    setCurrentIndex,
    setChecked,
    handleChangePage,
    handleChangeRowsPerPage,
    handleClickDeletePromotion,
    handleSnackBar
  } = props;

  // The below logic introduces a 500ms delay for showing the skeleton
  const [showSkeleton, setShowSkeleton] = useState<boolean>(false);

  const checkAll = () => {
    const newBulkChecked = [...checked];
    const countChecked = newBulkChecked.length;
    if (count > rowsPerPage) {
      if (countChecked !== rowsPerPage) {
        newBulkChecked.splice(0, countChecked);
        promotions.map(item => newBulkChecked.push(item.id));
      } else {
        newBulkChecked.splice(0, count);
      }
    } else {
      if (countChecked !== count) {
        newBulkChecked.splice(0, countChecked);
        promotions.map(item => newBulkChecked.push(item.id));
      } else {
        newBulkChecked.splice(0, count);
      }
    }
    setChecked(newBulkChecked);
  };

  const handleIndividualChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
    const id = parseInt(event.target.value);
    const countElement = checked.filter(newCheckedValue => newCheckedValue === id).length;
    if (countElement === 0) {
      checked.push(id);
    } else {
      checked.splice(checked.indexOf(id), 1);
    }
    setChecked([...checked]);
  };

  const handleUpdateStatus = async (indexStatus: number, status: boolean, id: number) => {
    const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();
    try {
      const { title, description, code, discountType, discountAmount, startDate, endDate } = promotions[indexStatus];
      const formData = {
        title: title,
        description: description,
        code: code,
        discountType: discountType,
        discountAmount: String(discountAmount),
        startDate: String(startDate),
        endDate: String(endDate),
        isActive: String(status ? false : true)
      };

      const config = {
        cancelToken: cancelTokenSource.token
      };

      const result = await axios.put(GET_EDIT_PROMOTION_URL(id), formData, config);
      promotions[indexStatus] = result.data;
      setPromotions(promotions);
      handleSnackBar(true, 'success', 'Status has been activated successfully.');
    } catch (err) {
      console.log('err : ', err);
      handleSnackBar(true, 'error', 'Status failed to activate.');
    }
  };

  const handleClickEditPromotion = (index: number): React.MouseEventHandler => () => {
    setCurrentIndex(index);
    setOpenEditForm(!openEditForm);
    setOpen(true);
  };

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: string ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  useEffect(() => {
    if (isLoadingData) {
      setShowSkeleton(true);
    }

    return () => {
      setShowSkeleton(false);
    };
  }, [isLoadingData]);

  return (
    <Fragment>
      <Paper variant='outlined' elevation={2} style={{ overflow: 'hidden', overflowX: 'auto' }}>
        <Table size='small'>
          <TableHead>
            <HeaderRow
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              height={'unset'}
              headers={[
                { label: 'CheckBox', verticalAlign: 'top', isCheckBox: true, checked, rowsPerPage, handleCheckAll: checkAll },
                { id: 'title', label: 'Title', sort: true },
                { id: 'description', label: 'Description', sort: true },
                { id: 'code', label: 'Code', sort: true },
                { label: 'Image' },
                { id: 'discountAmount', label: 'Discount', sort: true },
                { id: 'startDate', label: 'Start Date', sort: true },
                { id: 'endDate', label: 'End Date', sort: true },
                { label: 'Status' },
                { label: 'Action', align: 'center' }
              ]}
            />
          </TableHead>
          <TableBody>
            {showSkeleton ? (
              [0, 1, 2, 3].map(index => (
                <BodyRow
                  index={index}
                  key={index}
                  promotion={dummyPromotion}
                  isLoadingData={isLoadingData}
                  checked={checked}
                  handleIndividualChecked={handleIndividualChecked}
                  handleClickDeletePromotion={handleClickDeletePromotion}
                  handleClickEditPromotion={handleClickEditPromotion(index)}
                  handleUpdateStatus={handleUpdateStatus}
                />
              ))
            ) : promotions && promotions.length > 0 ? (
              promotions.map((value, index) => (
                <BodyRow
                  index={index}
                  key={index}
                  promotion={value}
                  isLoadingData={isLoadingData}
                  checked={checked}
                  handleIndividualChecked={handleIndividualChecked}
                  handleClickDeletePromotion={handleClickDeletePromotion}
                  handleClickEditPromotion={handleClickEditPromotion(index)}
                  handleUpdateStatus={handleUpdateStatus}
                />
              ))
            ) : (
              <Fragment>
                <TableRow>
                  <TableCell align='center' colSpan={10}>
                    Data Not Available.
                  </TableCell>
                </TableRow>
              </Fragment>
            )}
          </TableBody>
          <TableFooter>
            <TablePagination
              rowsPerPageOptions={[5, 10, 15]}
              count={count}
              rowsPerPage={rowsPerPage}
              page={currentPage}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </TableFooter>
        </Table>
      </Paper>
    </Fragment>
  );
};

export default PromotionTable;
