import React, { FC, useState, Fragment } from 'react';
import {
  Theme,
  createStyles,
  makeStyles,
  IconButton,
  TableRow,
  TableCell,
  Tooltip,
  Menu,
  MenuItem,
  Select,
  CircularProgress,
  Chip,
  Typography
} from '@material-ui/core';
import Skeleton from 'react-loading-skeleton';
import useRouter from 'hooks/useRouter';
import StatusType from 'typings/enum/StatusType';
import { BorderColor, CheckBoxRounded, CancelRounded, MoreHoriz } from '@material-ui/icons';
import { green } from '@material-ui/core/colors';
import { format } from 'date-fns';
import { ucwords } from 'utils';
import { GET_EXPORT_JOBS_URL, GET_DETAIL_JOBS_URL } from 'constants/url';
import axios, { CancelTokenSource } from 'axios';
import DeliveryType from 'typings/enum/DeliveryType';

interface Props {
  isLoadingData: boolean;
  currentIndex: number;
  isEdit: boolean;
  isSubmit: boolean;
  index: number;
  job: JobModel;
  currentTab?: number;
  setCurrentIndex: React.Dispatch<React.SetStateAction<number | -1>>;
  setEdit: React.Dispatch<React.SetStateAction<boolean | false>>;
  setSubmit: React.Dispatch<React.SetStateAction<boolean | false>>;
  handleJobStatus: (index: number, jobStatus: string, id: number) => void;
  handleOpenCancelled: (index: number, status: string, id: number) => void;
  handleSnackBar: (open: boolean, variant: 'success' | 'error', message: string) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableCellInnerTime: {
      color: '#388bf2',
      marginLeft: theme.spacing(1)
    }
  })
);

const BodyRow: FC<Props> = props => {
  const classes = useStyles();
  const { history } = useRouter();
  const { isLoadingData, job, index, currentTab, handleSnackBar } = props;
  const { id, collectionDate, deliveryDate, collectionTime, deliveryTime, jobStatus, User, Service } = job;
  const { currentIndex, setCurrentIndex, isEdit, setEdit, isSubmit, setSubmit, handleJobStatus, handleOpenCancelled } = props;

  let deliverType = '-';
  let fullName = '-';
  let fullAddress = '-';
  let collectionFullAddress = '-';
  let collectionFullDate = collectionDate + ' ' + collectionTime;
  let deliveryFullDate = deliveryDate + ' ' + deliveryTime;
  let driverName = User ? User.displayName : '-';
  let jobId = id;
  let invoiceId = '-';

  if (Service && Service !== null) {
    const { ServiceAddress, CollectionAddress, Customer, deliveryType, ServiceItems, Invoice } = Service!;
    deliverType = deliveryType;

    if (ServiceAddress && ServiceAddress !== null) {
      const { postalCode, address, floorNo, unitNo } = ServiceAddress!;
      fullAddress = `${address} ${floorNo ? floorNo : '-'}-${unitNo ? unitNo : '-'}, Singapore, ${postalCode}`;
    }

    if (CollectionAddress && CollectionAddress !== null) {
      const { postalCode, address, floorNo, unitNo } = CollectionAddress!;
      collectionFullAddress = `${address} ${floorNo ? floorNo : '-'}-${unitNo ? unitNo : '-'}, Singapore, ${postalCode}`;
    }

    if (Customer && Customer !== null) {
      const { firstName, lastName } = Customer;
      fullName = firstName + ' ' + lastName;
    }

    if (Invoice && Invoice !== null) {
      const { invoiceNumber } = Invoice!;
      invoiceId = invoiceNumber;
    }
  }

  const [openSort, setOpenSort] = useState<null | HTMLElement>(null);
  const [status, setStatus] = useState<string>(jobStatus);
  const statusType = Object.entries(StatusType).map(([keys, value]) => value);

  const handleDeliveryType = (type: string): string => {
    switch (type) {
      case DeliveryType.TWO_DAY_EXPRESS:
        return 'Two Days Express';
      case DeliveryType.ONE_DAY_EXPRESS:
        return 'One Day Express';
      case DeliveryType.SUPER_EXPRESS:
        return 'Super Express';
      default:
        return 'Standard';
    }
  };

  const handleLink = (path: string, id: number, currentTab?: number) => {
    history.push(path, { id: id, currentTab });
  };

  const handleClickEdit = () => {
    setEdit(true);
    setCurrentIndex(index);
    setStatus(jobStatus);
  };

  const handleClickCancel = () => {
    setEdit(false);
    setCurrentIndex(-1);
  };

  console.log('index >>', index);

  const handleJobChange = () => {
    if (status === StatusType.UNASSIGNED) {
      jobStatusChange();
    } else if (status === StatusType.CANCELLED) {
      handleOpenCancelled(index, status, id);
    } else {
      if (!User || User === null) {
        handleSnackBar(true, 'error', `For update status ${ucwords(status)} need to assign a driver.`);
        return;
      }

      jobStatusChange();
    }
  };

  const jobStatusChange = () => {
    setSubmit(true);
    handleJobStatus(index, status, id);
    setCurrentIndex(-1);
    setEdit(false);
    setSubmit(false);
  };

  const handleActionButtonCancel = () => {
    handleOpenCancelled(index, StatusType.CANCELLED, id);
    setOpenSort(null);
  };

  const handleExportPDF = async () => {
    try {
      const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();
      const result = await axios.get(GET_DETAIL_JOBS_URL(id), { cancelToken: cancelTokenSource.token });
      const resultServiceItems = result.data.Service.ServiceItems;
      const resultJobNotes = result.data.JobNotes;

      await axios
        .post(
          GET_EXPORT_JOBS_URL,
          {
            id,
            customerName: fullName,
            collectionAddress: collectionFullAddress,
            serviceAddress: fullAddress,
            collectionDate: collectionFullDate,
            deliveryDate: deliveryFullDate,
            driverName,
            jobStatus,
            serviceItems: resultServiceItems,
            jobNotes: resultJobNotes!.filter((val: any) => val.isHide === false)
          },
          {
            headers: {
              t: localStorage.getItem('token')
            },
            responseType: 'blob'
          }
        )
        .then(response => {
          const file = new Blob([response.data && response.data], { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);

          const newwindow = window.open(fileURL, 'name', 'height=700,width=750');
          if (newwindow && window.focus) {
            newwindow.focus();
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  const editView = () => (
    <Fragment>
      <Tooltip title='Update Job Status'>
        <IconButton size='small' onClick={handleClickEdit} disabled={jobStatus === StatusType.CANCELLED}>
          <BorderColor fontSize='small' />
        </IconButton>
      </Tooltip>
    </Fragment>
  );

  const jobStatusView = () => (
    <Select
      variant='outlined'
      margin='dense'
      labelId='jobStatus'
      required
      id='jobStatus'
      label='Job Status'
      value={status as string}
      disabled={isSubmit && index === currentIndex}
      onChange={event => setStatus(event.target.value as string)}
    >
      {statusType.map((val, idx) => (
        <MenuItem key={idx} value={val}>
          {ucwords(val)}
        </MenuItem>
      ))}
    </Select>
  );

  const cellView = (value: any) => (isLoadingData ? <Skeleton width={100} /> : value);

  return (
    <TableRow hover>
      <TableCell component='td'>{(isSubmit && index === currentIndex && <CircularProgress size={20} />) || cellView(fullName)}</TableCell>

      <TableCell style={{ width: '100px' }}>{cellView(jobId)}</TableCell>
      <TableCell>{cellView(invoiceId)}</TableCell>
      <TableCell>{cellView(fullAddress)}</TableCell>

      <TableCell component='td' align='center'>
        {cellView(
          Boolean(collectionDate) ? (
            <Fragment>
              <Typography component='span'>{format(new Date(collectionDate!), 'dd/MM/yyyy')}</Typography>
              <Typography component='span' className={classes.tableCellInnerTime}>
                {collectionTime ? collectionTime.slice(0, 5) : '-'}
              </Typography>
            </Fragment>
          ) : (
            '-'
          )
        )}
      </TableCell>

      <TableCell component='td' align='center'>
        {cellView(
          Boolean(deliveryDate) ? (
            <Fragment>
              <Typography component='span'>{format(new Date(deliveryDate!), 'dd/MM/yyyy')}</Typography>
              <Typography component='span' className={classes.tableCellInnerTime}>
                {deliveryTime ? deliveryTime.slice(0, 5) : '-'}
              </Typography>
            </Fragment>
          ) : (
            '-'
          )
        )}
      </TableCell>

      <TableCell component='td' align='center'>
        {cellView(User && User !== null ? User.displayName : '-')}
      </TableCell>

      <TableCell component='td' align='center'>
        {cellView(<Chip label={handleDeliveryType(deliverType)} size='small' variant='outlined' />)}
      </TableCell>

      <TableCell component='td' align='center'>
        {isEdit && index === currentIndex ? '' : ucwords(jobStatus)} &nbsp;
        {jobStatus !== StatusType.DELIVERED && jobStatus !== StatusType.CANCELLED
          ? cellView(isEdit && index === currentIndex ? jobStatusView() : editView())
          : ''}
      </TableCell>

      <TableCell component='td' align='center'>
        {isLoadingData ? (
          <Skeleton width='100%' />
        ) : isEdit && index === currentIndex ? (
          <Fragment>
            <Tooltip title='Save Changes'>
              <IconButton disabled={index === currentIndex && isSubmit} size='small' onClick={handleJobChange}>
                <CheckBoxRounded style={{ color: green[500] }} />
              </IconButton>
            </Tooltip>

            <Tooltip title='Cancel'>
              <IconButton size='small' onClick={handleClickCancel}>
                <CancelRounded />
              </IconButton>
            </Tooltip>
          </Fragment>
        ) : (
          <Fragment>
            <Tooltip title='More action'>
              <IconButton size='small' onClick={event => setOpenSort(event.currentTarget)}>
                <MoreHoriz />
              </IconButton>
            </Tooltip>

            <Menu id='sort' anchorEl={openSort} keepMounted open={Boolean(openSort)} onClose={event => setOpenSort(null)}>
              <MenuItem onClick={() => window.open(`/jobs/detail/${id}`, '_blank')}>View Job</MenuItem>
              <MenuItem onClick={handleActionButtonCancel} disabled={jobStatus === StatusType.DELIVERED || jobStatus === StatusType.CANCELLED}>
                Cancel Job
              </MenuItem>
              <MenuItem onClick={handleExportPDF}>Export Data</MenuItem>
            </Menu>
          </Fragment>
        )}
      </TableCell>
    </TableRow>
  );
};

export default BodyRow;
