import React, { FC, useState, useEffect, useCallback, Fragment } from 'react';
import {
  Checkbox,
  IconButton,
  makeStyles,
  TableRow,
  Theme,
  Tooltip,
  Typography,
  Avatar,
  createStyles
} from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import NewIcon from '@material-ui/icons/FiberNewOutlined';
import EditIcon from '@material-ui/icons/EditOutlined';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

import default_image from 'images/default_image.svg';
import axios from 'axios';
import aws from 'aws-sdk';
import Skeleton from 'react-loading-skeleton';
import BodyCell from 'components/BodyCell';
import { ucwords } from 'utils';
import { StandardConfirmationDialog } from 'components/AppDialog';
import { GET_DELETE_CATEGORY_URL } from 'constants/url';

interface Props {
  isLoadingData: boolean;
  index: number;
  checked: number[];
  imageCategories: string[];
  image: string;
  imageView: string;
  category: CategoryResponseModel;
  categories: CategoryResponseModel[];
  setCategories: React.Dispatch<React.SetStateAction<CategoryResponseModel[]>>;
  setImageCategories: React.Dispatch<React.SetStateAction<string[]>>;
  setIsLoadingData: React.Dispatch<React.SetStateAction<boolean>>;
  setImage: React.Dispatch<React.SetStateAction<string>>;
  setImageView: React.Dispatch<React.SetStateAction<string>>;
  handleIndividualChecked: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleClickEditCategory: React.MouseEventHandler;
  handleClickDeleteCategory: (index: number) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    newIcon: {
      color: green[500],
      fontSize: 30
    },
    actionIcon: {
      fontSize: 20
    },
    tableCellInner: {
      display: 'flex',
      alignItems: 'center'
    },
    nameTextCell: {
      display: 'flex',
      flexDirection: 'column',
      marginRight: theme.spacing(2)
    },
    checkBox: {
      '&&:hover': {
        backgroundColor: 'transparent'
      }
    },
    checkBoxSize: {
      fontSize: '16px'
    },
    square: {}
  })
);

const { REACT_APP_AWS_REGION, REACT_APP_AWS_ACCESS_KEY, REACT_APP_AWS_SECRET_KEY, REACT_APP_S3_BUCKET_NAME } = process.env;

aws.config.update({
  accessKeyId: REACT_APP_AWS_ACCESS_KEY,
  secretAccessKey: REACT_APP_AWS_SECRET_KEY,
  region: REACT_APP_AWS_REGION
});
const s3 = new aws.S3();

const BodyRow: FC<Props> = props => {
  const classes = useStyles();

  const {
    isLoadingData,
    index,
    checked,
    category,
    handleIndividualChecked,
    handleClickEditCategory,
    handleClickDeleteCategory
  } = props;

  const { idCategory, nameCategory, typeCategory, imageKey } = category;

  const [img, setImg] = useState<string>('');
  const [openDeleteCategoryDialog, setOpenDeleteCategoryDialog] = useState<boolean>(false);

  const deleteCategory: React.MouseEventHandler<HTMLButtonElement> = async event => {
    await axios.delete(GET_DELETE_CATEGORY_URL(idCategory));

    if (imageKey) {
      const object = { Key: `assets/${imageKey}` };
      const options = {
        Bucket: REACT_APP_S3_BUCKET_NAME!,
        Delete: { Objects: [object] }
      };
  
      await Promise.all([
        s3.deleteObjects(options, (err, data) => {
          if (err) {
            console.log(err, err.stack, data);
          }
        })
      ]);
    } 

    handleClickDeleteCategory(index);
    setOpenDeleteCategoryDialog(false);
  };

  const handleGetImageS3 = useCallback(async () => {
    let imageUrl = '';
    try {
      const signedUrlExpireSeconds = 60 * 5;
      const options = {
        Bucket: REACT_APP_S3_BUCKET_NAME,
        Key: `assets/${imageKey}`,
        Expires: signedUrlExpireSeconds
      };
      
      imageUrl = await new Promise((resolve, reject) => {
        s3.getSignedUrl('getObject', options, (err, url) => {
          err ? reject(err) : resolve(url);
        });
      });
      setImg(imageUrl);
    } catch (err) {
      console.error('err :', err)
      throw err;
    }
  }, [imageKey]);

  useEffect(() => {
    if (!imageKey) {
      return;
    }

    handleGetImageS3();
  }, [imageKey, category, handleGetImageS3]);

  return (
    <Fragment>
      <TableRow hover>
        <BodyCell cellWidth='5%' pR='10px' isComponent={true}>
          {!isLoadingData && (
            <Checkbox
              key={index}
              icon={<CheckBoxOutlineBlankIcon className={classes.checkBoxSize} />}
              checkedIcon={<CheckBoxIcon className={classes.checkBoxSize} />}
              name='category'
              edge='start'
              checked={checked.filter(checked => checked === idCategory).length ? true : false}
              value={idCategory}
              onChange={handleIndividualChecked}
              disableRipple
              tabIndex={-1}
            />
          )}
        </BodyCell>

        <BodyCell cellWidth='20%' isComponent={true}>
          <div className={classes.tableCellInner}>
            <div className={classes.nameTextCell}>
              <Typography variant='body1'>{isLoadingData ? <Skeleton width={150} /> : nameCategory}</Typography>
            </div>
            {category!.new! && <NewIcon className={classes.newIcon} />}
          </div>
        </BodyCell>

        <BodyCell cellWidth='20%' isComponent={true}>
          <div className={classes.tableCellInner}>
            <div className={classes.nameTextCell}>
              <Typography variant='body1'>{isLoadingData ? <Skeleton width={150} /> : ucwords(typeCategory).toUpperCase()}</Typography>
            </div>
          </div>
        </BodyCell>

        <BodyCell cellWidth='20%' isComponent={true}>
          {isLoadingData ? (
            <Skeleton width={100} />
          ) : img ? (
            <Avatar variant='square' src={img} className={classes.square} />
          ) : (
            <Avatar variant='square' src={default_image} className={classes.square} />
          )}
        </BodyCell>

        <BodyCell cellWidth='20%' align='center' isComponent={true}>
          {isLoadingData ? (
            <Skeleton width={100} />
          ) : (
            <Fragment>
              <Tooltip title={'Edit'} placement='top'>
                <IconButton size='small' onClick={handleClickEditCategory}>
                  <EditIcon className={classes.actionIcon} />
                </IconButton>
              </Tooltip>
              <Tooltip title={'Delete'} placement='top'>
                <IconButton size='small' onClick={event => setOpenDeleteCategoryDialog(true)}>
                  <DeleteIcon className={classes.actionIcon} />
                </IconButton>
              </Tooltip>
            </Fragment>
          )}
        </BodyCell>
      </TableRow>

      <StandardConfirmationDialog
        variant={'danger'}
        titleMessage={'Delete'}
        message={'Are you sure want to delete this data?'}
        open={openDeleteCategoryDialog}
        handleClose={() => setOpenDeleteCategoryDialog(false)}
        onConfirm={deleteCategory}
      />
    </Fragment>
  );
};

export default BodyRow;
