import React, { FC, Fragment, useState, useEffect, useCallback } from 'react';
import {
  Checkbox,
  IconButton,
  makeStyles,
  TableRow,
  Theme,
  Tooltip,
  Avatar,
  Typography,
  FormControlLabel,
  CircularProgress
} from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import NewIcon from '@material-ui/icons/FiberNewOutlined';
import EditIcon from '@material-ui/icons/EditOutlined';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

import default_image from 'images/default_image.svg';
import axios from 'axios';
import aws from 'aws-sdk';
import Skeleton from 'react-loading-skeleton';
import NumberFormat from 'react-number-format';
import { format } from 'date-fns';
import { StandardConfirmationDialog } from 'components/AppDialog';
import { AntSwitch } from 'components/CustomSwitch';
import BodyCell from 'components/BodyCell';

import { GET_DELETE_PROMOTION_URL } from 'constants/url';

interface Props {
  isLoadingData: boolean;
  index: number;
  checked: number[];
  promotion: PromotionModel;
  handleIndividualChecked: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleClickEditPromotion: React.MouseEventHandler;
  handleClickDeletePromotion: (index: number) => void;
  handleUpdateStatus: (indexStatus: number, status: boolean, id: number) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  newIcon: {
    color: green[500],
    fontSize: 30
  },
  actionIcon: {
    fontSize: 20
  },
  tableCellInner: {
    display: 'flex',
    alignItems: 'center'
  },
  nameTextCell: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: theme.spacing(2)
  },
  checkBox: {
    '&&:hover': {
      backgroundColor: 'transparent'
    }
  },
  checkBoxSize: {
    fontSize: '16px'
  }
}));

const { REACT_APP_AWS_REGION, REACT_APP_AWS_ACCESS_KEY, REACT_APP_AWS_SECRET_KEY, REACT_APP_S3_BUCKET_NAME } = process.env;

aws.config.update({
  accessKeyId: REACT_APP_AWS_ACCESS_KEY,
  secretAccessKey: REACT_APP_AWS_SECRET_KEY,
  region: REACT_APP_AWS_REGION
});
const s3 = new aws.S3();

const BodyRow: FC<Props> = props => {
  const classes = useStyles();
  const {
    isLoadingData,
    index,
    checked,
    promotion,
    handleIndividualChecked,
    handleClickEditPromotion,
    handleClickDeletePromotion,
    handleUpdateStatus
  } = props;

  const { id, title, description, code, image, discountType, discountAmount, startDate, endDate, isActive } = promotion;

  const [img, setImg] = useState<string>('');
  const [openDeletePromotionDialog, setOpenDeletePromotionDialog] = useState<boolean>(false);
  const [isSubmit, setSubmit] = useState<boolean>(false);
  const [currentIndex, setCurrentIndex] = useState<number>(-1);

  const deletePromotion: React.MouseEventHandler<HTMLButtonElement> = async event => {
    await axios.delete(GET_DELETE_PROMOTION_URL(id));

    if (image) {
      const object = { Key: `assets/promotions/${image}` };
      const options = {
        Bucket: REACT_APP_S3_BUCKET_NAME!,
        Delete: { Objects: [object] }
      };

      await Promise.all([
        s3.deleteObjects(options, (err, data) => {
          if (err) {
            console.log(err, err.stack, data);
          }
        })
      ]);
    }
    
    handleClickDeletePromotion(index);
    setOpenDeletePromotionDialog(false);
  };

  const handleStatusChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setSubmit(true);
    setCurrentIndex(index);
    handleUpdateStatus(index, isActive, id);
    setSubmit(false);
    setCurrentIndex(-1);
  };

  const handleGetImageS3 = useCallback(async () => {
    let imageUrl = '';
    try {
      const signedUrlExpireSeconds = 60 * 5;
      const options = {
        Bucket: REACT_APP_S3_BUCKET_NAME,
        Key: `assets/promotions/${image}`,
        Expires: signedUrlExpireSeconds
      };

      imageUrl = await new Promise((resolve, reject) => {
        s3.getSignedUrl('getObject', options, (err, url) => {
          err ? reject(err) : resolve(url);
        });
      });
      setImg(imageUrl);
    } catch (err) {
      console.error('err :', err);
      throw err;
    }
  }, [image]);

  useEffect(() => {
    if (!image) {
      return;
    }

    handleGetImageS3();
  }, [image, handleGetImageS3]);

  return (
    <Fragment>
      <TableRow hover>
        <BodyCell cellWidth='5%' pR='10px' isComponent={true}>
          {!isLoadingData &&
            (isSubmit && index === currentIndex ? (
              <CircularProgress size={20} />
            ) : (
              <Checkbox
                key={index}
                icon={<CheckBoxOutlineBlankIcon className={classes.checkBoxSize} />}
                checkedIcon={<CheckBoxIcon className={classes.checkBoxSize} />}
                name='promotion'
                edge='start'
                checked={checked.filter(checked => checked === id).length ? true : false}
                value={id}
                onChange={handleIndividualChecked}
                disableRipple
                tabIndex={-1}
              />
            ))}
        </BodyCell>

        <BodyCell cellWidth='30%' isComponent={true}>
          <div className={classes.tableCellInner}>
            <div className={classes.nameTextCell}>
              <Typography variant='body1'>{isLoadingData ? <Skeleton width={80} /> : title}</Typography>
            </div>
            {promotion!.new! && <NewIcon className={classes.newIcon} />}
          </div>
        </BodyCell>

        <BodyCell cellWidth='30%' isComponent={true}>
          {isLoadingData ? <Skeleton width={80} /> : description}
        </BodyCell>

        <BodyCell cellWidth='30%' isComponent={true}>
          {isLoadingData ? <Skeleton width={80} /> : code}
        </BodyCell>

        <BodyCell cellWidth='30%' isComponent={true}>
          {isLoadingData ? (
            <Skeleton width={80} />
          ) : (
            image && img ? (
              <Avatar variant='square' src={img} />
            ) : (
              <Tooltip title={'No Image'} placement='top'>
                <Avatar variant='square' src={default_image} />
              </Tooltip>
            )
          )
          }
        </BodyCell>

        <BodyCell cellWidth='30%' isComponent={true}>
          {isLoadingData ? (
            <Skeleton width={80} />
          ) : (
            discountType === 'PERCENT' ? (
              `${discountAmount} %`
            ) : (
              <NumberFormat value={discountAmount} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} fixedDecimalScale={true} />
            )
          )}
        </BodyCell>

        <BodyCell cellWidth='30%' isComponent={true}>
          {isLoadingData ? <Skeleton width={80} /> : `${format(new Date(startDate), 'dd/MM/yyyy')}`}
        </BodyCell>

        <BodyCell cellWidth='30%' isComponent={true}>
          {isLoadingData ? <Skeleton width={80} /> : `${format(new Date(endDate), 'dd/MM/yyyy')}`}
        </BodyCell>

        <BodyCell cellWidth='5%' isComponent={true}>
          {isLoadingData ? (
            <Skeleton width={80} />
          ) : (
            <FormControlLabel
              control={<AntSwitch checked={isActive} onChange={handleStatusChange} color='primary' />}
              label={isActive ? 'Active' : 'Inactive'}
            />
          )}
        </BodyCell>

        <BodyCell align='center' isComponent={true}>
          {isLoadingData ? (
            <Skeleton width={50} />
          ) : (
            <Fragment>
              <Tooltip title={'Edit'} placement='top'>
                <IconButton size='small' onClick={handleClickEditPromotion}>
                  <EditIcon className={classes.actionIcon} />
                </IconButton>
              </Tooltip>
              <Tooltip title={'Delete'} placement='top'>
                <IconButton size='small' onClick={event => setOpenDeletePromotionDialog(true)}>
                  <DeleteIcon className={classes.actionIcon} />
                </IconButton>
              </Tooltip>
            </Fragment>
          )}
        </BodyCell>
      </TableRow>

      <StandardConfirmationDialog
        variant={'danger'}
        titleMessage={'Delete'}
        message={'Are you sure want to delete this data?'}
        open={openDeletePromotionDialog}
        handleClose={() => setOpenDeletePromotionDialog(false)}
        onConfirm={deletePromotion}
      />
    </Fragment>
  );
};

export default BodyRow;
