import React, { FC, useState, Fragment, useEffect, useRef } from 'react';
import {
  Table,
  IconButton,
  TableBody,
  TableHead,
  Paper,
  TableRow,
  TableCell,
  makeStyles,
  Tooltip,
  ButtonGroup,
  Button,
  TextField,
  createStyles,
  Box
} from '@material-ui/core';
import Skeleton from 'react-loading-skeleton';
import NumberFormat from 'react-number-format';
import { CheckBoxRounded, CancelRounded } from '@material-ui/icons';
import EditIcon from '@material-ui/icons/EditOutlined';
import ItemType from 'typings/enum/ItemType';
import axios, { CancelTokenSource } from 'axios';
import { green } from '@material-ui/core/colors';
import { GET_EDIT_SERVICE_ITEM_URL, GET_EDIT_SERVICE_URL, GET_EDIT_INVOICE_BY_SERVICE_URL } from 'constants/url';
import DeliveryType from 'typings/enum/DeliveryType';
import NumberFormatCustom from 'components/NumberFormatCustom';

interface Props {
  serviceItems: ServiceItemModel[];
  isLoading: boolean;
  jobStatus: string;
  totalAmount: number;
  deliveryType: string;
  returnServiceItems: ServiceItemModel[];
  serviceDiscounts: ServiceDiscountModel[];
  setServiceItems: React.Dispatch<React.SetStateAction<ServiceItemModel[]>>;
  setReturnServiceItems: React.Dispatch<React.SetStateAction<ServiceItemModel[]>>;
}

const useStyles = makeStyles(() =>
  createStyles({
    odd: {
      background: '#F9F9FB'
    },
    even: {
      background: '#fff'
    },
    actionIcon: {
      fontSize: 20
    },
    textFieldFont: {
      fontSize: '13px',
      height: 18
    },
    cellQuantity: {
      width: '100px'
    }
  })
);

const defaultServiceItem = {
  id: 0,
  serviceType: '',
  serviceItemName: '',
  unitPrice: 0,
  quantity: 0,
  quantityOriginal: 0,
  totalPrice: 0,
  itemType: '',
  unit: '',
  remarks: '',
  Category: {
    id: 0,
    name: '',
    type: '',
    image: ''
  }
};

const ServiceItemTable: FC<Props> = props => {
  const { serviceItems, returnServiceItems, serviceDiscounts, totalAmount, deliveryType, setServiceItems, setReturnServiceItems, jobStatus, isLoading } = props;
  const classes = useStyles();
  const th = ['Laundry Type', 'Service Category', 'Service Item', 'Quantity', 'Price', 'Amount', 'Action'];
  const statusAllowChangeAndEdits: string[] = ['UNASSIGNED', 'ASSIGNED_FOR_COLLECTION', 'COLLECTING', 'COLLECTED', 'CHECKING'];

  const [isEdits, setEdits] = useState<boolean[]>([]);
  const [lengthSqFt, setLengthSqFt] = useState<number>(0);
  const [widthSqFt, setWidthSqFt] = useState<number>(0);
  
  const prevServiceItem = usePrevious(serviceItems);

  const handleClickEdit = (index: number) => {
    const currentIsEdits = [...isEdits];
    currentIsEdits[index] = true;
    
    setEdits(currentIsEdits);
    setLengthSqFt(0);
    setWidthSqFt(0);
  };

  const handleClickSave = async (index: number) => {
    const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();
    const currentIsEdits = [...isEdits];
    currentIsEdits[index] = false;
    
    setEdits(currentIsEdits);

    const idServiceItem = serviceItems[index].id;
    if (serviceItems.filter(value => value.id).length) {
      
      const result = await axios.put(
        GET_EDIT_SERVICE_ITEM_URL(idServiceItem),
        { 
          quantity: serviceItems[index].quantity,
          quantityOriginal: serviceItems[index].quantity > serviceItems[index].quantityOriginal ? serviceItems[index].quantity : serviceItems[index].quantityOriginal,
          totalPrice: serviceItems[index].totalPrice
        },
        { cancelToken: cancelTokenSource.token }
      );
      
      const { data } = result;
      
      if (returnServiceItems.filter(value => value.id == data.id).length) {
        if ((data.quantityOriginal - data.quantity) == 0) {
          setReturnServiceItems([...returnServiceItems.filter(value => value.id !== data.id)]);
        } else {
          setReturnServiceItems(
            [...returnServiceItems!.map(value => {
              if (value.id !== data.id) {
                return value;
              }
              
              return Object.assign({}, value, { quantity: Number(`-${data.quantityOriginal - data.quantity}`), totalPrice: Number(`-${(data.quantityOriginal - data.quantity) * data.unitPrice }`)});
            })]
          );
        }
      } else {
        if (data.quantityOriginal - data.quantity > 0) {
          const currentReturnServiceItems = [...returnServiceItems];
          data.Category = serviceItems[index].Category;
          data.quantity = data.quantityOriginal - data.quantity;
          data.totalPrice = (data.quantityOriginal - data.quantity) * data.unitPrice;
          currentReturnServiceItems.push(data);
          setReturnServiceItems([...currentReturnServiceItems]);
        }
      }

      let additionalDeliveryService = 0;

      if (deliveryType === DeliveryType.TWO_DAY_EXPRESS) {
        additionalDeliveryService = data.sumTotalPrice * 0.5;
      } else if (deliveryType === DeliveryType.ONE_DAY_EXPRESS) {
        additionalDeliveryService = data.sumTotalPrice;
      } else if (deliveryType === DeliveryType.SUPER_EXPRESS) {
        additionalDeliveryService = data.sumTotalPrice + data.sumTotalPrice;
      }

      const subTotal = Number(data.sumTotalPrice) + Number(additionalDeliveryService);
      let totalAmountDiscount = 0;
      serviceDiscounts.map(value => {
        if (value.discountType === 'PERCENT') {
          totalAmountDiscount += subTotal * (Number(value.discountAmount) / 100);
        } else {
          totalAmountDiscount += Number(value.discountAmount);
        }
      });
      
      const newTotalAmount = Number(data.sumTotalPrice) + Number(additionalDeliveryService) - totalAmountDiscount;

      await axios.put(
        GET_EDIT_SERVICE_URL(data.ServiceId),
        {
          totalAmount: newTotalAmount,
          discountAmount: totalAmountDiscount
        },
        { cancelToken: cancelTokenSource.token }
      );

      await axios.put(
        GET_EDIT_INVOICE_BY_SERVICE_URL(data.ServiceId),
        { totalPrice: newTotalAmount },
        { cancelToken: cancelTokenSource.token }
      );
    }

  };

  const handleClickCancel = (index: number) => {
    const currentIsEdits = [...isEdits];
    currentIsEdits[index] = false;

    setServiceItems(serviceItems.map((value, idx) => {
      if (idx === index) {
        value.quantity = prevServiceItem[idx].quantity;
        value.totalPrice = prevServiceItem[idx].totalPrice;
      }
      return value;
    }));
    
    setEdits(currentIsEdits);
  };

  const handleIncrement = (index: number) => {
    const newServiceItems = [...serviceItems];
    newServiceItems[index].quantity = Number(serviceItems[index].quantity) + 1;
    newServiceItems[index].quantityOriginal = Number(serviceItems[index].quantity);
    newServiceItems[index].totalPrice = Number(serviceItems[index].quantity) * serviceItems[index].unitPrice;

    setServiceItems(newServiceItems);
  };

  const handleDecrement = (index: number) => {
    const newServiceItems = [...serviceItems];
    newServiceItems[index].quantity = Number(serviceItems[index].quantity) - 1;
    newServiceItems[index].totalPrice = Number(serviceItems[index].quantity) * serviceItems[index].unitPrice;

    setServiceItems(newServiceItems);
  };

  const handleCountSqFeetAndKg = (index: number, quantity: number) => {
    const newServiceItems = [...serviceItems];
    const newSubTotel = quantity * serviceItems[index].unitPrice;
    newServiceItems[index].quantity = Number(quantity.toFixed(2));
    newServiceItems[index].totalPrice = Number(newSubTotel.toFixed(2));
    setServiceItems(newServiceItems);
  };

  const handleEditNaItem = (index: number, totalPrice: number) => {
    const newServiceItems = [...serviceItems];
    newServiceItems[index].totalPrice = totalPrice;
    setServiceItems(newServiceItems);
  }

  const cell = (value: any) => (isLoading ? <Skeleton width='100%' /> : value);

  const rows = (value: ServiceItemModel, index: number) => {
    let category = '-';
    let serviceType = 'Laundry';

    if (value.serviceType == 'DC') {
      serviceType = 'Dry Clean';
    }

    if (value.Category) {
      category = value.Category.name;
    }

    return (
      <TableRow className={index % 2 === 0 ? classes.odd : classes.even} key={index + 1}>
        <TableCell align='center'>{cell(serviceType)}</TableCell>
        <TableCell align='center'>{cell(category)}</TableCell>
        <TableCell align='center'>{cell(`${value.serviceItemName}`)}</TableCell>
        <TableCell align='center' className={classes.cellQuantity}>
          {!isEdits[index] || value.unit === 'NA' ? (
            cell(
              <NumberFormat
                value={value.quantity}
                displayType={'text'}
                thousandSeparator={true}
                prefix={''}
                decimalScale={value.itemType! === ItemType.MEASURE_ON_SITE ? 2 : 0}
                fixedDecimalScale={true}
              />
            )
          ) : value.unit === 'KG' ? (
            <TextField
              margin='dense'
              fullWidth
              variant='outlined'
              id='quantity'
              label='Quantity'
              autoComplete='off'
              value={value.quantity}
              onChange={event => {
                handleCountSqFeetAndKg(index, Number(+event.target.value));
              }}
              InputProps={{
                classes: {
                  input: classes.textFieldFont
                },
                inputComponent: NumberFormatCustom as any,
                inputProps: {
                  thousandSeparator: true,
                  decimalScale: 2,
                  fixedDecimalScale: true
                }
              }}
              InputLabelProps={{
                className: classes.textFieldFont
              }}
            />
          ) : value.unit === 'SQ_FT' ? (
            <Fragment>
              <TextField
                margin='dense'
                fullWidth
                variant='outlined'
                id='length'
                label='length'
                autoComplete='off'
                value={lengthSqFt}
                onChange={event => {
                  setLengthSqFt(+event.target.value);
                  handleCountSqFeetAndKg(index, Number(+event.target.value * (widthSqFt > 0 ? widthSqFt : 1)));
                }}
                InputProps={{
                  classes: {
                    input: classes.textFieldFont
                  },
                  inputComponent: NumberFormatCustom as any,
                  inputProps: {
                    thousandSeparator: true,
                    decimalScale: 2,
                    fixedDecimalScale: true
                  }
                }}
                InputLabelProps={{
                  className: classes.textFieldFont
                }}
              />
              <TextField
                margin='dense'
                fullWidth
                variant='outlined'
                id='width'
                label='Width'
                autoComplete='off'
                value={widthSqFt}
                onChange={event => {
                  setWidthSqFt(+event.target.value);
                  handleCountSqFeetAndKg(index, Number(+event.target.value * (lengthSqFt > 0 ? lengthSqFt : 1)));
                }}
                InputProps={{
                  classes: {
                    input: classes.textFieldFont
                  },
                  inputComponent: NumberFormatCustom as any,
                  inputProps: {
                    thousandSeparator: true,
                    decimalScale: 2,
                    fixedDecimalScale: true
                  }
                }}
                InputLabelProps={{
                  className: classes.textFieldFont
                }}
              />
            </Fragment>
          ) : (
            <ButtonGroup size='small' aria-label='small outlined button group'>
              {value.quantity > 0 && (
                <Button color='primary' variant='contained' onClick={event => handleDecrement(index)}>
                  -
                </Button>
              )}
              {value.quantity > 0 && (
                <Button>
                  <NumberFormat
                    value={value.quantity}
                    displayType={'text'}
                    thousandSeparator={true}
                    prefix={''}
                    decimalScale={value.itemType! === ItemType.MEASURE_ON_SITE ? 2 : 0}
                    fixedDecimalScale={true}
                  />
                </Button>
              )}
              <Button color='primary' variant='contained' onClick={event => handleIncrement(index)}>
                +
              </Button>
            </ButtonGroup>
          )}
        </TableCell>
        <TableCell align='center'>
          {cell(
            <NumberFormat
              value={value.unitPrice}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'$'}
              decimalScale={2}
              fixedDecimalScale={true}
            />
          )}
        </TableCell>
        <TableCell align='center'>
          {isEdits[index] && value.unit === 'NA' ? (
            <TextField
              margin='dense'
              fullWidth
              variant='outlined'
              id='totalPrice'
              label='Amount'
              autoComplete='off'
              value={value.totalPrice}
              onChange={event => {
                handleEditNaItem(index, Number(+event.target.value));
              }}
              InputProps={{
                classes: {
                  input: classes.textFieldFont
                },
                inputComponent: NumberFormatCustom as any,
                inputProps: {
                  thousandSeparator: true,
                  decimalScale: 2,
                  fixedDecimalScale: true
                }
              }}
              InputLabelProps={{
                className: classes.textFieldFont
              }}
            />
          ) : (
            cell(
              <NumberFormat
                value={value.totalPrice}
                displayType={'text'}
                thousandSeparator={true}
                prefix={'$'}
                decimalScale={2}
                fixedDecimalScale={true}
              />
            )
          )}
        </TableCell>
        <TableCell align='center'>
          {!isEdits[index] ? (
            <IconButton size='small' onClick={event => handleClickEdit(index)} disabled={!statusAllowChangeAndEdits.includes(jobStatus)}>
              <EditIcon className={classes.actionIcon} />
            </IconButton>
          ) : (
            <Fragment>
              <Tooltip title='Save Changes'>
                <IconButton size='small'>
                  <CheckBoxRounded style={{ color: green[500] }} onClick={event => handleClickSave(index)} />
                </IconButton>
              </Tooltip>

              <Tooltip title='Cancel'>
                <IconButton size='small'>
                  <CancelRounded onClick={event => handleClickCancel(index)} />
                </IconButton>
              </Tooltip>
            </Fragment>
          )}
        </TableCell>
      </TableRow>
    );
  };

  return (
    <Paper variant='outlined' style={{ width: '100%', overflow: 'hidden' }}>
      <Table>
        <TableHead>
          <TableRow>
            {th.map((val, i) => (
              <TableCell key={i + 1} align='center'>
                {val}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoading ? (
            [1, 2, 3, 4].map(index => rows(defaultServiceItem, index))
          ) : serviceItems.length > 0 ? (
            <Fragment>
              {serviceItems.map((value, index) => rows(value, index))}
              <TableRow>
                <TableCell colSpan={5} align='right'>
                  <Box fontWeight='fontWeightBold' m={1}>
                    Total Amount
                  </Box>
                </TableCell>
                <TableCell align='center'>
                  <Box fontWeight='fontWeightBold' m={1}>
                    {cell(
                      <NumberFormat
                        value={serviceItems.reduce((sum, current) => sum + Number(current.totalPrice), 0)}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={'$'}
                        decimalScale={2}
                        fixedDecimalScale={true}
                      />
                    )}
                  </Box>
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            </Fragment>
          ) : (
            <Fragment>
              <TableRow>
                <TableCell colSpan={7} align='center'>
                  Data not available
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={5} align='right'>
                  <Box fontWeight='fontWeightBold' m={1}>
                    Total Amount
                  </Box>
                </TableCell>
                <TableCell align='center'>
                  <Box fontWeight='fontWeightBold' m={1}>
                    -
                  </Box>
                </TableCell>
                <TableCell align='center'>
                </TableCell>
              </TableRow>
            </Fragment>
          )}
        </TableBody>
      </Table>
    </Paper>
  );
};

const usePrevious = (value: ServiceItemModel[]) => {
  const ref = useRef<number>(0);
  const originalServiceItems = useRef<any[]>([]);
  
  useEffect(() => {
    if (ref.current == 2) {
      return;
    }

    value.map((value, index) => {
      originalServiceItems.current[index] = { quantity: value.quantity, totalPrice: value.totalPrice };
    });

    ref.current = ref.current + 1;
    
  }, [value]);
  
  return originalServiceItems.current;
}

export default ServiceItemTable;
