import { format } from 'date-fns';
export const isValidEmail = (email: string): boolean => {
  var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const isStrongPassword = (password: string): boolean => {
  var regularExpression = /^(?=.{8,})(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+=,.:;?<>|~{}`\[\]/])/;
  return regularExpression.test(String(password));
};

export const ucwords = (str: string) => {
  return (str + '').toLowerCase().replace(/_/g, ' ').replace(/^([a-z])|\s+([a-z])/g, function ($1) {
    return $1.toUpperCase();
  });
};

export const min = format(new Date(), 'yyyy-MM-dd') + 'T00:00';

export const ucWords = (text: string): string => text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
