import React, { useState, useEffect, useCallback, Fragment } from 'react';
import { MenuItem, Avatar, Badge, ButtonBase, TextField, Theme, TableRow, IconButton, FormControl, InputLabel, Select, Tooltip } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/styles';
import BodyCell from 'components/BodyCell';

import aws from 'aws-sdk';
import SaveIcon from '@material-ui/icons/Save';
import RevertIcon from '@material-ui/icons/NotInterestedOutlined';
import UploadIcon from '@material-ui/icons/CloudUpload';
import CancelImageIcon from '@material-ui/icons/Close';

import { ucwords } from 'utils';
import CategoryType from 'typings/enum/CategoryType';

interface Error {
  name: boolean;
  nameMessage: string;
}

interface Props {
  name: string;
  type: string;
  image: string;
  imageView: string;
  imageError: string;
  isSubmitting: boolean;
  error: Error;
  openEditForm: boolean;
  setError: React.Dispatch<React.SetStateAction<Error>>;
  setName: React.Dispatch<React.SetStateAction<string>>;
  setType: React.Dispatch<React.SetStateAction<string>>;
  setImage: React.Dispatch<React.SetStateAction<string>>;
  setImageView: React.Dispatch<React.SetStateAction<string>>;
  setOldImageEdited: React.Dispatch<React.SetStateAction<string>>
  onSubmit: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onCancel: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonContainer: {
      display: 'flex'
    },
    input: {
      display: 'none'
    },
    button: {
      margin: 10
    },
    bigAvatar: (props: Props) => ({
      width: 30,
      height: 30,
      color: `${props.imageError !== '' ? '#f44336' : '#C4C4C4'}`,
      backgroundColor: '#FFFFFF',
      boxShadow: `0px 1px 1px 1px ${props.imageError !== '' ? '#f44336' : ''}`
    }),
    spacingNewLine: {
      marginBottom: theme.spacing(1.5)
    },
    buttonBaseStyle: {
      position: 'relative',
      [theme.breakpoints.down('xs')]: {
        width: '100% !important'
      }
    },
    inputImageStyle: {
      display: 'none'
    },
    cancelImage: {
      fontSize: '12px',
      cursor: 'pointer'
    }
  })
);

const { REACT_APP_AWS_REGION, REACT_APP_AWS_ACCESS_KEY, REACT_APP_AWS_SECRET_KEY, REACT_APP_S3_BUCKET_NAME } = process.env;

aws.config.update({
  accessKeyId: REACT_APP_AWS_ACCESS_KEY,
  secretAccessKey: REACT_APP_AWS_SECRET_KEY,
  region: REACT_APP_AWS_REGION
});
const s3 = new aws.S3();

const CategoryFormField: React.FC<Props> = (props: Props) => {
  const classes = useStyles(props);

  const { name, type, image, error, setError, setName, setType, onSubmit, onCancel } = props;
  const { isSubmitting, setImage, imageView, setImageView, openEditForm, setOldImageEdited } = props;
  const allType = Object.entries(CategoryType).map(([value]) => value);
  const [typeOption, setTypeOption] = useState<string[]>(allType);

  const handleGetImageS3 = useCallback(async () => {
    let imageUrl = '';
    try {
      const signedUrlExpireSeconds = 60 * 5;
      const options = {
        Bucket: REACT_APP_S3_BUCKET_NAME,
        Key: `assets/${image}`,
        Expires: signedUrlExpireSeconds
      };

      imageUrl = await new Promise((resolve, reject) => {
        s3.getSignedUrl('getObject', options, (err, url) => {
          err ? reject(err) : resolve(url);
        });
      });
      setImageView(imageUrl);
    } catch (err) {
      console.error('err :', err);
      throw err;
    }
  }, [image]);

  useEffect(() => {
    if (!image) {
      return;
    }

    if (openEditForm) {
      handleGetImageS3();
    }
  }, [image, openEditForm, handleGetImageS3]);

  const handleChooseImage = (event: any) => {
    let selectedImage;
    let selectedImageView;
    if (event.target.files[0] === undefined) {
      selectedImage = '';
      selectedImageView = '';
    } else {
      selectedImage = event.target.files[0];
      selectedImageView = URL.createObjectURL(event.target.files[0]);
    }

    if (openEditForm) {
      setOldImageEdited(image);
    } else {
      setOldImageEdited('');
    }
    
    setImage(selectedImage);
    setImageView(selectedImageView);
  };

  const cancelImage = () => {
    setImage('');
    setImageView('');
  };
  
  const renderImageComponent = () => {
    if (imageView === '') {
      return (
        <Fragment>
          <input
            accept='image/*'
            className={classes.inputImageStyle}
            id='outlined-button-file'
            type='file'
            onChange={event => handleChooseImage(event)}
          />
          <label htmlFor='outlined-button-file'>
            <ButtonBase focusRipple key={'Upload'} component='span' disableRipple>
              <Tooltip title='Choose an image' placement='top'>
                <Avatar className={classes.bigAvatar}>
                  <UploadIcon fontSize='large' />
                </Avatar>
              </Tooltip>
            </ButtonBase>
          </label>
        </Fragment>
      );
    } else {
      return (
        <Badge badgeContent={<CancelImageIcon className={classes.cancelImage} onClick={event => cancelImage()} />} color='primary'>
          <input
            accept='image/*'
            className={classes.inputImageStyle}
            id='outlined-button-file'
            type='file'
            onChange={event => handleChooseImage(event)}
          />
          <label htmlFor='outlined-button-file'>
            <ButtonBase focusRipple key={'Upload'} className={classes.buttonBaseStyle} component='span' disableRipple>
              <Tooltip title='Choose an image' placement='top'>
                <Avatar alt='Image' src={imageView} className={classes.bigAvatar} />
              </Tooltip>
            </ButtonBase>
          </label>
        </Badge>
      );
    }
  };

  return (
    <TableRow>
      <BodyCell></BodyCell>

      <BodyCell>
        <TextField
          required
          variant='outlined'
          margin='dense'
          label='Name'
          value={name}
          error={error.name}
          helperText={error.nameMessage}
          onFocus={() => setError({ ...error, name: false, nameMessage: '' })}
          onChange={event => setName(event.target.value)}
        />
      </BodyCell>

      <BodyCell>
        <FormControl fullWidth variant='outlined' margin='dense'>
          <InputLabel id='unit'>Unit</InputLabel>
          <Select labelId='unit' required id='unit' label='unit' value={type || CategoryType.LA_DC} onChange={event => setType(String(event.target.value))}>
            {typeOption.map((value: string, index: number) => (
              <MenuItem key={index + 1} value={value}>
                {ucwords(value).toUpperCase()}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </BodyCell>

      <BodyCell>{renderImageComponent()}</BodyCell>

      <BodyCell pL='110px' cellWidth='5%'>
        <div className={classes.buttonContainer}>
          <Tooltip title={'Save'}>
            <IconButton disabled={isSubmitting} aria-label='done' onClick={onSubmit}>
              <SaveIcon color='primary' fontSize='small' />
            </IconButton>
          </Tooltip>
          <Tooltip title={'Cancel'}>
            <IconButton aria-label='revert' onClick={onCancel}>
              <RevertIcon color='disabled' fontSize='small' />
            </IconButton>
          </Tooltip>
        </div>
      </BodyCell>
    </TableRow>
  );
};

export default CategoryFormField;
