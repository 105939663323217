import React, { FC, Fragment, useState, useEffect, useCallback } from 'react';
import { Grid, makeStyles, Theme, Typography, IconButton, Button, Divider, Tooltip } from '@material-ui/core';

import axios, { CancelTokenSource } from 'axios';
import { BLOCKED_DATE_BASE_URL, BULK_DELETE_BLOCKED_DATE_URL } from 'constants/url';
import useCurrentPageTitleUpdater from 'hooks/useCurrentPageTitleUpdater';
import { StandardConfirmationDialog } from 'components/AppDialog';
import DeleteIcon from '@material-ui/icons/Delete';
import Restore from '@material-ui/icons/Restore';
import SearchInput from 'components/SearchInput';
import useDebounce from 'hooks/useDebounce';
import BlockedDateTable from './components/BlockedDateTable';
import TableFilter from './components/TableFilter';

const useStyles = makeStyles((theme: Theme) => ({
  titleWrapper: {
    marginBottom: theme.spacing(2)
  },
  addButton: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1)
  },
  icon: {
    fontSize: 25,
    color: '#FF0909'
  },
  deleteText: {
    color: '#FF0909',
    paddingTop: 5,
    paddingLeft: 10
  }
}));

const dummyBlockedDates: BlockedDateModel[] = [
  {
    id: 0,
    startDate: null,
    endDate: null,
    startTime: '',
    endTime: '',
    isHoliday: false,
    isActive: false
  }
];

const defaultFilter = {
  startDate: '',
  endDate: '',
  startTime: '',
  endTime: '',
  isHoliday: '',
  isActive: ''
};

const BlockedDate: FC = () => {
  useCurrentPageTitleUpdater('BLOCKED DATE');
  const classes = useStyles();

  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [snackbarVariant, setSnackbarVariant] = useState<'success' | 'error'>('success');
  const [message, setMessage] = useState<string>('');

  const [isLoadingData, setIsLoadingData] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [openCreateForm, setOpenCreateForm] = useState<boolean>(false);
  const [openEditForm, setOpenEditForm] = useState<boolean>(false);
  const [openBulkDelete, setOpenBulkDelete] = useState<boolean>(false);
  const [count, setCount] = useState<number>(0);
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [blockedDates, setBlockedDates] = useState<BlockedDateModel[]>(dummyBlockedDates);
  const [checked, setChecked] = useState<number[]>([]);
  const [query, setQuery] = useState<string>('');
  const [queryString, setQueryString] = useState<string>();
  const [filter, setFilter] = useState<{ [keys: string]: string }>(defaultFilter);
  const [order, setOrder] = useState<'asc' | 'desc'>('desc');
  const [orderBy, setOrderBy] = useState<string>('createdAt');
  const [isReset, setReset] = useState<boolean>(false);

  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [startTime, setStartTime] = useState<Date | null>(null);
  const [endTime, setEndTime] = useState<Date | null>(null);
  const [isHoliday, setIsHoliday] = useState<boolean>(false);
  const [isActive, setIsActive] = useState<boolean>(false);
  const [id, setId] = useState<number>(0);

  const fetchData = useCallback(async () => {
    const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();
    setIsLoadingData(true);

    const getQueryParams = () => {
      const params = new URLSearchParams();

      if (debouncedSearchTerm) {
        if (queryString) params.append('q', queryString);
      }

      if (filter) {
        Object.entries(filter).map(([keys, value]) => {
          if (value !== '' && value !== undefined) {
            params.append('filter[' + keys + ']', value);
            setReset(true);
          }
          return [keys, value];
        });
      }
      params.append('s', (currentPage * rowsPerPage).toString());
      params.append('l', rowsPerPage.toString());
      return params.toString();
    };

    try {
      const result = await axios.get(BLOCKED_DATE_BASE_URL + '?' + getQueryParams(), { cancelToken: cancelTokenSource.token });
      setBlockedDates(result.data.BlockedDates);
      setCount(result.data.count);
      setIsLoadingData(false);
    } catch (err) {
      console.error('err: ', err);
      setIsLoadingData(false);
    }
    return () => cancelTokenSource.cancel();
  }, [rowsPerPage, currentPage, queryString, order, orderBy, filter]);

  const performActionAndRevertPage = (action: React.Dispatch<React.SetStateAction<any>>, actionParam: any) => {
    setCurrentPage(0);
    action(actionParam);
  };

  const handleSearch = useCallback((searchQuery: string) => {
    performActionAndRevertPage(setQueryString, searchQuery);
  }, []);

  const debouncedSearchTerm = useDebounce(query, 500);

  useEffect(() => {
    if (debouncedSearchTerm.length >= 3) {
      handleSearch(debouncedSearchTerm);
    } else if (debouncedSearchTerm.length === 0) {
      handleSearch(debouncedSearchTerm);
    }
  }, [debouncedSearchTerm, handleSearch]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleSnackBar = (open: boolean, variant: 'success' | 'error', message: string) => {
    setSnackbarVariant(variant);
    setOpenSnackbar(open);
    setOpenCreateForm(false);
    setOpenEditForm(false);
    setMessage(message);
  };

  const handleClickDeleteBlockedDate = (index: number) => {
    blockedDates.splice(index, 1);
    setBlockedDates([...blockedDates]);
    setCount(count => count - 1);
    handleSnackBar(true, 'success', 'Blocked Date has been deleted successfully.');
  };

  const handleBulkDelete = async () => {
    const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();
    try {
      await axios.post(BULK_DELETE_BLOCKED_DATE_URL, { id: checked }, { cancelToken: cancelTokenSource.token });
      setChecked([]);
      fetchData();
      handleSnackBar(true, 'success', 'Blocked Date has been deleted successfully.');
    } catch (err) {
      console.log(err);
      handleSnackBar(true, 'error', 'The Blocked Date has failed to delete.');
    }
    setOpenBulkDelete(false);
  };

  const handleResetFilter = () => {
    setFilter(defaultFilter);
    setReset(false);
  };

  const handleOpenCreateForm = () => {
    resetForm();
    setOpenCreateForm(!openCreateForm);
  };

  const resetForm = () => {
    setStartDate(null);
    setEndDate(null);
    setStartTime(null);
    setEndTime(null);
    setId(0);
    setIsHoliday(false);
    setIsActive(false);
  };

  return (
    <Fragment>
      <Grid container direction='row'>
        <Grid xs={12} className={classes.titleWrapper}>
          <Typography variant='caption' gutterBottom>
            Admin can block out collection and delivery date time.
          </Typography>
        </Grid>

        <StandardConfirmationDialog
          variant={snackbarVariant}
          titleMessage={snackbarVariant.charAt(0).toUpperCase() + snackbarVariant.slice(1) + '!'}
          message={message}
          open={openSnackbar}
          handleClose={() => setOpenSnackbar(false)}
          onConfirm={() => setOpenSnackbar(false)}
          noCancelButton={true}
        />
      </Grid>

      <Grid container direction='row'>
        <Grid container item lg={6} sm={6} md={6} justify='flex-start' alignItems='center'>
          <Grid item>
            <TableFilter filter={filter} setFilter={setFilter} />
            {isReset && (
              <Tooltip title='Reset Filter'>
                <IconButton area-label='reset' onClick={handleResetFilter}>
                  <Restore />
                </IconButton>
              </Tooltip>
            )}

            {checked.length !== 0 && (
              <Tooltip title='Delete selected item'>
                <IconButton size='small'>
                  <DeleteIcon className={classes.icon} onClick={() => setOpenBulkDelete(true)} />
                </IconButton>
              </Tooltip>
            )}
          </Grid>
        </Grid>

        <Grid container item lg={6} sm={6} md={6} justify='flex-end' alignItems='center'>
          <Button color='primary' size='medium' variant='contained' disabled={openEditForm} onClick={handleOpenCreateForm}>
            Add New
          </Button>
        </Grid>
      </Grid>

      <BlockedDateTable
        isLoadingData={isLoadingData}
        count={count}
        checked={checked}
        currentPage={currentPage}
        rowsPerPage={rowsPerPage}
        blockedDates={blockedDates}
        order={order}
        orderBy={orderBy}
        setOrder={setOrder}
        setOrderBy={setOrderBy}
        blockedDate={blockedDates[currentIndex]}
        openCreateForm={openCreateForm}
        openEditForm={openEditForm}
        currentIndex={currentIndex}
        setBlockedDates={setBlockedDates}
        setCurrentIndex={setCurrentIndex}
        setOpenCreateForm={setOpenCreateForm}
        setOpenEditForm={setOpenEditForm}
        startDate={startDate}
        endDate={endDate}
        startTime={startTime}
        endTime={endTime}
        id={id}
        isHoliday={isHoliday}
        isActive={isActive}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        setStartTime={setStartTime}
        setEndTime={setEndTime}
        setId={setId}
        setIsHoliday={setIsHoliday}
        setIsActive={setIsActive}
        handleChangePage={(event, page) => setCurrentPage(page)}
        handleChangeRowsPerPage={event => performActionAndRevertPage(setRowsPerPage, +event.target.value)}
        handleSnackBar={handleSnackBar}
        handleClickDeleteBlockedDate={handleClickDeleteBlockedDate}
        setChecked={setChecked}
      />

      <StandardConfirmationDialog
        variant={'danger'}
        titleMessage={'Delete'}
        message={'Are you sure want to delete this data?'}
        open={openBulkDelete}
        handleClose={() => setOpenBulkDelete(false)}
        onConfirm={handleBulkDelete}
      />
    </Fragment>
  );
};

export default BlockedDate;
