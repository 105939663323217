import React, { useState, useEffect, Fragment } from 'react';
import HeaderRow from 'components/HeaderRow';
import BodyRow from './components/BodyRow';

import { makeStyles } from '@material-ui/styles';
import {
  Grid,
  Theme,
  Table,
  TableBody,
  TableHead,
  Paper
} from '@material-ui/core';

interface Props {
  serviceItems: ServiceItemModel[];
}

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    marginBottom: theme.spacing(2)
  },
  controlDiv: {
    '& > :nth-child(n+2)': {
      marginLeft: theme.spacing(2)
    }
  },
  list: {
    padding: theme.spacing(0),
    width: '50%'
  },
  paperWrapper: {
    overflow: 'hidden',
    width: '100%',
    maxHeight: '252px',
    overflowY: 'scroll'
  },
  table: {
    width: '100%'
  },
  itemContainer: {
    marginBottom: theme.spacing(2),
    border: '0.5px solid grey',
    borderRadius: '10px',
    minHeight: '270px'
  },
  itemTitle: {
    fontWeight: 'bold',
    color: 'grey'
  },
  itemDetail: {
    padding: theme.spacing(1),
    width: '50%',
    fontWeight: 'bold'
  }
}));

const DetailServiceItems: React.FC<Props> = props => {
  const classes = useStyles();

  const { serviceItems } = props;
  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={classes.itemContainer}>
        <Fragment>
          <Paper variant='outlined' elevation={2} className={classes.paperWrapper}>
            <Table size='small' className={classes.table}>
              <TableHead>
                <HeaderRow
                  headers={[{ label: 'Service Category' }, { label: 'Service Item' }, { label: 'Qty' }, { label: 'Price' }, { label: 'Amount' }]}
                />
              </TableHead>
              <TableBody>
                  {
                    serviceItems.map((serviceItem, index) => (
                        <BodyRow
                          index={index}
                          key={index}
                          serviceItem={serviceItem}
                        />
                    ))
                  }
              </TableBody>
            </Table>
          </Paper>
        </Fragment>
      </Grid>
    </Grid>
  );
};

export default DetailServiceItems;
