import React, { useState, useCallback, useEffect, Fragment } from 'react';
import { IconButton, Theme, makeStyles, Modal, Typography, Grid } from '@material-ui/core';
import axios, { CancelTokenSource } from 'axios';
import CloseIcon from '@material-ui/icons/Close';
import CreateEditUserForm from './components/CreateEditUserForm';
import { USER_BASE_URL, GET_EDIT_USER_URL } from 'constants/url';
import { isValidEmail } from 'utils';
import { isStrongPassword } from '../../../../../../utils';
import { validate } from '@material-ui/pickers';

interface Props {
  user?: UserDetailsModel;
  open: boolean;
  edit: boolean;
  addNewUser(user: UserDetailsModel): void;
  updateIndividualUser: (updatedUserProperties: Partial<UserDetailsModel>) => void;
  handleCancel(): void;
  setOpenSnackbar: React.Dispatch<React.SetStateAction<boolean>>;
  setSnackbarVarient: React.Dispatch<React.SetStateAction<'success' | 'error'>>;
  handleSetMessageSuccess: (message: string) => void;
  handleSetMessageError: (message: string) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    position: 'absolute',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    outline: 'none',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: 4
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  },
  title: {
    marginBottom: theme.spacing(2),
    paddingLeft: 20
  }
}));

const CreateEditUserModal: React.FC<Props> = props => {
  const classes = useStyles();
  let cancelTokenSource: CancelTokenSource;

  const {
    user,
    open,
    edit,
    addNewUser,
    updateIndividualUser,
    handleCancel,
    setOpenSnackbar,
    setSnackbarVarient,
    handleSetMessageSuccess,
    handleSetMessageError
  } = props;

  const [isLoading, setLoading] = useState<boolean>(false);
  const [displayName, setDisplayName] = useState<string>('');
  const [userId, setUserId] = useState<number>(0);
  const [email, setEmail] = useState<string>('');
  const [contactNumber, setContactNumber] = useState<string>('');
  const [role, setRole] = useState<string>('DRIVER');
  const [password, setPassword] = useState<string>('');
  const [isShowPassword, setShowPassword] = useState<boolean>(false);

  const [displayNameError, setDisplayNameError] = useState<string>('');
  const [emailError, setEmailError] = useState<string>('');
  const [contactNumberError, setContactNumberError] = useState<string>('');
  const [passwordError, setPasswordError] = useState<string>('');
  const [isValid, setValid] = useState<boolean>(true);
  const [isLoadingEmail, setLoadingEmail] = useState<boolean>(false);
  const [isLoadingContact, setLoadingContact] = useState<boolean>(false);

  const resetFormValues = useCallback(() => {
    if (!user) {
      return;
    }

    const { id, displayName, email, contactNumber, role } = user;

    setUserId(id);
    setDisplayName(displayName);
    setEmail(email);
    setContactNumber(contactNumber);
    setRole(role);
    setPassword(password);
  }, [user]);

  // This to ensure the form value and errors are reset/cleared when selecteduser item changes
  // resetFormValues will be modified everytime user item changes, due to useCallback
  useEffect(() => {
    resetFormValues();
    clearFormErrors();
  }, [resetFormValues]);

  const handleClose = () => {
    handleCancel();
    clearFormValue();
    clearFormErrors();
  };

  const clearFormValue = () => {
    setDisplayName('');
    setEmail('');
    setContactNumber('');
    setPassword('');
    setRole('DRIVER');
  };

  const clearFormErrors = () => {
    setDisplayNameError('');
    setEmailError('');
    setContactNumberError('');
    setPasswordError('');
  };

  const validateForm = () => {
    let returnValidate = true;
    clearFormErrors();
    if (!email || !email.trim()) {
      setEmailError('User email can not be empty.');
      returnValidate = false;
    } else if (!contactNumber || !contactNumber.trim()) {
      setContactNumberError('Contact number can not be empty.');
      returnValidate = false;
    } else if (!displayName || !displayName.trim()) {
      setDisplayNameError('User name can not be empty.');
      returnValidate = false;
    }

    if (!edit) {
      returnValidate = handlePasswordValidation(password);
    }

    return returnValidate;
  };

  const handlePasswordValidation = (passwordCheck: string) => {
    let returnHandle = true;
    if (!passwordCheck) {
      setPasswordError('Password can not be empty.');
      returnHandle = false;
    } else if (passwordCheck.length < 8) {
      setPasswordError('Password at least 8 characters');
      returnHandle = false;
    } else if (!isStrongPassword(passwordCheck)) {
      setPasswordError('Password at least containing 1 uppercase letter, 1 number and 1 symbol');
      returnHandle = false;
    }

    if (returnHandle) {
      setPasswordError('');
    }

    return returnHandle;
  };

  const handleEmailValidation = async (emailCheck: string) => {
    let returnHandle = true;
    setEmailError('');

    if (!emailCheck || !emailCheck.trim()) {
      setEmailError('E-mail can not be empty.');
      returnHandle = false;
    } else {
      if (!isValidEmail(emailCheck)) {
        setEmailError('Invalid email address.');
        returnHandle = false;
      } else {
        setLoadingEmail(true);
        returnHandle = await handleCheckUser(emailCheck, 'email');

        if (!returnHandle) {
          setEmailError('E-mail address has been registered.');
        }

        setLoadingEmail(false);
      }
    }

    if (returnHandle) {
      setEmailError('');
    }

    return returnHandle;
  };

  const handleContactNumberValidation = async (contactNumberCheck: string) => {
    let returnHandle = true;
    setContactNumberError('');
    
    if (!contactNumberCheck) {
      setContactNumberError('Contact Number can not be empty.');
      returnHandle = false;
    } else {
      if (contactNumberCheck.length !== 8) {
        setContactNumberError('Contact Number must be 8 digits.');
        returnHandle = false;
      } else {
        setLoadingContact(true);
        returnHandle = await handleCheckUser(contactNumberCheck, 'contactNumber');

        if (!returnHandle) {
          setContactNumberError('Contact number has been registered.');
        }

        setLoadingContact(false);
      }
    }

    if (returnHandle) {
      setContactNumberError('');
    }

    return returnHandle;
  };

  const handleCheckUser = async (value: string, attribute: string) => {
    let returnHandle = true;
    try {
      const params = new URLSearchParams();
      params.append('params', value);
      params.append('role', role);

      if (edit) {
        params.append('userId', String(userId));
      }

      const result = await axios.get(`${USER_BASE_URL}/check?${params.toString()}`);
      const { isExist } = result.data;
      if (isExist) {
        returnHandle = false;
      }
    } catch (error) {
      console.log('error', error);
    }
    setValid(returnHandle);
    return returnHandle;
  };

  const handleOnSubmit: React.FormEventHandler = async event => {
    event.preventDefault();
    const validation = validateForm();
    const validateEmail = await handleEmailValidation(email);
    const validateContact = await handleContactNumberValidation(contactNumber);

    if (!validation || !isValid || !validateEmail || !validateContact) {
      return;
    } else {
      setLoading(true);
      try {
        cancelTokenSource = axios.CancelToken.source();
        if (edit === false) {
          const response = await axios.post(
            `${USER_BASE_URL}`,
            {
              displayName,
              email,
              contactNumber,
              role,
              password
            },
            { cancelToken: cancelTokenSource.token }
          );
          addNewUser(response.data);
          handleSetMessageSuccess('Successfully added new user');
        } else {
          const response = await axios.put(
            `${GET_EDIT_USER_URL(user!.id)}`,
            {
              displayName,
              email,
              contactNumber,
              role,
              newPassword: password
            },
            { cancelToken: cancelTokenSource.token }
          );
          updateIndividualUser(response.data);
          handleSetMessageSuccess('Successfully edited user data');
        }
        setSnackbarVarient('success');
        setOpenSnackbar(true);
        handleClose();
      } catch (err) {
        console.log(err);
        setSnackbarVarient('error');
        setOpenSnackbar(true);
        handleSetMessageError('Failed to add an user');
      }
    }
    setLoading(false);
  };

  return (
    <Modal aria-labelledby='modal-title' open={open} disableBackdropClick={true}>
      <Grid container item xs={8} sm={8} md={8} lg={5} xl={5} spacing={3} direction='column' className={classes.paper}>
        <Typography variant='h4' id='modal-title' color='primary' className={classes.title}>
          {edit === false ? 'Add New User' : 'Edit User'}
        </Typography>
        <IconButton size='small' className={classes.closeButton} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
        <CreateEditUserForm
          okLabel={edit === false ? 'Create Account' : 'Save'}
          displayName={displayName}
          setDisplayName={setDisplayName}
          displayNameError={displayNameError}
          setDisplayNameError={setDisplayNameError}
          email={email}
          setEmail={setEmail}
          emailError={emailError}
          setEmailError={setEmailError}
          contactNumber={contactNumber}
          setContactNumber={setContactNumber}
          contactNumberError={contactNumberError}
          setContactNumberError={setContactNumberError}
          password={password}
          setPassword={setPassword}
          passwordError={passwordError}
          setPasswordError={setPasswordError}
          isShowPassword={isShowPassword}
          setShowPassword={setShowPassword}
          role={role}
          setRole={setRole}
          isEdit={edit}
          isSubmitting={isLoading}
          onSubmit={handleOnSubmit}
          onCancel={handleClose}
          handleCheckUser={handleCheckUser}
          isLoadingEmail={isLoadingEmail}
          setLoadingEmail={setLoadingEmail}
          isLoadingContact={isLoadingContact}
          setLoadingContact={setLoadingContact}
          handleEmailValidation={handleEmailValidation}
          handleContactNumberValidation={handleContactNumberValidation}
          handlePasswordValidation={handlePasswordValidation}
        />
      </Grid>
    </Modal>
  );
};

export default CreateEditUserModal;
