import React, { FC, Fragment, useState, useEffect, useCallback } from 'react';
import {
  createStyles,
  makeStyles,
  Theme,
  Paper,
  IconButton,
  Tooltip,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  FormControl,
  Grid
} from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import CreateIcon from '@material-ui/icons/Create';
import Skeleton from 'react-loading-skeleton';
import aws from 'aws-sdk';
import default_image from 'images/default_image.svg';
import { AntSwitch } from 'components/CustomSwitch';

interface Props {
  job: JobNoteModel;
  id: number;
  image: string;
  notes: string;
  isHide: boolean;
  index: number;
  currentIndex: number;
  isLoading: boolean;
  isSwitch: boolean;
  openCreateForm: boolean;
  openEditForm: boolean;
  setImage: React.Dispatch<React.SetStateAction<string>>;
  setImageView: React.Dispatch<React.SetStateAction<string>>;
  handleSwitch: (index: number, id: number, isHide: boolean) => void;
  handleClickEdit: (index: number) => void;
  handleClickDelete: (index: number) => void;
  handleOpenDetail: (currentImageDetailUrl: string) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      display: 'flex',
      marginBottom: theme.spacing(2)
    },
    details: {
      display: 'flex',
      flexDirection: 'column',
      marginLeft: 20
    },
    content: {
      flex: '1 0 auto',
      padding: 10,
      height: 120
    },
    cover: {
      width: 155,
      height: 155,
      border: '2px solid #ECECEE',
      borderRadius: '8px',
      cursor: 'pointer'
    },
    button: {
      marginRight: theme.spacing(1)
    },
    editButton: {
      marginLeft: 0
    }
  })
);

const JobNotesRow: FC<Props> = props => {
  const classes = useStyles();

  const {
    index,
    currentIndex,
    id,
    image,
    notes,
    isHide,
    isLoading,
    isSwitch,
    openCreateForm,
    openEditForm,
    setImage,
    setImageView,
    handleSwitch,
    handleClickEdit,
    handleClickDelete,
    handleOpenDetail
  } = props;

  const [imageJobNote, setImageJobNote] = useState<string>('');

  const handleGetImageS3 = useCallback(async () => {
    let imageUrl = '';
    const { REACT_APP_AWS_REGION, REACT_APP_AWS_ACCESS_KEY, REACT_APP_AWS_SECRET_KEY, REACT_APP_S3_BUCKET_NAME } = process.env;

    try {
      aws.config.update({
        accessKeyId: REACT_APP_AWS_ACCESS_KEY,
        secretAccessKey: REACT_APP_AWS_SECRET_KEY,
        region: REACT_APP_AWS_REGION
      });
      const s3 = new aws.S3();
      const signedUrlExpireSeconds = 60 * 5;
      const options = {
        Bucket: REACT_APP_S3_BUCKET_NAME,
        Key: `assets/${image}`,
        Expires: signedUrlExpireSeconds
      };

      imageUrl = await new Promise((resolve, reject) => {
        s3.getSignedUrl('getObject', options, (err, url) => {
          err ? reject(err) : resolve(url);
        });
      });
      setImageJobNote(imageUrl);
    } catch (err) {
      console.error('err :', err);
      throw err;
    }
  }, [image]);

  useEffect(() => {
    if (!image) {
      return;
    }

    handleGetImageS3();
  }, [image, handleGetImageS3]);

  const onClickEdit = () => {
    handleClickEdit(index);
    setImage(image);
    setImageView(imageJobNote);
  };

  const openImage = () => {
    handleOpenDetail(imageJobNote ? imageJobNote : default_image);
  }

  return (
    <Fragment>
      <Grid key={index + 1} direction='row' container spacing={1} style={{ marginBottom: 8 }}>
        <Grid item lg={2} md={2} sm={2} xs={2}>
          <img alt='' title='View Image' onClick={openImage} src={imageJobNote ? imageJobNote : default_image} className={classes.cover} />
        </Grid>
        <Grid container direction='row' item lg={10} md={10} sm={10} xs={10} spacing={1}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Paper className={classes.content}>{notes}</Paper>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            {isLoading ? (
              <Skeleton width='25%' />
            ) : (
              <Fragment>
                <FormControl>
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Tooltip title='Edit'>
                          <IconButton size='small' disabled={openCreateForm || openEditForm || isSwitch} onClick={onClickEdit}>
                            <CreateIcon />
                          </IconButton>
                        </Tooltip>
                      }
                      className={classes.editButton}
                      label=''
                    />
                    <FormControlLabel
                      control={
                        <Tooltip title='Delete'>
                          <IconButton size='small' disabled={openCreateForm || openEditForm || isSwitch} onClick={() => handleClickDelete(index)}>
                            <Delete />
                          </IconButton>
                        </Tooltip>
                      }
                      label=''
                    />
                    <FormControlLabel
                      control={
                        <AntSwitch
                          title={isHide ? 'Switch to show in PDF' : 'Switch to hide in PDF'}
                          disabled={openCreateForm || openEditForm || isSwitch}
                          checked={!isHide}
                          onChange={event => handleSwitch(index, id, !isHide)}
                        />
                      }
                      label={isHide ? 'Hide in PDF' : 'Show in PDF'}
                    />
                    {isSwitch && index === currentIndex && <CircularProgress size={20} />}
                  </FormGroup>
                </FormControl>
              </Fragment>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default JobNotesRow;
