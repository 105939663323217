export const CategoryDummy: CategoryResponseModel = {
    idCategory: 0,
    nameCategory: '',
    typeCategory: '',
    imageCategory: '',
    imageKey: ''
};

export const PromotionDummy: PromotionModel = {
    id: 0,
    title: '',
    description: '',
    code: '',
    discountType: '',
    discountAmount: 0,
    image: '',
    startDate: new Date(),
    endDate: new Date(),
    isActive: false
};

export const ServiceItemDummy: ServiceItemModel = {
    id: 0,
    serviceType: '',
    serviceItemName: '',
    unitPrice: 0,
    remarks: '',
    itemType: 'NORMAL',
    unit: 'PCS',
    quantity: 0,
    quantityOriginal: 0,
    totalPrice: 0
};