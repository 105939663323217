import React, { FC, Fragment } from 'react';
import { createStyles, makeStyles, Modal, Theme, Typography, IconButton, Paper, WithStyles, withStyles } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import CloseIcon from '@material-ui/icons/Close';
import CustomerWizard from './components/CustomerWizard';

interface Props {
  customers: CustomerModel[];
  openCreateForm: boolean;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setCustomers: React.Dispatch<React.SetStateAction<CustomerModel[]>>;
  setOpenCreateForm: React.Dispatch<React.SetStateAction<boolean>>;
  handleSnackBar: (open: boolean, variant: 'success' | 'error', message: string) => void;
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
      color: '#FFFFFF',
      background: '#4B0B5F'
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: '#FFFFFF'
    }
  });

interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: 'absolute',
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2),
      outline: 'none',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      borderRadius: 4
    },
  })
);

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant='h6'>{children}</Typography>
      {onClose ? (
        <IconButton aria-label='close' className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

const CustomerFormModal: FC<Props> = props => {
  const classes = useStyles();
  const { customers, openCreateForm, open, setOpen, setCustomers, setOpenCreateForm, handleSnackBar } = props;

  return (
    <Fragment>
      <Dialog 
        maxWidth={'md'}
        fullWidth={true}
        disableBackdropClick={true}
        onClose={() => setOpen(false)} 
        aria-labelledby='customized-dialog-title' 
        open={open}
      >
        <DialogTitle id='customized-dialog-title' onClose={() => setOpen(false)}>
          <Typography id='modal-title' variant='h4' component='h4'>
            Add New Customers
          </Typography>
        </DialogTitle>
        <DialogContent dividers>
          <CustomerWizard
            customers={customers}
            setCustomers={setCustomers}
            setOpen={setOpen}
            setOpenCreateForm={setOpenCreateForm}
            handleSnackBar={handleSnackBar}
          />
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};

export default CustomerFormModal;
