import React, { FC, useState, Fragment, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TableFooter,
  Paper,
  TableContainer,
  makeStyles,
  createStyles,
  Theme
} from '@material-ui/core';

import { StandardConfirmationDialog } from 'components/AppDialog';
import HeaderRow from 'components/HeaderRow';
import BodyRow from './components/BodyRow';
import TablePagination from 'components/TablePagination';
import axios, { CancelTokenSource } from 'axios';
import { GET_UPDATE_STATUS_JOBS_URL } from 'constants/url';
import StatusType from 'typings/enum/StatusType';

interface Props {
  isLoadingData: boolean;
  count: number;
  currentTab: number;
  currentPage: number;
  rowsPerPage: number;
  jobs: JobModel[];
  order: 'asc' | 'desc';
  orderBy: string;
  setOrder: React.Dispatch<React.SetStateAction<'asc' | 'desc'>>;
  setOrderBy: React.Dispatch<React.SetStateAction<string>>;
  setJobs: React.Dispatch<React.SetStateAction<JobModel[]>>;
  handleChangeRowsPerPage: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  handleChangePage: (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => void;
  handleSnackBar: (open: boolean, variant: 'success' | 'error', message: string) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paperWrapper: {
      overflow: 'hidden',
      width: '100%'
    },
    table: {
      width: '100%'
    }
  })
);

const defaultJob = {
  id: 0,
  collectionDate: new Date(),
  deliveryDate: new Date(),
  collectionTime: '',
  deliveryTime: '',
  jobStatus: '',
  clientSignature: '',
  Service: {
    id: 0,
    serviceNumber: '',
    description: '',
    time: '',
    CustomerId: 0,
    deliveryType: '',
    promoCode: '',
    typePromo: '',
    discountType: '',
    discountAmount: '',
    totalAmount: 0,
    ServiceAddress: {
      id: 0,
      postalCode: '',
      address: '',
      floorNo: '',
      unitNo: ''
    },
    CollectionAddress: {
      id: 0,
      postalCode: '',
      address: '',
      floorNo: '',
      unitNo: ''
    },
    Customer: {
      id: 0,
      firstName: '',
      lastName: '',
      contactNumber: '',
      contactPersonName: '',
      contactPersonEmail: '',
      contactPersonNumber: '',
      primaryCollectionAddress: '',
      primaryCollectionAddressDetail: '',
      primaryServiceAddress: '',
      primaryServiceAddressDetail: '',
      email: ''
    },
    ServiceItems: [
      {
        id: 0,
        serviceType: '',
        serviceItemName: '',
        unitPrice: 0,
        quantity: 0,
        quantityOriginal: 0,
        totalPrice: 0,
        remarks: '',
        itemType: '',
        unit: '',
        ServiceItemTemplate: {
          id: 0,
          name: '',
          Category: {
            id: 0,
            name: '',
            type: '',
            image: ''
          }
        }
      }
    ]
  },
  User: {
    id: 0,
    displayName: ''
  }
};

const JobTable: FC<Props> = props => {
  const classes = useStyles();

  const {
    isLoadingData,
    count,
    currentTab,
    currentPage,
    rowsPerPage,
    jobs,
    setJobs,
    order,
    setOrder,
    orderBy,
    setOrderBy,
    handleChangePage,
    handleChangeRowsPerPage,
    handleSnackBar
  } = props;

  const [showSkeleton, setShowSkeleton] = useState<boolean>(false);
  const [confirmCancel, setConfirmCancel] = useState<boolean>(false);
  const [currentIndex, setCurrentIndex] = useState<number>(-1);
  const [isEdit, setEdit] = useState<boolean>(false);
  const [isSubmit, setSubmit] = useState<boolean>(false);

  const [thisIndex, setThisIndex] = useState<number>(0);
  const [thisStatus, setThisStatus] = useState<string>('');
  const [thisId, setThisId] = useState<number>(0);

  useEffect(() => {
    if (isLoadingData) setShowSkeleton(true);
    return () => setShowSkeleton(false);
  }, [isLoadingData]);

  const handleOpenCancelled = (thisIndex: number, thisStatus: string, thisId: number) => {
    setConfirmCancel(true);
    setThisIndex(thisIndex);
    setThisStatus(thisStatus);
    setThisId(thisId);
  };

  const handleCancelledConfirm = () => {
    setConfirmCancel(false);
    setSubmit(true);
    handleJobStatus(thisIndex, thisStatus, thisId);
    setCurrentIndex(-1);
    setEdit(false);
    setSubmit(false);
  };

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const updateIndividualJob = (tab: number, currentIndex: number, newStatus: string) => {
    if (tab !== 0) {
      jobs.splice(currentIndex, 1);
      setJobs(jobs);
    } else {
      setJobs(
        jobs.map((value, idx) => {
          if (idx === currentIndex) {
            value.jobStatus = newStatus;
          }
          return value;
        })
      );
    }
  };

  const handleJobStatus = async (index: number, jobStatus: string, id: number) => {
    const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();
    try {
      const { data } = await axios.put(GET_UPDATE_STATUS_JOBS_URL(id), { jobStatus }, { cancelToken: cancelTokenSource.token });
      handleSnackBar(true, 'success', 'Job has been successfully updated.');
      if (jobStatus === StatusType.CANCELLED) {
        setJobs(jobs.filter((value, idx) => idx !== index));
      } else {
        updateIndividualJob(currentTab, index, data.jobStatus as string);
      }
    } catch (err) {
      console.log('err:', err);
      handleSnackBar(true, 'error', '');
    }
  };

  return (
    <Fragment>
      <TableContainer component={Paper}>
        <Table className={classes.table}>
          <TableHead>
            <HeaderRow
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              headers={[
                { id: 'firstName', label: 'Customer Name', sort: true },
                { id: 'jobId', label: 'Job Id', sort: true },
                { id: 'invoiceId', label: 'Invoice Id', sort: true },
                { label: 'Delivery Address' },
                { id: 'collectionDate', label: 'Collection Time', sort: true },
                { id: 'deliveryDate', label: 'Delivery Time', sort: true },
                { label: 'Driver' },
                { label: 'Delivery Type' },
                { label: 'Job Status' },
                { label: 'Action' }
              ]}
            />
          </TableHead>
          <TableBody>
            {showSkeleton ? (
              [1, 2, 3, 4].map(index => (
                <BodyRow
                  index={index}
                  key={index}
                  job={defaultJob}
                  isLoadingData={isLoadingData}
                  handleJobStatus={handleJobStatus}
                  handleSnackBar={handleSnackBar}
                  currentIndex={currentIndex}
                  setCurrentIndex={setCurrentIndex}
                  isEdit={isEdit}
                  setEdit={setEdit}
                  isSubmit={isSubmit}
                  setSubmit={setSubmit}
                  handleOpenCancelled={handleOpenCancelled}
                />
              ))
            ) : jobs && jobs.length > 0 ? (
              jobs.map((value, index) => (
                <BodyRow
                  index={index}
                  key={index}
                  job={value}
                  isLoadingData={isLoadingData}
                  handleJobStatus={handleJobStatus}
                  handleSnackBar={handleSnackBar}
                  currentIndex={currentIndex}
                  currentTab={currentTab}
                  setCurrentIndex={setCurrentIndex}
                  isEdit={isEdit}
                  setEdit={setEdit}
                  isSubmit={isSubmit}
                  setSubmit={setSubmit}
                  handleOpenCancelled={handleOpenCancelled}
                />
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={9} align='center'>
                  Data not available
                </TableCell>
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TablePagination
              rowsPerPageOptions={[5, 10, 15]}
              count={count}
              rowsPerPage={rowsPerPage}
              page={currentPage}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              size='small'
            />
          </TableFooter>
        </Table>
      </TableContainer>

      <StandardConfirmationDialog
        variant={'danger'}
        titleMessage={'Cancel Job'}
        message={'Are you sure to cancel this order? please click ‘OK’ to confirm this assignment.'}
        open={confirmCancel}
        handleClose={() => setConfirmCancel(false)}
        onConfirm={handleCancelledConfirm}
      />
    </Fragment>
  );
};

export default JobTable;
