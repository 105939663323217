import React, { FC, useState, useEffect, Fragment } from 'react';
import { makeStyles, createStyles, Theme, Typography, ListItem, ListItemText, ListItemSecondaryAction } from '@material-ui/core';

import ItemType from 'typings/enum/ItemType';
import { ucwords } from 'utils';
import NumberFormat from 'react-number-format';

interface Props {
  index: number;
  serviceItem?: ServiceItemModel;
}

const dummyCategory: CategoryModel = {
  id: 0,
  name: '',
  type: ''
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    list: {
      padding: theme.spacing(0)
    },
    itemTitle: {
      paddingLeft: theme.spacing(1),
      fontWeight: 'bold'
    },
    itemDetail: {
      fontWeight: 'bold'
    },
    itemSecondaryContainer: {
        paddingLeft: theme.spacing(1),
    },
    itemDetailSecondary: {
      color: '#929292'
    }
  })
);

const ListRow: FC<Props> = props => {
  const classes = useStyles();

  const { serviceItem } = props;
 
  const [serviceItemType, setServiceItemType] = useState<string>('');
  const [itemName, setItemName] = useState<string>('');
  const [itemType, setItemType] = useState<string>('');
  const [itemQuantity, setItemQuantity] = useState<number>(0);
  const [itemPrice, setItemPrice] = useState<number>(0);
  const [itemTotal, setItemTotal] = useState<number>(0);
  const [categoryName, setCategoryName] = useState<string>('');

  useEffect(() => {
    if (!serviceItem) {
      return;
    }

    const { serviceType, serviceItemName, quantity, unitPrice, totalPrice, itemType, Category } = serviceItem;
    const { name } = !Category ? dummyCategory : Category;

    setServiceItemType(serviceType);
    setItemName(serviceItemName);
    setItemQuantity(quantity);
    setItemPrice(unitPrice);
    setItemTotal(totalPrice);
    setItemType(itemType);
    setCategoryName(name);
  }, [serviceItem]);

  return (
    <Fragment>
      <ListItem className={classes.list}>
        <ListItemText
          disableTypography
          primary={
            <Typography className={classes.itemTitle}>
              {itemName} / {categoryName}
            </Typography>
          }
          secondary={
            <Typography className={classes.itemSecondaryContainer}>
              <Typography component='span' className={classes.itemDetailSecondary}>
                <NumberFormat
                  value={itemQuantity}
                  className={classes.itemDetailSecondary}
                  displayType={'text'}
                  thousandSeparator={true}
                  prefix={''}
                  decimalScale={itemType === ItemType.NORMAL ? 0 : 2}
                  fixedDecimalScale={true}
                />
                {' x '}
                <NumberFormat
                  value={itemPrice}
                  className={classes.itemDetailSecondary}
                  displayType={'text'}
                  thousandSeparator={true}
                  prefix={'$'}
                  decimalScale={2}
                  fixedDecimalScale={true}
                />
              </Typography>
              <Typography component='span' className={classes.itemDetailSecondary}>
                {' '}
                ({ucwords(serviceItemType).toUpperCase()})
              </Typography>
            </Typography>
          }
        />
        <ListItemSecondaryAction className={classes.itemDetail}>
          <NumberFormat value={itemTotal} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} fixedDecimalScale={true} />
        </ListItemSecondaryAction>
      </ListItem>
    </Fragment>
  );
};

export default ListRow;
