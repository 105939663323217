import React, { FC, useState, Fragment, useEffect } from 'react';
import axios, { CancelTokenSource } from 'axios';
import { IconButton, makeStyles, TableRow, Theme, Tooltip, Typography, createStyles, Menu, MenuItem } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import Skeleton from 'react-loading-skeleton';
import BodyCell from 'components/BodyCell';
import useRouter from 'hooks/useRouter';
import { format } from 'date-fns';

import { GET_EXPORT_PDF_DELIVERY_ORDER_URL, GET_EXPORT_PDF_RECEIPT_URL, GET_DELIVERY_ORDER_DETAIL_URL } from 'constants/url';

interface Props {
  isLoadingData: boolean;
  index: number;
  service: ServiceModel;
  onDetailDeliveryOrder: React.MouseEventHandler;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    newIcon: {
      color: green[500],
      fontSize: 30
    },
    actionIcon: {
      fontSize: 20
    },
    tableCellInner: {
      display: 'flex',
      alignItems: 'center'
    },
    nameTextCell: {
      display: 'flex',
      flexDirection: 'column',
      marginRight: theme.spacing(2)
    },
    adrressTextCell: {
      color: 'grey',
      marginTop: theme.spacing(1)
    },
    tableCellInnerTime: {
      color: '#388bf2',
      marginLeft: theme.spacing(1)
    },
    tableCellInnerPaid: {
      color: green[500],
      display: 'flex',
      alignItems: 'center'
    },
    tableCellInnerUnPaid: {
      color: 'grey',
      display: 'flex',
      alignItems: 'center'
    }
  })
);

const BodyRow: FC<Props> = props => {
  const classes = useStyles();
  const { history } = useRouter();
  const [openSort, setOpenSort] = useState<null | HTMLElement>(null);
  const { isLoadingData, service, onDetailDeliveryOrder } = props;

  const [customerFirstName, setFirstName] = useState<string>('');
  const [customerLastName, setLastName] = useState<string>('');
  const [thisInvoiceNumber, setThisInvoiceNumber] = useState<string>('');
  const [invoiceCreated, setInvoiceCreated] = useState<string>('');
  const [invoicePaymentStatus, setInvoicePaymentStatus] = useState<string>('');
  const [invoiceTotalPrice, setInvoiceTotalPrice] = useState<number>(0);
  const [invoiceTotalCollected, setInvoiceTotalCollected] = useState<number>(0);
  const [invoiceTotalChanges, setInvoiceTotalChanges] = useState<number>(0);
  const [invoicePaymentMethod, setInvoicePaymentMethod] = useState<string>('');

  const [jobCollectionDate, setJobCollectionDate] = useState<Date | null>(null);
  const [jobCollectionTime, setJobCollectionTime] = useState<string>('');
  const [jobDeliveryDate, setJobDeliveryDate] = useState<Date | null>(null);
  const [jobDeliveryTime, setJobDeliveryTime] = useState<string>('');
  const [jobSignature, setJobSignature] = useState<string>('');
  const [serviceJobStatus, setServiceJobStatus] = useState<string>('');

  const [collectionAddress, setCollectionAddress] = useState<string>('');
  const [collectionUnitNo, setCollectionUnitNo] = useState<string>('');
  const [collectionFloorNo, setCollectionFloorNo] = useState<string>('');
  const [collectionPostalCode, setCollectionPostalCode] = useState<string>('');

  const [serviceAddress, setServiceAddress] = useState<string>('');
  const [serviceUnitNo, setServiceUnitNo] = useState<string>('');
  const [serviceFloorNo, setServiceFloorNo] = useState<string>('');
  const [servicePostalCode, setServicePostalCode] = useState<string>('');

  const { id, serviceNumber, discountAmount, discountType, totalAmount, deliveryType, Customer, CollectionAddress, ServiceAddress, Invoice, Job } = service;

  useEffect(() => {
    if (!Customer) {
      return;
    }

    const { firstName, lastName } = Customer;
    setFirstName(firstName);
    setLastName(lastName);
  }, [service]);

  useEffect(() => {
    if (!CollectionAddress) {
      return;
    }

    const { address, unitNo, floorNo, postalCode } = CollectionAddress;
    setCollectionAddress(address);
    setCollectionUnitNo(unitNo);
    setCollectionFloorNo(floorNo);
    setCollectionPostalCode(postalCode);
  }, [service]);

  useEffect(() => {
    if (!ServiceAddress) {
      return;
    }

    const { address, unitNo, floorNo, postalCode } = ServiceAddress;
    setServiceAddress(address);
    setServiceUnitNo(unitNo);
    setServiceFloorNo(floorNo);
    setServicePostalCode(postalCode);
  }, [service]);

  useEffect(() => {
    if (!Invoice) {
      return;
    }

    const { invoiceNumber, paymentStatus, totalPrice, totalCollected, totalChanges, paymentType, createdAt } = Invoice;
    setInvoicePaymentStatus(paymentStatus);
    setInvoiceTotalPrice(totalPrice);
    setInvoiceTotalCollected(totalCollected || 0);
    setInvoiceTotalChanges(totalChanges || 0);
    setInvoicePaymentMethod(paymentType);
    setThisInvoiceNumber(invoiceNumber);
    setInvoiceCreated(createdAt);
  }, [service]);

  useEffect(() => {
    if (!Job) {
      return;
    }

    const { collectionDate, collectionTime, deliveryDate, deliveryTime, jobStatus, clientSignature } = Job;
    setJobCollectionDate(collectionDate);
    setJobCollectionTime(collectionTime);
    setJobDeliveryDate(deliveryDate);
    setJobDeliveryTime(deliveryTime);
    setJobSignature(clientSignature || '');
    setServiceJobStatus(jobStatus);
  }, [service]);

  const handleDeliveryOrderPDF = async () => {
    try {
      const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();
      const result = await axios.get(GET_DELIVERY_ORDER_DETAIL_URL(id), { cancelToken: cancelTokenSource.token });

      await axios
        .post(
          GET_EXPORT_PDF_DELIVERY_ORDER_URL,
          {
            serviceId: id,
            invoiceNumber: thisInvoiceNumber,
            paymentStatus: invoicePaymentStatus,
            invoiceCreated,
            customerLastName,
            customerFirstName,
            collectionAddress,
            collectionUnitNo,
            collectionFloorNo,
            collectionPostalCode,
            serviceAddress,
            serviceUnitNo,
            serviceFloorNo,
            servicePostalCode,
            jobCollectionDate,
            jobCollectionTime,
            jobDeliveryDate,
            jobDeliveryTime,
            discountType,
            discountAmount,
            deliveryType,
            invoiceTotalPrice,
            invoicePaymentMethod,
            ServiceItems: result.data.ServiceItems
          },
          {
            headers: {
              t: localStorage.getItem('token')
            },
            responseType: 'blob'
          }
        )
        .then(response => {
          const file = new Blob([response.data && response.data], { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);

          const newwindow = window.open(fileURL, 'name', 'height=700,width=750');
          if (newwindow && window.focus) {
            newwindow.focus();
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleReceiptPDF = async () => {
    try {
      const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();
      const result = await axios.get(GET_DELIVERY_ORDER_DETAIL_URL(id), { cancelToken: cancelTokenSource.token });

      await axios
        .post(
          GET_EXPORT_PDF_RECEIPT_URL,
          {
            serviceId: id,
            invoiceNumber: thisInvoiceNumber,
            paymentStatus: invoicePaymentStatus,
            totalAmount,
            invoiceCreated,
            discountType,
            discountAmount,
            deliveryType,
            invoiceTotalPrice,
            invoiceTotalCollected,
            invoiceTotalChanges,
            invoicePaymentMethod,
            clientSignature: jobSignature,
            ServiceItems: result.data.ServiceItems
          },
          {
            headers: {
              t: localStorage.getItem('token')
            },
            responseType: 'blob'
          }
        )
        .then(response => {
          const file = new Blob([response.data && response.data], { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);

          const newwindow = window.open(fileURL, 'name', 'height=700,width=750');
          if (newwindow && window.focus) {
            newwindow.focus();
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Fragment>
      <TableRow>
        <BodyCell cellWidth='10%' isComponent={true}>
          <div className={classes.tableCellInner}>
            <div className={classes.nameTextCell}>
              <Typography variant='body1'>{isLoadingData ? <Skeleton width={100} /> : `DO/${serviceNumber}`}</Typography>
            </div>
          </div>
        </BodyCell>

        <BodyCell cellWidth='15%' isComponent={true}>
          <div className={classes.tableCellInner}>{isLoadingData ? <Skeleton width={100} /> : `${customerFirstName} ${customerLastName}`}</div>
        </BodyCell>

        <BodyCell cellWidth='15%' isComponent={true}>
          <div className={classes.tableCellInner}>
            {isLoadingData ? (
              <Skeleton width={100} />
            ) : !jobCollectionDate ? (
              '-'
            ) : (
              <Typography variant='body1'>
                <Typography component='span'>{jobCollectionDate ? format(new Date(jobCollectionDate), 'dd/MM/yyyy') : '-'}</Typography>
                <Typography component='span' className={classes.tableCellInnerTime}>
                  {jobCollectionTime ? jobCollectionTime.slice(0, 5) : '-'}
                </Typography>
              </Typography>
            )}
          </div>
          <div className={classes.adrressTextCell}>{isLoadingData ? <Skeleton width={100} /> : `${collectionUnitNo} ${collectionAddress}...`}</div>
        </BodyCell>

        <BodyCell cellWidth='15%' isComponent={true}>
          <div className={classes.tableCellInner}>
            {isLoadingData ? (
              <Skeleton width={100} />
            ) : !jobDeliveryDate ? (
              '-'
            ) : (
              <Typography variant='body1'>
                <Typography component='span'>{jobDeliveryDate ? format(new Date(jobDeliveryDate), 'dd/MM/yyyy') : '-'}</Typography>
                <Typography component='span' className={classes.tableCellInnerTime}>
                  {jobDeliveryTime ? jobDeliveryTime.slice(0, 5) : '-'}
                </Typography>
              </Typography>
            )}
          </div>
          <div className={classes.adrressTextCell}>{isLoadingData ? <Skeleton width={100} /> : `${serviceUnitNo} ${serviceAddress}...`}</div>
        </BodyCell>

        <BodyCell cellWidth='10%' isComponent={true}>
          <div className={classes.tableCellInner}>
            {isLoadingData ? <Skeleton width={100} /> : invoicePaymentStatus == 'PAID' ? `LB/${thisInvoiceNumber}` : '-'}
          </div>
        </BodyCell>

        <BodyCell cellWidth='20%' isComponent={true}>
          <div className={classes.tableCellInner}>
            {isLoadingData ? (
              <Skeleton width={100} />
            ) : !invoiceCreated ? (
              ''
            ) : invoicePaymentStatus == 'PAID' ? (
              <Typography variant='body1'>
                <Typography component='span'>{format(new Date(invoiceCreated), 'dd/MM/yyyy')}</Typography>
                <Typography component='span' className={classes.tableCellInnerTime}>
                  {format(new Date(invoiceCreated), 'HH:mm')}
                </Typography>
              </Typography>
            ) : (
              '-'
            )}
          </div>
        </BodyCell>

        <BodyCell cellWidth='10%' isComponent={true}>
          {isLoadingData ? (
            <Skeleton width={100} />
          ) : serviceJobStatus === 'CANCELLED' ? (
            <div className={classes.tableCellInnerUnPaid}>{serviceJobStatus}</div>
          ) : invoicePaymentStatus == 'PAID' ? (
            <div className={classes.tableCellInnerPaid}>{invoicePaymentStatus}</div>
          ) : (
            <div className={classes.tableCellInnerUnPaid}>{invoicePaymentStatus}</div>
          )}
        </BodyCell>

        <BodyCell cellWidth='5%' isComponent={true}>
          {isLoadingData ? null : (
            <Fragment>
              <Tooltip title='More action'>
                <IconButton
                  size='small'
                  area-label='Filter'
                  aria-controls='sort-menu'
                  aria-haspopup='true'
                  onClick={event => setOpenSort(event.currentTarget)}
                >
                  <MoreHorizIcon />
                </IconButton>
              </Tooltip>

              <Menu id='sort' anchorEl={openSort} keepMounted open={Boolean(openSort)} onClose={event => setOpenSort(null)}>
                <MenuItem onClick={onDetailDeliveryOrder}>View Details</MenuItem>
                {invoicePaymentStatus == 'PAID' ? <MenuItem onClick={event => handleReceiptPDF()}>Print Receipt</MenuItem> : ''}
                <MenuItem onClick={event => handleDeliveryOrderPDF()}>Export Data</MenuItem>
              </Menu>
            </Fragment>
          )}
        </BodyCell>
      </TableRow>
    </Fragment>
  );
};

export default BodyRow;
