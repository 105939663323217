import React, { Fragment, FC, useState, useEffect } from 'react';
import { createStyles, makeStyles, Table, TableBody, TableRow, TableCell, TableFooter, TableHead, Paper } from '@material-ui/core';
import HeaderRow from 'components/HeaderRow';
import BodyRow from './components/BodyRow';
import TablePagination from 'components/TablePagination';

interface Props {
  isLoadingData: boolean;
  users: UserDetailsModel[];
  count: number;
  currentPage: number;
  rowsPerPage: number;
  order: 'asc' | 'desc';
  orderBy: string;
  setOrder: React.Dispatch<React.SetStateAction<'asc' | 'desc'>>;
  setOrderBy: React.Dispatch<React.SetStateAction<string>>;
  setOpenSnackbar: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedId: React.Dispatch<React.SetStateAction<number | undefined>>;
  setSnackbarVarient: React.Dispatch<React.SetStateAction<'success' | 'error'>>;
  handleSetMessageSuccess: (message: string) => void;
  handleSetMessageError: (message: string) => void;
  handleChangePage: (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => void;
  handleChangeRowsPerPage: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  handleOpenEditUser: (userIndex: number) => React.MouseEventHandler;
  updateIndividualUser: (updatedUserProperties: Partial<UserDetailsModel>, userIndex?: number) => void;
  handleOpenConfirmationIndividualDelete: (userId: number) => React.MouseEventHandler;
  checked: number[];
  setChecked: React.Dispatch<React.SetStateAction<number[]>>;
}

const useStyles = makeStyles(() =>
  createStyles({
    tableWrapper: {
      overflowX: 'auto'
    }
  })
);

const UserTable: FC<Props> = props => {
  const classes = useStyles();

  const {
    isLoadingData,
    users,
    count,
    order,
    setOrder,
    orderBy,
    setOrderBy,
    setOpenSnackbar,
    setSnackbarVarient,
    setSelectedId,
    handleSetMessageSuccess,
    handleSetMessageError,
    currentPage,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
    handleOpenEditUser,
    updateIndividualUser,
    handleOpenConfirmationIndividualDelete,
    checked,
    setChecked
  } = props;

  const dummyUser: UserDetailsModel = {
    id: 0,
    loginName: '',
    displayName: '',
    email: '',
    contactNumber: '',
    role: '',
    active: false
  };

  // The below logic introduces a 500ms delay for showing the skeleton
  const [showSkeleton, setShowSkeleton] = useState<boolean>(false);

  const checkAll = () => {
    const newBulkChecked = [...checked];
    const countChecked = newBulkChecked.length;
    if (count > rowsPerPage) {
      if (countChecked !== rowsPerPage) {
        newBulkChecked.splice(0, countChecked);
        users.map(serviceItem => newBulkChecked.push(serviceItem.id));
      } else {
        newBulkChecked.splice(0, count);
      }
    } else {
      if (countChecked !== count) {
        newBulkChecked.splice(0, countChecked);
        users.map(serviceItem => newBulkChecked.push(serviceItem.id));
      } else {
        newBulkChecked.splice(0, count);
      }
    }
    setChecked(newBulkChecked);
  };

  const individualCheck = (id: number) => {
    const newChecked = [...checked];
    // count element in object selected filter for check already exist or not
    const countElement = newChecked.filter(newCheckedValue => newCheckedValue === id).length;
    if (countElement === 0) {
      newChecked.push(id);
    } else {
      // check index of element and remove object by index
      const checkedFilterIndex = newChecked.map(newCheckedValue => newCheckedValue).indexOf(id);
      newChecked.splice(checkedFilterIndex, 1);
    }
    setChecked(newChecked);
  };

  useEffect(() => {
    if (isLoadingData) {
      setShowSkeleton(true);
    }

    return () => {
      setShowSkeleton(false);
    };
  }, [isLoadingData]);

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: string ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return (
    <div className={classes.tableWrapper}>
      <Paper variant='outlined' elevation={2}>
        <Table size='small'>
          <TableHead>
            <HeaderRow
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              height={'unset'}
              headers={[
                { label: 'CheckBox', verticalAlign: 'top', isCheckBox: true, checked, rowsPerPage, handleCheckAll: checkAll },
                { id: 'displayName', label: 'Name', verticalAlign: 'top', sort: true },
                { id: 'email', label: 'Email', verticalAlign: 'top', sort: true },
                { id: 'contactNumber', label: 'Contact Number', verticalAlign: 'top', sort: true },
                { label: 'Role', verticalAlign: 'top' },
                { label: 'Status', verticalAlign: 'top' },
                { label: 'Action', verticalAlign: 'top' }
              ]}
            />
          </TableHead>
          <TableBody>
            {showSkeleton ? (
              [1, 2, 3, 4, 5].map(index => (
                <BodyRow
                  currentUserIndex={index}
                  key={index}
                  user={dummyUser}
                  updateUser={updateIndividualUser}
                  setOpenSnackbar={setOpenSnackbar}
                  setSnackbarVarient={setSnackbarVarient}
                  setSelectedId={setSelectedId}
                  handleSetMessageSuccess={handleSetMessageSuccess}
                  handleSetMessageError={handleSetMessageError}
                  onEditUser={handleOpenEditUser(index)}
                  onDeleteUser={handleOpenConfirmationIndividualDelete(index)}
                  isLoadingData={isLoadingData}
                  checked={checked}
                  handleIndividualCheck={individualCheck}
                />
              ))
            ) : users && users.length > 0 ? (
              users.map((user, index) => (
                <BodyRow
                  currentUserIndex={index}
                  key={user.id}
                  user={user}
                  updateUser={updateIndividualUser}
                  setOpenSnackbar={setOpenSnackbar}
                  setSnackbarVarient={setSnackbarVarient}
                  setSelectedId={setSelectedId}
                  handleSetMessageSuccess={handleSetMessageSuccess}
                  handleSetMessageError={handleSetMessageError}
                  onEditUser={handleOpenEditUser(index)}
                  onDeleteUser={handleOpenConfirmationIndividualDelete(user.id)}
                  isLoadingData={isLoadingData}
                  checked={checked}
                  handleIndividualCheck={individualCheck}
                />
              ))
            ) : (
              <Fragment>
                <TableRow>
                  <TableCell colSpan={7} align='center'>
                    Data Not Available
                  </TableCell>
                </TableRow>
              </Fragment>
            )}
          </TableBody>
          <TableFooter>
            <TablePagination
              rowsPerPageOptions={[10, 20, 50]}
              count={count}
              rowsPerPage={rowsPerPage}
              page={currentPage}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </TableFooter>
        </Table>
      </Paper>
    </div>
  );
};

export default UserTable;
