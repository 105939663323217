import React, { FC, Fragment, useEffect } from 'react';
import {
  TextField,
  Select,
  Theme,
  Button,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
  Tooltip,
  Avatar,
  Badge,
  ButtonBase,
  Typography,
} from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { makeStyles, createStyles } from '@material-ui/styles';
import UploadIcon from '@material-ui/icons/CloudUpload';
import CancelImageIcon from '@material-ui/icons/Close';
import NumberFormatCustom from 'components/NumberFormatCustom';
import { format } from 'date-fns';

interface Error {
  [keys: string]: string | boolean;
}

interface Props {
  title: string;
  description?: string;
  code: string;
  discountType: string;
  discountAmount: number;
  image: string;
  imageView: string;
  startDate: Date;
  endDate: Date;
  isActive: boolean;
  isSubmitting: boolean;
  error: Error;
  okLabel: string;
  openEditForm: boolean;
  isDuplicated: boolean;
  isError: boolean;
  setIsError: React.Dispatch<React.SetStateAction<boolean>>;
  setIsDuplicated: React.Dispatch<React.SetStateAction<boolean>>;
  setTitle: React.Dispatch<React.SetStateAction<string>>;
  setDescription: React.Dispatch<React.SetStateAction<string>>;
  setCode: React.Dispatch<React.SetStateAction<string>>;
  setDiscountType: React.Dispatch<React.SetStateAction<string>>;
  setDiscountAmount: React.Dispatch<React.SetStateAction<number>>;
  setImage: React.Dispatch<React.SetStateAction<string>>;
  setImageView: React.Dispatch<React.SetStateAction<string>>;
  setStartDate: React.Dispatch<React.SetStateAction<Date>>;
  setEndDate: React.Dispatch<React.SetStateAction<Date>>;
  setActive: React.Dispatch<React.SetStateAction<boolean>>;
  setError: React.Dispatch<React.SetStateAction<Error>>;
  setOldImageEdited: React.Dispatch<React.SetStateAction<string>>
  onSubmit: React.FormEventHandler;
  onCancel: React.FormEventHandler;
  checkValidate: () => void;
  checkExistCode: (code: string) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    controlDiv: {
      '& > :nth-child(n+2)': {
        marginLeft: theme.spacing(2)
      }
    },
    cancelButton: {
      marginRight: theme.spacing(1)
    },
    textFieldFont: {
      fontSize: '12px'
    },
    itemFont: {
      fontSize: '12px'
    },
    content: {
      paddingLeft: 20,
      paddingRight: 20
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120
    },
    selectEmpty: {
      marginTop: theme.spacing(2)
    },
    bigAvatar: {
      minWidth: '200px',
      minHeight: '90px',
      color: `#C4C4C4`,
      backgroundColor: '#FFFFFF',
      border: `1px solid #C4C4C4`,
      borderRadius: '10px'
    },
    spacingNewLine: {
      marginBottom: theme.spacing(1.5)
    },
    buttonBaseStyle: {
      position: 'relative',
      [theme.breakpoints.down('xs')]: {
        width: '100% !important'
      }
    },
    inputImageStyle: {
      display: 'none'
    },
    cancelImage: {
      fontSize: '12px',
      cursor: 'pointer'
    },
    imageRemarks: {
      color: 'grey',
      fontStyle: 'oblique',
      fontSize: '11px',
      marginTop: theme.spacing(1)
    }
  })
);

const PromotionFormField: FC<Props> = (props: Props) => {
  const classes = useStyles();

  /** PROPS */
  const {
    title,
    description,
    code,
    image,
    imageView,
    discountType,
    discountAmount,
    startDate,
    endDate,
    isSubmitting,
    okLabel,
    error,
    isError,
    setIsError,
    setTitle,
    setDescription,
    setCode,
    setDiscountType,
    setDiscountAmount,
    setStartDate,
    setEndDate,
    setImage,
    setImageView,
    setError,
    onSubmit,
    onCancel,
    isDuplicated,
    checkExistCode,
    checkValidate,
    openEditForm,
    setOldImageEdited
  } = props;

  useEffect(() => {
    if (!discountType) {
      setDiscountType('NOMINAL');
    }
  }, [discountType]);

  const handleOnBlurTitle = () => {
    if (title) {
      setError({ ...error, title: false, titleMessage: '' });
      checkValidate();
    }
  };

  const handleOnBlurCode = async () => {
    setIsError(true);
    if (code) {
      setError({ ...error, code: false, codeMessage: '' });
      if (code.length > 8 || code.length < 4) {
        setError({ ...error, code: true, codeMessage: 'Code more than 4 digits and less than 8 digits.' });
        return;
      }

      const existCheck = await checkExistCode(code);

      if (isDuplicated || existCheck) {
        setError({ ...error, code: true, codeMessage: 'Code has been used.' });
        setIsError(true);
        return;
      } else {
        checkValidate();
        setIsError(false);
      }
    }
  };

  const handleOnBlurDiscount = () => {
    if (discountAmount) {
      setError({ ...error, discountAmount: false, discountAmountMessage: '' });
      if (discountType === 'PERCENT' && (discountAmount < 1 || discountAmount > 100)) {
        setError({ ...error, discountAmount: true, discountAmountMessage: 'Disc Amount in Percent must be greater than 1 and less than 100.' });
        setIsError(true);
        return;
      }
      checkValidate();
      setIsError(false);
    }
  };

  const handleBlurType = () => {
    if (discountAmount) {
      setError({ ...error, discountAmount: false, discountAmountMessage: '' });
      if (discountType === 'PERCENT' && (discountAmount < 1 || discountAmount > 100)) {
        setError({ ...error, discountAmount: true, discountAmountMessage: 'Disc Amount in Percent must be greater than 1 and less than 100.' });
        setIsError(true);
        return;
      } else {
        setIsError(false);
        return;
      }
    }
  };

  const handleChangeTitle = (title: string) => {
    setIsError(true);
    setTitle(title);
  };

  const handleChangeCode = (code: string) => {
    setIsError(true);
    setCode(code.toUpperCase());
  };

  const handleChangeType = (event: React.ChangeEvent<{ value: unknown }>) => {
    setDiscountType(event.target.value as string);
  };

  const handleChangeAmount = (amount: number) => {
    setIsError(true);
    setDiscountAmount(amount);
  };

  const handleStartDateChange = (date: Date | null) => {
    setStartDate(date!);
    setIsError(true);

    if (date) {
      setError({ ...error, startDate: false, startDateMessage: '' });

      if (endDate) {
        const date1 = format(new Date(date), 'yyyy-MM-dd');
        const date2 = format(new Date(endDate), 'yyyy-MM-dd');
        
        if (date1 === date2) {
          setError({
            ...error,
            endDate: true,
            endDateMessage: 'Start Date and End Date cannot be the same.',
            startDate: true,
            startDateMessage: 'Start Date and End Date cannot be the same.'
          });
        } else {
          setError({ ...error, endDate: false, endDateMessage: '', startDate: false, startDateMessage: '' });
          setIsError(false);
        }
      }
    } else {
      setError({ ...error, startDate: true, startDateMessage: 'Start date can not be empty.' });
    }
  };

  const handleEndDateChange = (date: Date | null) => {
    setEndDate(date!);
    setIsError(true);

    if (date) {
      setError({ ...error, endDate: false, endDateMessage: '' });

      if (startDate) {
        const date1 = format(new Date(startDate), 'yyyy-MM-dd');
        const date2 = format(new Date(date), 'yyyy-MM-dd');
        
        if (date1 === date2) {
          setError({
            ...error,
            endDate: true,
            endDateMessage: 'Start Date and End Date cannot be the same.',
            startDate: true,
            startDateMessage: 'Start Date and End Date cannot be the same.'
          });
        } else {
          setError({ ...error, endDate: false, endDateMessage: '', startDate: false, startDateMessage: '' });
          setIsError(false);
        }
      } 
    } else {
      setError({ ...error, startDate: true, startDateMessage: 'End date can not be empty.' });
    }
  };

  useEffect(() => {
    if (!image) {
      return;
    }
  }, [image]);

  const handleChooseImage = (event: any) => {
    let selectedImage;
    let selectedImageView;
    if (event.target.files[0] === undefined) {
      selectedImage = '';
      selectedImageView = '';
    } else {
      selectedImage = event.target.files[0];
      selectedImageView = URL.createObjectURL(event.target.files[0]);
    }

    if (openEditForm) {
      setOldImageEdited(image);
    } else {
      setOldImageEdited('');
    }
    
    setImage(selectedImage);
    setImageView(selectedImageView);
  };

  const cancelImage = () => {
    setImage('');
    setImageView('');
  };
  
  const renderImageComponent = () => {
    if (imageView === '') {
      return (
        <Fragment>
          <input
            accept='image/*'
            className={classes.inputImageStyle}
            id='outlined-button-file'
            type='file'
            onChange={event => handleChooseImage(event)}
          />
          <label htmlFor='outlined-button-file'>
            <ButtonBase focusRipple key={'Upload'} component='span' disableRipple>
              <Tooltip title='Choose an image' placement='top'>
                <Avatar className={classes.bigAvatar}>
                  <UploadIcon fontSize='large' />
                </Avatar>
              </Tooltip>
            </ButtonBase>
          </label>
        </Fragment>
      );
    } else {
      return (
        <Badge badgeContent={<CancelImageIcon className={classes.cancelImage} onClick={event => cancelImage()} />} color='primary'>
          <input
            accept='image/*'
            className={classes.inputImageStyle}
            id='outlined-button-file'
            type='file'
            onChange={event => handleChooseImage(event)}
          />
          <label htmlFor='outlined-button-file'>
            <ButtonBase focusRipple key={'Upload'} className={classes.buttonBaseStyle} component='span' disableRipple>
              <Tooltip title='Choose an image' placement='top'>
                <Avatar alt='Image' src={imageView} className={classes.bigAvatar} />
              </Tooltip>
            </ButtonBase>
          </label>
        </Badge>
      );
    }
  };

  const TextFieldComponent = (props: any) => {
    return <TextField {...props} disabled={false} />
  };

  return (
    <form noValidate onSubmit={onSubmit}>
      <Grid container spacing={1} className={classes.content}>
        <Grid item lg={12} md={12} sm={12}>
          <TextField
            required
            fullWidth
            variant='outlined'
            margin='dense'
            label='Title'
            value={title}
            error={Boolean(error.title)}
            helperText={error.titleMessage}
            onChange={event => handleChangeTitle(event.target.value)}
            onBlur={handleOnBlurTitle}
          />
        </Grid>
        <Grid item lg={12} md={12} sm={12}>
          <TextField
            fullWidth
            multiline
            rows={4}
            variant='outlined'
            margin='dense'
            label='Description'
            value={description}
            error={Boolean(error.description)}
            helperText={error.descriptionMessage}
            onChange={event => setDescription(event.target.value)}
            inputProps={{ maxLength: 50 }}
          />
        </Grid>

        <Grid item lg={12} md={12} sm={12}>
          <TextField
            required
            fullWidth
            variant='outlined'
            margin='dense'
            label='Code'
            value={code}
            error={Boolean(error.code)}
            helperText={error.codeMessage}
            onChange={event => handleChangeCode(event.target.value)}
            onBlur={handleOnBlurCode}
          />
        </Grid>
        <Grid item lg={6} md={6} sm={6}>
          <FormControl fullWidth variant='outlined' margin='dense'>
            <InputLabel id='disc-type'>Discount Type</InputLabel>
            <Select
              labelId='disc-type'
              required
              id='disc-type'
              label='Discount Type'
              value={discountType || 'NOMINAL'}
              onChange={handleChangeType}
              onBlur={handleBlurType}
            >
              <MenuItem value={'NOMINAL'}>Nominal</MenuItem>
              <MenuItem value={'PERCENT'}>Percent (%)</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item lg={6} md={6} sm={6}>
          <TextField
            required
            fullWidth
            variant='outlined'
            margin='dense'
            label='Discount Amount '
            value={discountAmount}
            error={Boolean(error.discountAmount)}
            helperText={error.discountAmountMessage}
            onChange={event => handleChangeAmount(+event.target.value)}
            onBlur={handleOnBlurDiscount}
            InputProps={{
              inputComponent: NumberFormatCustom as any,
              inputProps: {
                prefix: discountType === 'PERCENT' ? '' : '$',
                thousandSeparator: true,
                decimalScale: 2,
                fixedDecimalScale: true
              }
            }}
          />
        </Grid>
        <Grid item container lg={6} md={6} sm={6} alignItems='center'>
          <Grid container justify='center' alignItems='center'>
            {renderImageComponent()}
          </Grid>
          <Typography variant='body1' className={classes.imageRemarks}>
            Make sure image is in .JPG (400x400 px)
          </Typography>
        </Grid>
        <Grid item lg={6} md={6} sm={6}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              autoOk
              disableToolbar
              fullWidth
              variant='inline'
              inputVariant='outlined'
              format='dd/MM/yyyy'
              margin='dense'
              id='startDate'
              label='Start Date'
              TextFieldComponent={TextFieldComponent}
              value={startDate}
              error={Boolean(error.startDate)}
              helperText={error.startDateMessage}
              onChange={handleStartDateChange}
              KeyboardButtonProps={{
                'aria-label': 'start date'
              }}
            />
          </MuiPickersUtilsProvider>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              autoOk
              disableToolbar
              fullWidth
              variant='inline'
              inputVariant='outlined'
              format='dd/MM/yyyy'
              margin='dense'
              id='endDate'
              label='End Date'
              TextFieldComponent={TextFieldComponent}
              value={endDate}
              error={Boolean(error.endDate)}
              helperText={error.endDateMessage}
              onChange={handleEndDateChange}
              KeyboardButtonProps={{
                'aria-label': 'end date'
              }}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid container item justify='flex-end' xs={12} sm={12} md={12} lg={12} xl={12} className={classes.controlDiv}>
          <Button variant='contained' className={classes.cancelButton} onClick={onCancel} disabled={isSubmitting}>
            Cancel
          </Button>
          <Button color='primary' size='medium' variant='contained' onClick={onSubmit} disabled={isSubmitting || isError}>
            {okLabel}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default PromotionFormField;
