import React, { FC, Fragment, useState, useEffect, useCallback } from 'react';
import { Button, Grid, makeStyles, Theme, IconButton, Tooltip } from '@material-ui/core';
import axios, { CancelTokenSource } from 'axios';

import SearchInput from 'components/SearchInput';
import useDebounce from 'hooks/useDebounce';
import UserTable from './components/UserTable';
import TableFilter from './components/TableFilter';
import CreateEditUserModal from './components/CreateEditUserModal';
import { StandardConfirmationDialog } from 'components/AppDialog';
import { USER_BASE_URL, DELETE_USER_URL } from 'constants/url';

import { Restore } from '@material-ui/icons';
import DeleteIcon from '@material-ui/icons/Delete';

import useCurrentPageTitleUpdater from 'hooks/useCurrentPageTitleUpdater';

const useStyles = makeStyles((theme: Theme) => ({
  addButton: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1)
  },
  dividerColor: {
    backgroundColor: '#000'
  }
}));

const defaultFilter = {
  role: '',
  isActive: ''
};

const UsersPage: FC = () => {
  useCurrentPageTitleUpdater('ACCOUNTS');

  const classes = useStyles();

  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [isReset, setReset] = useState<boolean>(false);
  const [snackbarVarient, setSnackbarVarient] = useState<'success' | 'error'>('success');
  const [messageSuccess, setMessageSuccess] = useState<string>('');
  const [messageError, setMessageError] = useState<string>('');

  const [query, setQuery] = useState<string>('');
  const [queryString, setQueryString] = useState<string>();
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [isSearchingUser, setSearchingUser] = useState<boolean>(false);
  const [users, setUsers] = useState<UserDetailsModel[]>([]);
  const [count, setCount] = useState<number>(0);

  const [openCreateUser, setOpenCreateUser] = useState<boolean>(false);
  const [edit, setEdit] = useState<boolean>(false);
  const [currentEditingUserIndex, setCurrentEditingUserIndex] = useState<number>(-1);

  const [checked, setChecked] = useState<number[]>([]);
  const [selectedId, setSelectedId] = useState<number>();
  const [openConfirmationBulk, setOpenConfirmationBulk] = useState<boolean>(false);
  const [filter, setFilter] = useState<{ [keys: string]: string }>(defaultFilter);
  const [order, setOrder] = useState<'asc' | 'desc'>('desc');
  const [orderBy, setOrderBy] = useState<string>('createdAt');

  const countChecked = checked.length;
  // Search User whenever rowsPerPage, currentPage, queryString changes
  const fetchData = useCallback(() => {
    const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();

    const getQueryParams = () => {
      const params = new URLSearchParams();

      if (queryString) {
        params.append('q', queryString);
      }

      if (orderBy) {
        params.append('orderBy', orderBy);
      }

      if (order) {
        params.append('order', order.toUpperCase());
      }

      if (filter) {
        Object.entries(filter).map(([keys, value]) => {
          if (value !== '' && value !== undefined) {
            params.append('filter[' + keys + ']', value);
            setReset(true);
          }
          return [keys, value];
        });
      }

      params.append('s', (currentPage * rowsPerPage).toString());
      params.append('l', rowsPerPage.toString());

      return params.toString();
    };

    const searchUser = async () => {
      setSearchingUser(true);

      try {
        const url = `${USER_BASE_URL}?${getQueryParams()}`;
        const { data } = await axios.get(url, { cancelToken: cancelTokenSource.token });
        setCount(data.count);
        setUsers(data.users);
      } catch (err) {
        console.log(err);
      }

      setSearchingUser(false);
    };

    searchUser();

    return () => {
      cancelTokenSource.cancel();
    };
  }, [rowsPerPage, currentPage, queryString, order, orderBy, filter]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const performActionAndRevertPage = (action: React.Dispatch<React.SetStateAction<any>>, actionParam: any) => {
    setCurrentPage(0);
    action(actionParam);
  };

  const handleSearch = useCallback((searchQuery: string) => {
    performActionAndRevertPage(setQueryString, searchQuery);
  }, []);

  const debouncedSearchTerm = useDebounce(query, 500);
  // Load user data to populate on search list
  useEffect(() => {
    if (debouncedSearchTerm.length >= 3) {
      handleSearch(debouncedSearchTerm);
    } else if (debouncedSearchTerm.length === 0) {
      handleSearch(debouncedSearchTerm);
    }
  }, [debouncedSearchTerm, handleSearch]);

  const handleOpenCreateUser = () => {
    setOpenCreateUser(true);
  };

  const handleCancelCreateUser = () => {
    setOpenCreateUser(false);
    setEdit(false);
    setCurrentEditingUserIndex(-1);
  };

  const handleOpenEditUser = (userIndex: number): React.MouseEventHandler => () => {
    setCurrentEditingUserIndex(userIndex);
    setOpenCreateUser(true);
    setEdit(true);
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleSetMessageSuccess = (message: string) => {
    setMessageSuccess(message);
  };

  const handleSetMessageError = (message: string) => {
    setMessageError(message);
  };

  const addNewUser = (user: UserDetailsModel) => {
    user.new = true;
    users.unshift(user);
    setUsers([...users].slice(0, rowsPerPage));
    setCount(c => c + 1);
  };

  const updateIndividualUser = (updatedUserProperties: Partial<UserDetailsModel>, userIndex?: number) => {
    let currentEditingIndex: number;
    if (userIndex === undefined) {
      currentEditingIndex = currentEditingUserIndex;
    } else {
      currentEditingIndex = userIndex;
    }
    setUsers(
      users!.map((user, index) => {
        if (index !== currentEditingIndex) {
          return user;
        }

        return Object.assign({}, user, updatedUserProperties);
      })
    );
  };

  const handleCloseConfirmationBulk = () => {
    setOpenConfirmationBulk(false);
  };

  const handleOpenConfirmationDelete = () => {
    setOpenConfirmationBulk(true);
  };

  const handleOpenConfirmationIndividualDelete = (userId: number): React.MouseEventHandler => () => {
    setSelectedId(userId);
    setOpenConfirmationBulk(true);
  };

  const deleteUser = async (selectedId: number) => {
    setOpenConfirmationBulk(false);
    try {
      await axios.delete(DELETE_USER_URL([selectedId]));
      setSnackbarVarient('success');
      setOpenSnackbar(true);
      handleSetMessageSuccess('User has been deleted');
      setChecked([]);
      fetchData();
    } catch (err) {
      console.log(err);
      setSnackbarVarient('error');
      setOpenSnackbar(true);
      handleSetMessageError('Failed to delete user');
    }
  };

  const bulkDeleteUsers = async () => {
    setOpenConfirmationBulk(false);

    const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();

    try {
      await axios.delete(DELETE_USER_URL(checked));
      setSnackbarVarient('success');
      setOpenSnackbar(true);
      handleSetMessageSuccess('Successfully to bulk delete user');
      setChecked([]);
      fetchData();
    } catch (err) {
      console.log(err);
      setSnackbarVarient('error');
      setOpenSnackbar(true);
      handleSetMessageError('Failed to bulk delete user');
    }
  };

  return (
    <Fragment>
      <Grid container direction='row' justify='space-between'>
        <Grid container item lg={6} sm={6} md={6} justify='flex-start' alignItems='center'>
          <Grid item>
            <SearchInput
              withBorder
              withTransition={false}
              width={150}
              placeHolder='Search User...'
              iconColor='#989898'
              tableSearchValue={query}
              setTableSearchValue={setQuery}
            />
          </Grid>

          <Grid item>
            <TableFilter filter={filter} setFilter={setFilter} />

            {isReset && (
              <Tooltip title='Reset Filter'>
                <IconButton
                  area-label='reset'
                  onClick={event => {
                    setReset(false);
                    setFilter(defaultFilter);
                  }}
                >
                  <Restore />
                </IconButton>
              </Tooltip>
            )}

            {countChecked !== 0 && (
              <Tooltip title='Delete'>
                <IconButton area-label='Delete' onClick={handleOpenConfirmationDelete}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            )}
          </Grid>
        </Grid>

        <Grid container item lg={6} sm={6} md={6} justify='flex-end' alignItems='center'>
          <Grid item>
            <Button
              color='primary'
              size='medium'
              variant='contained'
              className={classes.addButton}
              onClick={() => {
                handleOpenCreateUser();
              }}
            >
              New Account
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <StandardConfirmationDialog
        variant={'danger'}
        titleMessage={'Delete'}
        message={'Are you sure want to delete this data?'}
        open={openConfirmationBulk}
        handleClose={handleCloseConfirmationBulk}
        onConfirm={checked.length === 0 ? event => selectedId && deleteUser(selectedId) : bulkDeleteUsers}
      />

      <StandardConfirmationDialog
        variant={snackbarVarient}
        titleMessage={snackbarVarient === 'success' ? 'Success!' : 'Error!'}
        message={snackbarVarient === 'success' ? messageSuccess : messageError}
        open={openSnackbar}
        handleClose={handleCloseSnackbar}
        onConfirm={handleCloseSnackbar}
        noCancelButton={true}
      />

      <UserTable
        isLoadingData={isSearchingUser}
        users={users}
        count={count}
        checked={checked}
        setChecked={setChecked}
        setSelectedId={setSelectedId}
        order={order}
        orderBy={orderBy}
        setOrder={setOrder}
        setOrderBy={setOrderBy}
        updateIndividualUser={updateIndividualUser}
        setOpenSnackbar={setOpenSnackbar}
        setSnackbarVarient={setSnackbarVarient}
        handleSetMessageSuccess={handleSetMessageSuccess}
        handleSetMessageError={handleSetMessageError}
        handleOpenConfirmationIndividualDelete={handleOpenConfirmationIndividualDelete}
        currentPage={currentPage}
        rowsPerPage={rowsPerPage}
        handleChangePage={(event, page) => setCurrentPage(page)}
        handleChangeRowsPerPage={event => performActionAndRevertPage(setRowsPerPage, +event.target.value)}
        handleOpenEditUser={handleOpenEditUser}
      />

      <CreateEditUserModal
        user={currentEditingUserIndex < 0 ? undefined : users[currentEditingUserIndex]}
        open={openCreateUser}
        edit={edit}
        handleCancel={handleCancelCreateUser}
        addNewUser={addNewUser}
        updateIndividualUser={updateIndividualUser}
        setOpenSnackbar={setOpenSnackbar}
        setSnackbarVarient={setSnackbarVarient}
        handleSetMessageSuccess={handleSetMessageSuccess}
        handleSetMessageError={handleSetMessageError}
      />
    </Fragment>
  );
};

export default UsersPage;
