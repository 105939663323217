import React, { FC } from 'react';
import { TableCell, TableSortLabel, Theme, makeStyles, createStyles } from '@material-ui/core';
import clsx from 'clsx';
import { LabelImportantTwoTone } from '@material-ui/icons';

interface Props {
  id?: string;
  pL?: string;
  pR?: string;
  pT?: string;
  pB?: string;
  align?: 'left' | 'center' | 'right' | 'justify' | 'inherit' | undefined;
  verticalAlign?: 'top' | 'middle' | 'bottom';
  isCheckBox?: boolean;
  orderBy?: string;
  order?: 'asc' | 'desc';
  sort?: boolean;
  onRequestSort?: (event: React.MouseEvent<unknown>, property: string) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    headerCellText: {
      fontWeight: 500,
      color: theme.palette.grey.A200
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1
    },
    cellStyle: (props: Props) => ({
      paddingLeft: props.pL === '' || props.pL === null ? theme.spacing(0) : props.pL,
      paddingRight: props.pR === '' || props.pR === null ? theme.spacing(0) : props.pR,
      paddingTop: props.pT === '' || props.pT === null ? theme.spacing(0) : props.pT,
      paddingBottom: props.pB === '' || props.pB === null ? theme.spacing(0) : props.pB,
      verticalAlign: props.verticalAlign === undefined ? 'middle' : props.verticalAlign
    })
  })
);

const HeaderCell: FC<Props> = props => {
  const { id, pL, pR, pT, pB, order, orderBy, align, sort, onRequestSort } = props;
  const classes = useStyles(props);

  const createSortHandler = (property: string) => (event: React.MouseEvent<unknown>) => {
    onRequestSort!(event, property);
  };

  return (
    <TableCell align={align} className={clsx({ [classes.cellStyle]: pL || pR || pT || pB })}>
      {sort && sort! ? (
        <TableSortLabel active={orderBy === id} direction={orderBy === id ? order : 'asc'} onClick={createSortHandler(id ? id : '')}>
          {props.children}
          {orderBy === id ? <span className={classes.visuallyHidden}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</span> : null}
        </TableSortLabel>
      ) : (
        props.children
      )}
    </TableCell>
  );
};

export default HeaderCell;
