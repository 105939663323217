import React, { FC, useState, Fragment, useEffect } from 'react';
import BodyCell from 'components/BodyCell';
import ItemType from 'typings/enum/ItemType';
import NumberFormat from 'react-number-format';
import { makeStyles, TableRow, Theme, Typography, createStyles } from '@material-ui/core';

interface Props {
  index: number;
  serviceItem: ServiceItemModel;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableCellInner: {
      display: 'flex',
      alignItems: 'center'
    }
  })
);

const BodyRow: FC<Props> = props => {
  const classes = useStyles();
  const { serviceItem } = props;
  const { itemType, serviceItemName, unitPrice, quantity, totalPrice, Category } = serviceItem;
  const [categoryName, setCategoryName] = useState<string>('');

  useEffect(() => {
      if (!Category) {
        return
      }
      
      const { name } = Category;
      setCategoryName(name);
  }, [serviceItem]);

  return (
    <Fragment>
      <TableRow>
        <BodyCell cellWidth='10%' isComponent={true}>
          <div className={classes.tableCellInner}>
            <Typography variant='body1'>{categoryName}</Typography>
          </div>
        </BodyCell>
        <BodyCell cellWidth='10%' isComponent={true}>
          <div className={classes.tableCellInner}>
            <Typography variant='body1'>{serviceItemName}</Typography>
          </div>
        </BodyCell>
        <BodyCell cellWidth='10%' isComponent={true}>
          <div className={classes.tableCellInner}>
            <NumberFormat
              value={quantity}
              displayType={'text'}
              thousandSeparator={true}
              prefix={''}
              decimalScale={itemType === ItemType.NORMAL ? 0 : 2}
              fixedDecimalScale={true}
            />
          </div>
        </BodyCell>
        <BodyCell cellWidth='10%' isComponent={true}>
          <div className={classes.tableCellInner}>
            <Typography variant='body1'>
              <NumberFormat value={unitPrice} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} fixedDecimalScale={true} />
            </Typography>
          </div>
        </BodyCell>
        <BodyCell cellWidth='10%' isComponent={true}>
          <div className={classes.tableCellInner}>
            <Typography variant='body1'>
              <NumberFormat value={totalPrice} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} fixedDecimalScale={true} />
            </Typography>
          </div>
        </BodyCell>
      </TableRow>
    </Fragment>
  );
};

export default BodyRow;
