import * as firebase from 'firebase/app';
import 'firebase/messaging';

const firebaseConfig = {
    apiKey: "AIzaSyCWZQvBSBxjkjz6YgP7BZa_APur-Kbih3o",
    authDomain: "laundry-butler-2fe1c.firebaseapp.com",
    databaseURL: "https://laundry-butler-2fe1c.firebaseio.com",
    projectId: "laundry-butler-2fe1c",
    storageBucket: "laundry-butler-2fe1c.appspot.com",
    messagingSenderId: "414724210785",
    appId: "1:414724210785:web:794ced9e63fe774db46a20",
    measurementId: "G-3EX0F6FC7V"
  };

firebase.initializeApp(firebaseConfig);

export default firebase;


