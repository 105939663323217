import React, { FC, Fragment, useState } from 'react';
import { Grid, IconButton, RadioGroup, Radio, FormControl, FormLabel, FormControlLabel, TextField, Menu, MenuItem } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import axios from 'axios';
import { GET_USER_BY_ROLE_URL } from 'constants/url';
import { FilterList } from '@material-ui/icons';

interface Props {
  filter: { [keys: string]: string };
  setFilter: React.Dispatch<React.SetStateAction<{ [keys: string]: string }>>;
}

interface DriverFilters {
  id: number;
  displayName: string;
}

const defaultDriver = {
  id: 0,
  displayName: ''
};

const TableFilter: FC<Props> = props => {
  const { filter, setFilter } = props;

  const [anchorEll, setAnchorEll] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEll);

  const [drivers, setDrivers] = useState<DriverFilters[]>([defaultDriver]);
  const [driver, setDriver] = useState<DriverFilters>(defaultDriver);

  const handleSearchDriver = async (value: string) => {
    try {
      const params = new URLSearchParams();
      params.append('q', value);
      params.append('role', 'DRIVER');
      const { data } = await axios.get(`${GET_USER_BY_ROLE_URL}?${params}`);

      setDrivers(data.users);
    } catch (err) {
      console.log('err : ', err);
    }
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEll(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEll(null);
  };

  return (
    <Fragment>
      <IconButton aria-label='more' aria-controls='long-menu' aria-haspopup='true' onClick={handleClick}>
        <FilterList />
      </IconButton>
      <Menu id='long-menu' anchorEl={anchorEll} keepMounted open={open} onClose={handleClose}>
        <MenuItem>
          <FormControl component='div'>
            <FormLabel component='label'>Delivery Type</FormLabel>
            <RadioGroup
              row
              aria-label='jobStatus'
              value={filter.jobStatus}
              onChange={event => setFilter({ ...filter, deliveryType: (event.currentTarget as HTMLInputElement).value })}
            >
              <FormControlLabel value='NORMAL' control={<Radio size='small' />} label='Normal' />
              <FormControlLabel value='EXPRESS' control={<Radio size='small' />} label='Express' />
            </RadioGroup>
          </FormControl>
        </MenuItem>
        <MenuItem>
          <Autocomplete
            fullWidth
            id='Driver'
            size='small'
            value={driver}
            options={drivers}
            getOptionLabel={option => option.displayName}
            onChange={(event: any, value: any) => {
              setDriver(value);
              setFilter({ ...filter, UserId: value ? value.id : '' });
            }}
            renderInput={params => (
              <TextField
                {...params}
                onChange={event => handleSearchDriver(event.target.value)}
                margin='dense'
                label='Driver'
                variant='outlined'
                InputProps={{
                  ...params.InputProps
                }}
              />
            )}
          />
        </MenuItem>
        <MenuItem>
          <Grid container direction='row' spacing={1}>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <TextField
                id='collectionDate'
                fullWidth
                variant='outlined'
                margin='dense'
                label='Collection Time'
                type='date'
                value={filter.collectionDate}
                onChange={event => setFilter({ ...filter, collectionDate: event.target.value })}
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>

            <Grid item lg={6} md={6} sm={6} xs={6}>
              <TextField
                id='deliveryDate'
                fullWidth
                variant='outlined'
                margin='dense'
                label='Delivery Time'
                type='date'
                value={filter.deliveryDate}
                onChange={event => setFilter({ ...filter, deliveryDate: event.target.value })}
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>
          </Grid>
        </MenuItem>
      </Menu>
    </Fragment>
  );
};

export default TableFilter;
