import React, { FC, Fragment, useState, useEffect, useCallback } from 'react';
import { Button, Grid, IconButton, makeStyles, Theme,Tooltip } from '@material-ui/core';

import DeleteIcon from '@material-ui/icons/Delete';
import Restore from '@material-ui/icons/Restore';
import SearchInput from 'components/SearchInput';
import useDebounce from 'hooks/useDebounce';

import { StandardConfirmationDialog } from 'components/AppDialog';
import { PROMOTION_BASE_URL, BULK_DELETE_PROMOTION_URL } from 'constants/url';
import { ArrowDropUp, ArrowDropDown } from '@material-ui/icons';
import axios, { CancelTokenSource } from 'axios';
import aws from 'aws-sdk';
import useCurrentPageTitleUpdater from 'hooks/useCurrentPageTitleUpdater';
import PromotionTable from './components/PromotionTable';
import PromotionFormModal from './components/PromotionFormModal';
import TableFilter from './components/TableFilter';
import { PromotionDummy } from 'constants/DummyData';

const useStyles = makeStyles((theme: Theme) => ({
  addButton: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1)
  },
  icon: {
    fontSize: 25,
    color: '#FF0909'
  },
  deleteText: {
    color: '#FF0909',
    paddingTop: 5,
    paddingLeft: 10
  }
}));

const dummyPromotions = [PromotionDummy];

const defaultFilter = {
  discountType: '',
  startDate: '',
  endDate: '',
  isActive: ''
};

const { REACT_APP_AWS_REGION, REACT_APP_AWS_ACCESS_KEY, REACT_APP_AWS_SECRET_KEY, REACT_APP_S3_BUCKET_NAME } = process.env;

aws.config.update({
  accessKeyId: REACT_APP_AWS_ACCESS_KEY,
  secretAccessKey: REACT_APP_AWS_SECRET_KEY,
  region: REACT_APP_AWS_REGION
});
const s3 = new aws.S3();

const Promotion: FC = () => {
  const classes = useStyles();
  useCurrentPageTitleUpdater('PROMOTIONS')
  const [isLoadingData, setIsLoadingData] = useState<boolean>(false);
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [isReset, setReset] = useState<boolean>(false);
  const [snackbarVariant, setSnackbarVariant] = useState<'success' | 'error'>('success');
  const [message, setMessage] = useState<string>('');
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [openCreateForm, setOpenCreateForm] = useState<boolean>(false);
  const [openEditForm, setOpenEditForm] = useState<boolean>(false);
  const [openBulkDelete, setOpenBulkDelete] = useState<boolean>(false);
  const [count, setCount] = useState<number>(0);
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [promotions, setPromotions] = useState<PromotionModel[]>(dummyPromotions);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [checked, setChecked] = useState<number[]>([]);

  const [sort, setSort] = useState<string>();
  const [sortingType, setSortingType] = useState<string>('ASC');
  const [order, setOrder] = useState<'asc' | 'desc'>('desc');
  const [orderBy, setOrderBy] = useState<string>('createdAt');
  const [query, setQuery] = useState<string>('');
  const [queryString, setQueryString] = useState<string>();
  const [filter, setFilter] = useState<{ [keys: string]: string }>(defaultFilter);
  const [open, setOpen] = useState<boolean>(false);

  const fetchData = useCallback(async () => {
    const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();
    setIsLoadingData(true);

    const params = new URLSearchParams();
    if (queryString) {
      params.append('q', queryString);
    }

    if (orderBy) {
      params.append('orderBy', orderBy);
    }

    if (order) {
      params.append('order', order.toUpperCase());
    }

    if (filter) {
      Object.entries(filter).map(([keys, value]) => {
        if (value !== '' && value !== undefined) {
          params.append('filter[' + keys + ']', value);
          setReset(true);
        }
        return [keys, value];
      });
    }
    params.append('s', (currentPage * rowsPerPage).toString());
    params.append('l', rowsPerPage.toString());

    try {
      const result = await axios.get(PROMOTION_BASE_URL + '?' + params.toString(), { cancelToken: cancelTokenSource.token });
      setPromotions(result.data.promotion);
      setCount(result.data.count);
    } catch (err) {
      console.error('err: ', err);
    }
    setIsLoadingData(false);
    return () => cancelTokenSource.cancel();
  }, [rowsPerPage, currentPage, queryString, order, orderBy, filter]);

  const performActionAndRevertPage = (action: React.Dispatch<React.SetStateAction<any>>, actionParam: any) => {
    setCurrentPage(0);
    action(actionParam);
  };

  const handleSearch = useCallback((searchQuery: string) => {
    performActionAndRevertPage(setQueryString, searchQuery);
  }, []);

  const debouncedSearchTerm = useDebounce(query, 500);

  const handleSnackBar = (open: boolean, variant: 'success' | 'error', message: string) => {
    setSnackbarVariant(variant);
    setOpenSnackbar(open);
    setOpenCreateForm(false);
    setOpenEditForm(false);
    setMessage(message);
  };

  const handleOpenCreateForm: React.MouseEventHandler = event => {
    setOpen(true);
    setOpenCreateForm(true);
    setOpenEditForm(false);
  };

  const handleClickDeletePromotion = (index: number) => {
    promotions.splice(index, 1);
    setPromotions([...promotions]);
    setCount(count => count - 1);
    handleSnackBar(true, 'success', 'Promo has been successfully deleted.');
  };

  const handleBulkDelete = async () => {
    const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();
    try {
      await axios.post(BULK_DELETE_PROMOTION_URL, { id: checked }, { cancelToken: cancelTokenSource.token });
      
      await Promise.all([
        checked.map(async id => {
          const findFirst = promotions.find(promo => promo.id === id);

          if (findFirst && findFirst.image) {
            const object = { Key: `assets/promotions/${findFirst.image}` };
            const options = {
              Bucket: REACT_APP_S3_BUCKET_NAME!,
              Delete: { Objects: [object] }
            };

            await s3.deleteObjects(options, (err: any, data: any) => {
              if (err) {
                console.log(err, err.stack, data);
              }
            });
          }
        })
      ]);

      setChecked([]);
      fetchData();
      handleSnackBar(true, 'success', 'Promo has been successfully deleted.');
    } catch (err) {
      console.log(err);
      handleSnackBar(true, 'error', 'Promo failed to delete.');
    }
    setOpenBulkDelete(false);
  };

  useEffect(() => {
    if (debouncedSearchTerm.length >= 3) {
      handleSearch(debouncedSearchTerm);
    } else if (debouncedSearchTerm.length === 0) {
      handleSearch(debouncedSearchTerm);
    }
  }, [debouncedSearchTerm, handleSearch]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleResetFilter = () => {
    setFilter(defaultFilter);
    setReset(false);
  };

  return (
    <Fragment>
      <Grid container direction='row' justify='space-between'>
        <Grid container item lg={8} sm={8} md={8} justify='flex-start' alignItems='center'>
          <Grid item>
            <SearchInput
              withBorder
              withTransition={false}
              width={150}
              placeHolder='Search Promotion...'
              iconColor='#989898'
              tableSearchValue={query}
              setTableSearchValue={setQuery}
            />
          </Grid>

          <Grid item>
            <TableFilter filter={filter} setFilter={setFilter} />

            {isReset && (
              <Tooltip title='Reset Filter'>
                <IconButton area-label='reset' onClick={handleResetFilter}>
                  <Restore />
                </IconButton>
              </Tooltip>
            )}

            {checked.length !== 0 && (
              <Tooltip title='Filter'>
                <IconButton size='small'>
                  <DeleteIcon className={classes.icon} onClick={() => setOpenBulkDelete(true)} />
                </IconButton>
              </Tooltip>
            )}
          </Grid>
        </Grid>

        <Grid container item lg={4} sm={4} md={4} justify='flex-end' alignItems='center'>
          <Grid item>
            <Button
              color='primary'
              size='medium'
              variant='contained'
              disabled={openEditForm}
              className={classes.addButton}
              onClick={handleOpenCreateForm}
            >
              Add New Promotion
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <PromotionTable
        isLoadingData={isLoadingData}
        count={count}
        checked={checked}
        currentPage={currentPage}
        rowsPerPage={rowsPerPage}
        promotions={promotions}
        promotion={promotions[currentIndex]}
        openEditForm={openEditForm}
        setOpen={setOpen}
        order={order}
        orderBy={orderBy}
        setOrder={setOrder}
        setOrderBy={setOrderBy}
        setPromotions={setPromotions}
        setCurrentIndex={setCurrentIndex}
        setOpenEditForm={setOpenEditForm}
        handleChangePage={(event, page) => setCurrentPage(page)}
        handleChangeRowsPerPage={event => performActionAndRevertPage(setRowsPerPage, +event.target.value)}
        handleClickDeletePromotion={handleClickDeletePromotion}
        handleSnackBar={handleSnackBar}
        setChecked={setChecked}
      />

      <StandardConfirmationDialog
        variant={'danger'}
        titleMessage={'Delete'}
        message={'Are you sure want to delete this data?'}
        open={openBulkDelete}
        handleClose={() => setOpenBulkDelete(false)}
        onConfirm={handleBulkDelete}
      />

      <StandardConfirmationDialog
        variant={snackbarVariant}
        titleMessage={snackbarVariant.charAt(0).toUpperCase() + snackbarVariant.slice(1) + '!'}
        message={message}
        open={openSnackbar}
        handleClose={() => setOpenSnackbar(false)}
        onConfirm={() => setOpenSnackbar(false)}
        noCancelButton={true}
      />

      <PromotionFormModal
        promotions={promotions}
        promotion={promotions[currentIndex]}
        openCreateForm={openCreateForm}
        openEditForm={openEditForm}
        index={currentIndex}
        open={open}
        setOpen={setOpen}
        setPromotions={setPromotions}
        setOpenCreateForm={setOpenCreateForm}
        setOpenEditForm={setOpenEditForm}
        handleSnackBar={handleSnackBar}
      />
    </Fragment>
  );
};

export default Promotion;
