import React, { useState, useEffect, useCallback } from 'react';
import axios, { CancelTokenSource } from 'axios';
import NumberFormat from 'react-number-format';
import { format } from 'date-fns';
import { makeStyles } from '@material-ui/styles';
import { Grid, Theme, Button, List, ListItem, ListItemText, ListItemSecondaryAction } from '@material-ui/core';

import ListRow from './components/ListRow';
import DeliveryType from 'typings/enum/DeliveryType';
import { ucwords } from 'utils';
import { GET_EXPORT_PDF_INVOICE_URL, GET_EXPORT_PDF_RECEIPT_URL, GET_DETAIL_INVOICE_URL } from 'constants/url';
import StatusType from 'typings/enum/StatusType';

interface Props {
  id: number;
  index?: number;
  jobId: number;
  invoiceNumber: string;
  createdAt: string;
  jobStatus: string;
  paymentType: string;
  paymentStatus: string;
  totalPrice: number;
  totalCollected: number;
  totalChanges: number;
  deliveryType: string;
  discountType: string;
  discountAmount: number;
  totalAmount: number;
  clientSignature: string;
  customerFirstName: string;
  customerLastName: string;
  serviceItems?: ServiceItemModel[];
  serviceAddress?: ServiceAddressModel;
  collectionAddress?: CollectionAddressModel;
  handleClose: React.FormEventHandler;
  handleOpenCancelled: (index: number, status: string, id: number) => void;
}

interface DiscountsDetail {
  discountFrom: string;
  discountAmount: number;
}

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    marginBottom: theme.spacing(2)
  },
  controlDiv: {
    '& > :nth-child(n+2)': {
      marginLeft: theme.spacing(1)
    }
  },
  listContainer: {
    overflowX: 'scroll',
    maxHeight: '300px'
  },
  list: {
    padding: theme.spacing(0)
  },
  invoiceDetail: {
    fontWeight: 'bold'
  },
  customerName: {
    textAlign: 'right'
  },
  customerinfo: {
    textAlign: 'right'
  },
  invoiceNumber: {
    fontWeight: 'bold',
    color: '#388bf2'
  },
  itemContainer: {
    marginBottom: theme.spacing(1)
  },
  itemHeader: {
    padding: theme.spacing(1),
    backgroundColor: '#ececee',
    fontWeight: 'bold'
  },
  itemTitle: {
    paddingLeft: theme.spacing(1),
    fontWeight: 'bold'
  },
  itemTitleSecond: {
    paddingLeft: theme.spacing(1),
    fontWeight: 'bold',
    marginLeft: theme.spacing(2)
  },
  itemDetail: {
    fontWeight: 'bold',
    width: '70%',
    textAlign: 'right'
  }
}));

const DetailInvoice: React.FC<Props> = props => {
  const classes = useStyles();

  const {
    id,
    jobId,
    invoiceNumber,
    createdAt,
    paymentType,
    paymentStatus,
    totalPrice,
    deliveryType,
    jobStatus,
    discountType,
    discountAmount,
    totalAmount,
    totalCollected,
    totalChanges,
    clientSignature,
    customerFirstName,
    customerLastName,
    serviceAddress,
    collectionAddress,
    handleClose,
    handleOpenCancelled
  } = props;

  const totalDiscount = 0 + discountAmount ? discountAmount : 0;
  const [allServiceItems, setAllServiceItems] = useState<ServiceItemModel[]>();

  const [servicePostalCode, setServicePostalCode] = useState<string>('');
  const [serviceThisAddress, setServiceThisAddress] = useState<string>('');
  const [serviceFloorNo, setServiceFloorNo] = useState<string>('');
  const [serviceUnitNo, setServiceUnitNo] = useState<string>('');

  const [collectionPostalCode, setCollectionPostalCode] = useState<string>('');
  const [collectionThisAddress, setCollectionThisAddress] = useState<string>('');
  const [collectionFloorNo, setCollectionFloorNo] = useState<string>('');
  const [collectionUnitNo, setCollectionUnitNo] = useState<string>('');

  const [serviceId, setServiceId] = useState<number>(0);
  const [deliveryFee, setDeliveryFee] = useState<number>(0);
  const [subTotal, setSubTotal] = useState<number>(0);
  const [discountsDetail, setDiscountsDetails] = useState<DiscountsDetail[]>([]);

  useEffect(() => {
    if (!id) {
      return;
    }

    if (id > 0) {
      fetchDetailData(id);
    }
  }, [id]);

  useEffect(() => {
    if (!serviceAddress) {
      return;
    }

    const { postalCode, address, floorNo, unitNo } = serviceAddress;
    setServicePostalCode(postalCode);
    setServiceThisAddress(address);
    setServiceFloorNo(floorNo);
    setServiceUnitNo(unitNo);
  }, [serviceAddress]);

  useEffect(() => {
    if (!collectionAddress) {
      return;
    }

    const { postalCode, address, floorNo, unitNo } = collectionAddress;
    setCollectionPostalCode(postalCode);
    setCollectionThisAddress(address);
    setCollectionFloorNo(floorNo);
    setCollectionUnitNo(unitNo);
  }, [collectionAddress]);

  const fetchDetailData = useCallback(async (invoiceId: number) => {
    const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();

    try {
      const { data } = await axios.get(GET_DETAIL_INVOICE_URL(invoiceId), { cancelToken: cancelTokenSource.token });
      const { Service } = data;
      const { ServiceItems, ServiceDiscounts } = Service;
      setAllServiceItems(ServiceItems);
      setServiceId(Service.id);

      const currentSubTotal = ServiceItems.reduce((total: number, val: ServiceItemModel) => {
        return total + Number(val.totalPrice);
      }, 0);

      setSubTotal(currentSubTotal);

      let deliveryFee = 0;
      if (deliveryType === DeliveryType.TWO_DAY_EXPRESS) {
        deliveryFee = currentSubTotal * 0.5;
        setDeliveryFee(currentSubTotal * 0.5);
      } else if (deliveryType === DeliveryType.ONE_DAY_EXPRESS) {
        deliveryFee = currentSubTotal;
        setDeliveryFee(currentSubTotal);
      } else if (deliveryType === DeliveryType.SUPER_EXPRESS) {
        deliveryFee = currentSubTotal + currentSubTotal;
        setDeliveryFee(currentSubTotal + currentSubTotal);
      }

      if (ServiceDiscounts && ServiceDiscounts.length > 0) {
        const discounts: DiscountsDetail[] = [];
        const gandTotal = currentSubTotal + deliveryFee;

        ServiceDiscounts.map((disc: any) => {
          const discountValue = Number(disc.discountAmount);
          const discountAmount = disc.discountType == 'NOMINAL' ? discountValue : gandTotal * (discountValue / 100);

          discounts.push({
            discountFrom: disc.discountFrom,
            discountAmount: +discountAmount.toFixed(3)
          });
        });

        setDiscountsDetails(discounts);
      }
    } catch (err) {
      console.error('err: ', err);
    }

    return () => cancelTokenSource.cancel();
  }, []);

  const handleInvoicePDF = async () => {
    try {
      await axios
        .post(
          GET_EXPORT_PDF_INVOICE_URL,
          {
            id: serviceId,
            invoiceNumber,
            createdAt,
            paymentType,
            paymentStatus,
            totalPrice,
            deliveryType,
            discountType,
            discountAmount,
            totalAmount,
            customerFirstName,
            customerLastName,
            serviceItems: allServiceItems
          },
          {
            headers: {
              t: localStorage.getItem('token')
            },
            responseType: 'blob'
          }
        )
        .then(response => {
          const file = new Blob([response.data && response.data], { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);

          const newwindow = window.open(fileURL, 'name', 'height=700,width=750');
          if (newwindow && window.focus) {
            newwindow.focus();
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleReceiptPDF = async () => {
    try {
      await axios
        .post(
          GET_EXPORT_PDF_RECEIPT_URL,
          {
            serviceId,
            invoiceNumber,
            paymentStatus,
            totalAmount,
            discountType,
            discountAmount,
            deliveryType,
            clientSignature,
            invoiceCreated: createdAt,
            invoiceTotalPrice: totalPrice,
            invoiceTotalCollected: totalCollected,
            invoiceTotalChanges: totalChanges,
            invoicePaymentMethod: paymentType,
            ServiceItems: allServiceItems
          },
          {
            headers: {
              t: localStorage.getItem('token')
            },
            responseType: 'blob'
          }
        )
        .then(response => {
          const file = new Blob([response.data && response.data], { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);

          const newwindow = window.open(fileURL, 'name', 'height=700,width=750');
          if (newwindow && window.focus) {
            newwindow.focus();
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleActionButtonCancel = () => {
    handleOpenCancelled(id, 'CANCELLED', jobId);
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <List>
          <ListItem className={classes.list}>
            <ListItemText primary='Invoice Number' />
            <ListItemSecondaryAction className={classes.invoiceNumber}>{invoiceNumber}</ListItemSecondaryAction>
          </ListItem>
          <ListItem className={classes.list}>
            <ListItemText primary='Date Issued' />
            <ListItemSecondaryAction className={classes.itemDetail}>
              {createdAt ? format(new Date(createdAt), 'dd MMMM yyyy') : ''}
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem className={classes.list}>
            <ListItemText primary='Collecting Address' />
            <ListItemSecondaryAction className={classes.itemDetail}>
              {serviceThisAddress} {serviceFloorNo} {serviceUnitNo} {servicePostalCode}
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem className={classes.list}>
            <ListItemText primary='Delivery Address' />
            <ListItemSecondaryAction className={classes.itemDetail}>
              {collectionThisAddress} {collectionFloorNo} {collectionUnitNo} {collectionPostalCode}
            </ListItemSecondaryAction>
          </ListItem>
        </List>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <List className={classes.listContainer}>
          <ListItem className={classes.list}>
            <ListItemText classes={{ primary: classes.itemHeader }} primary='Order Summary' />
          </ListItem>
          <ListItem className={classes.list}>
            <ListItemText classes={{ primary: classes.itemTitle }} primary='Subtotal' />
            <ListItemSecondaryAction className={classes.itemDetail}>
              <NumberFormat value={subTotal} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} fixedDecimalScale={true} />
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem className={classes.list}>
            <ListItemText classes={{ primary: classes.itemTitle }} primary={ucwords(deliveryType)} />
            <ListItemSecondaryAction className={classes.itemDetail}>
              <NumberFormat
                value={deliveryFee}
                displayType={'text'}
                thousandSeparator={true}
                prefix={'$'}
                decimalScale={2}
                fixedDecimalScale={true}
              />
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem className={classes.list}>
            <ListItemText classes={{ primary: classes.itemTitle }} primary='Total Discount' />
            <ListItemSecondaryAction className={classes.itemDetail}>
              <NumberFormat
                value={Number(totalDiscount).toFixed(2)}
                displayType={'text'}
                thousandSeparator={true}
                prefix={'($'}
                suffix={')'}
                decimalScale={2}
                fixedDecimalScale={false}
              />
            </ListItemSecondaryAction>
          </ListItem>
          {discountsDetail && discountsDetail.length > 0
            ? discountsDetail.map((discDetail: DiscountsDetail, index: number) => (
                <ListItem key={index} className={classes.list}>
                  <ListItemText classes={{ primary: classes.itemTitleSecond }} primary={ucwords(discDetail.discountFrom)} />
                  <ListItemSecondaryAction className={classes.itemDetail}>
                    <NumberFormat
                      value={discDetail.discountAmount ? discDetail.discountAmount.toFixed(2) : 0}
                      displayType={'text'}
                      thousandSeparator={true}
                      prefix={'($'}
                      suffix={')'}
                      decimalScale={2}
                      fixedDecimalScale={false}
                    />
                  </ListItemSecondaryAction>
                </ListItem>
              ))
            : ''}
          <ListItem className={classes.list}>
            <ListItemText classes={{ primary: classes.itemHeader }} primary='Total Payment' />
            <ListItemSecondaryAction className={classes.itemDetail}>
              <NumberFormat value={totalPrice} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} fixedDecimalScale={true} />
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem className={classes.list}>
            <ListItemText classes={{ primary: classes.itemTitle }} primary='Payment Method' />
            <ListItemSecondaryAction className={classes.itemDetail}>{paymentType || '-'}</ListItemSecondaryAction>
          </ListItem>
          <ListItem className={classes.list}>
            <ListItemText classes={{ primary: classes.itemTitle }} primary='Status Payment' />
            <ListItemSecondaryAction className={classes.itemDetail}>{paymentStatus}</ListItemSecondaryAction>
          </ListItem>
          <ListItem className={classes.list}>
            <ListItemText classes={{ primary: classes.itemHeader }} primary='Item Details' />
          </ListItem>
          {!allServiceItems ? null : allServiceItems.map((value, index) => <ListRow index={index} serviceItem={value} />)}
        </List>
      </Grid>

      <Grid container item justify='flex-end' xs={12} sm={12} md={12} lg={12} xl={12} className={classes.controlDiv}>
        <Button variant='contained' onClick={handleClose}>
          Close
        </Button>
        {jobStatus != 'CANCELLED' && (
          <Button variant='contained' style={{ color: '#fff', backgroundColor: '#FF0909' }} onClick={event => handleActionButtonCancel()}>
            Cancel Invoice
          </Button>
        )}
        <Button variant='contained' color='primary' onClick={() => window.open(`/jobs/detail/${jobId}`, '_blank')}>
          Job Detail
        </Button>
        {paymentStatus !== 'PAID' ? (
          ''
        ) : (
          <Button variant='contained' color='primary' onClick={event => handleReceiptPDF()}>
            Print Receipt
          </Button>
        )}
        <Button variant='contained' color='primary' onClick={event => handleInvoicePDF()}>
          Export as PDF
        </Button>
      </Grid>
    </Grid>
  );
};

export default DetailInvoice;
