import React, { useState, useEffect } from 'react';
import { MenuItem, FormControl, InputLabel, Select, TextField, Theme, TableRow, IconButton, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import NumberFormatCustom from 'components/NumberFormatCustom';
import BodyCell from 'components/BodyCell';

import SaveIcon from '@material-ui/icons/Save';
import RevertIcon from '@material-ui/icons/NotInterestedOutlined';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';

import { ucwords } from 'utils';
import ItemType from 'typings/enum/ItemType';
import ServiceItemUnit from 'typings/enum/ServiceItemUnit';
import CategoryType from 'typings/enum/CategoryType';

interface Props {
  okLabel?: string;
  category: CategoryResponseModel;
  name: string;
  type: string;
  unit: string;
  priceLA: number;
  priceDC: number;
  nameError: string;
  isSubmitting: boolean;
  primaryButtonLabel: string;
  customBackground?: string;
  categoryType: string;
  categories: CategoryResponseModel[];
  setCategory: React.Dispatch<React.SetStateAction<CategoryResponseModel>>;
  onSubmit: React.FormEventHandler;
  onCancel: React.MouseEventHandler;
  openEditServiceItemTemplate: boolean;
  setCategoryId: React.Dispatch<React.SetStateAction<number>>;
  setCategoryType: React.Dispatch<React.SetStateAction<string>>;
  setName: React.Dispatch<React.SetStateAction<string>>;
  setType: React.Dispatch<React.SetStateAction<string>>;
  setUnit: React.Dispatch<React.SetStateAction<string>>;
  setPriceLA: React.Dispatch<React.SetStateAction<number>>;
  setPriceDC: React.Dispatch<React.SetStateAction<number>>;
  onHandleSearchCategory: (event: string) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  controlDiv: {
    '& > :nth-child(n+2)': {
      marginLeft: theme.spacing(2)
    }
  },
  cancelButton: {
    marginRight: theme.spacing(1)
  },
  textFieldFont: {
    fontSize: '13px',
    height: 18
  },
  textFieldRoot: (props: Props) => ({
    backgroundColor: props.customBackground
  }),
  buttonContainer: {
    display: 'flex'
  },
  actionIcon: {
    fontSize: 20
  }
}));

const CreateEditServiceItemTemplateForm: React.FC<Props> = props => {
  const classes = useStyles(props);

  const {
    name,
    setName,
    nameError,
    type,
    setType,
    unit,
    setUnit,
    categories,
    category,
    categoryType,
    setCategoryId,
    setCategoryType,
    setCategory,
    priceLA,
    setPriceLA,
    priceDC,
    setPriceDC,
    isSubmitting,
    onHandleSearchCategory,
    onSubmit,
    onCancel
  } = props;

  const [open, setOpen] = useState<boolean>(false);
  const allType = Object.entries(ItemType).map(([value]) => value);
  const allUnit = Object.entries(ServiceItemUnit).map(([value]) => value);
  const normalUnit =
    allUnit.filter(value => value === ServiceItemUnit.PCS || value === ServiceItemUnit.SET || value === ServiceItemUnit.SITTER) || allUnit;
  const measureUnit =
    allUnit.filter(value => value !== ServiceItemUnit.PCS && value !== ServiceItemUnit.SET && value !== ServiceItemUnit.SITTER) || allUnit;
  const [unitOption, setUnitOption] = useState<string[]>(normalUnit);
  const typeOption = allType;

  useEffect(() => {
    if (type === ItemType.MEASURE_ON_SITE) {
      setUnitOption(measureUnit);
    } else {
      setUnitOption(normalUnit);
    }
  }, [type]);

  const handleCategory = (value: CategoryResponseModel) => {
    if (value) {
      const { idCategory, typeCategory } = value;
      setCategoryId(idCategory);
      setCategoryType(typeCategory);
      setCategory(value);
    }
  };

  const handleOnChangeType = (value: string) => {
    setType(value);
    if (value === ItemType.MEASURE_ON_SITE) {
      setUnitOption(measureUnit);
      setUnit(ServiceItemUnit.KG);
    } else {
      setUnitOption(normalUnit);
      setUnit(ServiceItemUnit.PCS);
    }
  };

  const loading = open && categories.length === 0;

  return (
    <TableRow>
      <BodyCell></BodyCell>

      <BodyCell cellWidth='10%' isComponent={true}>
        <Autocomplete
          id='Category'
          size='small'
          value={category}
          options={categories}
          getOptionLabel={option => option.nameCategory}
          loading={loading}
          onChange={(event: any, value: CategoryResponseModel | any) => handleCategory(value)}
          onOpen={() => {
            onHandleSearchCategory('');
            setOpen(!open);
          }}
          onClose={() => setOpen(!open)}
          open={open}
          renderInput={params => (
            <TextField
              {...params}
              label='Category'
              onChange={event => onHandleSearchCategory(event.target.value)}
              variant='outlined'
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loading ? <CircularProgress color='inherit' size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                )
              }}
            />
          )}
        />
      </BodyCell>

      <BodyCell cellWidth='25%' isComponent={true}>
        <TextField
          margin='dense'
          fullWidth
          variant='outlined'
          required
          id='name'
          label='Service Name'
          error={nameError !== ''}
          helperText={nameError}
          value={name}
          onChange={event => setName(event.target.value)}
          autoComplete='off'
        />
      </BodyCell>

      <BodyCell cellWidth='25%' isComponent={true}>
        <FormControl fullWidth variant='outlined' margin='dense'>
          <InputLabel id='type'>Type</InputLabel>
          <Select
            labelId='type'
            required
            id='type'
            label='Type'
            value={type || 'NORMAL'}
            onChange={event => handleOnChangeType(String(event.target.value))}
          >
            {typeOption.map((value: string, index: number) => (
              <MenuItem key={index + 1} value={value}>
                {ucwords(value)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </BodyCell>

      <BodyCell cellWidth='20%' isComponent={true}>
        <FormControl fullWidth variant='outlined' margin='dense'>
          <InputLabel id='unit'>Unit</InputLabel>
          <Select
            labelId='unit'
            required
            id='unit'
            label='unit'
            value={unit || ServiceItemUnit.PCS}
            onChange={event => setUnit(String(event.target.value))}
          >
            {unitOption.map((value: string, index: number) => (
              <MenuItem key={index + 1} value={value}>
                {ucwords(value).toUpperCase()}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </BodyCell>

      <BodyCell cellWidth='40%' isComponent={true}>
        <TextField
          margin='dense'
          variant='outlined'
          id='priceLA'
          label={categoryType === CategoryType.OTHER_SERVICE ? 'Price' : 'Price LA'}
          value={priceLA}
          onChange={event => setPriceLA(+event.target.value)}
          autoComplete='off'
          InputProps={{
            inputComponent: NumberFormatCustom as any,
            inputProps: {
              prefix: '$',
              thousandSeparator: true,
              decimalScale: 2,
              fixedDecimalScale: true
            }
          }}
        />
        {categoryType === CategoryType.OTHER_SERVICE ? ('') : (
          <TextField
            margin='dense'
            fullWidth
            variant='outlined'
            id='priceDC'
            label='Price DC'
            value={priceDC}
            onChange={event => setPriceDC(+event.target.value)}
            autoComplete='off'
            InputProps={{
              inputComponent: NumberFormatCustom as any,
              inputProps: {
                prefix: '$',
                thousandSeparator: true,
                decimalScale: 2,
                fixedDecimalScale: true
              }
            }}
          />
        )}
      </BodyCell>

      <BodyCell isComponent={true} pL='10px'>
        <div className={classes.buttonContainer}>
          <Tooltip title={'Save'}>
            <IconButton disabled={isSubmitting} aria-label='done' onClick={onSubmit}>
              <SaveIcon color='primary' fontSize='small' />
            </IconButton>
          </Tooltip>
          <Tooltip title={'Cancel'}>
            <IconButton aria-label='revert' onClick={onCancel}>
              <RevertIcon color='disabled' fontSize='small' />
            </IconButton>
          </Tooltip>
        </div>
      </BodyCell>
    </TableRow>
  );
};

export default CreateEditServiceItemTemplateForm;
