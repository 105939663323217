import React, { FC, useState, useCallback, useEffect } from 'react';
import { createStyles, makeStyles, Modal, Theme, Grid, Typography, IconButton } from '@material-ui/core';
import axios, { CancelTokenSource } from 'axios';
import PromotionFormField from './components/PromotionFormField';
import CloseIcon from '@material-ui/icons/Close';

import aws from 'aws-sdk';
import { addDays, format } from 'date-fns';
import { PROMOTION_BASE_URL, GET_EDIT_PROMOTION_URL, VALIDATION_PROMOTION_CODE_URL } from 'constants/url';

interface Props {
  promotions: PromotionModel[];
  promotion: PromotionModel;
  openCreateForm: boolean;
  openEditForm: boolean;
  index: number;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setPromotions: React.Dispatch<React.SetStateAction<PromotionModel[]>>;
  setOpenCreateForm: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenEditForm: React.Dispatch<React.SetStateAction<boolean>>;
  handleSnackBar: (open: boolean, variant: 'success' | 'error', message: string) => void;
}

interface Error {
  [keys: string]: string | boolean;
}

const { REACT_APP_AWS_REGION, REACT_APP_AWS_ACCESS_KEY, REACT_APP_AWS_SECRET_KEY, REACT_APP_S3_BUCKET_NAME } = process.env;

aws.config.update({
  accessKeyId: REACT_APP_AWS_ACCESS_KEY,
  secretAccessKey: REACT_APP_AWS_SECRET_KEY,
  region: REACT_APP_AWS_REGION
});
const s3 = new aws.S3();

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: 'absolute',
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(4),
      outline: 'none',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      borderRadius: 4
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500]
    },
    title: {
      marginBottom: theme.spacing(2),
      paddingLeft: 20
    }
  })
);

const errorDefault: Error = {
  title: false,
  titleMessage: '',
  description: false,
  descriptionMessage: '',
  code: false,
  codeMessage: '',
  discountType: false,
  discountTypeMessage: '',
  discountAmount: false,
  discountAmountMessage: '',
  startDate: false,
  startDateMessage: '',
  endDate: false,
  endDateMessage: '',
  status: false,
  statusMessage: ''
};

const PromotionFormModal: FC<Props> = props => {
  const classes = useStyles();
  let cancelTokenSource: CancelTokenSource = axios.CancelToken.source();

  const {
    index,
    promotions,
    promotion,
    openCreateForm,
    openEditForm,
    open,
    setOpen,
    setPromotions,
    setOpenCreateForm,
    setOpenEditForm,
    handleSnackBar
  } = props;

  const [id, setId] = useState<number>(0);
  const [title, setTitle] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [discountType, setDiscountType] = useState<string>('');
  const [discountAmount, setDiscountAmount] = useState<number>(0);
  const [code, setCode] = useState<string>('');
  const [isActive, setActive] = useState<boolean>(false);
  const [image, setImage] = useState<string>('');
  const [imageView, setImageView] = useState<string>('');
  const [oldImageEdited, setOldImageEdited] = useState<string>('');
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [endDate, setEndDate] = useState<Date>(addDays(new Date(), 1));
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isDuplicated, setIsDuplicated] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(true);
  const [error, setError] = useState<Error>(errorDefault);

  useEffect(() => {
    if (!promotion) {
      return
    }

    const currentImage = promotion.image || '';
    setImage(currentImage);
  }, [promotion]);

  useEffect(() => {
    if (openCreateForm) {
      setImageView('')
    }

    return () => {
      setImageView(imageView)
    };
  }, [openCreateForm]);

  useEffect(() => {
    if (!openEditForm && !promotion) {
      return () => {
        setImageView('')
      }
    }
    
    const currentImage = promotion.image || '';
    if (currentImage) {
      const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();
      const loadData = async () => {
        let imageUrl = '';
        
        try {
          const signedUrlExpireSeconds = 60 * 5;
          const options = {
            Bucket: REACT_APP_S3_BUCKET_NAME,
            Key: `assets/promotions/${currentImage}`,
            Expires: signedUrlExpireSeconds
          };
          
          imageUrl = await new Promise((resolve, reject) => {
            s3.getSignedUrl('getObject', options, (err, url) => {
              err ? reject(err) : resolve(url);
            });
          });
          setImageView(imageUrl);
        } catch (err) {
          console.error('err :', err);
          throw err;
        }
      };
    
      loadData();
      return () => {
        cancelTokenSource.cancel();
      };
    }
  }, [openEditForm]);

  const onSubmit: React.FormEventHandler = event => addData();

  const onCancel: React.FormEventHandler = event => {
    setIsSubmitting(false);
    setOpenCreateForm(false);
    setOpenEditForm(false);
    setOpen(false);
    resetPromotion();
  };

  const getCurrentPromotion = useCallback(() => {
    setTitle(promotion.title);
    setDescription(promotion.description);
    setCode(promotion.code);
    setDiscountType(promotion.discountType);
    setDiscountAmount(promotion.discountAmount);
    setStartDate(promotion.startDate);
    setEndDate(promotion.endDate);
    setActive(promotion.isActive);
    setId(promotion.id);
    setIsError(false);
  }, [promotion]);

  const resetPromotion = useCallback(() => {
    setTitle('');
    setDescription('');
    setCode('');
    setDiscountType('');
    setDiscountAmount(0);
    setStartDate(new Date());
    setEndDate(addDays(new Date(), 1));
    setActive(false);
    setId(0);
  }, []);

  const addData = async () => {
    if (validation()) return;

    const existCheck = await checkExistCode(code);
    
    if (isDuplicated || existCheck) {
      setError({ ...error, code: true, codeMessage: 'Code has been used.' });
      return;
    }

    if (openCreateForm) {
      try {
        setIsSubmitting(true);

        let newImageKey = '';
        let fileExtension = '';
        if (image) {
          // @ts-ignore
          newImageKey = image ? image.name : '';
          fileExtension = newImageKey ? newImageKey.split('.').pop()! : '';
        }

        const formData = {
          title,
          description,
          discountType,
          code: code.toUpperCase(),
          discountAmount: String(discountAmount),
          startDate: String(startDate),
          endDate: String(endDate),
          isActive: String(isActive),
          image: newImageKey
        };

        const config = { cancelToken: cancelTokenSource.token };

        const { data } = await axios.post(PROMOTION_BASE_URL, formData, config);

        if (data.imageUrl) {
          const myHeaders = new Headers();
          myHeaders.append('Content-Type', `image/${fileExtension}`);

          const config = {
            method: 'PUT',
            headers: myHeaders,
            body: image
          };

          fetch(data.imageUrl, config)
            .then(response => response.text())
            .then(result => console.log(result))
            .catch(error => console.log('error', error));
        }

        setPromotions([{ ...data, new: true }, ...promotions]);
        handleSnackBar(true, 'success', 'Promo has been added successfully.');
        resetPromotion();
        setOpen(!open);
      } catch (error) {
        console.log('err: ', error);
        handleSnackBar(true, 'error', 'The promo has failed to add.');
      }
    }

    if (openEditForm) {
      try {
        setIsSubmitting(true);

        if (oldImageEdited) {
          const object = { Key: `assets/promotions/${oldImageEdited}` };
          const options = {
            Bucket: REACT_APP_S3_BUCKET_NAME!,
            Delete: { Objects: [object] }
          };

          await Promise.all([
            s3.deleteObjects(options, (err, data) => {
              if (err) {
                console.log(err, err.stack, data);
              }
            })
          ]);
        }

        let newImageKey = '';
        let fileExtension = '';
        if (image) {
          // @ts-ignore
          newImageKey = image ? image.name : '';
          fileExtension = newImageKey ? newImageKey.split('.').pop()! : '';
        }

        const formData = {
          title,
          description,
          discountType,
          code: code.toUpperCase(),
          discountAmount: String(discountAmount),
          startDate: String(startDate),
          endDate: String(endDate),
          isActive: String(isActive),
          image: newImageKey
        };

        const config = { cancelToken: cancelTokenSource.token };

        const { data } = await axios.put(GET_EDIT_PROMOTION_URL(id), formData, config);

        if (data.imageUrl) {
          const myHeaders = new Headers();
          myHeaders.append('Content-Type', `image/${fileExtension}`);

          const config = {
            method: 'PUT',
            headers: myHeaders,
            body: image
          };

          fetch(data.imageUrl, config)
            .then(response => response.text())
            .then(result => console.log(result))
            .catch(error => console.log('error', error));
        }
        
        promotions[index] = data;
        setPromotions(promotions);
        handleSnackBar(true, 'success', 'Promo has been successfully updated.');
        resetPromotion();
        setOpen(!open);
      } catch (error) {
        console.log('err: ', error);
        handleSnackBar(true, 'error', 'Promo has failed to update.');
      }
    }
    setError(errorDefault);
    setIsSubmitting(false);
  };

  const checkExistCode = async (codeCheck: string) => {
    let result;
    if (!codeCheck) {
      return; 
    }

    setIsError(true);
    setIsDuplicated(false);
    try {
      const params = new URLSearchParams();
      params.append('code', codeCheck);
      
      if (openEditForm) {
        params.append('id', String(id));
      }

      const { data } = await axios.get(`${VALIDATION_PROMOTION_CODE_URL}?${params.toString()}`);
      result = data;

      if (data.length > 0) {
        setIsError(true);
        setIsDuplicated(true);
        setError({ ...error, code: true, codeMessage: 'Code has been used.' });
      } else {
        setIsError(false);
      }
    } catch (error) {
      console.log('error:', error);
    }

    return result;
  };

  const checkValidate = () => {
    if (error.title || error.code || error.discountAmount || error.startDate || error.endDate) {
      setIsError(true);
    } else if (!title || !code || !discountAmount || !startDate || !endDate || startDate === endDate) {
      setIsError(true);
    } else {
      setIsError(false);
    }
  }

  const validation = (): boolean => {
    let ret = false;
    const date1 = format(new Date(startDate), 'yyyy-MM-dd');
    const date2 = format(new Date(endDate), 'yyyy-MM-dd');

    if (title === '' || !title) {
      setError({ ...error, title: true, titleMessage: 'Title can not be empty.' });
      ret = true;
    } else if (code === '' || !code) {
      setError({ ...error, code: true, codeMessage: 'Code can not be empty.' });
      ret = true;
    } else if (code.length > 8 || code.length < 4) {
      setError({ ...error, code: true, codeMessage: 'Code more than 4 digits and less than 8 digits.' });
      ret = true;
    } else if (discountType === '' || !discountType) {
      setError({ ...error, discountType: true, discountTypeMessage: 'Disc Type can not be empty.' });
      ret = true;
    } else if (!discountAmount || discountAmount === 0) {
      setError({ ...error, discountAmount: true, discountAmountMessage: 'Disc Amount can not be 0.' });
      ret = true;
    } else if (discountType === 'PERCENT' && (discountAmount < 1 || discountAmount > 100)) {
      setError({ ...error, discountAmount: true, discountAmountMessage: 'Disc Amount in Percent must be greater than 1 and less than 100.' });
      ret = true;
    } else if (date1 === date2) {
      setError({
        ...error,
        endDate: true,
        endDateMessage: 'Start Date and End Date cannot be the same.',
        startDate: true,
        startDateMessage: 'Start Date and End Date cannot be the same.'
      });
      ret = true;
    }

    return ret;
  };

  useEffect(() => {
    resetPromotion();
    setError(errorDefault);
    if (openEditForm) getCurrentPromotion();
  }, [open, openEditForm, getCurrentPromotion, resetPromotion]);

  return (
    <Modal aria-labelledby='modal-title' open={open}>
      <Grid container item xs={8} sm={8} md={8} lg={5} xl={5} spacing={3} direction='column' className={classes.paper}>
        <Typography variant='h4' id='modal-title' color='primary' className={classes.title}>
          {openCreateForm ? 'Add New Promotion' : 'Edit Promotion'}
        </Typography>
        <IconButton size='small' className={classes.closeButton} onClick={onCancel}>
          <CloseIcon />
        </IconButton>
        <PromotionFormField
          okLabel={openCreateForm ? 'Add New Promotion' : 'Save'}
          title={title}
          description={description}
          code={code}
          discountType={discountType}
          discountAmount={discountAmount}
          image={image}
          imageView={imageView}
          startDate={startDate}
          endDate={endDate}
          isActive={isActive}
          setTitle={setTitle}
          setDescription={setDescription}
          setCode={setCode}
          setDiscountType={setDiscountType}
          setDiscountAmount={setDiscountAmount}
          setImage={setImage}
          setImageView={setImageView}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          setActive={setActive}
          error={error}
          isSubmitting={isSubmitting}
          openEditForm={openEditForm}
          onSubmit={onSubmit}
          onCancel={onCancel}
          setError={setError}
          checkExistCode={checkExistCode}
          isDuplicated={isDuplicated}
          setIsDuplicated={setIsDuplicated}
          isError={isError}
          setIsError={setIsError}
          checkValidate={checkValidate}
          setOldImageEdited={setOldImageEdited}
        />
      </Grid>
    </Modal>
  );
};

export default PromotionFormModal;
