import React, { FC, Fragment, useState } from 'react';
import { Grid, IconButton, RadioGroup, Radio, FormControl, FormLabel, FormControlLabel, TextField, Menu, MenuItem, Tooltip } from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';

interface Props {
  filter: { [keys: string]: string };
  setFilter: React.Dispatch<React.SetStateAction<{ [key: string]: string }>>;
}

const TableFilter: FC<Props> = props => {
  const { filter, setFilter } = props;

  const [filterEl, setFilterEL] = useState<null | HTMLElement>(null);

  return (
    <Fragment>
      <Tooltip title='Filter by'>
        <IconButton area-label='filter' aria-controls='filter-menu' aria-haspopup='true' onClick={event => setFilterEL(event.currentTarget)}>
          <FilterListIcon />
        </IconButton>
      </Tooltip>

      <Menu id='promotion-menu' anchorEl={filterEl} keepMounted open={Boolean(filterEl)} onClose={event => setFilterEL(null)}>
        <MenuItem>
          <FormControl component='div'>
            <FormLabel component='label'>Discount Type</FormLabel>
            <RadioGroup
              row
              aria-label='discountType'
              value={filter.discountType}
              onChange={event => setFilter({ ...filter, discountType: (event.currentTarget as HTMLInputElement).value })}
            >
              <FormControlLabel value='PERCENT' control={<Radio size='small' />} label='Percent(%)' />
              <FormControlLabel value='NOMINAL' control={<Radio size='small' />} label='Nominal($)' />
            </RadioGroup>
          </FormControl>
        </MenuItem>

        <MenuItem>
          <FormControl component='div'>
            <FormLabel component='label'>Status Active</FormLabel>
            <RadioGroup
              row
              aria-label='isActive'
              value={filter.isActive}
              onChange={event => setFilter({ ...filter, isActive: (event.currentTarget as HTMLInputElement).value })}
            >
              <FormControlLabel value='true' control={<Radio size='small' />} label='Active' />
              <FormControlLabel value='false' control={<Radio size='small' />} label='Inactive' />
            </RadioGroup>
          </FormControl>
        </MenuItem>
        <MenuItem>
          <Grid container direction='row' spacing={1}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <TextField
                id='startDate'
                fullWidth
                variant='outlined'
                margin='dense'
                label='Start Date'
                type='date'
                value={filter.startDate}
                onChange={event => setFilter({ ...filter, startDate: event.target.value })}
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>

            <Grid item lg={12} md={12} sm={12} xs={12}>
              <TextField
                id='endDate'
                fullWidth
                variant='outlined'
                margin='dense'
                label='End Date'
                type='date'
                value={filter.endDate}
                onChange={event => setFilter({ ...filter, endDate: event.target.value })}
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>
          </Grid>
        </MenuItem>
      </Menu>
    </Fragment>
  );
};

export default TableFilter;
