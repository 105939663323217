import React, { FC, useState, Fragment, useEffect, useCallback, useRef } from 'react';
import { Table, TableBody, TableHead, Paper, TableRow, TableCell, makeStyles, createStyles, Box, IconButton, Tooltip } from '@material-ui/core';
import Skeleton from 'react-loading-skeleton';
import NumberFormat from 'react-number-format';
import ItemType from 'typings/enum/ItemType';
import ServiceType from 'typings/enum/ServiceType';
import CreateEditAdditionalItemForm from './component/CreateEditAdditionalItemForm';
import axios, { CancelTokenSource } from 'axios';
import {
  CATEGORY_BASE_URL,
  GET_EDIT_SERVICE_URL,
  GET_EDIT_SERVICE_ITEM_URL,
  SERVICE_ITEM_TEMPLATE_BASE_URL,
  SERVICE_ITEM_BASE_URL,
  GET_EDIT_INVOICE_BY_SERVICE_URL,
  GET_DELETE_SERVICE_ITEM_URL
} from 'constants/url';
import useRouter from 'hooks/useRouter';
import { StandardConfirmationDialog } from 'components/AppDialog';
import EditIcon from '@material-ui/icons/EditOutlined';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import DeliveryType from 'typings/enum/DeliveryType';

interface Props {
  serviceItems: ServiceItemModel[];
  isLoading: boolean;
  openCreateAdditionalItem: boolean;
  openEditAdditionalItem: boolean;
  serviceItem?: ServiceItemModel;
  currentEditingAdditionalItemIndex: number;

  jobStatus: string;
  serviceDiscounts: ServiceDiscountModel[];
  totalAmount: number;
  serviceId: number;
  deliveryType: string;
  setDelete: React.Dispatch<React.SetStateAction<boolean>>;
  setServiceItems: React.Dispatch<React.SetStateAction<ServiceItemModel[]>>;
  setOpenCreateAdditionalItem: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenEditAdditionalItem: React.Dispatch<React.SetStateAction<boolean>>;
  setCurrentEditingAdditionalItemIndex: React.Dispatch<React.SetStateAction<number>>;
}

const useStyles = makeStyles(() =>
  createStyles({
    odd: {
      background: '#F9F9FB'
    },
    even: {
      background: '#fff'
    },
    actionIcon: {
      fontSize: 20
    }
  })
);

const defaultServiceItem = {
  id: 0,
  serviceType: '',
  serviceItemName: '',
  unitPrice: 0,
  quantity: 0,
  quantityOriginal: 0,
  totalPrice: 0,
  itemType: '',
  unit: '',
  remarks: '',
  Category: {
    id: 0,
    name: '',
    type: '',
    image: ''
  }
};

const AdditionalItemTable: FC<Props> = props => {
  const classes = useStyles();
  const { location } = useRouter();
  const {
    serviceItems,
    totalAmount,
    serviceId,
    setServiceItems,
    serviceDiscounts,
    setOpenCreateAdditionalItem,
    jobStatus,
    openEditAdditionalItem,
    openCreateAdditionalItem,
    setOpenEditAdditionalItem,
    currentEditingAdditionalItemIndex,
    serviceItem,
    setCurrentEditingAdditionalItemIndex,
    isLoading,
    setDelete,
    deliveryType
  } = props;

  const th = ['Service Category', 'Laundry Type', 'Service Item', 'Quantity', 'Price', 'Amount', 'Action'];
  const statusAllowChangeAndEdits: string[] = ['UNASSIGNED', 'ASSIGNED_FOR_COLLECTION', 'COLLECTING', 'COLLECTED', 'CHECKING'];

  const [categories, setCategories] = useState<CategoryResponseModel[]>([]);
  const [serviceTypes, setServiceTypes] = useState<Select[]>([]);
  const [serviceItemTemplates, setServiceItemTemplates] = useState<ServiceItemTemplatesModel[]>([]);

  const [snackbarVariant, setSnackbarVariant] = useState<'success' | 'error' | 'warning'>('success');
  const [message, setMessage] = useState<string>('');
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [isProcessing, setProcessing] = useState<boolean>(false);
  const [categoryId, setCategoryId] = useState<number>(0);
  const [categoryType, setCategoryType] = useState<string>('');
  const [serviceItemTemplateId, setServiceItemTemplateId] = useState<number>(0);
  const [serviceItemId, setServiceItemId] = useState<number>(0);
  const [serviceTypeCode, setServiceTypeCode] = useState<string>('');
  const [quantity, setQuantity] = useState<number>(0);
  const [unitPrice, setUnitPrice] = useState<number>(0);
  const [totalPrice, setTotalPrice] = useState<number>(0);
  const [lengthSqFt, setLengthSqFt] = useState<number>(0);
  const [widthSqFt, setWidthSqFt] = useState<number>(0);
  const [selectedId, setSelectedId] = useState<number>();

  const [inputValueCategory, setInputValueCategory] = useState<string>('');
  const [inputValueServiceType, setInputValueServiceType] = useState<string>('');
  const [inputValueServiceItem, setInputValueServiceItem] = useState<string>('');

  const fectDataServiceItem = useCallback(async () => {
    const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();

    const getQueryParams = () => {
      const params = new URLSearchParams();

      if (categoryId) {
        params.append('filter', String(categoryId));
      }

      return params.toString();
    };

    const { data } = await axios.get(`${SERVICE_ITEM_TEMPLATE_BASE_URL}?${getQueryParams()}`, { cancelToken: cancelTokenSource.token });

    const serviceItemTemplates: ServiceItemTemplatesModel[] = [...data.serviceItemTemplates];
    setServiceItemTemplates(serviceItemTemplates);

    const currentServiceType: any[] = [ServiceType];
    const categoryTypeTemplates = String(serviceItemTemplates.map(value => value.Category && value.Category.typeCategory)[0]);
    const newCategoryTypeTemplates = categoryTypeTemplates === 'OTHER_SERVICE' ? categoryTypeTemplates : categoryTypeTemplates.split('_');
    
    const dataServiceType = Object.keys(ServiceType).filter(value => newCategoryTypeTemplates.includes(value));
    setServiceTypes(
      dataServiceType.map(key => {
        return {
          id: key,
          name: String(currentServiceType[0][key])
        };
      })
    );

    return () => cancelTokenSource.cancel();
  }, [categoryId]);

  const fetchData = useCallback(async () => {
    const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();

    const getQueryParams = () => {
      const params = new URLSearchParams();

      return params.toString();
    };

    try {
      const { data } = await axios.get(`${CATEGORY_BASE_URL}?${getQueryParams()}`, { cancelToken: cancelTokenSource.token });

      setCategories(data.Categories);

      
    } catch (err) {
      console.error('err: ', err);
    }
    return () => cancelTokenSource.cancel();
  }, []);

  const resetEditFormValues = useCallback(() => {
    if (!serviceItem || serviceItem.id === 0 ) {
      return;
    }

    const {
      id,
      serviceType,
      serviceItemName,
      unitPrice,
      quantity,
      totalPrice,
      Category
    } = serviceItem;

    const currentServiceItemTemplateIds = serviceItemTemplates.filter(value => value.name === serviceItemName);

    if (currentServiceItemTemplateIds.length) {
      setServiceItemTemplateId(currentServiceItemTemplateIds[0].id);
    }
    setServiceItemId(id);
    setQuantity(quantity);
    setUnitPrice(unitPrice);
    setTotalPrice(totalPrice);
    setServiceTypeCode(serviceType);

    setInputValueServiceItem(serviceItemName);
    if (Category) {
      setInputValueCategory(Category.name);
      setCategoryId(Category.id);
      setCategoryType(Category.type);
    }
    
    switch (serviceType) {
      case 'DC':
        setInputValueServiceType('Dry Cleaner');
        break;
      case 'LA':
        setInputValueServiceType('Laundry');
        break;
      default:
        setInputValueServiceType('Other Service');
        break;
    }

  }, [serviceItem]);

  useEffect(() => {
    fetchData();
    fectDataServiceItem();

    if (openEditAdditionalItem) {
      resetEditFormValues();
    }
    
  }, [fetchData, fectDataServiceItem, openEditAdditionalItem, serviceDiscounts, totalAmount, resetEditFormValues]);

  const resetInputFormValues = () => {
    setInputValueCategory('');
    setInputValueServiceType('');
    setInputValueServiceItem('');
    setCategoryId(0);
    setCategoryType('');
    setServiceItemTemplateId(0);
    setQuantity(0);
    setTotalPrice(0);
    setUnitPrice(0);
    setLengthSqFt(0);
    setWidthSqFt(0);
    setServiceTypeCode('');
  };

  const handleOnCancel: React.FormEventHandler = async event => {
    event.preventDefault();
    resetInputFormValues();
    setOpenCreateAdditionalItem(false);
    setOpenEditAdditionalItem(false);
    
    if (serviceItem && serviceItem.id === 0) {
      serviceItem.id = serviceItemId;
    }
  };

  const handleCloseDialog = () => {
    setOpenSnackbar(false);
  };

  const actionWrapper = async (action: () => Promise<void>, actionMessage: string) => {
    setProcessing(true);

    try {
      await action();
      handleCloseDialog();
      setDelete(true);
      setMessage(`Successfully ${actionMessage}`);
      setSnackbarVariant('success');
      setOpenSnackbar(false);
      setSelectedId(0);
    } catch (err) {
      handleCloseDialog();
      setMessage(`Failed to ${actionMessage}`);
      setSnackbarVariant('error');
      setOpenSnackbar(false);
      setSelectedId(0);
    }

    setProcessing(false);
  };

  const handleClickEdit = (serviceItemIndex: number): React.MouseEventHandler => (event) => {
    event.preventDefault();
    setCurrentEditingAdditionalItemIndex(serviceItemIndex);
    setOpenEditAdditionalItem(true);
    setOpenCreateAdditionalItem(false);
  };

  const handleDeleteActionClick = (serviceItemIndex: number): React.MouseEventHandler => async (event) => {
    event.preventDefault();
    setOpenSnackbar(true);
    setSelectedId(serviceItemIndex);
    setSnackbarVariant('warning');
    setMessage('Are you sure you want to delete this?');
  };

  const handleReset = () => {
    setCategoryId(0);
    setInputValueServiceType('');
    setServiceTypeCode('');
    setInputValueServiceItem('');
    setServiceItemTemplateId(0);
    setUnitPrice(0);
    setQuantity(0);
    setTotalPrice(0);
    setLengthSqFt(0);
    setWidthSqFt(0);
    if (serviceItem) {
      serviceItem.id = 0;
    }
  };

  const handleClickDelete = async (serviceItemIndex: number) => {
    await actionWrapper(async () => {
      await axios.delete(GET_DELETE_SERVICE_ITEM_URL(serviceItemIndex));
    }, 'delete Service Item Additional');
  };

  const handleOnSubmit: React.FormEventHandler = async event => {
    event.preventDefault();
    try {
      const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();
      if (!openEditAdditionalItem) {
        const { data } = await axios.post(
          SERVICE_ITEM_BASE_URL,
          {
            serviceType: serviceTypeCode,
            serviceItemName: inputValueServiceItem,
            itemType:
            serviceItemTemplates.filter(value => value.id === serviceItemTemplateId).length &&
            serviceItemTemplates.filter(value => value.id === serviceItemTemplateId)[0].type,
            unit:
            serviceItemTemplates.filter(value => value.id === serviceItemTemplateId).length &&
            serviceItemTemplates.filter(value => value.id === serviceItemTemplateId)[0].unit,
            unitPrice: unitPrice,
            remarks: null,
            quantity: quantity,
            totalPrice: totalPrice,
            ServiceId: serviceId,
            CategoryId: categoryId,
            isAdditionalItem: true
          },
          { cancelToken: cancelTokenSource.token }
        );
        
        const currentAdditionalServiceItem = [...serviceItems];
        const newCategory: CategoryModel = {
          id: categories.filter(value => value.idCategory === categoryId)[0].idCategory,
          name: categories.filter(value => value.idCategory === categoryId)[0].nameCategory,
          type: categories.filter(value => value.idCategory === categoryId)[0].typeCategory,
          image: categories.filter(value => value.idCategory === categoryId)[0].imageCategory
        };
        currentAdditionalServiceItem.push({
          id: data.id,
          serviceType: data.serviceType,
          serviceItemName: data.serviceItemName,
          unitPrice: data.unitPrice,
          remarks: data.remarks,
          quantity: data.quantity,
          quantityOriginal: data.quantityOriginal,
          totalPrice: data.totalPrice,
          itemType: data.itemType,
          unit: data.unit,
          Category: newCategory,
          ServiceItemTemplate: serviceItemTemplates.filter(value => value.id === serviceItemTemplateId)[0]
        });

        setServiceItems(currentAdditionalServiceItem);

        let additioanlDeliveryService = 0;

        if (deliveryType === DeliveryType.TWO_DAY_EXPRESS) {
          additioanlDeliveryService = Number(data.sumTotalPrice) * 0.5;
        } else if (deliveryType === DeliveryType.ONE_DAY_EXPRESS) {
          additioanlDeliveryService = Number(data.sumTotalPrice);
        } else if (deliveryType === DeliveryType.SUPER_EXPRESS) {
          additioanlDeliveryService = Number(data.sumTotalPrice) + Number(data.sumTotalPrice);
        }

        const newSumTotalPrice = (Number(data.sumTotalPrice) + Number(additioanlDeliveryService));

        // Add new item
        let totalAmountDiscount = 0;
        serviceDiscounts.map(value => {
          if (value.discountType === 'PERCENT') {
            totalAmountDiscount += (Number(value.discountAmount) / 100) * newSumTotalPrice;
          } else {
            totalAmountDiscount += Number(value.discountAmount);
          }
        });

        await axios.put(
          GET_EDIT_SERVICE_URL(data.ServiceId),
          {
            totalAmount: newSumTotalPrice - totalAmountDiscount,
            discountAmount: totalAmountDiscount
          },
          { cancelToken: cancelTokenSource.token }
        );

        await axios.put(
          GET_EDIT_INVOICE_BY_SERVICE_URL(data.ServiceId),
          {
            totalPrice: newSumTotalPrice - totalAmountDiscount
          },
          { cancelToken: cancelTokenSource.token }
        );
      } else {
        const { data } = await axios.put(
          GET_EDIT_SERVICE_ITEM_URL(serviceItemId),
          {
            serviceType: serviceTypeCode,
            serviceItemName: inputValueServiceItem,
            itemType:
            serviceItemTemplates.filter(value => value.id === serviceItemTemplateId).length &&
            serviceItemTemplates.filter(value => value.id === serviceItemTemplateId)[0].type,
            unit:
            serviceItemTemplates.filter(value => value.id === serviceItemTemplateId).length &&
            serviceItemTemplates.filter(value => value.id === serviceItemTemplateId)[0].unit,
            unitPrice: unitPrice,
            remarks: null,
            quantity: quantity,
            quantityOriginal: quantity,
            totalPrice: totalPrice
          },
          { cancelToken: cancelTokenSource.token }
        );

        setServiceItems([
          ...serviceItems!.map((value, index) => {
            if (index !== currentEditingAdditionalItemIndex) {
              return value;
            }
  
            return Object.assign({}, value, {
              serviceType: serviceTypeCode,
              serviceItemName: inputValueServiceItem,
              itemType:
              serviceItemTemplates.filter(value => value.id === serviceItemTemplateId).length &&
              serviceItemTemplates.filter(value => value.id === serviceItemTemplateId)[0].type,
              unit:
                serviceItemTemplates.filter(value => value.id === serviceItemTemplateId).length &&
                serviceItemTemplates.filter(value => value.id === serviceItemTemplateId)[0].unit,
              unitPrice: unitPrice,
              remarks: null,
              quantity: quantity,
              totalPrice: totalPrice
            });
          })
        ]);
        // Edit item
        let additioanlDeliveryService = 0;

        if (deliveryType === DeliveryType.TWO_DAY_EXPRESS) {
          additioanlDeliveryService = Number(data.sumTotalPrice) * 0.5;
        } else if (deliveryType === DeliveryType.ONE_DAY_EXPRESS) {
          additioanlDeliveryService = Number(data.sumTotalPrice);
        } else if (deliveryType === DeliveryType.SUPER_EXPRESS) {
          additioanlDeliveryService = Number(data.sumTotalPrice) + Number(data.sumTotalPrice);
        }

        const newSumTotalPrice = (Number(data.sumTotalPrice) + Number(additioanlDeliveryService));
        
        let totalAmountDiscount = 0;
        serviceDiscounts.map(value => {
          if (value.discountType === 'PERCENT') {
            totalAmountDiscount += (Number(value.discountAmount) / 100) * newSumTotalPrice;
          } else {
            totalAmountDiscount += (Number(value.discountAmount) / 100) * newSumTotalPrice;
          }
        });

        await axios.put(
          GET_EDIT_SERVICE_URL(data.ServiceId),
          {
            totalAmount: newSumTotalPrice - totalAmountDiscount,
            discountAmount: totalAmountDiscount
          },
          { cancelToken: cancelTokenSource.token }
        );

        await axios.put(
          GET_EDIT_INVOICE_BY_SERVICE_URL(data.ServiceId),
          {
            totalPrice: newSumTotalPrice - totalAmountDiscount
          },
          { cancelToken: cancelTokenSource.token }
        );
      }
      resetInputFormValues();
      setOpenCreateAdditionalItem(false);
      setOpenEditAdditionalItem(false);
    } catch (error) {
      console.log(error);
    }
  };

  const cell = (value: any) => (isLoading ? <Skeleton width='100%' /> : value);

  const rows = (value: ServiceItemModel, index: number) => {
    let category = '-';
    let serviceType = 'Laundry';

    if (value.serviceType == 'DC') {
      serviceType = 'Dry Clean';
    }

    if (value.Category) {
      category = value.Category.name;
    }

    return (
      <TableRow className={index % 2 === 0 ? classes.odd : classes.even} key={index + 1}>
        <TableCell align='center'>{cell(category)}</TableCell>
        <TableCell align='center'>{cell(serviceType)}</TableCell>
        <TableCell align='center'>{cell(value.serviceItemName)}</TableCell>
        <TableCell align='center'>
          {cell(
            <NumberFormat
              value={value.quantity}
              displayType={'text'}
              thousandSeparator={true}
              prefix={''}
              decimalScale={value.itemType! === ItemType.MEASURE_ON_SITE ? 2 : 0}
              fixedDecimalScale={true}
            />
          )}
        </TableCell>
        <TableCell align='center'>
          {cell(
            <NumberFormat
              value={value.unitPrice}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'$'}
              decimalScale={2}
              fixedDecimalScale={true}
            />
          )}
        </TableCell>
        <TableCell align='center'>
          {cell(
            <NumberFormat
              value={value.totalPrice}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'$'}
              decimalScale={2}
              fixedDecimalScale={true}
            />
          )}
        </TableCell>
        <TableCell align='center'>
          <IconButton size='small' onClick={handleClickEdit(index)} disabled={!statusAllowChangeAndEdits.includes(jobStatus)}>
            <EditIcon className={classes.actionIcon} />
          </IconButton>
          <IconButton size='small' onClick={handleDeleteActionClick(value.id)} disabled={!statusAllowChangeAndEdits.includes(jobStatus)}>
            <DeleteIcon className={classes.actionIcon} />
          </IconButton>
        </TableCell>
      </TableRow>
    );
  };

  return (
    <Paper variant='outlined' style={{ width: '100%', overflow: 'hidden' }}>
      <Table>
        <TableHead>
          <TableRow>
            {th.map((val, i) => (
              <TableCell key={i + 1} align='center'>
                {val}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {openCreateAdditionalItem && statusAllowChangeAndEdits.includes(jobStatus) && (
            <CreateEditAdditionalItemForm
              categories={categories}
              categoryId={categoryId}
              setCategoryId={setCategoryId}
              categoryType={categoryType}
              setCategoryType={setCategoryType}
              serviceTypes={serviceTypes}
              serviceItemTemplates={serviceItemTemplates}
              setServiceItemTemplateId={setServiceItemTemplateId}
              serviceItemTemplateId={serviceItemTemplateId}
              serviceTypeCode={serviceTypeCode}
              setServiceTypeCode={setServiceTypeCode}
              quantity={quantity}
              setQuantity={setQuantity}
              unitPrice={unitPrice}
              setUnitPrice={setUnitPrice}
              totalPrice={totalPrice}
              setTotalPrice={setTotalPrice}
              lengthSqFt={lengthSqFt}
              setLengthSqFt={setLengthSqFt}
              widthSqFt={widthSqFt}
              setWidthSqFt={setWidthSqFt}
              inputValueCategory={inputValueCategory}
              setInputValueCategory={setInputValueCategory}
              inputValueServiceType={inputValueServiceType}
              setInputValueServiceType={setInputValueServiceType}
              inputValueServiceItem={inputValueServiceItem}
              setInputValueServiceItem={setInputValueServiceItem}
              onSubmit={handleOnSubmit}
              onCancel={handleOnCancel}
              onReset={handleReset}
            />
          )}
          {isLoading ? (
            [1, 2, 3, 4].map(index => rows(defaultServiceItem, index))
          ) : serviceItems.length > 0 ? (
            <Fragment>
              {serviceItems.map((value, index) =>
                openEditAdditionalItem && currentEditingAdditionalItemIndex === index && statusAllowChangeAndEdits.includes(jobStatus) ? (
                  <CreateEditAdditionalItemForm
                    key={value.id}
                    categories={categories}
                    categoryId={categoryId}
                    setCategoryId={setCategoryId}
                    categoryType={categoryType}
                    setCategoryType={setCategoryType}
                    serviceTypes={serviceTypes}
                    serviceItemTemplates={serviceItemTemplates}
                    setServiceItemTemplateId={setServiceItemTemplateId}
                    serviceItemTemplateId={serviceItemTemplateId}
                    serviceTypeCode={serviceTypeCode}
                    setServiceTypeCode={setServiceTypeCode}
                    quantity={quantity}
                    setQuantity={setQuantity}
                    unitPrice={unitPrice}
                    setUnitPrice={setUnitPrice}
                    totalPrice={totalPrice}
                    setTotalPrice={setTotalPrice}
                    lengthSqFt={lengthSqFt}
                    setLengthSqFt={setLengthSqFt}
                    widthSqFt={widthSqFt}
                    setWidthSqFt={setWidthSqFt}
                    inputValueCategory={inputValueCategory}
                    setInputValueCategory={setInputValueCategory}
                    inputValueServiceType={inputValueServiceType}
                    setInputValueServiceType={setInputValueServiceType}
                    inputValueServiceItem={inputValueServiceItem}
                    setInputValueServiceItem={setInputValueServiceItem}
                    onSubmit={handleOnSubmit}
                    onCancel={handleOnCancel}
                    onReset={handleReset}
                  />
                ) : (
                  rows(value, index)
                )
              )}
              <TableRow>
                <TableCell colSpan={5} align='right'>
                  <Box fontWeight='fontWeightBold' m={1}>
                    Total Amount
                  </Box>
                </TableCell>
                <TableCell align='center'>
                  <Box fontWeight='fontWeightBold' m={1}>
                    {cell(
                      <NumberFormat
                        value={serviceItems.reduce((sum, current) => sum + Number(current.totalPrice), 0)}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={'$'}
                        decimalScale={2}
                        fixedDecimalScale={true}
                      />
                    )}
                  </Box>
                </TableCell>
              </TableRow>
            </Fragment>
          ) : (
            <Fragment>
              <TableRow>
                <TableCell colSpan={7} align='center'>
                  Data not available
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={5} align='right'>
                  <Box fontWeight='fontWeightBold' m={1}>
                    Total Amount
                  </Box>
                </TableCell>
                <TableCell align='center'>
                  <Box fontWeight='fontWeightBold' m={1}>
                    -
                  </Box>
                </TableCell>
                <TableCell align='center'></TableCell>
              </TableRow>
            </Fragment>
          )}
        </TableBody>
      </Table>
      <StandardConfirmationDialog
        variant={snackbarVariant}
        message={message}
        open={openSnackbar}
        handleClose={handleCloseDialog}
        onConfirm={event => {
          if (selectedId) {
            console.log(selectedId);
            handleClickDelete(selectedId);
          }
        }}
      />
    </Paper>
  );
};

export default AdditionalItemTable;
