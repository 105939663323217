import React, { useState, useEffect } from 'react';
import { IconButton, Theme, makeStyles, Modal, Typography, Grid } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import DetailInvoice from './components/DetailInvoice';
import { ServiceItemDummy } from 'constants/DummyData';
import { StandardConfirmationDialog } from 'components/AppDialog';
import Axios, { CancelTokenSource } from 'axios';
import { GET_UPDATE_STATUS_JOBS_URL } from 'constants/url';

interface Props {
  invoice?: InvoiceModel;
  open: boolean;
  handleCancel(): void;
  setOpenSnackbar: React.Dispatch<React.SetStateAction<boolean>>;
  setSnackbarVarient: React.Dispatch<React.SetStateAction<'success' | 'error'>>;
  handleSetMessageSuccess: (message: string) => void;
  handleSetMessageError: (message: string) => void;
  handleSnackBar: (open: boolean, variant: 'success' | 'error', message: string) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    position: 'absolute',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    outline: 'none',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: 4
  },
  modalWrapper: {
    overflow: 'hidden',
    overflowY: 'auto'
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  },
  title: {
    marginBottom: theme.spacing(2)
  }
}));

const dummyCollectionAddress: CollectionAddressModel = {
  id: 0,
  address: '',
  postalCode: '',
  floorNo: '',
  unitNo: ''
};

const dummyServiceAddress: ServiceAddressModel = {
  id: 0,
  address: '',
  postalCode: '',
  floorNo: '',
  unitNo: ''
};

const dummyCustomer: CustomerModel = {
  id: 0,
  firstName: '',
  lastName: '',
  contactNumber: '',
  contactPersonName: '',
  contactPersonEmail: '',
  contactPersonNumber: '',
  primaryCollectionAddress: '',
  primaryCollectionAddressDetail: '',
  primaryServiceAddress: '',
  primaryServiceAddressDetail: '',
  email: ''
};

const dummyServiceItem: ServiceItemModel[] = [ServiceItemDummy];

const dummyService: ServiceModel = {
  id: 0,
  serviceNumber: '',
  description: '',
  time: '',
  CustomerId: 0,
  totalAmount: 0,
  deliveryType: '',
  promoCode: '',
  typePromo: '',
  discountType: '',
  discountAmount: 0
};

const DetailInvoiceModal: React.FC<Props> = props => {
  const classes = useStyles();

  const { invoice, open, handleCancel, handleSnackBar } = props;

  const [invoiceid, setInvoiceid] = useState<number>(0);
  const [thisInvoiceNumber, setThisInvoiceNumber] = useState<string>('');
  const [invoiceCreatedAt, setInvoiceCreatedAt] = useState<string>('');
  const [invoicePaymentType, setInvoicePaymentType] = useState<string>('');
  const [invoicePaymentStatus, setInvoicePaymentStatus] = useState<string>('');
  const [invoiceTotalPrice, setInvoiceTotalPrice] = useState<number>(0);
  const [invoiceTotalCollected, setInvoiceTotalCollected] = useState<number>(0);
  const [invoiceTotalChanges, setInvoiceTotalChanges] = useState<number>(0);
  const [jobStatus, setJobStatus] = useState<string>('');
  const [customerFirstName, setFirstName] = useState<string>('');
  const [customerLastName, setLastName] = useState<string>('');
  const [serviceDeliveryType, setServiceDeliveryType] = useState<string>('');
  const [serviceDiscountType, setServiceDiscountType] = useState<string>('');
  const [serviceDiscountAmount, setServiceDiscountAmount] = useState<number>(0);
  const [serviceTotalAmount, setServiceTotalAmount] = useState<number>(0);
  const [jobId, setJobId] = useState<number>(0);
  const [jobSignature, setJobSignature] = useState<string>('');

  const [serviceItemsData, setServiceItemsData] = useState<ServiceItemModel[]>();
  const [customerServiceAddress, setCustomerServiceAddress] = useState<ServiceAddressModel>();
  const [customerCollectionAddress, setCustomerCollectionAddress] = useState<CollectionAddressModel>();

  const [confirmCancel, setConfirmCancel] = useState<boolean>(false);
  const [thisIndex, setThisIndex] = useState<number>(0);
  const [thisStatus, setThisStatus] = useState<string>('');
  const [thisId, setThisId] = useState<number>(0);
  const [currentIndex, setCurrentIndex] = useState<number>(-1);
  const [isEdit, setEdit] = useState<boolean>(false);
  const [isSubmit, setSubmit] = useState<boolean>(false);

  const handleClose = () => {
    handleCancel();
  };

  const handleCancelledConfirm = () => {
    setConfirmCancel(false);
    setSubmit(true);
    handleJobStatus(thisIndex, thisStatus, thisId);
    handleCancel();
  };

  const handleJobStatus = async (index: number, jobStatus: string, id: number) => {
    const cancelTokenSource: CancelTokenSource = Axios.CancelToken.source();
    try {
      const { data } = await Axios.put(GET_UPDATE_STATUS_JOBS_URL(id), { jobStatus }, { cancelToken: cancelTokenSource.token });

      handleSnackBar(true, 'success', 'Invoice has been successfully updated.');
    } catch (err) {
      console.log('err:', err);
      handleSnackBar(true, 'error', '');
    }
  };

  useEffect(() => {
    if (!invoice) {
      return;
    }

    const { id, invoiceNumber, createdAt, paymentType, paymentStatus, totalPrice, totalCollected, totalChanges, Service } = invoice;
    setInvoiceid(id);
    setThisInvoiceNumber(invoiceNumber);
    setInvoiceCreatedAt(createdAt);
    setInvoicePaymentType(paymentType);
    setInvoicePaymentStatus(paymentStatus);
    setInvoiceTotalPrice(totalPrice);
    setInvoiceTotalCollected(totalCollected || 0);
    setInvoiceTotalChanges(totalChanges || 0);

    const { deliveryType, totalAmount, discountAmount, discountType, Customer, ServiceItems, ServiceAddress, CollectionAddress, Job } = !Service
      ? dummyService
      : Service;
    setServiceDeliveryType(deliveryType);
    setServiceDiscountType(discountType);
    setServiceDiscountAmount(discountAmount);
    setServiceTotalAmount(totalAmount);

    if (Job) {
      const { clientSignature } = Job;
      setJobSignature(clientSignature || '');
      setJobId(Job.id);
      setJobStatus(Job.jobStatus);
    }

    setCustomerCollectionAddress(!CollectionAddress ? dummyCollectionAddress : CollectionAddress);
    setCustomerServiceAddress(!ServiceAddress ? dummyServiceAddress : ServiceAddress);
    setServiceItemsData(!ServiceItems ? dummyServiceItem : ServiceItems);

    const { firstName, lastName } = !Customer ? dummyCustomer : Customer;
    setFirstName(firstName);
    setLastName(lastName);
  }, [invoice]);

  const handleOpenCancelled = (thisIndex: number, thisStatus: string, thisId: number) => {
    setConfirmCancel(true);
    setThisIndex(thisIndex);
    setThisStatus(thisStatus);
    setThisId(thisId);
  };

  return (
    <>
      <Modal aria-labelledby='modal-title' open={open} disableBackdropClick={true} className={classes.modalWrapper}>
        <Grid container item xs={8} sm={8} md={8} lg={5} xl={5} spacing={3} direction='column' className={classes.paper}>
          <Typography variant='h2' id='modal-title' className={classes.title}>
            {customerFirstName} {customerLastName}
          </Typography>
          <IconButton size='small' className={classes.closeButton} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
          <DetailInvoice
            id={invoiceid}
            jobId={jobId}
            customerFirstName={customerFirstName}
            customerLastName={customerLastName}
            invoiceNumber={thisInvoiceNumber}
            createdAt={invoiceCreatedAt}
            paymentType={invoicePaymentType}
            paymentStatus={invoicePaymentStatus}
            totalPrice={invoiceTotalPrice}
            totalCollected={invoiceTotalCollected}
            totalChanges={invoiceTotalChanges}
            deliveryType={serviceDeliveryType}
            discountType={serviceDiscountType}
            discountAmount={serviceDiscountAmount}
            totalAmount={serviceTotalAmount}
            clientSignature={jobSignature}
            serviceItems={serviceItemsData}
            serviceAddress={customerServiceAddress}
            collectionAddress={customerCollectionAddress}
            jobStatus={jobStatus}
            handleClose={handleClose}
            handleOpenCancelled={handleOpenCancelled}
          />
        </Grid>
      </Modal>
      <StandardConfirmationDialog
        variant={'danger'}
        titleMessage={'Cancel Invoice'}
        message={'Are you sure to cancel this order? please click ‘OK’ to confirm this assignment.'}
        open={confirmCancel}
        handleClose={() => setConfirmCancel(false)}
        onConfirm={handleCancelledConfirm}
      />
    </>
  );
};

export default DetailInvoiceModal;
