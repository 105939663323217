import React, { FC, useState, useCallback, useEffect, useContext } from 'react';
import clsx from 'clsx';
import { Container, makeStyles, createStyles, Theme, Typography, Grid, Button, IconButton, Tooltip, Snackbar } from '@material-ui/core';
import axios, { CancelTokenSource } from 'axios';
import { Restore } from '@material-ui/icons';
import SearchInput from 'components/SearchInput';
import useDebounce from 'hooks/useDebounce';
import { JOBS_BASE_URL } from 'constants/url';
import { StandardConfirmationDialog } from 'components/AppDialog';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import StatusType from 'typings/enum/StatusType';
import useRouter from 'hooks/useRouter';
import useCurrentPageTitleUpdater from 'hooks/useCurrentPageTitleUpdater';
import { CurrentJobTabContext } from '../../contexts/CurrentJobTabContext';
import CustomizedTabs from 'components/CustomizedTabs';
import JobTable from './components/JobTable';
import TableFilter from './components/TableFilter';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4)
    },
    container: {
      '& > :nth-child(n+2)': {
        marginTop: theme.spacing(2)
      }
    }
  })
);

const Alert = (props: AlertProps) => {
  return <MuiAlert elevation={6} variant='filled' {...props} />;
};

const defaultJob = {
  id: 0,
  collectionDate: new Date(),
  deliveryDate: new Date(),
  collectionTime: '',
  deliveryTime: '',
  jobStatus: '',
  clientSignature: '',
  Service: {
    id: 0,
    serviceNumber: '',
    description: '',
    time: '',
    CustomerId: 0,
    deliveryType: '',
    promoCode: '',
    typePromo: '',
    discountType: '',
    discountAmount: '',
    totalAmount: 0,
    ServiceAddress: {
      id: 0,
      postalCode: '',
      address: '',
      floorNo: '',
      unitNo: ''
    },
    CollectionAddress: {
      id: 0,
      postalCode: '',
      address: '',
      floorNo: '',
      unitNo: ''
    },
    Customer: {
      id: 0,
      firstName: '',
      lastName: '',
      contactNumber: '',
      contactPersonName: '',
      contactPersonEmail: '',
      contactPersonNumber: '',
      primaryCollectionAddress: '',
      primaryCollectionAddressDetail: '',
      primaryServiceAddress: '',
      primaryServiceAddressDetail: '',
      email: ''
    },
    ServiceItems: [
      {
        id: 0,
        serviceType: '',
        serviceItemName: '',
        unitPrice: 0,
        quantity: 0,
        quantityOriginal: 0,
        totalPrice: 0,
        itemType: '',
        unit: '',
        remarks: '',
        Category: {
          id: 0,
          name: '',
          type: '',
          image: ''
        }
      }
    ]
  },
  User: {
    id: 0,
    displayName: ''
  }
};

const defaultFilter = {
  jobStatus: 'UNASSIGNED',
  UserId: '',
  collectionDate: '',
  deliveryDate: '',
  deliveryType: ''
};

const JobsPage: FC = () => {
  useCurrentPageTitleUpdater('JOBS');

  const classes = useStyles();
  const { location, history } = useRouter();
  const { currentJobTab } = useContext(CurrentJobTabContext);
  const [isLoadingData, setIsLoadingData] = useState<boolean>(false);
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [isOpen, setOpen] = useState<boolean>(false);
  const [isReload, setReload] = useState<boolean>(false);
  const [isReset, setReset] = useState<boolean>(false);

  const [snackbarVariant, setSnackbarVariant] = useState<'success' | 'error'>('success');
  const [message, setMessage] = useState<string>('');

  const [currentPage, setCurrentPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [count, setCount] = useState<number>(0);
  const [query, setQuery] = useState<string>('');
  const [queryString, setQueryString] = useState<string>();

  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  const [orderBy, setOrderBy] = useState<string>('');
  const [filter, setFilter] = useState<{ [keys: string]: string }>(defaultFilter);
  const [tabValue, setTabValue] = useState<number>(currentJobTab);

  const [jobs, setJobs] = useState<JobModel[]>([defaultJob]);
  const statusType = Object.entries(StatusType).map(([keys, value]) => value);
  const tabHeader = ['All', ...statusType];

  const fetchData = useCallback(async () => {
    const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();
    setIsLoadingData(true);

    const params = new URLSearchParams();
    if (queryString) {
      params.append('q', queryString);
    }

    if (orderBy) {
      params.append('orderBy', orderBy);
    } else {
      if (tabValue === 7 || tabValue === 8 || tabValue === 9) {
        params.append('orderBy', 'deliveryDate');
        setOrderBy('deliveryDate');
      } else {
        params.append('orderBy', 'collectionDate');
        setOrderBy('collectionDate');
      }
    }

    if (order) {
      params.append('order', order.toUpperCase());
    }

    if (filter) {
      Object.entries(filter).map(([keys, value]) => {
        if (value !== '' && value !== undefined) {
          params.append('filter[' + keys + ']', value);
          setReset(true);
        }
        return [keys, value];
      });
    }

    params.append('s', (currentPage * rowsPerPage).toString());
    params.append('l', rowsPerPage.toString());

    try {
      const { data } = await axios.get(JOBS_BASE_URL + '?' + params.toString(), { cancelToken: cancelTokenSource.token });
      setJobs(data.jobs);
      setCount(data.count);

      if (isReload) {
        handleSnackBarService(true, 'Connected', 'success');
        setReload(false);
      }
    } catch (err) {
      console.error('err: ', err);
      handleSnackBarService(true, 'Request error!', 'error');
    }
    setReload(false);
    setIsLoadingData(false);
    return () => cancelTokenSource.cancel();
  }, [rowsPerPage, currentPage, queryString, order, orderBy, filter, isReload, tabValue]);

  const performActionAndRevertPage = (action: React.Dispatch<React.SetStateAction<any>>, actionParam: any) => {
    setCurrentPage(0);
    action(actionParam);
  };

  const handleSearch = useCallback((searchQuery: string) => {
    performActionAndRevertPage(setQueryString, searchQuery);
  }, []);

  const debouncedSearchTerm = useDebounce(query, 500);

  const handleSnackBar = (open: boolean, variant: 'success' | 'error', message: string) => {
    setSnackbarVariant(variant);
    setOpenSnackbar(open);
    setMessage(message);
  };

  useEffect(() => {
    if (!tabValue) {
      return;
    }

    if (tabValue === 7 || tabValue === 8 || tabValue === 9) {
      setOrderBy('deliveryDate');
    }

    handleChangeTab(tabValue);
  }, [tabValue]);

  useEffect(() => {
    if (debouncedSearchTerm.length >= 3) {
      handleSearch(debouncedSearchTerm);
    } else if (debouncedSearchTerm.length === 0) {
      handleSearch(debouncedSearchTerm);
    }
  }, [debouncedSearchTerm, handleSearch]);

  useEffect(() => {
    if (orderBy === '') {
      setOrderBy('collectionDate');
    }

    fetchData();
  }, [fetchData]);

  useEffect(() => {
    if(history.action === 'POP'){
      setFilter({ ...defaultFilter, jobStatus: statusType[currentJobTab - 1] });
    }
  }, []);

  const handleSnackBarService = (isOpen: boolean, message: string, severity: 'success' | 'error') => {
    setOpen(isOpen);
    setMessage(message);
    setSnackbarVariant(severity);
  };

  const handleSnackBarServiceClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleReload = () => {
    setReload(true);
    setOpen(false);
  };

  const handleChangeTab = (tabId: number) => {
    setTabValue(tabId);
    setFilter({ ...defaultFilter, jobStatus: statusType[tabId - 1] });
  };

  const handleResetFilter = () => {
    setFilter({ ...defaultFilter, jobStatus: statusType[tabValue - 1] });
    setReset(false);
  };

  return (
    <Container maxWidth='lg' className={clsx(classes.root, classes.container)}>
      <Grid container direction='row' justify='space-between'>
        <Grid container item xs={8} sm={8} md={8} lg={8} alignItems='center'>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Typography component='h6' variant='h6' gutterBottom>
              List of all jobs.
            </Typography>
          </Grid>
        </Grid>

        <Grid container item xs={4} sm={4} md={4} lg={4} justify='flex-end' alignItems='center'></Grid>
      </Grid>

      <Grid container direction='row' justify='space-between'>
        <Grid container item lg={6} sm={6} md={6} justify='flex-start' alignItems='center'>
          <Grid item>
            <SearchInput
              withBorder
              withTransition={false}
              width={150}
              placeHolder='Search Jobs...'
              iconColor='#989898'
              tableSearchValue={query}
              setTableSearchValue={setQuery}
            />
          </Grid>
        </Grid>

        <Grid container item lg={6} sm={6} md={6} justify='flex-end' alignItems='center'>
          <Grid item>
            <Tooltip title='Filter'>
              <span>
                <TableFilter filter={filter} setFilter={setFilter} />
              </span>
            </Tooltip>

            {isReset && (
              <Tooltip title='Reset Filter'>
                <IconButton area-label='reset' onClick={handleResetFilter}>
                  <Restore />
                </IconButton>
              </Tooltip>
            )}
          </Grid>
        </Grid>
      </Grid>

      <Grid container direction='row'>
        <CustomizedTabs tabs={tabHeader.map((val, index) => ({ id: index, name: val }))} selectedTabId={tabValue} onSelect={handleChangeTab} />

        <JobTable
          isLoadingData={isLoadingData}
          count={count}
          currentTab={tabValue}
          currentPage={currentPage}
          rowsPerPage={rowsPerPage}
          jobs={jobs}
          setJobs={setJobs}
          order={order}
          orderBy={orderBy}
          setOrder={setOrder}
          setOrderBy={setOrderBy}
          handleSnackBar={handleSnackBar}
          handleChangePage={(event, page) => setCurrentPage(page)}
          handleChangeRowsPerPage={event => performActionAndRevertPage(setRowsPerPage, +event.target.value)}
        />
      </Grid>

      <StandardConfirmationDialog
        variant={snackbarVariant}
        titleMessage={snackbarVariant.charAt(0).toUpperCase() + snackbarVariant.slice(1) + '!'}
        message={message}
        open={openSnackbar}
        handleClose={() => setOpenSnackbar(false)}
        onConfirm={() => setOpenSnackbar(false)}
        noCancelButton={true}
      />

      <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={isOpen} onClose={handleSnackBarServiceClose}>
        <Alert severity={snackbarVariant} onClose={handleSnackBarServiceClose}>
          {message}
          {snackbarVariant === 'error' && (
            <Button color='default' size='small' onClick={handleReload}>
              Reload!
            </Button>
          )}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default JobsPage;
