import React, { Fragment, FC, useState, useCallback, useEffect } from 'react';
import { Table, TableBody, TableHead, TableCell, TableRow, TableFooter, Paper } from '@material-ui/core';

import HeaderRow from 'components/HeaderRow';
import TablePagination from 'components/TablePagination';
import axios, { CancelTokenSource } from 'axios';
import { CATEGORY_BASE_URL, GET_EDIT_CATEGORY_URL } from 'constants/url';
import BodyRow from './components/BodyRow';
import CategoryFormField from './components/CategoryFormField';

import aws from 'aws-sdk';
import CategoryType from 'typings/enum/CategoryType';
import { CategoryDummy } from 'constants/DummyData';

interface Props {
  isLoadingData: boolean;
  count: number;
  checked: number[];
  currentPage: number;
  rowsPerPage: number;
  categories: CategoryResponseModel[];
  openCreateForm: boolean;
  openEditForm: boolean;
  currentIndex: number;
  order: 'asc' | 'desc';
  orderBy: string;
  setOrder: React.Dispatch<React.SetStateAction<'asc' | 'desc'>>;
  setOrderBy: React.Dispatch<React.SetStateAction<string>>;
  setIsLoadingData: React.Dispatch<React.SetStateAction<boolean>>;
  setCategories: React.Dispatch<React.SetStateAction<CategoryResponseModel[]>>;
  setCurrentIndex: React.Dispatch<React.SetStateAction<number>>;
  setOpenCreateForm: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenEditForm: React.Dispatch<React.SetStateAction<boolean>>;
  setChecked: React.Dispatch<React.SetStateAction<number[]>>;
  handleChangePage: (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => void;
  handleChangeRowsPerPage: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  handleSnackBar: (open: boolean, variant: 'success' | 'error', message: string) => void;
  handleClickDeleteCategory: (index: number) => void;
}

interface Error {
  name: boolean;
  nameMessage: string;
}

const dummyCategory: CategoryResponseModel = CategoryDummy;

const { REACT_APP_AWS_REGION, REACT_APP_AWS_ACCESS_KEY, REACT_APP_AWS_SECRET_KEY, REACT_APP_S3_BUCKET_NAME } = process.env;
aws.config.update({
  accessKeyId: REACT_APP_AWS_ACCESS_KEY,
  secretAccessKey: REACT_APP_AWS_SECRET_KEY,
  region: REACT_APP_AWS_REGION
});

const s3 = new aws.S3();

const CategoryTable: FC<Props> = props => {
  let cancelTokenSource: CancelTokenSource = axios.CancelToken.source();

  const {
    isLoadingData,
    count,
    checked,
    currentIndex,
    currentPage,
    rowsPerPage,
    categories,
    order,
    setOrder,
    orderBy,
    setOrderBy,
    openCreateForm,
    openEditForm,
    setIsLoadingData,
    setCategories,
    setOpenCreateForm,
    setOpenEditForm,
    setCurrentIndex,
    setChecked,
    handleChangePage,
    handleChangeRowsPerPage,
    handleSnackBar,
    handleClickDeleteCategory
  } = props;

  // The below logic introduces a 500ms delay for showing the skeleton
  const [showSkeleton, setShowSkeleton] = useState<boolean>(false);
  const [name, setName] = useState<string>('');
  const [type, setType] = useState<string>(CategoryType.LA_DC);
  const [image, setImage] = useState<string>('');
  const [imageView, setImageView] = useState<string>('');
  const [imageError, setImageError] = useState<string>('');
  const [oldImageEdited, setOldImageEdited] = useState<string>('');
  const [imageCategories, setImageCategories] = useState<string[]>([]);
  const [id, setId] = useState<number>(0);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [error, setError] = useState<Error>({
    name: false,
    nameMessage: ''
  });

  const checkAll = () => {
    const newBulkChecked = [...checked];
    const countChecked = newBulkChecked.length;
    if (count > rowsPerPage) {
      if (countChecked !== rowsPerPage) {
        newBulkChecked.splice(0, countChecked);
        categories.map(item => newBulkChecked.push(item.idCategory));
      } else {
        newBulkChecked.splice(0, count);
      }
    } else {
      if (countChecked !== count) {
        newBulkChecked.splice(0, countChecked);
        categories.map(item => newBulkChecked.push(item.idCategory));
      } else {
        newBulkChecked.splice(0, count);
      }
    }
    setChecked(newBulkChecked);
  };

  const handleIndividualChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
    const id = parseInt(event.target.value);
    const countElement = checked.filter(newCheckedValue => newCheckedValue === id).length;
    if (countElement === 0) {
      checked.push(id);
    } else {
      checked.splice(checked.indexOf(id), 1);
    }
    setChecked([...checked]);
  };

  const onSubmit = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (name === '' && !name) {
      setError({ ...error, name: true, nameMessage: 'Name can not be empty.' });
      return;
    }

    if (!imageView || !imageView.trim()) {
      setImageError('Please choose category image');
      return;
    }

    
    if (openCreateForm) {
      try {
        setIsSubmitting(true);
        setImageError('');
        setError({ ...error, nameMessage: 'Loading...' });

        cancelTokenSource = axios.CancelToken.source();
        const config = { cancelToken: cancelTokenSource.token };

        // @ts-ignore
        const newImageKey = image ? image.name : '';
        const fileExtension = newImageKey.split('.').pop();

        const { data } = await axios.post(CATEGORY_BASE_URL, { name, type, image: newImageKey }, config);

        if (data.image) {
          const myHeaders = new Headers();
          myHeaders.append('Content-Type', `image/${fileExtension}`);

          const config = {
            method: 'PUT',
            headers: myHeaders,
            body: image,
          };

          fetch(data.image, config)
            .then(response => response.text())
            .then(result => console.log(result))
            .catch(error => console.log('error', error));
        }

        setCategories([
          {
            nameCategory: data.name,
            imageCategory: data.image,
            imageKey: data.imageKey,
            typeCategory: data.type,
            idCategory: data.id,
            new: true
          },
          ...categories
        ]);
        resetCategory();
        handleSnackBar(true, 'success', 'Category has been added successfully.');
      } catch (error) {
        console.log('err: ', error);
        handleSnackBar(true, 'error', 'The category has failed to add.');
      }
    }

    if (openEditForm) {
      try {
        setIsSubmitting(true);
        setError({ ...error, nameMessage: 'Loading...' });

        // @ts-ignore
        const newImageKey = image ? image.name : '';
        const fileExtension = newImageKey.split('.').pop();

        cancelTokenSource = axios.CancelToken.source();

        const formData = { name, type, image: newImageKey };
        const config = { cancelToken: cancelTokenSource.token };

        const { data } = await axios.put(GET_EDIT_CATEGORY_URL(id), formData, config);
        categories[currentIndex].nameCategory = data.name;
        categories[currentIndex].typeCategory = data.type;
        categories[currentIndex].imageCategory = data.image;
        categories[currentIndex].imageKey = data.imageKey;

        if (oldImageEdited) {
          const object = { Key: `assets/${oldImageEdited}` };
          const options = {
            Bucket: REACT_APP_S3_BUCKET_NAME!,
            Delete: { Objects: [object] }
          };

          await Promise.all([
            s3.deleteObjects(options, (err, data) => {
              if (err) {
                console.log(err, err.stack, data);
              }
            })
          ]);
        }

        if (data.image) {
          const myHeaders = new Headers();
          myHeaders.append('Content-Type', `image/${fileExtension}`);

          const config = {
            method: 'PUT',
            headers: myHeaders,
            body: image
          };

          fetch(data.image, config)
            .then(response => response.text())
            .then(result => console.log(result))
            .catch(error => console.log('error', error));
        }

        setCategories(categories);
        resetCategory();
        handleSnackBar(true, 'success', 'Category updated successfully.');
      } catch (error) {
        console.log('err: ', error);
        handleSnackBar(true, 'error', 'The category has failed to update.');
      }
    }
    setError({ ...error, nameMessage: '' });
    setIsSubmitting(false);
  };

  const onCancel = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setIsSubmitting(false);
    setOpenCreateForm(false);
    setOpenEditForm(false);
    resetCategory();
  };

  const handleClickEditCategory = (index: number): React.MouseEventHandler => () => {
    resetCategory();
    setCurrentIndex(index);
    setOpenEditForm(!openEditForm);
    getCurrentCategory(index);
  };

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const getCurrentCategory = useCallback(
    (index: number) => {
      const { idCategory, nameCategory, typeCategory, imageCategory, imageKey } = categories[index];
      const thisImage = imageCategories[index] ? imageCategories[index] : imageCategory;

      setId(idCategory);
      setName(nameCategory);
      setType(typeCategory);
      setImageView(thisImage);
      setImage(imageKey);
    },
    [categories]
  );

  const resetCategory = useCallback(() => {
    setName('');
    setType(CategoryType.LA_DC);
    setImage('');
    setImageView('');
    setId(0);
  }, []);

  useEffect(() => {
    if (isLoadingData) {
      setShowSkeleton(true);
    }

    return () => {
      setShowSkeleton(false);
    };
  }, [isLoadingData]);

  return (
    <div style={{ marginTop: 8 }}>
      <Paper variant='outlined' elevation={2}>
        <Table size='small'>
          <TableHead>
            <HeaderRow
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              height={'unset'}
              headers={[
                { label: 'CheckBox', verticalAlign: 'top', isCheckBox: true, checked, rowsPerPage, handleCheckAll: checkAll },
                { id: 'name', label: 'Name', sort: true },
                { id: 'type', label: 'Type', sort: true },
                { label: 'Icon' },
                { label: 'Action', align: 'center' }
              ]}
            />
          </TableHead>
          <TableBody>
            {openCreateForm && (
              <CategoryFormField
                error={error}
                name={name}
                setName={setName}
                type={type}
                setType={setType}
                isSubmitting={isSubmitting}
                onSubmit={onSubmit}
                onCancel={onCancel}
                setError={setError}
                image={image}
                imageView={imageView}
                setImage={setImage}
                setImageView={setImageView}
                imageError={imageError}
                openEditForm={openEditForm}
                setOldImageEdited={setOldImageEdited}
              />
            )}

            {showSkeleton ? (
              [1, 2, 3, 4, 5].map(index => (
                <BodyRow
                  index={index}
                  key={index}
                  checked={checked}
                  category={dummyCategory}
                  categories={categories}
                  setCategories={setCategories}
                  image={image}
                  imageView={imageView}
                  setImage={setImage}
                  setImageView={setImageView}
                  isLoadingData={isLoadingData}
                  setIsLoadingData={setIsLoadingData}
                  setImageCategories={setImageCategories}
                  imageCategories={imageCategories}
                  handleIndividualChecked={handleIndividualChecked}
                  handleClickDeleteCategory={handleClickDeleteCategory}
                  handleClickEditCategory={handleClickEditCategory(index)}
                />
              ))
            ) : categories && categories.length > 0 ? (
              categories.map((value, index) =>
              openEditForm && currentIndex === index ? (
                <CategoryFormField
                    error={error}
                    name={name}
                    setName={setName}
                    type={type}
                    setType={setType}
                    isSubmitting={isSubmitting}
                    onSubmit={onSubmit}
                    onCancel={onCancel}
                    setError={setError}
                    image={image}
                    imageView={imageView}
                    setImage={setImage}
                    setImageView={setImageView}
                    imageError={imageError}
                    openEditForm={openEditForm}
                    setOldImageEdited={setOldImageEdited}
                  />
                ) : (
                  <BodyRow
                    index={index}
                    key={index}
                    checked={checked}
                    category={value}
                    categories={categories}
                    setCategories={setCategories}
                    image={image}
                    imageView={imageView}
                    setImage={setImage}
                    setImageView={setImageView}
                    isLoadingData={isLoadingData}
                    setIsLoadingData={setIsLoadingData}
                    setImageCategories={setImageCategories}
                    imageCategories={imageCategories}
                    handleIndividualChecked={handleIndividualChecked}
                    handleClickDeleteCategory={handleClickDeleteCategory}
                    handleClickEditCategory={handleClickEditCategory(index)}
                  />
                )
              )
            ) : (
              <Fragment>
                <TableRow>
                  <TableCell colSpan={4} align='center'>
                    Data Not Available
                  </TableCell>
                </TableRow>
              </Fragment>
            )}
          </TableBody>
          <TableFooter>
            <TablePagination
              rowsPerPageOptions={[5, 10, 15]}
              count={count}
              rowsPerPage={rowsPerPage}
              page={currentPage}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </TableFooter>
        </Table>
      </Paper>
    </div>
  );
};

export default CategoryTable;
