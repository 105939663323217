import React, { FC, useState, Fragment, useEffect } from 'react';
import {
  Checkbox,
  IconButton,
  makeStyles,
  Typography,
  TableRow,
  TableCell,
  Theme,
  Tooltip,
  CircularProgress,
  FormControlLabel
} from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import NewIcon from '@material-ui/icons/FiberNewOutlined';
import EditIcon from '@material-ui/icons/EditOutlined';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import Skeleton from 'react-loading-skeleton';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import axios from 'axios';
import { GET_DELETE_BLOCKED_DATE_URL } from 'constants/url';
import { StandardConfirmationDialog } from 'components/AppDialog';
import { AntSwitch } from 'components/CustomSwitch';
import { format } from 'date-fns';

interface Props {
  isLoadingData: boolean;
  openEditForm: boolean;
  openCreateForm: boolean;
  index: number;
  checked: number[];
  blockedDate: BlockedDateModel;
  handleUpdateStatus: (indexStatus: number, status: boolean, id: number) => void;
  handleIndividualChecked: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleClickEditBlockedDate: React.MouseEventHandler;
  handleClickDeleteBlockedDate: (index: number) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  newIcon: {
    color: green[500],
    fontSize: 30
  },
  actionIcon: {
    fontSize: 20
  },
  tableCellInner: {
    display: 'flex',
    alignItems: 'center'
  },
  nameTextCell: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: theme.spacing(2)
  },
  checkBox: {
    '&&:hover': {
      backgroundColor: 'transparent'
    }
  },
  checkBoxSize: {
    fontSize: '16px'
  },
  tableCellInnerTime: {
    color: '#388bf2',
    marginLeft: theme.spacing(1)
  }
}));

const BodyRow: FC<Props> = props => {
  const classes = useStyles();
  const {
    isLoadingData,
    index,
    checked,
    blockedDate,
    openEditForm,
    openCreateForm,
    handleUpdateStatus,
    handleIndividualChecked,
    handleClickEditBlockedDate,
    handleClickDeleteBlockedDate
  } = props;

  const { id, startDate, endDate, startTime, endTime, isHoliday, isActive } = blockedDate;
  const [openDeleteBlockedDateDialog, setOpenDeleteBlockedDateDialog] = useState<boolean>(false);
  const [isSubmit, setSubmit] = useState<boolean>(false);
  const [isFormOpen, setFormOpen] = useState<boolean>(false);
  const [currentIndex, setCurrentIndex] = useState<number>(-1);

  useEffect(() => {
    if (openCreateForm || openEditForm) {
      setFormOpen(true);
    } else {
      setFormOpen(false);
    }

    return;
  }, [openEditForm, openCreateForm]);

  const deleteBlockedDate: React.MouseEventHandler<HTMLButtonElement> = async event => {
    await axios.delete(GET_DELETE_BLOCKED_DATE_URL(id));
    handleClickDeleteBlockedDate(index);
    setOpenDeleteBlockedDateDialog(false);
  };

  const handleStatusChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setSubmit(true);
    setCurrentIndex(index);
    handleUpdateStatus(index, isActive, id);
    setSubmit(false);
    setCurrentIndex(-1);
  };

  return (
    <Fragment>
      <TableRow hover>
        <TableCell>
          {!isLoadingData &&
            (isSubmit && index === currentIndex ? (
              <CircularProgress size={20} />
            ) : (
              <Checkbox
                key={index}
                icon={<CheckBoxOutlineBlankIcon className={classes.checkBoxSize} />}
                checkedIcon={<CheckBoxIcon className={classes.checkBoxSize} />}
                name='BlockedDate'
                edge='start'
                checked={checked.filter(checked => checked === id).length ? true : false}
                value={id}
                onChange={handleIndividualChecked}
                disableRipple
                tabIndex={-1}
              />
            ))}
        </TableCell>

        <TableCell>
          {isLoadingData ? (
            <Skeleton width={100} />
          ) : !startDate ? (
            '-'
          ) : (
            <Typography variant='body1'>
              <Typography component='span'>{startDate ? format(new Date(startDate), 'dd-MM-yyyy') : '-'}</Typography>
              <Typography component='span' className={classes.tableCellInnerTime}>
                {startTime ? startTime.slice(0, 5) : '-'}
              </Typography>
              {blockedDate!.isNew! && <NewIcon className={classes.newIcon} />}
            </Typography>
          )}
        </TableCell>

        <TableCell>
          {isLoadingData ? (
            <Skeleton width={100} />
          ) : !endDate ? (
            '-'
          ) : (
            <Typography variant='body1'>
              <Typography component='span'>{endDate ? format(new Date(endDate), 'dd-MM-yyyy') : '-'}</Typography>
              <Typography component='span' className={classes.tableCellInnerTime}>
                {endTime ? endTime.slice(0, 5) : '-'}
              </Typography>
            </Typography>
          )}
        </TableCell>

        <TableCell>{isLoadingData ? <Skeleton width={100} /> : isHoliday ? 'Yes' : 'No'}</TableCell>

        <TableCell align='center'>
          {isLoadingData ? (
            <Skeleton width={100} />
          ) : (
            <FormControlLabel
              control={<AntSwitch disabled={isFormOpen} checked={isActive ? true : false} onChange={handleStatusChange} color='primary' />}
              label={isActive ? 'Active' : 'Inactive'}
              disabled={isFormOpen}
            />
          )}
        </TableCell>

        <TableCell align='center'>
          {isLoadingData ? (
            <Skeleton width={100} />
          ) : (
            <Fragment>
              <Tooltip title={'Edit'} placement='top'>
                <IconButton size='small' onClick={handleClickEditBlockedDate} disabled={isFormOpen}>
                  <EditIcon className={classes.actionIcon} />
                </IconButton>
              </Tooltip>
              <Tooltip title={'Delete'} placement='top'>
                <IconButton size='small' onClick={event => setOpenDeleteBlockedDateDialog(true)} disabled={isFormOpen}>
                  <DeleteIcon className={classes.actionIcon} />
                </IconButton>
              </Tooltip>
            </Fragment>
          )}
        </TableCell>
      </TableRow>

      <StandardConfirmationDialog
        variant={'danger'}
        titleMessage={'Delete'}
        message={'Are you sure want to delete this data?'}
        open={openDeleteBlockedDateDialog}
        handleClose={() => setOpenDeleteBlockedDateDialog(false)}
        onConfirm={deleteBlockedDate}
      />
    </Fragment>
  );
};

export default BodyRow;
