import React, { FC, useState, useEffect, Fragment } from 'react';
import { Button, makeStyles, TableRow, Theme, Typography, SwitchClassKey, createStyles } from '@material-ui/core';
import Skeleton from 'react-loading-skeleton';
import BodyCell from 'components/BodyCell';
import axios, { CancelTokenSource } from 'axios';
import NumberFormat from 'react-number-format';
import { format } from 'date-fns';
import { green } from '@material-ui/core/colors';
import { ServiceItemDummy } from 'constants/DummyData';
import { GET_PAID_INVOICE_URL } from 'constants/url';

interface Styles extends Partial<Record<SwitchClassKey, string>> {
  focusVisible?: string;
}

interface Props {
  currentInvoiceIndex: number;
  isLoadingData: boolean;
  index: number;
  invoice: InvoiceModel;
  classes?: Styles;
  checked: number[];
  onDetailInvoice: React.MouseEventHandler;
  onCollectPayment: React.MouseEventHandler;
  setOpenSnackbar: React.Dispatch<React.SetStateAction<boolean>>;
  setSnackbarVarient: React.Dispatch<React.SetStateAction<'success' | 'error'>>;
  handleIndividualCheck: (id: number) => void;
  updateInvoice: (updatedInvoiceProperties: Partial<InvoiceModel>, invoiceIndex?: number) => void;
  handleSetMessageSuccess: (message: string) => void;
  handleSetMessageError: (message: string) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableRow: {
      height: 'unset'
    },
    newIcon: {
      color: green[500],
      fontSize: 30
    },
    actionIcon: {
      fontSize: 20
    },
    tableCellInner: {
      display: 'flex',
      alignItems: 'center'
    },
    nameTextCell: {
      display: 'flex',
      flexDirection: 'column',
      marginRight: theme.spacing(2)
    },
    adrressTextCell: {
      color: 'grey'
    },
    checkBox: {
      '&&:hover': {
        backgroundColor: 'transparent'
      }
    },
    checkBoxSize: {
      fontSize: '16px'
    },
    tableCellInnerPaid: {
      color: green[500],
      display: 'flex',
      alignItems: 'center'
    },
    tableCellInnerCancel: {
      color: 'grey',
      display: 'flex',
      alignItems: 'center',
    },
    tableCellInnerUnPaid: {
      color: 'grey',
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      textDecoration: 'underline'
    }
  })
);

const dummyService: ServiceModel = {
  id: 0,
  serviceNumber: '',
  description: '',
  time: '',
  CustomerId: 0,
  totalAmount: 0,
  deliveryType: '',
  promoCode: '',
  typePromo: '',
  discountType: '',
  discountAmount: 0
};

const dummyInvoiceServiceItem: ServiceItemModel[] = [ServiceItemDummy];

const dummyJob = {
  id: 0,
  collectionDate: new Date(),
  deliveryDate: new Date(),
  collectionTime: '',
  deliveryTime: '',
  jobStatus: '',
  clientSignature: ''
};

const BodyRow: FC<Props> = props => {
  const classes = useStyles();

  const {
    index,
    currentInvoiceIndex,
    isLoadingData,
    invoice,
    updateInvoice,
    setOpenSnackbar,
    setSnackbarVarient,
    handleSetMessageSuccess,
    handleSetMessageError,
    onCollectPayment,
    onDetailInvoice,
    checked
  } = props;

  const [isProcessing, setProcessing] = useState<boolean>(false);
  const [customerFirstName, setFirstName] = useState<string>('');
  const [customerLastName, setLastName] = useState<string>('');

  const { id, invoiceNumber, createdAt, paymentType, paymentStatus, totalPrice, totalCollected, Service } = invoice;
  const { Customer } = !Service ? dummyService : Service;
  const [serviceJob, setServiceJob] = useState<JobModel>(dummyJob);
  const [serviceJobStatus, setServiceJobStatus] = useState<string>('');

  let jobId = 0;
  let driverName = '-';
  if (Service && Service.Job) {
    jobId = Service!.Job!.id;

    if (Service!.Job!.UserId) {
      driverName = Service!.Job!.User!.displayName;
    }
  }
  const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();

  useEffect(() => {
    if (!Service) {
      return;
    }

    const { Job } = Service;
    if (Job) {
      const { jobStatus } = Job;
      setServiceJob(Job);
      setServiceJobStatus(jobStatus);
    }
  }, [Service]);

  useEffect(() => {
    if (!Customer) {
      return;
    }

    const { firstName, lastName } = Customer;
    setFirstName(firstName);
    setLastName(lastName);
  }, [Customer]);

  const actionWrapper = async (action: () => Promise<void>) => {
    setProcessing(true);

    try {
      await action();
      setOpenSnackbar(true);
      handleSetMessageSuccess(paymentStatus === 'PAID' ? 'Successfully unpaid Invoice' : 'Successfully paid invoice');
      setSnackbarVarient('success');
    } catch (err) {
      setOpenSnackbar(true);
      handleSetMessageError('Failed to operation');
      setSnackbarVarient('error');
    }

    setProcessing(false);
  };

  const unPaidInvoice: React.ChangeEventHandler<HTMLInputElement> = async event => {
    await actionWrapper(async () => {
      await axios.put(GET_PAID_INVOICE_URL(id), { paid: false }, { cancelToken: cancelTokenSource.token });
      updateInvoice({ paymentStatus: 'UNPAID' }, currentInvoiceIndex);
    });
  };

  const paidInvoice: React.ChangeEventHandler<HTMLInputElement> = async event => {
    await actionWrapper(async () => {
      await axios.put(GET_PAID_INVOICE_URL(id), { paid: true }, { cancelToken: cancelTokenSource.token });
      updateInvoice({ paymentStatus: 'PAID' }, currentInvoiceIndex);
    });
  };

  const renderCollect = () => {
    if (serviceJobStatus === 'CANCELLED') {
      return (<div className={classes.tableCellInnerCancel}>{serviceJobStatus}</div>);
    }

    if (paymentStatus == 'PAID') {
      return (<div className={classes.tableCellInnerPaid}>{paymentStatus}</div>);
    }
 
    if (totalCollected && totalCollected > 0) {
      return (<NumberFormat onClick={onCollectPayment} value={totalCollected} className={classes.tableCellInnerUnPaid} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} fixedDecimalScale={true} />);
    }

    return (<div onClick={onCollectPayment} className={classes.tableCellInnerUnPaid}>{paymentStatus}</div>);
  };

  const checkedCheckbox = checked.filter(checkedValue => checkedValue === id).length;

  return (
    <TableRow className={classes.tableRow} hover>
      <BodyCell cellWidth='15%' isComponent={true}>
        <div className={classes.tableCellInner}>
          <div className={classes.nameTextCell}>
            <Typography variant='body1'>{isLoadingData ? <Skeleton width={100} /> : `${invoiceNumber}`}</Typography>
          </div>
        </div>
      </BodyCell>

      <BodyCell cellWidth='15%' isComponent={true}>
        {isLoadingData ? <Skeleton width={100} /> : jobId}
      </BodyCell>

      <BodyCell cellWidth='15%' isComponent={true}>
        <div className={classes.tableCellInner}>
          {isLoadingData ? <Skeleton width={100} /> : createdAt ? format(new Date(createdAt), 'dd/MM/yyyy') : ''}
        </div>
      </BodyCell>

      <BodyCell cellWidth='15%' isComponent={true}>
        {isLoadingData ? <Skeleton width={100} /> : `${customerFirstName} ${customerLastName}`}
      </BodyCell>

      <BodyCell cellWidth='15%' isComponent={true}>
        {isLoadingData ? <Skeleton width={100} /> : driverName}
      </BodyCell>

      <BodyCell cellWidth='5%' isComponent={true}>
        {isLoadingData ? (
          <Skeleton width={100} />
        ) : (
          <NumberFormat value={totalPrice} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} fixedDecimalScale={true} />
        )}
      </BodyCell>

      <BodyCell cellWidth='15%' isComponent={true}>
        {isLoadingData ? <Skeleton width={100} /> : paymentType || '-'}
      </BodyCell>

      <BodyCell cellWidth='15%' isComponent={true}>
        {isLoadingData ? <Skeleton width={100} height={25} /> : renderCollect()}
      </BodyCell>

      <BodyCell cellWidth='5%' isComponent={true}>
        {isLoadingData ? null : (
          <Fragment>
            <Button variant='text' size='small' color='primary' onClick={onDetailInvoice}>
              Details
            </Button>
          </Fragment>
        )}
      </BodyCell>
    </TableRow>
  );
};

export default BodyRow;
