import React, { Fragment, FC, useState, useCallback } from 'react';
import {
  Grid,
  TextField,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  IconButton,
  Button,
  makeStyles,
  Theme,
  createStyles,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Tooltip
} from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import axios, { CancelTokenSource } from 'axios';
import { GET_EDIT_CUSTOMER_URL, CUSTOMER_BASE_URL } from 'constants/url';
import NumberFormatCustom from 'components/NumberFormatCustom';

interface Props {
  customer: CustomerModel;
  setCustomer: React.Dispatch<React.SetStateAction<CustomerModel>>;
  handleSnackBar: (open: boolean, variant: 'success' | 'error', message: string) => void;
}

interface ErrorHandling {
  error: boolean;
  message: string;
}

interface CustomerError {
  [keys: string]: ErrorHandling;
}

const customerError: CustomerError = {
  firstName: { error: false, message: '' },
  lastName: { error: false, message: '' },
  email: { error: false, message: '' },
  contactNumber: { error: false, message: '' }
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: '100%'
    },
    list: {
      margin: 0
    },
    cardHeader: {},
    cardContent: {
      padding: 0
    },
    form: {
      marginRight: 10,
      marginLeft: 10
    },
    remarkDetail: {
      width: '70%',
      textAlign: 'right',
      paddingTop: 15,
      overflowWrap: 'break-word'
    }
  })
);

const CustomerDetail: FC<Props> = props => {
  const classes = useStyles();

  const { customer, setCustomer, handleSnackBar } = props;
  const [customerTemp, setCustomerTemp] = useState<CustomerModel>(customer);
  const { id, firstName, lastName, email, contactNumber, privilegeDiscount, remark } = customer;
  const [isEdit, setEdit] = useState<boolean>(false);
  const [isSubmit, setSubmit] = useState<boolean>(false);

  const [firstNameError, setFirstNameError] = useState<string>('');
  const [lastNameError, setLastNameError] = useState<string>('');
  const [contactNumberError, setContactNumberError] = useState<string>('');
  const [privilegeDiscountError, setPrivilegeDiscountError] = useState<string>('');

  const handleContactNumberOnBlur = (event: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if (event.target.value) {
      handleCheckUser(event.target.value.replace(' ', '').trim(), 'contactNumber');
      const timer = setTimeout(() => {
        clearTimeout(timer);
      }, 800);
    }
  };

  const handleCheckUser = useCallback(
    async (value: string, attribute: string) => {
      setContactNumberError('');
      try {
        const params = new URLSearchParams();
        params.append('params', value);
        params.append('customerId', String(id));
        params.append('role', 'CUSTOMER');

        const result = await axios.get(`${CUSTOMER_BASE_URL}/check?${params.toString()}`);
        const { isExist } = result.data;
        if (isExist > 0) {
          setContactNumberError('Contact number has been registered');
        }
      } catch (error) {
        console.log('error', error);
      }
    },
    [email, customerError]
  );

  const handleCustomerError = (): boolean => {
    let i = 0;
    const newValue: { [keys: string]: ErrorHandling } = {};

    for (const [keys, value] of Object.entries(customer)) {
      if (keys === 'firstName' || keys === 'lastName' || keys === 'contactNumber') {
        newValue[keys] = { error: value === '', message: value === '' ? 'Cannot be empty' : '' };
        if (value === '') i++;
      }
    }

    return i > 0 ? true : false;
  };

  const handleFirstNameChange = (value: string) => {
    setCustomer({ ...customer, firstName: value });
    if (value) {
      setFirstNameError('');
    } else {
      setFirstNameError('First Name cannot be empty');
    }
  };

  const handleLastNameChange = (value: string) => {
    setCustomer({ ...customer, lastName: value });
    if (value) {
      setLastNameError('');
    } else {
      setLastNameError('Last Name cannot be empty');
    }
  };

  const handleContactNumberChange = (value: string) => {
    setCustomer({ ...customer, contactNumber: value });
    if (value) {
      setContactNumberError('');
      if (value.length != 8) {
        setContactNumberError('Contact number must be 8 digits');
      } else if (value.includes('.')) {
        setContactNumberError('Contact number only can number');
      } else {
        handleCheckUser(value, 'contactNumber');
        const timer = setTimeout(() => {
          clearTimeout(timer);
        }, 800);
      }
    } else {
      setContactNumberError('Contact number cannot be empty');
    }
  };

  const handlePrivilegeDiscount = () => {
    setPrivilegeDiscountError('');
    if (privilegeDiscount && privilegeDiscount > 100) {
      setPrivilegeDiscountError('Discount cannot be more than 100%.');
    }
  };

  const handleOnSubmit = async () => {
    if (firstNameError !== '' || lastNameError !== '' || contactNumberError !== '' || privilegeDiscountError !== '') {
      return;
    } else {
      setSubmit(true);
      if (!isSubmit) {
        const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();
        try {
          const result = await axios.put(GET_EDIT_CUSTOMER_URL(id), customer, { cancelToken: cancelTokenSource.token });
          handleSnackBar(true, 'success', 'Customer has been update successfully.');
          setCustomer(result.data);
          setEdit(false);
        } catch (error) {
          console.log('err : ', error);
          handleSnackBar(true, 'error', 'The customer has failed to update.');
        }
        setSubmit(false);
      }
    }
  };

  const handleClickEdit = () => {
    setCustomerTemp(customer);
    setFirstNameError('');
    setLastNameError('');
    setContactNumberError('');
    setEdit(true);
  };

  const handleClickCancel = () => {
    setCustomer(customerTemp);
    setFirstNameError('');
    setLastNameError('');
    setContactNumberError('');
    setEdit(false);
  };

  const listItem = () => (
    <List className={classes.list}>
      <ListItem selected>
        <ListItemText primary='Customer Name' />
        <ListItemSecondaryAction>{`${firstName} ${lastName}`}</ListItemSecondaryAction>
      </ListItem>
      <ListItem>
        <ListItemText primary='Email' />
        <ListItemSecondaryAction>{email}</ListItemSecondaryAction>
      </ListItem>
      <ListItem selected>
        <ListItemText primary='Contact Number' />
        <ListItemSecondaryAction>{contactNumber}</ListItemSecondaryAction>
      </ListItem>
      <ListItem selected>
        <ListItemText primary='Privilege Discount' />
        <ListItemSecondaryAction>{privilegeDiscount || 0}%</ListItemSecondaryAction>
      </ListItem>
      <ListItem>
        <ListItemText primary='Remark' />
        <ListItemText className={classes.remarkDetail}>{remark && remark !== '' ? remark : '-'}</ListItemText>
      </ListItem>
    </List>
  );

  const customerForm = () => (
    <form className={classes.form}>
      <Grid container direction='row' spacing={1}>
        <Grid container spacing={1} justify='space-between' item lg={12} md={12} sm={12} xs={12}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <TextField
              id='firstName'
              label='First Name'
              placeholder='First Name'
              variant='outlined'
              margin='dense'
              required
              fullWidth
              error={firstNameError !== ''}
              helperText={firstNameError}
              value={firstName}
              onChange={event => handleFirstNameChange(event.target.value)}
            />
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <TextField
              id='lastName'
              label='Last Name'
              placeholder='Last Name'
              variant='outlined'
              margin='dense'
              required
              fullWidth
              error={lastNameError !== ''}
              helperText={lastNameError}
              value={lastName}
              onChange={event => handleLastNameChange(event.target.value)}
            />
          </Grid>
        </Grid>

        <Grid item lg={12} md={12} sm={12} xs={12}>
          <TextField
            required
            id='email'
            label='Email'
            placeholder='Email'
            variant='outlined'
            margin='dense'
            fullWidth
            value={email}
            disabled={true}
            type='email'
          />
        </Grid>

        <Grid item lg={12} md={12} sm={12} xs={12}>
          <TextField
            required
            id='contactNumber'
            label='Contact Number'
            placeholder='e.g. 61234567 / 91234567'
            variant='outlined'
            margin='dense'
            fullWidth
            error={contactNumberError !== ''}
            helperText={contactNumberError}
            value={contactNumber}
            onChange={event => handleContactNumberChange(event.target.value.replace(/\s/g, '').trim())}
            InputProps={{
              inputComponent: NumberFormatCustom as any
            }}
          />
        </Grid>

        <Grid item lg={12} md={12} sm={12} xs={12}>
          <TextField
            id='privilegeDiscount'
            label='Privilege Discount (%)'
            placeholder='Discount (%)'
            variant='outlined'
            margin='dense'
            fullWidth
            error={privilegeDiscountError !== ''}
            helperText={privilegeDiscountError}
            value={privilegeDiscount}
            onChange={event => setCustomer({ ...customer, privilegeDiscount: +event.target.value })}
            onBlur={handlePrivilegeDiscount}
            InputProps={{
              inputComponent: NumberFormatCustom as any
            }}
          />
        </Grid>

        <Grid item lg={12} md={12} sm={12} xs={12}>
          <TextField
            id='remark'
            label='Remark'
            placeholder='Remark'
            variant='outlined'
            margin='dense'
            fullWidth
            multiline
            rows={4}
            value={remark}
            onChange={event => setCustomer({ ...customer, remark: event.target.value })}
          />
        </Grid>
      </Grid>
    </form>
  );

  return (
    <Card className={classes.root}>
      <CardHeader title='Customer Info' className={classes.cardHeader} />
      <CardContent className={classes.cardContent}>{!isEdit ? listItem() : customerForm()}</CardContent>
      <CardActions disableSpacing>
        {isEdit ? (
          <Fragment>
            <Button aria-label='Save' onClick={handleOnSubmit}>
              Save Changes
            </Button>
            <Button aria-label='Cancel' onClick={handleClickCancel}>
              Cancel
            </Button>
          </Fragment>
        ) : (
          <Fragment>
            <Tooltip title='Edit Data'>
              <IconButton aria-label='edit' onClick={handleClickEdit}>
                <Edit />
              </IconButton>
            </Tooltip>
          </Fragment>
        )}
      </CardActions>
    </Card>
  );
};

export default CustomerDetail;
