import React, { Fragment, FC, useState, useCallback, useEffect } from 'react';
import { Table, TableBody, TableRow, TableCell, TableHead, TableFooter, Paper } from '@material-ui/core';

import HeaderRow from 'components/HeaderRow';
import TablePagination from 'components/TablePagination';
import axios, { CancelTokenSource } from 'axios';
import { GET_EDIT_BLOCKED_DATE_URL, BLOCKED_DATE_BASE_URL } from 'constants/url';
import BodyRow from './components/BodyRow';
import BlockDateFormField from './components/BlockDateFormField';
import { format } from 'date-fns';

interface Props {
  startDate: Date | null;
  endDate: Date | null;
  startTime: Date | null;
  endTime: Date | null;
  id: number | 0;
  isHoliday: boolean | false;
  isActive: boolean | false;
  isLoadingData: boolean;
  count: number;
  checked: number[];
  currentPage: number;
  rowsPerPage: number;
  blockedDates: BlockedDateModel[];
  blockedDate: BlockedDateModel;
  openCreateForm: boolean;
  openEditForm: boolean;
  currentIndex: number;
  order: 'asc' | 'desc';
  orderBy: string;
  setOrder: React.Dispatch<React.SetStateAction<'asc' | 'desc'>>;
  setOrderBy: React.Dispatch<React.SetStateAction<string>>;
  setBlockedDates: React.Dispatch<React.SetStateAction<BlockedDateModel[]>>;
  setCurrentIndex: React.Dispatch<React.SetStateAction<number>>;
  setOpenCreateForm: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenEditForm: React.Dispatch<React.SetStateAction<boolean>>;
  setChecked: React.Dispatch<React.SetStateAction<number[]>>;
  setStartDate: React.Dispatch<React.SetStateAction<Date | null>>;
  setEndDate: React.Dispatch<React.SetStateAction<Date | null>>;
  setStartTime: React.Dispatch<React.SetStateAction<Date | null>>;
  setEndTime: React.Dispatch<React.SetStateAction<Date | null>>;
  setId: React.Dispatch<React.SetStateAction<number | 0>>;
  setIsHoliday: React.Dispatch<React.SetStateAction<boolean | false>>;
  setIsActive: React.Dispatch<React.SetStateAction<boolean | false>>;
  handleChangePage: (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => void;
  handleChangeRowsPerPage: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  handleSnackBar: (open: boolean, variant: 'success' | 'error', message: string) => void;
  handleClickDeleteBlockedDate: (index: number) => void;
}

interface Error {
  name: boolean;
  nameMessage: string;
}

const dummyBlockedDate = {
  startDate: null,
  endDate: null,
  startTime: '',
  endTime: '',
  id: 0,
  isHoliday: false,
  isActive: false
};

const BlockedDateTable: FC<Props> = props => {
  const {
    id,
    startDate,
    endDate,
    startTime,
    endTime,
    setStartDate,
    setEndDate,
    setStartTime,
    setEndTime,
    setId,
    isHoliday,
    isActive,
    isLoadingData,
    count,
    checked,
    order,
    setOrder,
    orderBy,
    setOrderBy,
    currentIndex,
    currentPage,
    rowsPerPage,
    blockedDates,
    openEditForm,
    openCreateForm,
    setBlockedDates,
    setOpenCreateForm,
    setOpenEditForm,
    setCurrentIndex,
    setChecked,
    setIsHoliday,
    setIsActive,
    handleChangePage,
    handleChangeRowsPerPage,
    handleSnackBar,
    handleClickDeleteBlockedDate
  } = props;

  // The below logic introduces a 500ms delay for showing the skeleton
  const [showSkeleton, setShowSkeleton] = useState<boolean>(false);
  // const [isActive, setActive] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isStartDateError, setStartDateError] = useState<boolean>(false);
  const [isEndDateError, setEndDateError] = useState<boolean>(false);
  const [isStartTimeError, setStartTimeError] = useState<boolean>(false);
  const [isEndTimeError, setEndTimeError] = useState<boolean>(false);
  const [startDateMessage, setStartDateMessage] = useState<string>('');
  const [endDateMessage, setEndDateMessage] = useState<string>('');
  const [startTimeMessage, setStartTimeMessage] = useState<string>('');
  const [endTimeMessage, setEndTimeMessage] = useState<string>('');

  const checkAll = () => {
    const newBulkChecked = [...checked];
    const countChecked = newBulkChecked.length;
    if (count > rowsPerPage) {
      if (countChecked !== rowsPerPage) {
        newBulkChecked.splice(0, countChecked);
        blockedDates.map(item => newBulkChecked.push(item.id));
      } else {
        newBulkChecked.splice(0, count);
      }
    } else {
      if (countChecked !== count) {
        newBulkChecked.splice(0, countChecked);
        blockedDates.map(item => newBulkChecked.push(item.id));
      } else {
        newBulkChecked.splice(0, count);
      }
    }
    setChecked(newBulkChecked);
  };

  const handleIndividualChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
    const idBlockedDate = parseInt(event.target.value);
    const countElement = checked.filter(newCheckedValue => newCheckedValue === idBlockedDate).length;
    if (countElement === 0) {
      checked.push(idBlockedDate);
    } else {
      checked.splice(checked.indexOf(idBlockedDate), 1);
    }
    setChecked([...checked]);
  };

  const handleOnSubmit = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (!validation()) return;
    let cancelTokenSource: CancelTokenSource = axios.CancelToken.source();
    if (openEditForm) {
      try {
        setIsSubmitting(true);
        const result = await axios.put(
          GET_EDIT_BLOCKED_DATE_URL(id),
          {
            startDate: format(startDate ? new Date(startDate) : new Date(), 'yyyy-MM-dd'),
            endDate: format(endDate ? new Date(endDate) : new Date(), 'yyyy-MM-dd'),
            startTime: format(startTime!, 'HH:mm'),
            endTime: format(endTime!, 'HH:mm'),
            isHoliday,
            isActive
          },
          {
            cancelToken: cancelTokenSource.token
          }
        );
        handleSnackBar(true, 'success', 'Block date updated successfully.');
        blockedDates[currentIndex] = result.data;
        setBlockedDates(blockedDates);
        resetBlockedDate();
      } catch (error) {
        console.log('err: ', error);
        handleSnackBar(true, 'error', 'The Block Date has failed to update.');
      }
      setIsSubmitting(false);
    } else {
      try {
        setIsSubmitting(true);
        const result = await axios.post(
          BLOCKED_DATE_BASE_URL,
          {
            startDate: format(startDate ? new Date(startDate) : new Date(), 'yyyy-MM-dd'),
            endDate: format(endDate ? new Date(endDate) : new Date(), 'yyyy-MM-dd'),
            startTime: format(startTime ? new Date(startTime) : new Date(), 'HH:mm'),
            endTime: format(endTime ? new Date(endTime) : new Date(), 'HH:mm'),
            isHoliday,
            isActive
          },
          { cancelToken: cancelTokenSource.token }
        );
        setBlockedDates([{ ...result.data, isNew: true }, ...blockedDates]);
        handleSnackBar(true, 'success', 'Blocked Date has been add successfully.');
        resetBlockedDate();
      } catch (err) {
        console.log(`err:${err}`);
        handleSnackBar(true, 'error', 'Blocked Date has been failed to add.');
      }
      setIsSubmitting(false);
    }
  };

  const handleUpdateStatus = async (indexStatus: number, status: boolean, id: number) => {
    try {
      const result = await axios.put(GET_EDIT_BLOCKED_DATE_URL(id), { ...blockedDates[indexStatus], isActive: status ? false : true });
      blockedDates[indexStatus] = result.data;
      setBlockedDates(blockedDates);
      handleSnackBar(true, 'success', 'Blocked Date status has been ' + (result.data.isActive ? 'activated' : 'inactive') + ' successfully.');
    } catch (err) {
      console.log('err : ', err);
      handleSnackBar(true, 'error', 'Blocked Date status failed to activate.');
    }
  };

  const onCancel = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setIsSubmitting(false);
    setOpenCreateForm(false);
    setOpenEditForm(false);
    resetBlockedDate();
  };

  const handleClickEditBlockedDate = (index: number): React.MouseEventHandler => () => {
    setCurrentIndex(index);
    setOpenEditForm(!openEditForm);
    getCurrentBlockedDate(index);
  };

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const getCurrentBlockedDate = useCallback(
    (index: number) => {
      const currentBlockedDate = blockedDates[index];
      setStartDate(currentBlockedDate.startDate);
      setEndDate(currentBlockedDate.endDate);
      setStartTime(new Date(currentBlockedDate.startDate!.toString() + ' ' + currentBlockedDate.startTime));
      setEndTime(new Date(currentBlockedDate.endDate!.toString() + ' ' + currentBlockedDate.endTime));
      setId(currentBlockedDate.id);
      setIsHoliday(currentBlockedDate.isHoliday);
      setIsActive(currentBlockedDate.isActive);
    },
    [blockedDates]
  );

  const resetBlockedDate = useCallback(() => {
    setStartDate(null);
    setEndDate(null);
    setStartTime(null);
    setEndTime(null);
    setId(0);
    // setActive(false);
    setStartDateError(false);
    setStartDateMessage('');
    setEndDateError(false);
    setEndDateMessage('');
    setStartTimeError(false);
    setStartTimeMessage('');
    setEndTimeError(false);
    setEndTimeMessage('');
  }, []);

  useEffect(() => {
    if (isLoadingData) {
      setShowSkeleton(true);
    }

    return () => {
      setShowSkeleton(false);
    };
  }, [isLoadingData]);

  const validation = (): boolean => {
    let ret = true;
    if (!startDate) {
      setStartDateError(true);
      setStartDateMessage('Start date can not be empty.');
      ret = false;
    } else if (!endDate) {
      setEndDateError(true);
      setEndDateMessage('End date can not be empty.');
      ret = false;
    } else if (!startTime) {
      setStartTimeError(true);
      setStartTimeMessage('Start time can not be empty.');
      ret = false;
    } else if (!endTime) {
      setEndTimeError(true);
      setEndTimeMessage('Start time can not be empty.');
      ret = false;
    } else if (endDate < startDate) {
      setEndDateError(true);
      setEndDateMessage('End Date can not be earlier than start date');
      ret = false;
    } else if (startDate > endDate) {
      setStartDateError(true);
      setStartDateMessage('Start Date can not be later than end date');
      ret = false;
    }

    return ret;
  };

  return (
    <Paper variant='outlined' elevation={2}>
      <Table>
        <TableHead>
          <HeaderRow
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            height={'unset'}
            headers={[
              { label: 'CheckBox', verticalAlign: 'top', isCheckBox: true, checked, rowsPerPage, handleCheckAll: checkAll },
              { id: 'startDate', label: 'Start Date Time', sort: true },
              { id: 'endDate', label: 'End Date Time', sort: true },
              { label: 'Public Holiday' },
              { label: 'Status' },
              { label: 'Action', align: 'center' }
            ]}
          />
        </TableHead>
        <TableBody>
          {openCreateForm && (
            <BlockDateFormField
              startDate={startDate}
              endDate={endDate}
              startTime={startTime}
              endTime={endTime}
              isHoliday={isHoliday}
              isActive={isActive}
              index={0}
              isSubmitting={isSubmitting}
              isStartDateError={isStartDateError}
              isEndDateError={isEndDateError}
              isStartTimeError={isStartTimeError}
              isEndTimeError={isEndTimeError}
              startDateMessage={startDateMessage}
              endDateMessage={endDateMessage}
              startTimeMessage={startTimeMessage}
              endTimeMessage={endTimeMessage}
              onSubmit={handleOnSubmit}
              onCancel={onCancel}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              setStartTime={setStartTime}
              setEndTime={setEndTime}
              setIsHoliday={setIsHoliday}
              setIsActive={setIsActive}
              setStartDateError={setStartDateError}
              setEndDateError={setEndDateError}
              setStartDateMessage={setStartDateMessage}
              setEndDateMessage={setEndDateMessage}
            />
          )}

          {showSkeleton ? (
            [0, 1, 2, 3].map(index => (
              <BodyRow
                index={index}
                key={index}
                openEditForm={openEditForm}
                blockedDate={dummyBlockedDate}
                isLoadingData={isLoadingData}
                checked={checked}
                openCreateForm={openCreateForm}
                handleIndividualChecked={handleIndividualChecked}
                handleClickDeleteBlockedDate={handleClickDeleteBlockedDate}
                handleClickEditBlockedDate={handleClickEditBlockedDate(index)}
                handleUpdateStatus={handleUpdateStatus}
              />
            ))
          ) : blockedDates && blockedDates.length > 0 ? (
            blockedDates.map((value, index) =>
              openEditForm && currentIndex === index ? (
                <BlockDateFormField
                  startDate={startDate}
                  endDate={endDate}
                  startTime={startTime}
                  endTime={endTime}
                  isHoliday={isHoliday}
                  isActive={isActive}
                  isStartDateError={isStartDateError}
                  isEndDateError={isEndDateError}
                  isStartTimeError={isStartTimeError}
                  isEndTimeError={isEndTimeError}
                  startDateMessage={startDateMessage}
                  endDateMessage={endDateMessage}
                  startTimeMessage={startTimeMessage}
                  endTimeMessage={endTimeMessage}
                  index={index}
                  isSubmitting={isSubmitting}
                  onSubmit={handleOnSubmit}
                  onCancel={onCancel}
                  setStartDate={setStartDate}
                  setEndDate={setEndDate}
                  setStartTime={setStartTime}
                  setEndTime={setEndTime}
                  setIsHoliday={setIsHoliday}
                  setIsActive={setIsActive}
                  setStartDateError={setStartDateError}
                  setEndDateError={setEndDateError}
                  setStartDateMessage={setStartDateMessage}
                  setEndDateMessage={setEndDateMessage}
                />
              ) : (
                <BodyRow
                  index={index}
                  key={index}
                  blockedDate={value}
                  isLoadingData={isLoadingData}
                  checked={checked}
                  openEditForm={openEditForm}
                  openCreateForm={openCreateForm}
                  handleIndividualChecked={handleIndividualChecked}
                  handleClickDeleteBlockedDate={handleClickDeleteBlockedDate}
                  handleClickEditBlockedDate={handleClickEditBlockedDate(index)}
                  handleUpdateStatus={handleUpdateStatus}
                />
              )
            )
          ) : (
            <Fragment>
              <TableRow>
                <TableCell colSpan={7} align='center'>
                  Data Not Available.
                </TableCell>
              </TableRow>
            </Fragment>
          )}
        </TableBody>
        <TableFooter>
          <TablePagination
            rowsPerPageOptions={[5, 10, 15]}
            count={count}
            rowsPerPage={rowsPerPage}
            page={currentPage}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </TableFooter>
      </Table>
    </Paper>
  );
};

export default BlockedDateTable;
