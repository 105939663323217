import React, { FC, Fragment } from 'react';
import {
  Table,
  TableBody,
  TableHead,
  Paper,
  TableRow,
  TableCell,
  makeStyles,
  createStyles,
  Box
} from '@material-ui/core';
import Skeleton from 'react-loading-skeleton';
import NumberFormat from 'react-number-format';
import ItemType from 'typings/enum/ItemType';

interface Props {
  serviceItems: ServiceItemModel[];
  isLoading: boolean;
  jobStatus: string;
  setServiceItems: React.Dispatch<React.SetStateAction<ServiceItemModel[]>>;
}

const useStyles = makeStyles(() =>
  createStyles({
    odd: {
      background: '#F9F9FB'
    },
    even: {
      background: '#fff'
    }
  })
);

const defaultServiceItem = {
  id: 0,
  serviceType: '',
  serviceItemName: '',
  unitPrice: 0,
  quantity: 0,
  quantityOriginal: 0,
  totalPrice: 0,
  itemType: '',
  unit: '',
  remarks: '',
  Category: {
    id: 0,
    name: '',
    type: '',
    image: ''
  }
};

const ReturnItemTable: FC<Props> = props => {
  const { serviceItems, setServiceItems, jobStatus, isLoading } = props;
  const styles = useStyles();
  const th = ['Laundry Type', 'Service Category', 'Service Item', 'Quantity', 'Price', 'Amount'];

  const cell = (value: any) => (isLoading ? <Skeleton width='100%' /> : value);

  const rows = (value: ServiceItemModel, index: number) => {
    let category = '-';
    let serviceType = 'Laundry';

    if (value.serviceType == 'DC') {
      serviceType = 'Dry Clean';
    }

    if (value.Category) {
      category = value.Category.name;
    }

    return (
      <TableRow className={index % 2 === 0 ? styles.odd : styles.even} key={index + 1}>
        <TableCell align='center'>{cell(serviceType)}</TableCell>
        <TableCell align='center'>{cell(category)}</TableCell>
        <TableCell align='center'>{cell(value.serviceItemName)}</TableCell>
        <TableCell align='center'>
          {cell(
            <NumberFormat
              value={value.quantity}
              displayType={'text'}
              thousandSeparator={true}
              prefix={''}
              decimalScale={value.itemType! === ItemType.MEASURE_ON_SITE ? 2 : 0}
              fixedDecimalScale={true}
            />
          )}
        </TableCell>
        <TableCell align='center'>
          {cell(
            <NumberFormat
              value={value.unitPrice}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'$'}
              decimalScale={2}
              fixedDecimalScale={true}
            />
          )}
        </TableCell>
        <TableCell align='center'>
          $
          {cell(
            <NumberFormat
              value={value.totalPrice}
              displayType={'text'}
              thousandSeparator={true}
              decimalScale={2}
              fixedDecimalScale={true}
            />
          )}
        </TableCell>
      </TableRow>
    );
  };

  return (
    <Paper variant='outlined' style={{ width: '100%', overflow: 'hidden' }}>
      <Table>
        <TableHead>
          <TableRow>
            {th.map((val, i) => (
              <TableCell key={i + 1} align='center'>
                {val}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoading ? (
            [1, 2, 3, 4].map(index => rows(defaultServiceItem, index))
          ) : serviceItems && serviceItems.length > 0 ? (
            <Fragment>
              {serviceItems.map((value, index) => rows(value, index))}
              <TableRow>
                <TableCell colSpan={5} align='right'>
                  <Box fontWeight='fontWeightBold' m={1}>
                    Total Amount
                  </Box>
                </TableCell>
                <TableCell align='center'>
                  <Box fontWeight='fontWeightBold' m={1}>
                    $
                    {cell(
                      <NumberFormat
                        value={serviceItems.reduce((sum, current) => sum + Number(current.totalPrice), 0)}
                        displayType={'text'}
                        thousandSeparator={true}
                        decimalScale={2}
                        fixedDecimalScale={true}
                      />
                    )}
                  </Box>
                </TableCell>
              </TableRow>
            </Fragment>
          ) : (
            <Fragment>
              <TableRow>
                <TableCell colSpan={6} align='center'>
                  Data not available
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={5} align='right'>
                  <Box fontWeight='fontWeightBold' m={1}>
                    Total Amount
                  </Box>
                </TableCell>
                <TableCell align='center'>
                  <Box fontWeight='fontWeightBold' m={1}>
                    -
                  </Box>
                </TableCell>
              </TableRow>
            </Fragment>
          )}
        </TableBody>
      </Table>
    </Paper>
  );
};

export default ReturnItemTable;
