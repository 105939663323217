import React, { FC } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import SuccessIcon from '@material-ui/icons/CheckCircle';
import WarningIcon from '@material-ui/icons/Error';
import DangerIcon from '@material-ui/icons/DeleteForever';
import ErrorIcon from '@material-ui/icons/Close';
import { green } from '@material-ui/core/colors';
import { orange } from '@material-ui/core/colors';
import { red } from '@material-ui/core/colors';

interface Props {
  variant: string;
  titleMessage?: string;
  message: string;
  open: boolean;
  handleClose(): void;
  onConfirm?: React.MouseEventHandler<HTMLButtonElement>;
  noCancelButton?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  successAvatarIcon: {
    fontSize: 60,
    color: green[500]
  },
  warnigAvatarIcon: {
    fontSize: 60,
    color: orange[500]
  },
  dangerAvatarIcon: {
    fontSize: 60,
    color: '#FF0909'
  },
  cancelButton: (props: Props) => ({
    marginRight: theme.spacing(props.noCancelButton ? 0 : 3)
  }),
  okButton: {
    backgroundColor: '#FF0909',
    '&:hover': {
      backgroundColor: red[700]
    }
  },
  dialogActions: {
    marginBottom: theme.spacing(2)
  },
  titleText: {
    paddingBottom: 4
  },
  messageText: {
    paddingBottom: theme.spacing(4)
  }
}));

export const StandardConfirmationDialog: FC<Props> = props => {
  const classes = useStyles(props);
  const { variant, titleMessage, message, open, handleClose, onConfirm, noCancelButton } = props;

  return (
    <Dialog open={open}>
      <DialogTitle>
        <Grid container justify='center' alignItems='center'>
          {variant === 'success' && <SuccessIcon className={classes.successAvatarIcon} />}
          {variant === 'warning' && <WarningIcon className={classes.warnigAvatarIcon} />}
          {variant === 'danger' && <DangerIcon className={classes.dangerAvatarIcon} />}
          {variant === 'error' && <ErrorIcon className={classes.dangerAvatarIcon} />}
        </Grid>
      </DialogTitle>
      <DialogContent className={classes.titleText}>
        <Typography variant='h3' align={'center'}>
          {titleMessage}
        </Typography>
      </DialogContent>
      <DialogContent className={classes.messageText}>
        <Typography variant='h6' align={'center'}>
          {message}
        </Typography>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Grid container justify='center' alignItems='center'>
          <Button variant='contained' color={noCancelButton ? 'primary' : 'default'} className={classes.cancelButton} onClick={handleClose}>
            {noCancelButton ? 'Ok' : 'Cancel'}
          </Button>
          {!noCancelButton && (
            <Button variant='contained' color='primary' className={classes.okButton} onClick={onConfirm}>
              Ok
            </Button>
          )}
        </Grid>
      </DialogActions>
    </Dialog>
  );
};
