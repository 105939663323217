import React, { useState, useEffect } from 'react';
import { TextField, Button, IconButton, Theme, makeStyles, Modal, Typography, Grid } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import NumberFormatCustom from 'components/NumberFormatCustom';
import axios, { CancelTokenSource } from 'axios';
import { GET_UPDATE_INVOICE_URL } from 'constants/url';

interface Props {
  open: boolean;
  index: number;
  invoice?: InvoiceModel;
  handleClose: () => void;
  handleSnackBar: (open: boolean, variant: 'success' | 'error', message: string) => void;
  updateIndividualInvoice: (updatedInvoiceProperties: Partial<InvoiceModel>, invoiceIndex?: number) => void;
}

const dummyInvoice: InvoiceModel = {
  id: 0,
  invoiceNumber: '',
  totalPrice: 0,
  paymentStatus: '',
  paymentType: '',
  cardNumber: '',
  cardExpired: '',
  cardName: '',
  createdAt: ''
};

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    position: 'absolute',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    outline: 'none',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: 4
  },
  modalWrapper: {
    overflow: 'hidden',
    overflowY: 'auto'
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  },
  title: {
    marginBottom: theme.spacing(2)
  },
  controlDiv: {
    '& > :nth-child(n+2)': {
      marginLeft: theme.spacing(2)
    }
  }
}));

const CollectPaymentModal: React.FC<Props> = props => {
  const classes = useStyles();

  const { index, invoice, open, handleClose, handleSnackBar, updateIndividualInvoice } = props;

  const [currentId, setCurrentId] = useState<number>(0);
  const [currentCollect, setCurrentCollect] = useState<number>(0);
  const [currentTotal, setCurrentTotal] = useState<number>(0);
  const [error, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!invoice) {
      return;
    }

    const { id, totalPrice, totalCollected } = invoice;
    setCurrentCollect(totalCollected || 0);
    setCurrentTotal(totalPrice || 0);
    setCurrentId(id || 0);
  }, [invoice]);

  const onSubmit = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (currentId < 1) {
      return;
    }

    if (!currentCollect) {
      setError(true);
      setErrorMessage('Please insert collected payment.');
      return;
    }

    try {
      setLoading(true);
      const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();

      const { data } = await axios.put(
        GET_UPDATE_INVOICE_URL(currentId),
        { totalCollected: currentCollect },
        { cancelToken: cancelTokenSource.token }
      );

      const currentInvoice = invoice
        ? { ...invoice, totalCollected: data.totalCollected, paymentStatus: data.paymentStatus, totalChanges: data.totalChanges }
        : dummyInvoice;

      updateIndividualInvoice(currentInvoice, index);
      handleSnackBar(true, 'success', 'Invoice updated successfully.');
      setCurrentCollect(0);
      setErrorMessage('');
      setError(false);
      handleClose();
    } catch (error) {
      console.log('err: ', error);
      handleSnackBar(true, 'error', 'Invoice has failed to update.');
    }
  };

  return (
    <Modal aria-labelledby='modal-title' open={open} disableBackdropClick={true} className={classes.modalWrapper}>
      <Grid container item xs={6} sm={6} md={4} lg={4} xl={4} spacing={3} direction='column' className={classes.paper}>
        <Grid container item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography variant='h5' id='modal-title' className={classes.title}>
            Collect Payment Invoice
          </Typography>
          <IconButton size='small' className={classes.closeButton} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Grid>

        <Grid container item justify='flex-end' xs={12} sm={12} md={12} lg={12} xl={12}>
          <TextField
            disabled
            fullWidth
            variant='outlined'
            margin='dense'
            label='Total Amount'
            value={currentTotal}
            InputProps={{
              inputComponent: NumberFormatCustom as any,
              inputProps: {
                prefix: '$',
                thousandSeparator: true,
                decimalScale: 2,
                fixedDecimalScale: true
              }
            }}
          />

          <TextField
            required
            fullWidth
            variant='outlined'
            margin='dense'
            label='Total Collected'
            value={currentCollect}
            error={error}
            helperText={errorMessage}
            onChange={event => setCurrentCollect(+event.target.value)}
            InputProps={{
              inputComponent: NumberFormatCustom as any,
              inputProps: {
                prefix: '$',
                thousandSeparator: true,
                decimalScale: 2,
                fixedDecimalScale: true
              }
            }}
          />
        </Grid>

        <Grid container item justify='flex-end' xs={12} sm={12} md={12} lg={12} xl={12} className={classes.controlDiv}>
          <Button variant='contained' onClick={handleClose}>
            Close
          </Button>
          <Button variant='contained' color='primary' onClick={onSubmit}>
            Save
          </Button>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default CollectPaymentModal;
