import React, { FC, useState, useCallback, useEffect, useContext } from 'react';
import clsx from 'clsx';
import { Container, makeStyles, createStyles, Theme, Typography, Grid, Button } from '@material-ui/core';
import axios, { CancelTokenSource } from 'axios';
import SearchInput from 'components/SearchInput';
import useDebounce from 'hooks/useDebounce';
import { CUSTOMER_BASE_URL } from 'constants/url';
import { StandardConfirmationDialog } from 'components/AppDialog';
import { CurrentJobTabContext } from 'contexts/CurrentJobTabContext';
import CustomerTable from './components/CustomerTable';
import CustomerFormModal from './components/CustomerFormModal';

import useCurrentPageTitleUpdater from 'hooks/useCurrentPageTitleUpdater';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4)
    },
    container: {
      '& > :nth-child(n+2)': {
        marginTop: theme.spacing(2)
      }
    },
    divider: {
      marginBottom: theme.spacing(4)
    },
    paper: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(2),
      margin: 'auto'
    },
    subMenuGrid: {
      borderRight: '1px solid #dcdcdc',
      maxWidth: theme.spacing(15)
    },
    content: {
      paddingLeft: theme.spacing(2),
      paddingTop: theme.spacing(0.2)
    },
    headerSubMenuTitleContainer: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      paddingLeft: theme.spacing(0)
    },
    headerPageTitleContainer: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(4),
      paddingLeft: theme.spacing(2)
    },
    contentContainer: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(0),
      paddingBottom: theme.spacing(2)
    }
  })
);

const dummyCustomer: CustomerModel[] = [
  {
    id: 0,
    firstName: '',
    lastName: '',
    contactNumber: '',
    contactPersonName: '',
    contactPersonEmail: '',
    contactPersonNumber: '',
    primaryCollectionAddress: '',
    primaryCollectionAddressDetail: '',
    primaryServiceAddress: '',
    primaryServiceAddressDetail: '',
    email: ''
  }
];

const CustomerPage: FC = () => {
  useCurrentPageTitleUpdater('CUSTOMERS');
  const classes = useStyles();
  const { setCurrentJobTab } = useContext(CurrentJobTabContext);
  setCurrentJobTab(1);

  const [isLoadingData, setIsLoadingData] = useState<boolean>(false);
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [snackbarVariant, setSnackbarVariant] = useState<'success' | 'error'>('success');
  const [message, setMessage] = useState<string>('');
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [openCreateForm, setOpenCreateForm] = useState<boolean>(false);
  const [count, setCount] = useState<number>(0);
  const [customers, setCustomers] = useState<CustomerModel[]>(dummyCustomer);
  const [query, setQuery] = useState<string>('');
  const [queryString, setQueryString] = useState<string>();
  const [open, setOpen] = useState<boolean>(false);
  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  const [orderBy, setOrderBy] = useState<string>('createdAt');

  const fetchData = useCallback(async () => {
    const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();
    setIsLoadingData(true);

    const getQueryParams = () => {
      const params = new URLSearchParams();
      if (debouncedSearchTerm) {
        if (queryString) params.append('q', queryString);
      }

      if (orderBy) params.append('orderBy', orderBy);
      if (order) params.append('order', order.toUpperCase());

      params.append('s', (currentPage * rowsPerPage).toString());
      params.append('l', rowsPerPage.toString());
      return params.toString();
    };

    try {
      const result = await axios.get(CUSTOMER_BASE_URL + '?' + getQueryParams(), { cancelToken: cancelTokenSource.token });
      setCustomers(result.data.customers);
      setCount(result.data.count);
      setIsLoadingData(false);
    } catch (err) {
      console.error('err: ', err);
      setIsLoadingData(false);
    }
    return () => cancelTokenSource.cancel();
  }, [rowsPerPage, currentPage, queryString, order, orderBy]);

  const performActionAndRevertPage = (action: React.Dispatch<React.SetStateAction<any>>, actionParam: any) => {
    setCurrentPage(0);
    action(actionParam);
  };

  const handleSearch = useCallback((searchQuery: string) => {
    performActionAndRevertPage(setQueryString, searchQuery);
  }, []);

  const debouncedSearchTerm = useDebounce(query, 500);

  const handleSnackBar = (open: boolean, variant: 'success' | 'error', message: string) => {
    setSnackbarVariant(variant);
    setOpenSnackbar(open);
    setOpenCreateForm(false);
    setMessage(message);
  };

  const handleOpenCreateForm: React.MouseEventHandler = event => {
    setOpen(true);
    setOpenCreateForm(true);
  };

  useEffect(() => {
    if (debouncedSearchTerm.length >= 3) {
      handleSearch(debouncedSearchTerm);
    } else if (debouncedSearchTerm.length === 0) {
      handleSearch(debouncedSearchTerm);
    }
  }, [debouncedSearchTerm, handleSearch]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <Container maxWidth='lg' className={clsx(classes.root, classes.container)}>
      <Grid container direction='row' justify='space-between'>
        <Grid container item xs={8} sm={8} md={8} lg={8} alignItems='center'>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Typography component='h6' variant='h6' gutterBottom>
              List of all customers.
            </Typography>
          </Grid>
        </Grid>

        <Grid container item xs={4} sm={4} md={4} lg={4} justify='flex-end' alignItems='center'>
          <Button color='primary' size='medium' variant='contained' onClick={handleOpenCreateForm}>
            New Customer
          </Button>
        </Grid>
      </Grid>

      <Grid container direction='row' justify='space-between'>
        <Grid container item lg={6} sm={6} md={6} justify='flex-start' alignItems='center'>
          <Grid item>
            <SearchInput
              withBorder
              withTransition={false}
              width={150}
              placeHolder='Search Customer...'
              iconColor='#989898'
              tableSearchValue={query}
              setTableSearchValue={setQuery}
            />
          </Grid>
        </Grid>

        <Grid container item lg={6} sm={6} md={6} justify='flex-end' alignItems='center'></Grid>
      </Grid>

      <Grid container direction='row'>
        <CustomerTable
          isLoadingData={isLoadingData}
          count={count}
          currentPage={currentPage}
          rowsPerPage={rowsPerPage}
          customers={customers}
          queryString={queryString}
          order={order}
          orderBy={orderBy}
          setOrder={setOrder}
          setOrderBy={setOrderBy}
          handleChangePage={(event, page) => setCurrentPage(page)}
          handleChangeRowsPerPage={event => performActionAndRevertPage(setRowsPerPage, +event.target.value)}
        />
      </Grid>

      <CustomerFormModal
        customers={customers}
        openCreateForm={openCreateForm}
        open={open}
        setOpen={setOpen}
        setCustomers={setCustomers}
        setOpenCreateForm={setOpenCreateForm}
        handleSnackBar={handleSnackBar}
      />

      <StandardConfirmationDialog
        variant={snackbarVariant}
        titleMessage={snackbarVariant.charAt(0).toUpperCase() + snackbarVariant.slice(1) + '!'}
        message={message}
        open={openSnackbar}
        handleClose={() => setOpenSnackbar(false)}
        onConfirm={() => setOpenSnackbar(false)}
        noCancelButton={true}
      />
    </Container>
  );
};

export default CustomerPage;
