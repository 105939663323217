export default class RandomPassword {

  public characters: string = "";
  public length: number = 0;
  public resultPassword: string = "";

  constructor() {
    this.characters = "";
  }
  setLength(length: number) {
    this.length = length;
    return this;
  }
  setUpperCase(isUpperCase: boolean) {
    if (isUpperCase) {
      this.characters += "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    }
    return this;
  }
  setLowerCase(isLowerCase: boolean) {
    if (isLowerCase) {
      this.characters += "abcdefghijklmnopqrstuvwxyz";
    }
    return this;
  }
  setNumberCase(isNumeric: boolean) {
    if (isNumeric) {
      this.characters += "0123456789";
    }
    return this;
  }
  setSymbol(isSymbolic: boolean) {
    if (isSymbolic) {
      this.characters += "!@$%^&*()<>,.?/[]{}-=_+";
    }
    return this;
  }
  render() {
    this.resultPassword = '';
    for (let i = 0; i <= this.length; ++i) {
      this.resultPassword += this.characters[getRandomInt(0, this.characters.length - 1)];
    }
  }
  generate() {
    if (this.characters.length <= 0) {
      return "May'be you're in search of unknown! Keep looking";
    }

    while (!isStrong(this.resultPassword)) {
      this.render();
    }

    return this.resultPassword;
  }
}

function isStrong(password: string) {
  var regularExpression = /^(?=.{8,})(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+=,.:;?<>|~{}`\[\]/])/;
  return regularExpression.test(String(password));
}

function getRandomInt(min: number, max: number) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}