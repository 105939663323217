import React, { FC, Fragment } from 'react';
import { Theme, createStyles, makeStyles, Typography, TableRow, TableCell, Chip, Button } from '@material-ui/core';
import Skeleton from 'react-loading-skeleton';
import useRouter from 'hooks/useRouter';
import { format } from 'date-fns';
import { ucwords } from 'utils';

interface Props {
  isLoadingData: boolean;
  index: number;
  job: JobModel;
  handleJobStatus: (index: number, jobStatus: string, id: number) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableCellInnerTime: {
      color: '#388bf2',
      marginLeft: theme.spacing(1)
    }
  })
);

const BodyRow: FC<Props> = props => {
  const classes = useStyles();
  const { history } = useRouter();

  const { isLoadingData, job, index, handleJobStatus } = props;

  const { id, collectionDate, deliveryDate, collectionTime, deliveryTime, jobStatus, User, Service } = job;

  let deliverType = '-';
  let fullAddress = '-';
  let fullName = '-';

  if (Service && Service !== null) {
    const { ServiceAddress, Customer, deliveryType } = Service!;
    deliverType = deliveryType;

    if (ServiceAddress && ServiceAddress !== null) {
      const { postalCode, address, floorNo, unitNo } = ServiceAddress!;
      fullAddress = (address + ' ' + floorNo + ' ' + unitNo + ' ' + postalCode).substr(0, 100) + '...';
    }

    if (Customer && Customer !== null) {
      const { firstName, lastName } = Customer;
      fullName = firstName + ' ' + lastName;
    }
  }

  const handleLink = (path: string, id: number) => {
    history.push(path, { id: id });
  };

  const cellView = (value: any) => (isLoadingData ? <Skeleton width={100} /> : value);

  return (
    <TableRow hover>
      <TableCell component='td' align='center'>
        {cellView(id)}
      </TableCell>

      <TableCell>{cellView(fullAddress)}</TableCell>

      <TableCell component='td' align='center'>
        {cellView(
          Boolean(collectionDate) ? (
            <Fragment>
              <Typography component='span'>{format(new Date(collectionDate!), 'dd/MM/yyyy')}</Typography>
              <Typography component='span' className={classes.tableCellInnerTime}>
                {collectionTime ? collectionTime.slice(0, 5) : '-'}
              </Typography>
            </Fragment>
          ) : (
            '-'
          )
        )}
      </TableCell>

      <TableCell component='td' align='center'>
        {cellView(
          Boolean(deliveryDate) ? (
            <Fragment>
              <Typography component='span'>{format(new Date(deliveryDate!), 'dd/MM/yyyy')}</Typography>
              <Typography component='span' className={classes.tableCellInnerTime}>
                {deliveryTime ? deliveryTime.slice(0, 5) : '-'}
              </Typography>
            </Fragment>
          ) : (
            '-'
          )
        )}
      </TableCell>

      <TableCell component='td' align='center'>
        {cellView(User && User !== null ? User.displayName : '-')}
      </TableCell>

      <TableCell component='td' align='center'>
        {cellView(<Chip label={ucwords(deliverType)} size='small' variant='outlined' />)}
      </TableCell>

      <TableCell component='td' align='center'>
        {cellView(ucwords(jobStatus))}
      </TableCell>

      <TableCell component='td' align='center'>
        {cellView(
          <Button variant='text' onClick={() => window.open(`/jobs/detail/${id}`, '_blank')}>
            Details
          </Button>
        )}
      </TableCell>
    </TableRow>
  );
};

export default BodyRow;
