import React, { FC, Fragment, useState } from 'react';
import { TextField, Grid, InputAdornment, CircularProgress, makeStyles, createStyles } from '@material-ui/core';
import AlternateEmailOutlinedIcon from '@material-ui/icons/AlternateEmailOutlined';
import PhoneInTalkIcon from '@material-ui/icons/PhoneInTalk';
import PhoneNumberMask from 'components/PhoneNumberMask';
import { isValidEmail } from 'utils';
import NumberFormatCustom from 'components/NumberFormatCustom';

interface Props {
  firstName: string;
  lastName: string;
  remark: string;
  email: string;
  contactNumber: string;
  privilegeDiscount: number;
  smsNotification: boolean;
  customerError: Error;
  isLoadingContact: boolean;
  isLoadingEmail: boolean;
  setCustomerError: React.Dispatch<React.SetStateAction<Error>>;
  setFirstName: React.Dispatch<React.SetStateAction<string>>;
  setLastName: React.Dispatch<React.SetStateAction<string>>;
  setRemark: React.Dispatch<React.SetStateAction<string>>;
  setPrivilegeDiscount: React.Dispatch<React.SetStateAction<number>>;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
  setSmsNotification: React.Dispatch<React.SetStateAction<boolean>>;
  setContactNumber: React.Dispatch<React.SetStateAction<string>>;
  setLoadingEmail: React.Dispatch<React.SetStateAction<boolean>>;
  setLoadingContact: React.Dispatch<React.SetStateAction<boolean>>;
  setValidFirstStep: React.Dispatch<React.SetStateAction<boolean>>;
  handleEmailValidate: () => void;
  handleContactNumberValidate: () => void;
  handleCheckUser: (value: string, attribute: string) => void;
  handleCustomerError: () => void;
  handleFirstStepValid: () => void;
}

interface ErrorHandling {
  error: boolean;
  message: string;
}

interface Error {
  [keys: string]: ErrorHandling;
}
const useStyles = makeStyles(() =>
  createStyles({
    textField: {
      marginLeft: 2
    }
  })
);

const StepCustomer: FC<Props> = props => {
  const classes = useStyles();
  const {
    firstName,
    lastName,
    email,
    contactNumber,
    remark,
    privilegeDiscount,
    customerError,
    isLoadingEmail,
    isLoadingContact,
    setPrivilegeDiscount,
    setEmail,
    setFirstName,
    setLastName,
    setRemark,
    setContactNumber,
    setValidFirstStep,
    setCustomerError,
    handleEmailValidate,
    handleContactNumberValidate,
    handleFirstStepValid
  } = props;

  const handleResetValidFirstStep = () => {
    setValidFirstStep(false);
  };

  const handleFirstNameBlur = () => {
    handleFirstStepValid();
  };

  const handleLastNameBlur = () => {
    handleFirstStepValid();
  };

  const handleEmailBlur = () => {
    if (email) {
      handleResetValidFirstStep();
      handleEmailValidate();
    }
  };

  const handleContactNumberBlur = () => {
    if (contactNumber) {
      handleResetValidFirstStep();
      handleContactNumberValidate();
    }
  };

  const handlePrivilegeDiscount = () => {
    handleResetValidFirstStep();
    if (privilegeDiscount && privilegeDiscount > 100) {
      setCustomerError({ ...customerError, privilegeDiscount: { error: true, message: 'Discount cannot be more than 100%.' } });
    } else {
      setCustomerError({ ...customerError, privilegeDiscount: { error: false, message: '' } });
    }
    handleFirstStepValid();
  };

  return (
    <Fragment>
      <Grid container direction='row' component='div' spacing={1}>
        <Grid item component='div' lg={6} md={6} sm={12} xs={12}>
          <TextField
            id='firstName'
            label='First Name'
            placeholder='First Name'
            variant='outlined'
            margin='dense'
            required
            fullWidth
            error={customerError.firstName.error}
            helperText={customerError.firstName.message}
            value={firstName}
            onChange={event => setFirstName(event.target.value)}
            onBlur={handleFirstNameBlur}
          />
        </Grid>

        <Grid item component='div' lg={6} md={6} sm={12} xs={12}>
          <TextField
            id='lastName'
            label='Last Name'
            placeholder='Last Name'
            variant='outlined'
            margin='dense'
            required
            fullWidth
            error={customerError.lastName.error}
            helperText={customerError.lastName.message}
            value={lastName}
            onChange={event => setLastName(event.target.value)}
            onBlur={handleLastNameBlur}
          />
        </Grid>

        <Grid item component='div' lg={5} md={5} sm={12} xs={12}>
          <TextField
            className={classes.textField}
            id='contactNumber'
            label='Contact Number'
            placeholder='e.g. 61234567 / 91234567'
            variant='outlined'
            margin='dense'
            autoComplete='false'
            required
            fullWidth
            error={customerError.contactNumber.error}
            helperText={customerError.contactNumber.message}
            value={contactNumber}
            onChange={event => setContactNumber(event.target.value.replace('.', ''))}
            onBlur={handleContactNumberBlur}
            InputProps={{
              inputComponent: NumberFormatCustom as any,
              endAdornment: (
                <InputAdornment position='end'>
                  {isLoadingContact ? <CircularProgress size={20} /> : <PhoneInTalkIcon color='primary' fontSize='small' />}
                </InputAdornment>
              )
            }}
          />
        </Grid>

        <Grid item component='div' lg={5} md={5} sm={12} xs={12}>
          <TextField
            id='email'
            label='Email'
            placeholder='Email'
            variant='outlined'
            margin='dense'
            autoComplete='false'
            required
            fullWidth
            error={customerError.email.error}
            helperText={customerError.email.message}
            value={email}
            type='email'
            onChange={event => setEmail(event.target.value.toLowerCase())}
            onBlur={handleEmailBlur}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  {isLoadingEmail ? <CircularProgress size={20} /> : <AlternateEmailOutlinedIcon color='primary' fontSize='small' />}
                </InputAdornment>
              )
            }}
          />
        </Grid>

        <Grid item component='div' lg={2} md={2} sm={12} xs={12}>
          <TextField
            id='privilegeDiscount'
            label='Privilege Discount (%)'
            placeholder='Discount (%)'
            variant='outlined'
            margin='dense'
            fullWidth
            error={customerError.privilegeDiscount.error}
            helperText={customerError.privilegeDiscount.message}
            value={privilegeDiscount}
            onChange={event => setPrivilegeDiscount(+event.target.value)}
            onBlur={handlePrivilegeDiscount}
            InputProps={{
              inputComponent: NumberFormatCustom as any
            }}
          />
        </Grid>

        <Grid item component='div' lg={12} md={12} sm={12} xs={12}>
          <TextField
            id='remark'
            label='Remark'
            placeholder='Remark'
            variant='outlined'
            margin='dense'
            fullWidth
            multiline
            rows={4}
            value={remark}
            onChange={event => setRemark(event.target.value)}
          />
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default StepCustomer;
