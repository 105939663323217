import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { format } from 'date-fns';
import { makeStyles } from '@material-ui/styles';
import { green } from '@material-ui/core/colors';
import { Grid, Theme, Button, List, ListItem, ListItemText, ListItemSecondaryAction, Typography } from '@material-ui/core';

import { GET_EXPORT_PDF_DELIVERY_ORDER_URL, GET_EXPORT_PDF_RECEIPT_URL } from 'constants/url';

interface Props {
  serviceId: number;
  serviceNumber: string;
  customerFirstName: string;
  customerLastName: string;
  collectionAddress: string;
  collectionUnitNo: string;
  collectionFloorNo: string;
  collectionPostalCode: string;
  collectionDate: Date | null;
  collectionTime: string;
  serviceAddress: string;
  serviceUnitNo: string;
  serviceFloorNo: string;
  servicePostalCode: string;
  deliveryDate: Date | null;
  clientSignature: string;
  deliveryTime: string;
  invoiceNumber: string;
  invoiceCreated: string;
  invoicePaymentStatus: string;
  invoiceTotalPrice: number;
  invoiceTotalCollected: number;
  invoiceTotalChanges: number;
  invoicePaymentMethod: string;
  discountType: string;
  discountAmount: number;
  totalAmount: number;
  deliveryType: string;
  serviceItems: ServiceItemModel[];
  handleClose: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    marginBottom: theme.spacing(2)
  },
  controlDiv: {
    '& > :nth-child(n+2)': {
      marginLeft: theme.spacing(2)
    }
  },
  list: {
    padding: theme.spacing(0),
    width: '50%'
  },
  itemPaid: {
    width: '50%',
    padding: theme.spacing(1),
    fontWeight: 'bold',
    color: green[500]
  },
  itemUnPaid: {
    width: '50%',
    padding: theme.spacing(1),
    fontWeight: 'bold',
    color: 'grey'
  },
  itemContainer: {
    marginBottom: theme.spacing(2),
    border: '0.5px solid grey',
    borderRadius: '10px'
  },
  itemTitle: {
    fontWeight: 'bold',
    color: 'grey'
  },
  itemDetail: {
    padding: theme.spacing(1),
    width: '50%',
    fontWeight: 'bold'
  },
  tableCellInnerInvoice: {
    color: '#388bf2'
  },
  tableCellInnerReceipt: {
    color: '#388bf2',
    float: 'right',
    padding: theme.spacing(0)
  },
  tableCellInnerDate: {
    fontWeight: 'bold'
  },
  tableCellInnerTime: {
    fontWeight: 'bold',
    marginLeft: theme.spacing(1)
  }
}));

const DetailDeliveryOrder: React.FC<Props> = props => {
  const classes = useStyles();

  const {
    serviceId,
    serviceNumber,
    customerFirstName,
    customerLastName,
    collectionAddress,
    collectionUnitNo,
    collectionFloorNo,
    collectionPostalCode,
    collectionDate,
    collectionTime,
    serviceAddress,
    serviceUnitNo,
    serviceFloorNo,
    servicePostalCode,
    deliveryDate,
    deliveryTime,
    clientSignature,
    invoiceNumber,
    invoiceCreated,
    invoicePaymentStatus,
    invoiceTotalPrice,
    invoiceTotalCollected,
    invoiceTotalChanges,
    invoicePaymentMethod,
    discountType,
    discountAmount,
    totalAmount,
    deliveryType,
    serviceItems,
    handleClose
  } = props;

  const handleDeliveryOrderPDF = async () => {
    try {
      await axios
        .post(
          GET_EXPORT_PDF_DELIVERY_ORDER_URL,
          {
            serviceId,
            invoiceNumber,
            paymentStatus: invoicePaymentStatus,
            invoiceCreated,
            customerLastName,
            customerFirstName,
            collectionAddress,
            collectionUnitNo,
            collectionFloorNo,
            collectionPostalCode,
            serviceAddress,
            serviceUnitNo,
            serviceFloorNo,
            servicePostalCode,
            discountType,
            discountAmount,
            deliveryType,
            invoiceTotalPrice,
            invoicePaymentMethod,
            jobCollectionDate: collectionDate,
            jobCollectionTime: collectionTime,
            jobDeliveryDate: deliveryDate,
            jobDeliveryTime: deliveryTime,
            ServiceItems: serviceItems
          },
          {
            headers: {
              t: localStorage.getItem('token')
            },
            responseType: 'blob'
          }
        )
        .then(response => {
          const file = new Blob([response.data && response.data], { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);

          const newwindow = window.open(fileURL, 'name', 'height=700,width=750');
          if (newwindow && window.focus) {
            newwindow.focus();
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleReceiptPDF = async () => {
    try {
      await axios
        .post(
          GET_EXPORT_PDF_RECEIPT_URL,
          {
            serviceId,
            invoiceNumber,
            paymentStatus: invoicePaymentStatus,
            totalAmount,
            invoiceCreated,
            discountType,
            discountAmount,
            deliveryType,
            invoiceTotalPrice,
            invoiceTotalCollected,
            invoiceTotalChanges,
            invoicePaymentMethod,
            clientSignature,
            ServiceItems: serviceItems
          },
          {
            headers: {
              t: localStorage.getItem('token')
            },
            responseType: 'blob'
          }
        )
        .then(response => {
          const file = new Blob([response.data && response.data], { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);

          const newwindow = window.open(fileURL, 'name', 'height=700,width=750');
          if (newwindow && window.focus) {
            newwindow.focus();
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={classes.itemContainer}>
        <List>
          <ListItem className={classes.list}>
            <ListItemText className={classes.itemTitle} primary='Delivery Order ID' />
            <ListItemSecondaryAction className={classes.itemDetail}>#{serviceNumber}</ListItemSecondaryAction>
          </ListItem>
          <ListItem className={classes.list}>
            <ListItemText className={classes.itemTitle} primary='Customer Name' />
            <ListItemSecondaryAction className={classes.itemDetail}>
              {customerFirstName} {customerLastName}
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem className={classes.list}>
            <ListItemText className={classes.itemTitle} primary='Collection Address' />
            <ListItemSecondaryAction className={classes.itemDetail}>
              {collectionAddress} {collectionFloorNo} {collectionUnitNo} {collectionPostalCode}
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem className={classes.list}>
            <ListItemText className={classes.itemTitle} primary='Collection Time' />
            <ListItemSecondaryAction className={classes.itemDetail}>
              {collectionDate ? format(new Date(collectionDate), 'dd/MM/yyyy') : '-'} {!collectionTime ? '-' : collectionTime.slice(0, 5)}
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem className={classes.list}>
            <ListItemText className={classes.itemTitle} primary='Delivery Address' />
            <ListItemSecondaryAction className={classes.itemDetail}>
              {serviceAddress} {serviceFloorNo} {serviceUnitNo} {servicePostalCode}
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem className={classes.list}>
            <ListItemText className={classes.itemTitle} primary='Delivery Time' />
            <ListItemSecondaryAction className={classes.itemDetail}>
              {deliveryDate ? format(new Date(deliveryDate), 'dd/MM/yyyy') : '-'} {!deliveryTime ? '-' : deliveryTime.slice(0, 5)}
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem className={classes.list}>
            <ListItemText className={classes.itemTitle} primary='Payment Status' />
            <ListItemSecondaryAction className={invoicePaymentStatus === 'PAID' ? classes.itemPaid : classes.itemUnPaid}>
              {invoicePaymentStatus}
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem className={classes.list}>
            <ListItemText className={classes.itemTitle} primary='Receipt ID' />
            <ListItemSecondaryAction className={classes.itemDetail}>
              <Typography variant='body1'>
                <Typography component='span' className={classes.tableCellInnerInvoice}>
                  {invoicePaymentStatus == 'PAID' ? `LB/${invoiceNumber}` : '-'}
                </Typography>
                <Typography component='span'>
                  {invoicePaymentStatus === 'PAID' ? (
                    <Button onClick={event => handleReceiptPDF()} className={classes.tableCellInnerReceipt}>
                      Download Receipt
                    </Button>
                  ) : (
                    ''
                  )}
                </Typography>
              </Typography>
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem className={classes.list}>
            <ListItemText className={classes.itemTitle} primary='Receipt Date Time' />
            <ListItemSecondaryAction className={classes.itemDetail}>
              {!invoiceCreated ? (
                <Typography variant='body1'>'-'</Typography>
              ) : (
                <Typography variant='body1'>
                  <Typography component='span' className={classes.tableCellInnerDate}>
                    {format(new Date(invoiceCreated), 'dd/MM/yyyy')}
                  </Typography>
                  <Typography component='span' className={classes.tableCellInnerTime}>
                    {format(new Date(invoiceCreated), 'HH:mm')}
                  </Typography>
                </Typography>
              )}
            </ListItemSecondaryAction>
          </ListItem>
        </List>
      </Grid>
      <Grid container item justify='flex-end' xs={12} sm={12} md={12} lg={12} xl={12} className={classes.controlDiv}>
        <Button variant='contained' onClick={handleClose}>
          Close
        </Button>
        <Button variant='contained' color='primary' onClick={event => handleDeliveryOrderPDF()}>
          Export as PDF
        </Button>
      </Grid>
    </Grid>
  );
};

export default DetailDeliveryOrder;
