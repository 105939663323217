import React, { Fragment, useState, useEffect } from 'react';
import clsx from 'clsx';
import { Box, Theme, Typography, Snackbar } from '@material-ui/core';
import { Switch, Route } from 'react-router';
import axios, { CancelTokenSource } from 'axios';
import { makeStyles } from '@material-ui/styles';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';

import ConditionalRoute from 'components/ConditionalRoute';
import AppHeader from 'components/AppHeader';
import AppDrawer from 'components/AppDrawer';
import LoginPage from 'pages/LoginPage';
import ForgotPasswordPage from 'pages/ForgotPasswordPage';
import ThankYouPage from 'pages/ThankYouPage';
import ResetPasswordPage from 'pages/ResetPasswordPage';
import InvoicesPage from 'pages/InvoicesPage';
import DeliveryOrderPage from 'pages/DeliveryOrderPage';
import SettingsPage from 'pages/SettingsPage';
import NotFoundPage from 'pages/NotFoundPage';
import CustomerPage from 'pages/CustomerPage';
import CustomerDetailPage from 'pages/CustomerDetailPage';
import CustomerJobPage from 'pages/CustomerJobPage';
import CustomerInvoicesPage from 'pages/CustomerInvoicesPage';
import JobsPage from 'pages/JobsPage';
import JobsDetailPage from 'pages/JobsDetailPage';
import AccountVerificationPage from 'pages/AccountVerifcationPage';
import PaypalPage from 'pages/PaypalPage';
import { CurrentUserProvider } from 'contexts/CurrentUserContext';
import { isUserAuthenticated, isUserRoleManager } from 'selectors';
import { attachTokenToHeader, detachTokenFromHeader } from 'utils/AxiosUtils';
import { GET_CURRENT_USER_URL, UPDATE_TOKEN } from 'constants/url';
import { CurrentPageProvider } from 'contexts/CurrentPageContext';
import { CurrentJobTabProvider } from 'contexts/CurrentJobTabContext';
import firebase from './utils/firebaseInit';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex'
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '95vh',
    overflow: 'auto'
  },
  footerPaddingIsLoggedIn: {
    paddingRight: theme.spacing(6),
    paddingTop: theme.spacing(2)
  }
}));

const Alert = (props: AlertProps) => {
  return <MuiAlert elevation={6} variant='filled' {...props} />;
};

const App: React.FC = () => {
  const classes = useStyles();
  const [currentPageTitle, setCurrentPageTitle] = useState<string>('');
  const [currentJobTab, setCurrentJobTab] = useState<number>(1);
  const [CurrentUserData, setCurrentUserData] = useState<CurrentUser>();
  const [isAuthenticating, setAuthenticating] = useState(true);
  const [openDrawer, setOpenDrawer] = useState(true);
  const [hasNotification, setNotification] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');

  const isLoggedIn = isUserAuthenticated(CurrentUserData);
  const isRoleManager = isUserRoleManager(CurrentUserData);
  
  //@ts-ignore
  const isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && safari.pushNotification));
  //@ts-ignore
  const isIE = /*@cc_on!@*/false || !!document.documentMode;
  //@ts-ignore
  const isIosMobile = /iPhone|iPad|iPod/i.test(navigator.userAgent);

  const handleDrawerOpen = () => {
    setOpenDrawer(true);
  };

  const handleDrawerClose = () => {
    setOpenDrawer(false);
  };

  const setCurrentUser = (currentUser: CurrentUser, token: string): void => {
    localStorage.setItem('token', token);
    attachTokenToHeader(token);

    setCurrentUserData(currentUser);
  };

  const unsetCurrentUser = (): void => {
    localStorage.removeItem('token');
    detachTokenFromHeader();

    setCurrentUserData(undefined);
  };

  useEffect(() => {
    const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();

    const getPersistedToken = () => {
      return localStorage.getItem('token');
    };

    const getCurrentUserData = async () => {
      setAuthenticating(true);
      const token = getPersistedToken();
      if (token) {
        try {
          const response = await axios.get(GET_CURRENT_USER_URL, {
            headers: { Authorization: `Bearer ${token}` },
            cancelToken: cancelTokenSource.token
          });
          const currentUser: CurrentUser = response.data;
          setCurrentUser(currentUser, token);
        } catch (err) {
          unsetCurrentUser();
        }
      }

      setAuthenticating(false);
    };

    getCurrentUserData();

    return () => {
      cancelTokenSource.cancel();
    };
  }, []);

  useEffect(() => {
    if (isLoggedIn && !isSafari && !isIE && !isIosMobile) {
      const messaging = firebase.messaging();

      messaging.onMessage(payload => {
        const { body } = payload.notification;
        setNotification(true);
        setMessage(body);
      });

      Notification.requestPermission().then(async permission => {
        if (permission === 'granted') {
          const token = await messaging.getToken();
          updateToken(token);
        } else {
          console.log('Unable to get permission to notify.');
        }
      });
    }
  }, [isLoggedIn]);

  const updateToken = async (token: string) => {
    const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();
    try {
      await axios.put(
        UPDATE_TOKEN,
        {
          deviceToken: token
        },
        {
          cancelToken: cancelTokenSource.token
        }
      );
    } catch (err) {
      console.log('error:', err);
    }
  };

  return isAuthenticating ? null : (
    <Box>
      <CurrentUserProvider
        value={{
          currentUser: CurrentUserData,
          setCurrentUser,
          unsetCurrentUser
        }}
      >
        <CurrentPageProvider
          value={{
            currentPageTitle,
            setCurrentPageTitle
          }}
        >
          <CurrentJobTabProvider
            value={{
              currentJobTab,
              setCurrentJobTab
            }}
          >
            <div className={classes.root}>
              {isLoggedIn && (
                <Fragment>
                  <AppHeader open={openDrawer} handleDrawerOpen={handleDrawerOpen} />
                  <AppDrawer openDrawer={openDrawer} handleDrawerClose={handleDrawerClose} />
                </Fragment>
              )}
              <main className={classes.content}>
                {isLoggedIn && <div className={classes.appBarSpacer} />}
                <Switch>
                  <ConditionalRoute exact={true} path={'/'} routeCondition={!isLoggedIn} component={LoginPage} redirectTo={'/jobs'} />
                  <ConditionalRoute
                    exact={true}
                    path={'/forgotpassword'}
                    routeCondition={!isLoggedIn}
                    component={ForgotPasswordPage}
                    redirectTo={'/thankyou'}
                  />
                  <ConditionalRoute exact={true} path={'/paypal/:id'} routeCondition={!isLoggedIn} component={PaypalPage} redirectTo={'/'} />
                  <ConditionalRoute exact={true} path={'/thankyou'} routeCondition={!isLoggedIn} component={ThankYouPage} redirectTo={'/'} />
                  <ConditionalRoute
                    exact={true}
                    path={'/resetpassword'}
                    routeCondition={!isLoggedIn}
                    component={ResetPasswordPage}
                    redirectTo={'/'}
                  />
                  <ConditionalRoute
                    exact={true}
                    path={'/customers'}
                    routeCondition={isLoggedIn && !isRoleManager}
                    component={CustomerPage}
                    redirectTo={'/'}
                  />
                  <ConditionalRoute
                    exact={true}
                    path={'/customers/profile/:id'}
                    routeCondition={isLoggedIn}
                    component={CustomerDetailPage}
                    redirectTo={'/'}
                  />
                  <ConditionalRoute exact={true} path={'/customers/job/:id'} routeCondition={isLoggedIn} component={CustomerJobPage} redirectTo={'/'} />
                  <ConditionalRoute exact={true} path={'/customers/invoices/:id'} routeCondition={isLoggedIn} component={CustomerInvoicesPage} redirectTo={'/'} />
                  <ConditionalRoute exact={true} path={'/jobs'} routeCondition={isLoggedIn} component={JobsPage} redirectTo={'/'} />
                  <ConditionalRoute exact={true} path={'/jobs/detail/:id'} routeCondition={isLoggedIn} component={JobsDetailPage} redirectTo={'/'} />
                  <ConditionalRoute
                    exact={true}
                    path={'/invoices'}
                    routeCondition={isLoggedIn && !isRoleManager}
                    component={InvoicesPage}
                    redirectTo={'/'}
                  />
                  <ConditionalRoute
                    exact={true}
                    path={'/deliveries'}
                    routeCondition={isLoggedIn && !isRoleManager}
                    component={DeliveryOrderPage}
                    redirectTo={'/'}
                  />
                  <ConditionalRoute
                    exact={true}
                    path={'/settings'}
                    routeCondition={isLoggedIn && !isRoleManager}
                    component={SettingsPage}
                    redirectTo={'/'}
                  />
                  <ConditionalRoute
                    exact={true}
                    path={'/activation'}
                    routeCondition={!isLoggedIn}
                    component={AccountVerificationPage}
                    redirectTo={'/'}
                  />
                  <Route component={NotFoundPage} />
                </Switch>
              </main>
            </div>
          </CurrentJobTabProvider>
        </CurrentPageProvider>
      </CurrentUserProvider>
      <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={hasNotification} onClose={() => setNotification(false)}>
        <Alert onClose={() => setNotification(false)} severity='info'>
          {message}
        </Alert>
      </Snackbar>
      <Typography
        variant={isLoggedIn ? 'body2' : 'h6'}
        color='textSecondary'
        align={!isLoggedIn ? 'center' : 'right'}
        className={clsx({ [classes.footerPaddingIsLoggedIn]: isLoggedIn })}
      >
        {'© ' + new Date().getFullYear() + ' Laundry Butler All Rights Reserved'}
      </Typography>
    </Box>
  );
};

export default App;
