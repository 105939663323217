import React, { FC, Fragment, useState } from 'react';
import { FormControlLabel, Checkbox, TextField, Grid, Button, IconButton, Tooltip, Typography, Divider, makeStyles, createStyles, Theme } from '@material-ui/core';
import { Delete } from '@material-ui/icons';

interface Props {
  collectionAddress: CollectionAddressModel[];
  serviceAddress: ServiceAddressModel[];
  collectingAddressError: Error[];
  serviceAddressError: Error[];
  isDuplicatedCollection: boolean;
  isDuplicatedDelivery: boolean;
  setIsDupplicatedCollection: React.Dispatch<React.SetStateAction<boolean>>;
  setIsDupplicatedDelivery: React.Dispatch<React.SetStateAction<boolean>>;
  setCollectionAddress: React.Dispatch<React.SetStateAction<CollectionAddressModel[]>>;
  setServiceAddress: React.Dispatch<React.SetStateAction<ServiceAddressModel[]>>;
  setCollectingAddressError: React.Dispatch<React.SetStateAction<Error[]>>;
  setServiceAddressError: React.Dispatch<React.SetStateAction<Error[]>>;
  handleCollectionAddress: (type: string, index?: number, payload?: CollectionAddressModel) => void;
  handleServiceAddress: (type: string, index?: number, payload?: ServiceAddressModel) => void;
  handleSecondStepValid: () => void;
  handleErrorCollectionAddress: () => void;
  handleErrorServiceAddress: () => void;
}

interface ErrorHandling {
  error: boolean;
  message: string;
}

interface Error {
  [keys: string]: ErrorHandling;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      overflow: 'hidden',
      overflowY: 'auto',
      maxHeight: 400,
      minHeight: 200
    },
    divider: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1)
    },
    checkAddress: {
      padding: 0
    }
  })
);

const StepAddresses: FC<Props> = props => {
  const classes = useStyles();
  const {
    collectionAddress,
    serviceAddress,
    setCollectionAddress,
    setServiceAddress,
    collectingAddressError,
    serviceAddressError,
    setCollectingAddressError,
    setServiceAddressError,
    handleCollectionAddress,
    handleServiceAddress,
    handleSecondStepValid,
    handleErrorCollectionAddress,
    handleErrorServiceAddress,
    isDuplicatedCollection,
    setIsDupplicatedCollection,
    isDuplicatedDelivery,
    setIsDupplicatedDelivery
  } = props;

  const [isSameAddress, setIsSameAddres] = useState<boolean>(false);

  const individualCollectionAddressError = (key: string, index: number) => (event: React.FocusEvent<HTMLInputElement>) => {
    const currentValue = event.target.value;
    let currentError = {
      error: currentValue === '',
      message: currentValue === '' ? 'This field can not be empty.' : ''
    };

    if (key === 'postalCode') {
      if (currentValue.length < 6) {
        currentError = {
          error: true,
          message: 'Postal Code must be 6 digits'
        };
      }
    }

    setCollectingAddressError(
      collectingAddressError.map((value, idx) => {
        if (index === idx) {
          value = {
            ...value,
            [key]: currentError
          };
        }
        return value;
      })
    );

    if (isDuplicatedCollection) {
      handleErrorCollectionAddress();
    }

    handleSecondStepValid();
  };

  const resetCollectionAddressError = (key: string, index: number) => (event: React.FocusEvent<HTMLInputElement>) => {
    setCollectingAddressError(
      collectingAddressError.map((value, idx) => {
        if (index === idx) {
          value = {
            ...value,
            [key]: {
              error: false,
              message: ''
            }
          };
        }
        return value;
      })
    );
  };

  const individualServiceAddressError = (key: string, index: number) => (event: React.FocusEvent<HTMLInputElement>) => {
    const currentValue = event.target.value;
    let currentError = {
      error: currentValue === '',
      message: currentValue === '' ? 'This field can not be empty.' : ''
    };

    if (key === 'postalCode') {
      if (currentValue.length < 6) {
        currentError = {
          error: true,
          message: 'Postal Code must be 6 digits'
        };
      }
    }

    setServiceAddressError(
      serviceAddressError.map((value, idx) => {
        if (index === idx) {
          value = {
            ...value,
            [key]: currentError
          };
        }
        return value;
      })
    );

    if (isDuplicatedDelivery) {
      handleErrorServiceAddress();
    }

    handleSecondStepValid();
  };

  const resetServiceAddressError = (key: string, index: number) => {
    setServiceAddressError(
      serviceAddressError.map((value, idx) => {
        if (index === idx) {
          value = {
            ...value,
            [key]: {
              error: false,
              message: ''
            }
          };
        }
        return value;
      })
    );
  };

  const handleSameAddressChange = () => {
    setIsSameAddres(!isSameAddress);
    const firstCollectionAddress = collectionAddress[0];

    if (!isSameAddress && firstCollectionAddress) {
      handleServiceAddress('SET_VALUE', 0, { ...firstCollectionAddress });

      serviceAddressError[0] = {
        ['address']: { error: false, message: '' }, 
        ['floorNo']: { error: false, message: '' }, 
        ['unitNo']: { error: false, message: '' }, 
        ['postalCode']: { error: false, message: '' }
      }
    }
  };

  return (
    <Fragment>
      <Grid container spacing={1} justify='space-between' className={classes.wrapper}>
        <Grid item lg={6} md={6} sm={6} xs={6}>
          <Grid container item lg={12} md={12} sm={12} xs={12}>
            <Typography>Collecting Address</Typography>
          </Grid>
          {collectionAddress.map((value, index) => (
            <Fragment key={index + 1}>
              {index !== 0 && (
                <Grid container item lg={12} md={12} sm={12} xs={12} justify='flex-end'>
                  <Tooltip title='Remove'>
                    <IconButton size='small' color='primary' onClick={event => handleCollectionAddress('DELETE_COLLECTION', index)}>
                      <Delete color='error' />
                    </IconButton>
                  </Tooltip>
                </Grid>
              )}
              <Grid container item spacing={1} lg={12} md={12} sm={12} xs={12}>

                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <TextField
                    id={'collectionAddress' + index}
                    key={index + 1}
                    label='Collection Address'
                    placeholder='Collection Address'
                    variant='outlined'
                    margin='dense'
                    fullWidth
                    required
                    error={collectingAddressError[index] ? collectingAddressError[index].address.error : false}
                    helperText={collectingAddressError[index] ? collectingAddressError[index].address.message : false}
                    value={value.address}
                    onChange={event => handleCollectionAddress('SET_VALUE', index, { ...value, address: event.target.value })}
                    onBlur={individualCollectionAddressError('address', index)}
                  />
                </Grid>

                <Grid item lg={4} md={4} sm={12} xs={12}>
                  <TextField
                    id={'floorNo' + index}
                    key={index + 1}
                    label='Floor No'
                    placeholder='Floor No'
                    variant='outlined'
                    margin='dense'
                    fullWidth
                    required
                    error={collectingAddressError[index] ? collectingAddressError[index].floorNo.error : false}
                    helperText={collectingAddressError[index] ? collectingAddressError[index].floorNo.message : false}
                    value={value.floorNo}
                    onChange={event => handleCollectionAddress('SET_VALUE', index, { ...value, floorNo: event.target.value.replace(/\D+/g,'') })}
                    onBlur={individualCollectionAddressError('floorNo', index)}
                  />
                </Grid>

                <Grid item lg={4} md={4} sm={12} xs={12}>
                  <TextField
                    id={'unitNo' + index}
                    key={index + 1}
                    label='Unit No'
                    placeholder='Unit No'
                    variant='outlined'
                    margin='dense'
                    fullWidth
                    required
                    error={collectingAddressError[index] ? collectingAddressError[index].unitNo.error : false}
                    helperText={collectingAddressError[index] ? collectingAddressError[index].unitNo.message : false}
                    value={value.unitNo}
                    onChange={event => handleCollectionAddress('SET_VALUE', index, { ...value, unitNo: event.target.value.replace(/\D+/g,'') })}
                    onBlur={individualCollectionAddressError('unitNo', index)}
                  />
                </Grid>

                <Grid item lg={4} md={4} sm={12} xs={12}>
                  <TextField
                    id={'postalCode' + index}
                    key={index + 1}
                    label='Postal Code'
                    placeholder='Postal Code'
                    variant='outlined'
                    margin='dense'
                    fullWidth
                    required
                    error={collectingAddressError[index] ? collectingAddressError[index].postalCode.error : false}
                    helperText={collectingAddressError[index] ? collectingAddressError[index].postalCode.message : false}
                    value={value.postalCode}
                    onChange={event => handleCollectionAddress('SET_VALUE', index, { ...value, postalCode: event.target.value.replace(/\D+/g,'') })}
                    onKeyDown={event => {
                      if (event.keyCode === 190 || event.keyCode === 110) event.preventDefault();
                    }}
                    inputProps={{
                      maxLength: 6,
                    }}
                    onBlur={individualCollectionAddressError('postalCode', index)}
                  />
                </Grid>
              </Grid>
              <Divider className={classes.divider} />
            </Fragment>
          ))}

          <Grid container item lg={12} md={12} sm={12} xs={12} justify='flex-end'>
            <Button
              size='small'
              variant='text'
              color='primary'
              disabled={collectionAddress.length === 4 ? true : false}
              onClick={event => handleCollectionAddress('ADD_COLLECTION')}
            >
              Add Collection Address
            </Button>
          </Grid>
        </Grid>

        <Grid item lg={6} md={6} sm={6} xs={6}>
          <Grid container item lg={12} md={12} sm={12} xs={12}>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <Typography>Delivery Address</Typography>
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={6} justify='flex-end'>
              <FormControlLabel
                control={<Checkbox size='small' checked={isSameAddress} onChange={handleSameAddressChange} name='checkedB' color='primary' className={classes.checkAddress} />}
                label='Same as collection address ?'
              />
            </Grid>
          </Grid>
          {serviceAddress.map((value, index) => (
            <Fragment key={index + 1}>
              {index !== 0 && (
                <Grid container lg={12} md={12} sm={12} xs={12} justify='flex-end'>
                  <Tooltip title='Remove'>
                    <IconButton size='small' color='primary' onClick={event => handleServiceAddress('DELETE_SERVICE', index)}>
                      <Delete color='error' />
                    </IconButton>
                  </Tooltip>
                </Grid>
              )}
              <Grid container spacing={1} item lg={12} md={12} sm={12} xs={12}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <TextField
                    id={'serviceAddress' + index}
                    key={index + 1}
                    label='Service Address'
                    placeholder='Service Address'
                    variant='outlined'
                    margin='dense'
                    fullWidth
                    required
                    error={serviceAddressError[index] ? serviceAddressError[index].address.error : false}
                    helperText={serviceAddressError[index] ? serviceAddressError[index].address.message : false}
                    value={value.address}
                    onChange={event => handleServiceAddress('SET_VALUE', index, { ...value, address: event.target.value })}
                    onBlur={individualServiceAddressError('address', index)}
                  />
                </Grid>

                <Grid item lg={4} md={4} sm={12} xs={12}>
                  <TextField
                    id={'floorNoService' + index}
                    key={index + 1}
                    label='Floor No'
                    placeholder='Floor No'
                    variant='outlined'
                    margin='dense'
                    fullWidth
                    required
                    error={serviceAddressError[index] ? serviceAddressError[index].floorNo.error : false}
                    helperText={serviceAddressError[index] ? serviceAddressError[index].floorNo.message : false}
                    value={value.floorNo}
                    onChange={event => handleServiceAddress('SET_VALUE', index, { ...value, floorNo: event.target.value.replace(/\D+/g,'') })}
                    onBlur={individualServiceAddressError('floorNo', index)}
                  />
                </Grid>

                <Grid item lg={4} md={4} sm={12} xs={12}>
                  <TextField
                    id={'unitNoService' + index}
                    key={index + 1}
                    label='Unit No'
                    placeholder='Unit No'
                    variant='outlined'
                    margin='dense'
                    fullWidth
                    required
                    error={serviceAddressError[index] ? serviceAddressError[index].unitNo.error : false}
                    helperText={serviceAddressError[index] ? serviceAddressError[index].unitNo.message : false}
                    value={value.unitNo}
                    onChange={event => handleServiceAddress('SET_VALUE', index, { ...value, unitNo: event.target.value.replace(/\D+/g,'') })}
                    onBlur={individualServiceAddressError('unitNo', index)}
                  />
                </Grid>

                <Grid item lg={4} md={4} sm={12} xs={12}>
                  <TextField
                    id={'postalCodeService' + index}
                    key={index + 1}
                    label='Postal Code'
                    placeholder='Postal Code'
                    variant='outlined'
                    margin='dense'
                    fullWidth
                    required
                    error={serviceAddressError[index] ? serviceAddressError[index].postalCode.error : false}
                    helperText={serviceAddressError[index] ? serviceAddressError[index].postalCode.message : false}
                    value={value.postalCode}
                    onChange={event => handleServiceAddress('SET_VALUE', index, { ...value, postalCode: event.target.value.replace(/\D+/g,'') })}
                    onBlur={individualServiceAddressError('postalCode', index)}
                  />
                </Grid>

              </Grid>
              <Divider className={classes.divider} />
            </Fragment>
          ))}
          <Grid container item lg={12} md={12} sm={12} xs={12} justify='flex-end'>
            <Button
              size='small'
              variant='text'
              color='primary'
              disabled={serviceAddress.length === 4 ? true : false}
              onClick={event => handleServiceAddress('ADD_SERVICE')}
            >
              Add Delivery Address
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default StepAddresses;
