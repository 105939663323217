import React, { FC, useState, Fragment } from 'react';
import NumberFormat from 'react-number-format';
import { Typography, Checkbox, IconButton, makeStyles, TableRow, Theme, Tooltip } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import NewIcon from '@material-ui/icons/FiberNewOutlined';
import EditIcon from '@material-ui/icons/EditOutlined';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Skeleton from 'react-loading-skeleton';

import axios from 'axios';
import BodyCell from 'components/BodyCell';
import { ucwords } from 'utils';
import { GET_DELETE_SERVICE_ITEM_TEMPLATE_URL } from 'constants/url';
import { StandardConfirmationDialog } from 'components/AppDialog';

interface Props {
  isLoadingData: boolean;
  serviceItemTemplate: ServiceItemTemplatesResponseModel;
  setOpenSnackbar: React.Dispatch<React.SetStateAction<boolean>>;
  setSnackbarVarient: React.Dispatch<React.SetStateAction<'success' | 'error'>>;
  index: number;
  checked: number[];
  onEditServiceItemTemplate: React.MouseEventHandler;
  handleSetMessageSuccess: (message: string) => void;
  handleSetMessageError: (message: string) => void;
  deleteIndividualServiceItemTemplate: (serviceItemTemplateIndex: number) => void;
  handleIndividualCheck: (id: number) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  newIcon: {
    color: green[500],
    fontSize: 30
  },
  actionIcon: {
    fontSize: 20
  },
  tableCellInner: {
    display: 'flex',
    alignItems: 'center'
  },
  nameTextCell: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: theme.spacing(2)
  },
  checkBox: {
    '&&:hover': {
      backgroundColor: 'transparent'
    }
  },
  checkBoxSize: {
    fontSize: '16px'
  }
}));

const BodyRow: FC<Props> = props => {
  const classes = useStyles();
  const {
    isLoadingData,
    serviceItemTemplate,
    setSnackbarVarient,
    setOpenSnackbar,
    handleSetMessageSuccess,
    handleSetMessageError,
    index,
    deleteIndividualServiceItemTemplate,
    onEditServiceItemTemplate,
    checked,
    handleIndividualCheck
  } = props;
  const { id, name, type, unit, priceLA, priceDC, new: isNew, Category } = serviceItemTemplate;
  const { nameCategory, typeCategory } = Category;
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [isProcessing, setProcessing] = useState<boolean>(false);

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const actionWrapper = async (action: () => Promise<void>) => {
    setProcessing(true);

    try {
      await action();
      handleSetMessageSuccess('Successfully deleted a service item');
      setSnackbarVarient('success');
      setOpenSnackbar(true);
    } catch (err) {
      handleSetMessageError('Failed to delete a service item');
      setSnackbarVarient('error');
      setOpenSnackbar(true);
    }

    setProcessing(false);
  };

  const deleteServiceItemTemplate: React.MouseEventHandler<HTMLButtonElement> = async event => {
    await actionWrapper(async () => {
      await axios.delete(GET_DELETE_SERVICE_ITEM_TEMPLATE_URL(id));
    });
    deleteIndividualServiceItemTemplate(index);
  };

  const checkedCheckbox = checked.filter(checkedValue => checkedValue === id).length;

  return (
    <Fragment>
      <TableRow>
        <BodyCell cellWidth='3%' pR='10px' isComponent={true}>
          {isLoadingData ? (
            <Skeleton width={15} />
          ) : (
            <Checkbox
              key={id}
              icon={<CheckBoxOutlineBlankIcon className={classes.checkBoxSize} />}
              checkedIcon={<CheckBoxIcon className={classes.checkBoxSize} />}
              edge='start'
              color='primary'
              className={classes.checkBox}
              checked={checkedCheckbox === 0 ? false : true}
              tabIndex={-1}
              disableRipple
              onChange={event => handleIndividualCheck && handleIndividualCheck(id)}
            />
          )}
        </BodyCell>

        <BodyCell cellWidth='15%'>
          <div className={classes.tableCellInner}>
            <div className={classes.nameTextCell}>{isLoadingData ? <Skeleton width={150} /> : nameCategory}</div>
            {isNew && (
              <div>
                <NewIcon className={classes.newIcon} />
              </div>
            )}
          </div>
        </BodyCell>

        <BodyCell cellWidth='25%' isComponent={true}>
          {isLoadingData ? <Skeleton width={100} /> : name}
        </BodyCell>

        <BodyCell cellWidth='15%' isComponent={true}>
          {isLoadingData ? <Skeleton width={100} /> : ucwords(type)}
        </BodyCell>

        <BodyCell cellWidth='15%' isComponent={true}>
          {isLoadingData ? <Skeleton width={100} /> : ucwords(unit).toUpperCase()}
        </BodyCell>

        <BodyCell cellWidth='15%'>
          {isLoadingData ? (
            <Skeleton width={80} />
          ) : (
            <Typography variant='inherit'>
              {(typeCategory === 'LA_DC') ? (
                <Typography variant='body1'>
                  <Typography variant='body2'>
                    {'LA : '}
                    <NumberFormat value={priceLA} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} fixedDecimalScale={true} />
                  </Typography>
                  <Typography variant='body2'>
                    {'DC : '}
                    <NumberFormat value={priceDC} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} fixedDecimalScale={true} />
                  </Typography>
                </Typography>
              ) : (
                <NumberFormat value={priceLA} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} fixedDecimalScale={true} />
              )}
            </Typography>
          )}
        </BodyCell>

        <BodyCell align='center' isComponent={true}>
          {isLoadingData ? (
            <Skeleton width={100} />
          ) : (
            <Fragment>
              <Tooltip title={'Edit'} placement='top'>
                <IconButton size='small' onClick={onEditServiceItemTemplate} disabled={isProcessing}>
                  <EditIcon className={classes.actionIcon} />
                </IconButton>
              </Tooltip>
              <Tooltip title={'Delete'} placement='top'>
                <IconButton size='small' onClick={event => setOpenDialog(true)} disabled={isProcessing}>
                  <DeleteIcon className={classes.actionIcon} />
                </IconButton>
              </Tooltip>
            </Fragment>
          )}
        </BodyCell>
      </TableRow>
      <StandardConfirmationDialog
        variant={'danger'}
        titleMessage={'Delete'}
        message={'Are you sure want to delete this data?'}
        open={openDialog}
        handleClose={handleCloseDialog}
        onConfirm={deleteServiceItemTemplate}
      />
    </Fragment>
  );
};

export default BodyRow;
