import React, { FC, useState, Fragment, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TableFooter,
  Paper,
  TableContainer,
  makeStyles,
  createStyles,
  Theme
} from '@material-ui/core';

import TablePagination from 'components/TablePagination';
import HeaderRow from 'components/HeaderRow';
import BodyRow from './components/BodyRow';
import axios, { CancelTokenSource } from 'axios';
import { GET_UPDATE_STATUS_JOBS_URL } from 'constants/url';

interface Props {
  isLoadingData: boolean;
  count: number;
  currentPage: number;
  rowsPerPage: number;
  jobs: JobModel[];
  order: 'asc' | 'desc';
  orderBy: string;
  setOrder: React.Dispatch<React.SetStateAction<'asc' | 'desc'>>;
  setOrderBy: React.Dispatch<React.SetStateAction<string>>;
  setJobs: React.Dispatch<React.SetStateAction<JobModel[]>>;
  handleChangeRowsPerPage: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  handleChangePage: (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => void;
  handleSnackBar: (open: boolean, variant: 'success' | 'error', message: string) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paperWrapper: {
      overflow: 'hidden',
      width: '100%'
    },
    table: {
      width: '100%'
    }
  })
);

const defaultJob = {
  id: 0,
  collectionDate: new Date(),
  deliveryDate: new Date(),
  collectionTime: '',
  deliveryTime: '',
  jobStatus: '',
  clientSignature: '',
  Service: {
    id: 0,
    serviceNumber: '',
    description: '',
    time: '',
    CustomerId: 0,
    deliveryType: '',
    promoCode: '',
    typePromo: '',
    discountType: '',
    discountAmount: '',
    totalAmount: 0,
    ServiceAddress: {
      id: 0,
      postalCode: '',
      address: '',
      floorNo: '',
      unitNo: ''
    },
    CollectionAddress: {
      id: 0,
      postalCode: '',
      address: '',
      floorNo: '',
      unitNo: ''
    },
    Customer: {
      id: 0,
      firstName: '',
      lastName: '',
      contactNumber: '',
      contactPersonName: '',
      contactPersonEmail: '',
      contactPersonNumber: '',
      primaryCollectionAddress: '',
      primaryCollectionAddressDetail: '',
      primaryServiceAddress: '',
      primaryServiceAddressDetail: '',
      email: ''
    },
    ServiceItems: [
      {
        id: 0,
        serviceType: '',
        serviceItemName: '',
        unitPrice: 0,
        quantity: 0,
        quantityOriginal: 0,
        totalPrice: 0,
        itemType: '',
        unit: '',
        remarks: '',
        ServiceItemTemplate: {
          id: 0,
          name: '',
          Category: {
            id: 0,
            name: '',
            type: '',
            image: ''
          }
        }
      }
    ]
  },
  User: {
    id: 0,
    displayName: ''
  }
};

const JobTable: FC<Props> = props => {
  const classes = useStyles();

  const {
    isLoadingData,
    count,
    currentPage,
    rowsPerPage,
    jobs,
    order,
    orderBy,
    setJobs,
    handleChangePage,
    handleChangeRowsPerPage,
    handleSnackBar,
    setOrder,
    setOrderBy
  } = props;

  const [showSkeleton, setShowSkeleton] = useState<boolean>(false);

  useEffect(() => {
    if (isLoadingData) setShowSkeleton(true);
    return () => setShowSkeleton(false);
  }, [isLoadingData]);

  const handleJobStatus = async (index: number, jobStatus: string, id: number) => {
    const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();
    try {
      const { data } = await axios.put(GET_UPDATE_STATUS_JOBS_URL(id), { jobStatus }, { cancelToken: cancelTokenSource.token });
      setJobs(jobs.map((val, i) => (i === index ? { ...val, jobStatus: data.jobStatus } : val)));
      handleSnackBar(true, 'success', '');
    } catch (err) {
      console.log('err:', err);
      handleSnackBar(true, 'error', '');
    }
  };

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return (
    <Fragment>
      <TableContainer component={Paper}>
        <Table className={classes.table}>
          <TableHead>
            <HeaderRow
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              headers={[
                { label: 'Job ID', id: 'id', sort: true },
                { label: 'Delivery Address' },
                { label: 'Collection Time', id: 'collectionDate', sort: true },
                { label: 'Delivery Time', id: 'deliveryDate', sort: true },
                { label: 'Driver' },
                { label: 'Delivery Type' },
                { label: 'Job Status' },
                { label: 'Action', align: 'center' }
              ]}
            />
          </TableHead>
          <TableBody>
            {showSkeleton ? (
              [1, 2, 3, 4].map(index => (
                <BodyRow index={index} key={index} job={defaultJob} isLoadingData={isLoadingData} handleJobStatus={handleJobStatus} />
              ))
            ) : jobs && jobs.length > 0 ? (
              jobs.map((value, index) => (
                <BodyRow index={index} key={index} job={value} isLoadingData={isLoadingData} handleJobStatus={handleJobStatus} />
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={9} align='center'>
                  Data not available
                </TableCell>
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TablePagination
              rowsPerPageOptions={[5, 10, 15]}
              count={count}
              rowsPerPage={rowsPerPage}
              page={currentPage}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              size='small'
            />
          </TableFooter>
        </Table>
      </TableContainer>
    </Fragment>
  );
};

export default JobTable;
