import React, { FC, useState, useEffect } from 'react';
import { Table, TableBody, TableHead, Paper, makeStyles, createStyles, Theme } from '@material-ui/core';

import HeaderRow from 'components/HeaderRow';
import TablePagination from 'components/TablePagination';
import BodyRow from './components/BodyRow';

interface Props {
  isLoadingData: boolean;
  count: number;
  currentPage: number;
  rowsPerPage: number;
  invoices: InvoiceModel[];
  order: 'asc' | 'desc';
  orderBy: string;
  setOrder: React.Dispatch<React.SetStateAction<'asc' | 'desc'>>;
  setOrderBy: React.Dispatch<React.SetStateAction<string>>;
  setOpenSnackbar: React.Dispatch<React.SetStateAction<boolean>>;
  setSnackbarVarient: React.Dispatch<React.SetStateAction<'success' | 'error'>>;
  handleSetMessageSuccess: (message: string) => void;
  handleSetMessageError: (message: string) => void;
  handleChangePage: (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => void;
  updateIndividualInvoice: (updatedInvoiceProperties: Partial<InvoiceModel>, invoiceIndex?: number) => void;
  handleOpenCollectPayment: (invoiceIndex: number) => React.MouseEventHandler;
  handleOpenDetailInvoice: (invoiceIndex: number) => React.MouseEventHandler;
  handleChangeRowsPerPage: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  checked: number[];
  setChecked: React.Dispatch<React.SetStateAction<number[]>>;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableWrapper: {
      overflowX: 'auto',
      width: '100%'
    },
    paperWrapper: {
      overflow: 'hidden',
      width: '100%',
      overflowX: 'auto'
    },
    table: {
      width: '100%'
    }
  })
);

const dummyInvoice: InvoiceModel = {
  id: 0,
  invoiceNumber: '',
  totalPrice: 0,
  paymentStatus: '',
  paymentType: '',
  cardNumber: '',
  cardExpired: '',
  cardName: '',
  createdAt: ''
};

const InvoicesTable: FC<Props> = props => {
  const classes = useStyles();

  const {
    isLoadingData,
    count,
    currentPage,
    rowsPerPage,
    invoices,
    order,
    setOrder,
    orderBy,
    setOrderBy,
    setOpenSnackbar,
    setSnackbarVarient,
    handleSetMessageSuccess,
    handleSetMessageError,
    handleChangePage,
    handleChangeRowsPerPage,
    handleOpenDetailInvoice,
    handleOpenCollectPayment,
    updateIndividualInvoice,
    checked,
    setChecked
  } = props;

  // The below logic introduces a 500ms delay for showing the skeleton
  const [showSkeleton, setShowSkeleton] = useState<boolean>(false);

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const individualCheck = (id: number) => {
    const newChecked = [...checked];
    // count element in object selected filter for check already exist or not
    const countElement = newChecked.filter(newCheckedValue => newCheckedValue === id).length;
    if (countElement === 0) {
      newChecked.push(id);
    } else {
      // check index of element and remove object by index
      const checkedFilterIndex = newChecked.map(newCheckedValue => newCheckedValue).indexOf(id);
      newChecked.splice(checkedFilterIndex, 1);
    }
    setChecked(newChecked);
  };

  useEffect(() => {
    if (isLoadingData) {
      setShowSkeleton(true);
    }

    return () => {
      setShowSkeleton(false);
    };
  }, [isLoadingData]);

  return (
    <div className={classes.tableWrapper}>
      <Paper variant='outlined' elevation={2} className={classes.paperWrapper}>
        <Table size='small' className={classes.table}>
          <TableHead>
            <HeaderRow
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              headers={[
                { id: 'invoiceNumber', label: 'Invoice ID', sort: true },
                { label: 'Job ID' },
                { id: 'createdAt', label: 'Invoice Date', sort: true },
                { id: 'firstName', label: 'Customer Name', sort: true },
                { label: 'Driver' },
                { label: 'Amount' },
                { label: 'Payment Mode' },
                { label: 'Amount Collected' },
                { label: 'Action' }
              ]}
            />
          </TableHead>
          <TableBody>
            {showSkeleton
              ? [0, 1, 2].map(index => (
                  <BodyRow
                    currentInvoiceIndex={index}
                    index={index}
                    key={index}
                    invoice={dummyInvoice}
                    updateInvoice={updateIndividualInvoice}
                    setOpenSnackbar={setOpenSnackbar}
                    setSnackbarVarient={setSnackbarVarient}
                    handleSetMessageSuccess={handleSetMessageSuccess}
                    handleSetMessageError={handleSetMessageError}
                    handleIndividualCheck={individualCheck}
                    onCollectPayment={handleOpenCollectPayment(index)}
                    onDetailInvoice={handleOpenDetailInvoice(index)}
                    isLoadingData={isLoadingData}
                    checked={checked}
                  />
                ))
              : invoices.map((invoice, index) => (
                  <BodyRow
                    currentInvoiceIndex={index}
                    index={index}
                    key={index}
                    invoice={invoice}
                    updateInvoice={updateIndividualInvoice}
                    setOpenSnackbar={setOpenSnackbar}
                    setSnackbarVarient={setSnackbarVarient}
                    handleSetMessageSuccess={handleSetMessageSuccess}
                    handleSetMessageError={handleSetMessageError}
                    handleIndividualCheck={individualCheck}
                    onCollectPayment={handleOpenCollectPayment(index)}
                    onDetailInvoice={handleOpenDetailInvoice(index)}
                    isLoadingData={isLoadingData}
                    checked={checked}
                  />
                ))}
          </TableBody>
        </Table>

        <TablePagination
          rowsPerPageOptions={[5, 10, 15]}
          count={count}
          rowsPerPage={rowsPerPage}
          page={currentPage}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
};

export default InvoicesTable;
