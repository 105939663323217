import React, { Fragment, FC, useState, useEffect, useCallback } from 'react';
import {
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  createStyles,
  makeStyles,
  Button,
  Typography,
  Badge,
  ButtonBase,
  Tooltip,
  Avatar
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import axios, { CancelTokenSource } from 'axios';
import { GET_USER_BY_ROLE_URL } from 'constants/url';
import { format } from 'date-fns';
import aws from 'aws-sdk';
import { ucwords } from '../../../utils';
import StatusType from 'typings/enum/StatusType';
import { MuiPickersUtilsProvider, MaterialUiPickersDate, KeyboardDateTimePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import default_image from 'images/default_image.svg';

interface Props {
  job: JobModel;
  isEdit: boolean;
  isDriverError: boolean;
  driverMessage: string;
  isCollectionDateError: boolean;
  collectionDateMessage: string;
  isDeliveryDateError: boolean;
  deliveryDateMessage: string;
  setDriverError: React.Dispatch<React.SetStateAction<boolean>>;
  setDriverMessage: React.Dispatch<React.SetStateAction<string>>;
  setCollectionDateError: React.Dispatch<React.SetStateAction<boolean>>;
  setCollectionDateMessage: React.Dispatch<React.SetStateAction<string>>;
  setDeliveryDateError: React.Dispatch<React.SetStateAction<boolean>>;
  setDeliveryDateMessage: React.Dispatch<React.SetStateAction<string>>;
  setEdit: React.Dispatch<React.SetStateAction<boolean>>;
  setJob: React.Dispatch<React.SetStateAction<JobModel>>;
  setInBlockedDate: React.Dispatch<React.SetStateAction<boolean>>;
  setInPublicHoliday: React.Dispatch<React.SetStateAction<boolean>>;
  handleSnackBar: (open: boolean, variant: 'success' | 'error', message: string) => void;
  handleCancel: () => void;
  handleOnSubmit: () => void;
  handleExportPDF: () => void;
  handleBlockedDateValidation: (date: Date | null) => void;
  handlePublicHolidayValidation: (date: Date | null) => void;
}

const useStyles = makeStyles(() =>
  createStyles({
    textFieldActive: {
      color: '#000000',
      background: '#FFFFFF'
    },
    textFieldDisabled: {
      color: '#9e9e9e',
      background: '#F9F9FB'
    },
    label: {
      '& .MuiFormLabel-root': {
        color: '#000000'
      },
      '&$focusedLabel': {
        color: '#000000'
      },
      '&$disabledLabel': {
        color: '#000000'
      }
    },
    btnExport: {
      background: '#FFF',
      marginRight: 10
    },
    cover: {
      width: 130,
      margin: '0 auto'
    },
    signatureContainer: {
      border: `1px solid #BDBDBD`,
      borderRadius: 10,
      marginTop: '2px'
    },
    focusedLabel: {},
    disabledLabel: {}
  })
);

const ServiceDetail: FC<Props> = props => {
  const classes = useStyles();
  const {
    job,
    isEdit,
    setEdit,
    setJob,
    isDriverError,
    setDriverError,
    driverMessage,
    setDriverMessage,
    isCollectionDateError,
    collectionDateMessage,
    isDeliveryDateError,
    deliveryDateMessage,
    setCollectionDateError,
    setCollectionDateMessage,
    setDeliveryDateError,
    setDeliveryDateMessage,
    setInBlockedDate,
    setInPublicHoliday,
    handleCancel,
    handleOnSubmit,
    handleExportPDF,
    handleBlockedDateValidation,
    handlePublicHolidayValidation
  } = props;
  const { Service, User, jobStatus, collectionDate, deliveryDate, collectionTime, deliveryTime, clientSignature } = job;
  const { ServiceAddress, CollectionAddress, Customer, deliveryType } = Service!;

  const [driver, setDriver] = useState<{ id: number; displayName: string }[]>([{ id: 0, displayName: '' }]);
  const [open, setOpen] = useState<boolean>(false);
  const isLoading = open && driver.length === 0;
  const allStatus = Object.entries(StatusType).map(([keys, value]) => value);
  const [statusOption, setStatusOption] = useState<string[]>(allStatus);
  const [singatureImageView, setSignatureImageView] = useState<string>('');

  const [collectionThisAddress, setCollectionThisAddress] = useState<string>('');
  const [collectionFloorNo, setCollectionFloorNo] = useState<string>('');
  const [collectionUnitNo, setCollectionUnitNo] = useState<string>('');
  const [collectionPostalCode, setCollectionPostalCode] = useState<string>('');

  const [serviceThisAddress, setServiceThisAddress] = useState<string>('');
  const [serviceFloorNo, setServiceFloorNo] = useState<string>('');
  const [serviceUnitNo, setServiceUnitNo] = useState<string>('');
  const [servicePostalCode, setServicePostalCode] = useState<string>('');

  useEffect(() => {
    if (!CollectionAddress) {
      return;
    }

    if (CollectionAddress.address) {
      setCollectionThisAddress(CollectionAddress.address);
    }

    if (CollectionAddress.floorNo) {
      setCollectionFloorNo(CollectionAddress.floorNo);
    }

    if (CollectionAddress.unitNo) {
      setCollectionUnitNo(CollectionAddress.unitNo);
    }

    if (CollectionAddress.postalCode) {
      setCollectionPostalCode(CollectionAddress.postalCode);
    }
  }, [CollectionAddress]);

  useEffect(() => {
    if (!ServiceAddress) {
      return;
    }

    if (ServiceAddress.address) {
      setServiceThisAddress(ServiceAddress.address);
    }

    if (ServiceAddress.floorNo) {
      setServiceFloorNo(ServiceAddress.floorNo);
    }

    if (ServiceAddress.unitNo) {
      setServiceUnitNo(ServiceAddress.unitNo);
    }

    if (ServiceAddress.postalCode) {
      setServicePostalCode(ServiceAddress.postalCode);
    }
  }, [ServiceAddress]);

  const handleGetImageS3 = useCallback(async () => {
    let imageUrl = '';
    const { REACT_APP_AWS_REGION, REACT_APP_AWS_ACCESS_KEY, REACT_APP_AWS_SECRET_KEY, REACT_APP_S3_BUCKET_NAME } = process.env;

    try {
      aws.config.update({
        accessKeyId: REACT_APP_AWS_ACCESS_KEY,
        secretAccessKey: REACT_APP_AWS_SECRET_KEY,
        region: REACT_APP_AWS_REGION
      });
      const s3 = new aws.S3();
      const signedUrlExpireSeconds = 60 * 5;
      const options = {
        Bucket: REACT_APP_S3_BUCKET_NAME,
        Key: `assets/clientSignatures/${clientSignature}`,
        Expires: signedUrlExpireSeconds
      };

      imageUrl = await new Promise((resolve, reject) => {
        s3.getSignedUrl('getObject', options, (err, url) => {
          err ? reject(err) : resolve(url);
        });
      });
      setSignatureImageView(imageUrl);
    } catch (err) {
      console.error('err :', err);
      throw err;
    }
  }, [clientSignature]);

  useEffect(() => {
    if (!clientSignature) {
      return;
    }

    handleGetImageS3();
  }, [clientSignature, handleGetImageS3]);

  const handleSearchDriver = async (value: string) => {
    const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();
    try {
      const params = new URLSearchParams();
      params.append('q', value);
      params.append('role', 'DRIVER');
      const response = await axios.get(`${GET_USER_BY_ROLE_URL}?${params}`, { cancelToken: cancelTokenSource.token });
      setDriver(response.data.users);
      setDriverError(false);
      setDriverMessage('');
    } catch (err) {
      console.log('err : ', err);
    }
  };

  const handleSetDriver = (value: { id: number; displayName: string }) => {
    let newJob = { ...job, UserId: value ? value.id : null, User: value };
    if (job.jobStatus === 'UNASSIGNED') {
      newJob = { ...newJob, jobStatus: 'ASSIGNED_FOR_COLLECTION' };
    }

    setJob(newJob);
  };

  const handleCollectionDate = (date: MaterialUiPickersDate | null, value?: string | null) => {
    let setDate = null;
    let setTime = '';

    if (date) {
      const isBlockDate = date ? handleBlockedDateValidation(date) : false;
      const isPublicHoliday = date ? handlePublicHolidayValidation(date) : false;

      setCollectionDateError(false);
      setDeliveryDateError(false);
      setCollectionDateMessage('');
      setDeliveryDateMessage('');

      if (isBlockDate) {
        setInBlockedDate(true);
      }

      if (isPublicHoliday) {
        setInPublicHoliday(true);
      }

      setDate = date;
      setTime = format(date!, 'HH:mm:00');

      if (deliveryDate) {
        const deliveryDateTime = new Date(`${format(new Date(deliveryDate), 'yyyy-MM-dd')} ${deliveryTime}`);
        if (date > deliveryDateTime) {
          setCollectionDateError(true);
          setCollectionDateMessage('Collection date can not earlier than delivery date.');
        }
      }
    }

    setJob({ ...job, collectionDate: setDate, collectionTime: setTime });
  };

  const handleDeliveryDate = (date: MaterialUiPickersDate | null, value?: string | null) => {
    let setDate = null;
    let setTime = '';

    if (date) {
      setInPublicHoliday(false);
      setInBlockedDate(false);
      setCollectionDateError(false);
      setDeliveryDateError(false);
      setCollectionDateMessage('');
      setDeliveryDateMessage('');

      if (!collectionDate) {
        setCollectionDateError(true);
        setCollectionDateMessage('Collection date can not be empty.');
      } else {
        const isBlockDate = date ? handleBlockedDateValidation(date) : false;
        const isPublicHoliday = date ? handlePublicHolidayValidation(date) : false;
        const collectionDateTime = new Date(`${format(new Date(collectionDate), 'yyyy-MM-dd')} ${collectionTime}`);

        if (isBlockDate) {
          setInBlockedDate(true);
        }

        if (isPublicHoliday) {
          setInPublicHoliday(true);
        }

        setDate = date;
        setTime = format(date!, 'HH:mm:00');

        if (date < collectionDateTime) {
          setDeliveryDateError(true);
          setDeliveryDateMessage('Delivery date can not earlier than collection date.');
        }
      }
    } else if (jobStatus === 'DELIVERING' || jobStatus === 'DELIVERED') {
      setDeliveryDateError(true);
      setDeliveryDateMessage('Delivery date can not be empty in delivering job.');
    }

    setJob({ ...job, deliveryDate: setDate, deliveryTime: setTime });
  };

  const handleJobStatusChange = (value: string) => {
    setJob({ ...job, jobStatus: value });
    setDeliveryDateError(false);
    setDeliveryDateMessage('');
  };

  return (
    <Fragment>
      <Grid container item direction='row' spacing={1} lg={6} md={6} sm={6} xs={6}>
        <Grid item lg={6} md={6} sm={6} xs={6}>
          <TextField
            id='customerName'
            fullWidth
            variant='outlined'
            margin='dense'
            label='Costumer Name'
            disabled={true}
            value={Customer!.firstName + ' ' + Customer!.lastName}
            InputProps={{
              classes: {
                disabled: classes.textFieldDisabled
              }
            }}
            InputLabelProps={{
              classes: {
                root: classes.label,
                disabled: classes.focusedLabel,
                focused: classes.disabledLabel
              }
            }}
          />
        </Grid>

        <Grid item lg={6} md={6} sm={6} xs={6}>
          <TextField
            id='deliveryType'
            fullWidth
            variant='outlined'
            margin='dense'
            label='Delivery Type'
            disabled={true}
            value={deliveryType}
            InputProps={{
              classes: {
                disabled: classes.textFieldDisabled
              }
            }}
            InputLabelProps={{
              classes: {
                root: classes.label,
                disabled: classes.focusedLabel,
                focused: classes.disabledLabel
              }
            }}
          />
        </Grid>

        <Grid item lg={12} md={12} sm={12} xs={12}>
          <TextField
            id='collectionAddress'
            multiline
            fullWidth
            rows={3}
            variant='outlined'
            margin='dense'
            label='Collection Address'
            disabled
            value={`${collectionThisAddress} ${collectionFloorNo} ${collectionUnitNo} ${collectionPostalCode}`}
            InputProps={{
              classes: {
                disabled: classes.textFieldDisabled
              }
            }}
            InputLabelProps={{
              classes: {
                root: classes.label,
                disabled: classes.focusedLabel,
                focused: classes.disabledLabel
              }
            }}
          />
        </Grid>

        <Grid item lg={12} md={12} sm={12} xs={12}>
          <TextField
            id='serviceAddress'
            multiline
            fullWidth
            rows={3}
            variant='outlined'
            margin='dense'
            label='Delivery Address'
            disabled
            value={`${serviceThisAddress} ${serviceFloorNo} ${serviceUnitNo} ${servicePostalCode}`}
            InputProps={{
              classes: {
                disabled: classes.textFieldDisabled
              }
            }}
            InputLabelProps={{
              classes: {
                root: classes.label,
                disabled: classes.focusedLabel,
                focused: classes.disabledLabel
              }
            }}
          />
        </Grid>
      </Grid>

      <Grid container item direction='row' spacing={1} lg={6} md={6} sm={6} xs={6}>
        <Grid item lg={6} md={6} sm={6} xs={6}>
          <Autocomplete
            id='Driver'
            value={User}
            options={driver}
            getOptionLabel={option => option.displayName}
            getOptionSelected={(option: any, value: any) => option.id === value.id}
            disabled={!isEdit}
            loading={isLoading}
            onChange={(event: any, value: any) => handleSetDriver(value)}
            onOpen={() => {
              handleSearchDriver('');
              setOpen(!open);
            }}
            renderInput={params => (
              <TextField
                {...params}
                label='Driver'
                margin='dense'
                onChange={event => handleSearchDriver(event.target.value)}
                variant='outlined'
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <Fragment>
                      {isLoading ? <CircularProgress color='inherit' size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </Fragment>
                  ),
                  classes: {
                    root: classes.textFieldActive,
                    disabled: classes.textFieldActive
                  }
                }}
                InputLabelProps={{
                  classes: {
                    root: classes.label,
                    disabled: classes.focusedLabel,
                    focused: classes.disabledLabel
                  }
                }}
                error={isDriverError}
                helperText={driverMessage}
              />
            )}
          />
        </Grid>

        <Grid item lg={6} md={6} sm={6} xs={6}>
          <FormControl fullWidth variant='outlined' margin='dense'>
            <InputLabel id='jobStatus' className={classes.textFieldActive}>
              Job Status
            </InputLabel>
            <Select
              labelId='jobStatus'
              disabled={jobStatus === StatusType.CANCELLED || !isEdit}
              id='jobStatus'
              label='Job Status'
              value={jobStatus as string}
              onChange={event => handleJobStatusChange(event.target.value as string)}
              className={classes.textFieldActive}
              classes={{
                disabled: classes.textFieldActive
              }}
            >
              {statusOption.map((val, idx) => (
                <MenuItem key={idx + 1} value={val}>
                  {ucwords(val).toUpperCase()}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item lg={6} md={6} sm={6} xs={6}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDateTimePicker
              autoOk
              ampm={false}
              views={['date', 'hours']}
              minutesStep={60}
              label='Collection Date'
              value={collectionDate && collectionTime ? format(new Date(collectionDate), 'yyyy-MM-dd') + ' ' + collectionTime : null}
              onChange={handleCollectionDate}
              format='dd/MM/yyyy HH:00'
              inputVariant='outlined'
              disabled={!isEdit}
              margin='dense'
              disablePast={true}
              fullWidth
              InputProps={{
                classes: {
                  root: classes.textFieldActive,
                  disabled: classes.textFieldActive
                }
              }}
              InputLabelProps={{
                classes: {
                  root: classes.label,
                  disabled: classes.focusedLabel,
                  focused: classes.disabledLabel
                }
              }}
              error={isCollectionDateError}
              helperText={collectionDateMessage}
            />
          </MuiPickersUtilsProvider>
        </Grid>

        <Grid item lg={6} md={6} sm={6} xs={6}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDateTimePicker
              autoOk
              ampm={false}
              views={['date', 'hours']}
              minutesStep={60}
              label='Delivery Date'
              value={deliveryDate && deliveryTime ? format(new Date(deliveryDate), 'yyyy-MM-dd') + ' ' + deliveryTime : null}
              onChange={handleDeliveryDate}
              format='dd/MM/yyyy HH:00'
              inputVariant='outlined'
              disabled={!isEdit}
              margin='dense'
              disablePast={true}
              fullWidth
              InputProps={{
                classes: {
                  root: classes.textFieldActive,
                  disabled: classes.textFieldActive
                }
              }}
              InputLabelProps={{
                classes: {
                  root: classes.label,
                  disabled: classes.disabledLabel,
                  focused: classes.disabledLabel
                }
              }}
              error={isDeliveryDateError}
              helperText={deliveryDateMessage}
            />
          </MuiPickersUtilsProvider>
        </Grid>

        <Grid item container xs={12} sm={12} md={6} lg={6}>
          <Grid item container xs={12} sm={12} md={12} lg={12}>
            <Typography variant='caption'>Client Signature</Typography>
          </Grid>
          <Grid item container xs={12} sm={12} md={12} lg={12} className={classes.signatureContainer} alignItems='center'>
            <img alt='' src={clientSignature ? clientSignature : default_image} className={classes.cover} />
          </Grid>
        </Grid>
        <Grid item container justify='flex-end' xs={12} sm={12} md={6} lg={6} alignItems='flex-start'>
          <Grid item container justify='flex-end' xs={12} sm={12} md={12} lg={12} alignItems='flex-start'>
            <Button className={classes.btnExport} variant='contained' size='small' onClick={() => (isEdit ? handleCancel() : handleExportPDF())}>
              {isEdit ? 'Cancel' : ' Export Data'}
            </Button>
            {isEdit ? (
              <Button
                color='primary'
                variant='contained'
                size='small'
                disabled={isLoading || isDriverError || isCollectionDateError || isDeliveryDateError}
                onClick={() => handleOnSubmit()}
              >
                Save Changes
              </Button>
            ) : (
              <Button
                color='primary'
                variant='contained'
                size='small'
                disabled={jobStatus === StatusType.DELIVERED || jobStatus === StatusType.CANCELLED}
                onClick={() => setEdit(!isEdit)}
              >
                Edit Data
              </Button>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default ServiceDetail;
