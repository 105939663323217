import React, { FC, useState, useEffect } from 'react';
import { createStyles, makeStyles, Table, TableBody, TableHead, TableFooter, TableRow, TablePagination, Paper } from '@material-ui/core';
import HeaderRow from 'components/HeaderRow';
import BodyRow from './components/BodyRow';

interface Props {
  isLoadingData: boolean;
  logs: AppLogModel[];
  count: number;
  currentPage: number;
  rowsPerPage: number;
  handleChangePage: (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => void;
  handleChangeRowsPerPage: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
}

const useStyles = makeStyles(() =>
  createStyles({
    tableWrapper: {
      overflowX: 'auto'
    }
  })
);

const AppLogsTabel: FC<Props> = props => {
  const classes = useStyles();

  const { isLoadingData, logs, count, currentPage, rowsPerPage, handleChangeRowsPerPage, handleChangePage } = props;

  const dummyLog: AppLogModel = {
    id: 0,
    description: '',
    createdAt: new Date()
  };

  // The below logic introduces a 500ms delay for showing the skeleton
  const [showSkeleton, setShowSkeleton] = useState<boolean>(false);
  useEffect(() => {
    let timeout: NodeJS.Timeout;

    if (isLoadingData) {
      timeout = setTimeout(() => {
        setShowSkeleton(true);
      }, 500);
    }

    setShowSkeleton(false);

    return () => {
      clearTimeout(timeout);
    };
  }, [isLoadingData]);

  return (
    <div className={classes.tableWrapper}>
      <Paper variant='outlined' elevation={2}>
        <Table>
          <TableHead>
            <HeaderRow
              headers={[
                { label: 'User', verticalAlign: 'top' },
                { label: 'Action', verticalAlign: 'top' },
                { label: 'Date & Time', verticalAlign: 'top' }
              ]}
            />
          </TableHead>
          <TableBody>
            {showSkeleton
              ? [1, 2, 3, 4, 5].map(index => <BodyRow key={index} appLog={dummyLog} isLoadingData={showSkeleton} />)
              : logs.map((log, index) => <BodyRow key={log.id} appLog={log} isLoadingData={showSkeleton} />)}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 15]}
                colSpan={5}
                count={count}
                rowsPerPage={rowsPerPage}
                page={currentPage}
                SelectProps={{
                  inputProps: { 'aria-label': 'Rows per page' },
                  native: true
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </Paper>
    </div>
  );
};

export default AppLogsTabel;
