import React, { useState } from 'react';
import NumberFormatCustom from 'components/NumberFormatCustom';
import { Grid, TextField, MenuItem, Button, Theme, InputAdornment, IconButton, Typography, CircularProgress } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import AlternateEmailOutlinedIcon from '@material-ui/icons/AlternateEmailOutlined';
import PhoneInTalkIcon from '@material-ui/icons/PhoneInTalk';
import { makeStyles } from '@material-ui/styles';
import { isValidEmail } from 'utils';
import RandomPassword from '../../../../../../../typings/RandomPassword';

interface Props {
  okLabel: string;
  displayName: string;
  email: string;
  contactNumber: string;
  role: string;
  displayNameError: string;
  emailError: string;
  contactNumberError: string;
  passwordError: string;
  isSubmitting: boolean;
  password: string;
  isEdit: boolean;
  isShowPassword: boolean;
  isLoadingEmail: boolean;
  isLoadingContact: boolean;
  handleCheckUser: (value: string, attribute: string) => void;
  setDisplayName: React.Dispatch<React.SetStateAction<string>>;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
  setContactNumber: React.Dispatch<React.SetStateAction<string>>;
  setContactNumberError: React.Dispatch<React.SetStateAction<string>>;
  setEmailError: React.Dispatch<React.SetStateAction<string>>;
  setPasswordError: React.Dispatch<React.SetStateAction<string>>;
  setDisplayNameError: React.Dispatch<React.SetStateAction<string>>;
  setRole: React.Dispatch<React.SetStateAction<string>>;
  setPassword: React.Dispatch<React.SetStateAction<string>>;
  setShowPassword: React.Dispatch<React.SetStateAction<boolean>>;
  setLoadingEmail: React.Dispatch<React.SetStateAction<boolean>>;
  setLoadingContact: React.Dispatch<React.SetStateAction<boolean>>;
  onSubmit: React.FormEventHandler;
  onCancel: React.MouseEventHandler;
  handleEmailValidation: (email: string) => void;
  handleContactNumberValidation: (contactNumber: string) => void;
  handlePasswordValidation: (password: string) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  controlDiv: {
    '& > :nth-child(n+2)': {
      marginLeft: theme.spacing(2)
    }
  },
  cancelButton: {
    marginRight: theme.spacing(1)
  },
  textFieldFont: {
    fontSize: '12px'
  },
  itemFont: {
    fontSize: '12px'
  },
  content: {
    paddingLeft: 20,
    paddingRight: 20
  },
  showLoading: {
    display: 'block',
    fontStyle: 'italic',
    color: '#888686'
  },
  showNone: {
    display: 'none'
  }
}));

const CreateEditUserForm: React.FC<Props> = props => {
  const classes = useStyles();

  const { displayName, setDisplayName, displayNameError } = props;
  const { email, setEmail, emailError } = props;
  const { password, setPassword, isShowPassword, setShowPassword, passwordError } = props;

  const {
    okLabel,
    contactNumber,
    contactNumberError,
    setContactNumber,
    role,
    setRole,
    isEdit,
    isSubmitting,
    onSubmit,
    onCancel,
    setEmailError,
    setContactNumberError,
    setDisplayNameError,
    setPasswordError,
    isLoadingEmail,
    isLoadingContact,
    handleEmailValidation,
    handleContactNumberValidation,
    handlePasswordValidation
  } = props;

  const generatePassword = () => {
    let pwd = new RandomPassword()
      .setLength(8)
      .setLowerCase(true)
      .setUpperCase(true)
      .setNumberCase(true)
      .setSymbol(true)
      .generate();

    setPassword(pwd);
    handlePasswordValidation(pwd);
  };

  const handleEmailChange = (value: string) => {
    value = value.toLowerCase();
    setEmail(value);
  };

  const handleEmailBlur = () => {
    handleEmailValidation(email);
  }

  const handleContactNumberChange = (value: string) => {
    value = value.replace(' ', '');
    setContactNumber(value);
  };

  const handleContactNumberBlur = () => {
    handleContactNumberValidation(contactNumber);
  };

  const handleDisplayNameChange = (value: string) => {
    setDisplayName(value);
    if (value) {
      setDisplayNameError('');
    } else {
      setDisplayNameError('User Name can not be empty.');
    }
  };

  const handlePasswordChange = (value: string) => {
    setPassword(value);
    handlePasswordValidation(value);
  };

  const handleSetRole = (event: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setRole(event.target.value);
  };

  return (
    <form noValidate onSubmit={onSubmit}>
      <Grid container spacing={1} className={classes.content}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <TextField
            variant='outlined'
            margin='dense'
            label='Email '
            required
            fullWidth
            id='email'
            disabled={isEdit}
            error={emailError !== ''}
            helperText={emailError}
            value={email}
            onChange={event => handleEmailChange(event.target.value)}
            onBlur={handleEmailBlur}
            autoComplete='off'
            autoCapitalize='none'
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  {isLoadingEmail ? <CircularProgress size={20} /> : <AlternateEmailOutlinedIcon color='primary' fontSize='small' />}
                </InputAdornment>
              )
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <TextField
            variant='outlined'
            required
            margin='dense'
            fullWidth
            id='contactNumber'
            label='Contact Number'
            value={contactNumber}
            onChange={event => handleContactNumberChange(event.target.value)}
            onBlur={handleContactNumberBlur}
            onKeyDown={event => {
              if (event.keyCode === 190 || event.keyCode === 110) event.preventDefault();
            }}
            autoComplete='off'
            InputProps={{
              inputComponent: NumberFormatCustom as any,
              endAdornment: (
                <InputAdornment position='end'>
                  {isLoadingContact ? <CircularProgress size={20} /> : <PhoneInTalkIcon color='primary' fontSize='small' />}
                </InputAdornment>
              )
            }}
            error={contactNumberError !== ''}
            helperText={contactNumberError}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <TextField
            variant='outlined'
            margin='dense'
            required
            fullWidth
            id='name'
            label='Name'
            error={displayNameError !== ''}
            helperText={displayNameError}
            value={displayName}
            onChange={event => handleDisplayNameChange(event.target.value)}
            autoComplete='off'
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <TextField
            variant='outlined'
            select
            margin='dense'
            required
            fullWidth
            id='role'
            label='User Role'
            value={role}
            onChange={handleSetRole}
            autoComplete='off'
          >
            <MenuItem key={0} value={'ADMIN'} className={classes.itemFont}>
              Admin
            </MenuItem>
            <MenuItem key={1} value={'MANAGER'} className={classes.itemFont}>
              Manager
            </MenuItem>
            <MenuItem key={1} value={'DRIVER'} className={classes.itemFont}>
              Driver
            </MenuItem>
          </TextField>
        </Grid>
        {isEdit ? (
          ''
        ) : (
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <TextField
              variant='outlined'
              margin='dense'
              required
              fullWidth
              name='password'
              label='Password'
              type={isShowPassword ? 'text' : 'password'}
              id='password'
              autoComplete='current-password'
              onChange={event => handlePasswordChange(event.target.value)}
              value={password}
              error={passwordError !== ''}
              helperText={passwordError}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton edge='end' aria-label='toggle password visibility' onClick={event => setShowPassword(!isShowPassword)}>
                      {isShowPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
            <Button onClick={generatePassword}>Generate Password</Button>
          </Grid>
        )}
        <Grid container item justify='flex-end' xs={12} sm={12} md={12} lg={12} xl={12} className={classes.controlDiv}>
          <Typography variant='h6' className={isSubmitting ? classes.showLoading : classes.showNone}>
            Loading....
          </Typography>
          <Button variant='contained' className={classes.cancelButton} onClick={onCancel} disabled={isSubmitting}>
            Cancel
          </Button>
          <Button color='primary' size='medium' variant='contained' onClick={onSubmit} disabled={isSubmitting}>
            {okLabel}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default CreateEditUserForm;
