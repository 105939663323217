import React, { FC, Fragment } from 'react';
import { createStyles, makeStyles, Theme, Grid, Badge, TextField, Button, ButtonBase, Tooltip, Avatar } from '@material-ui/core';

import UploadIcon from '@material-ui/icons/CloudUpload';
import CancelImageIcon from '@material-ui/icons/Close';

interface Error {
  note: boolean;
  noteMessage: string;
}

interface Props {
  note: string;
  image: string;
  imageView: string;
  error: Error;
  isSubmitting: boolean;
  openEditForm: boolean;
  setError: React.Dispatch<React.SetStateAction<Error>>;
  setNote: React.Dispatch<React.SetStateAction<string>>;
  setImage: React.Dispatch<React.SetStateAction<string>>;
  setImageView: React.Dispatch<React.SetStateAction<string>>;
  setOpenEditForm: React.Dispatch<React.SetStateAction<boolean>>;
  onSubmit: React.MouseEventHandler;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      display: 'flex',
      marginBottom: theme.spacing(2)
    },
    details: {
      display: 'flex',
      flexDirection: 'column'
    },
    content: {
      flex: '1 0 auto'
    },
    cover: {
      minWidth: 200,
      minHeight: 200
    },
    inputArea: {
      width: '100%'
    },
    bigAvatar: {
      minWidth: 160,
      minHeight: 100,
      color: `#C4C4C4`,
      backgroundColor: '#FFFFFF',
      borderRadius: '10px',
      border: `1px solid #C4C4C4`
    },
    spacingNewLine: {
      marginBottom: theme.spacing(1.5)
    },
    button: {
      marginRight: theme.spacing(1)
    },
    buttonBaseStyle: {
      position: 'relative',
      [theme.breakpoints.down('xs')]: {
        width: '100% !important'
      }
    },
    actionEdit: {
      marginBottom: theme.spacing(1.5)
    },
    inputImageStyle: {
      display: 'none'
    },
    cancelImage: {
      fontSize: '12px',
      cursor: 'pointer'
    }
  })
);

const JobNotesForm: FC<Props> = props => {
  const classes = useStyles();

  const { error, setError, note, setNote, image, setImage, imageView, setImageView, isSubmitting, openEditForm, setOpenEditForm, onSubmit } = props;

  const handleChooseImage = (event: any) => {
    let selectedImage;
    let selectedImageView;
    if (event.target.files[0] === undefined) {
      selectedImage = '';
      selectedImageView = '';
    } else {
      selectedImage = event.target.files[0];
      selectedImageView = URL.createObjectURL(event.target.files[0]);
    }

    setImage(selectedImage);
    setImageView(selectedImageView);
  };

  const cancelImage = () => {
    setImage('');
    setImageView('');
  };

  const renderImageComponent = () => {
    if (imageView === '') {
      return (
        <Fragment>
          <input
            accept='image/*'
            className={classes.inputImageStyle}
            id='outlined-button-file'
            type='file'
            onChange={event => handleChooseImage(event)}
          />
          <label htmlFor='outlined-button-file'>
            <ButtonBase focusRipple key={'Upload'} component='span' disableRipple>
              <Tooltip title='Choose an image' placement='top'>
                <Avatar className={classes.bigAvatar}>
                  <UploadIcon fontSize='large' />
                </Avatar>
              </Tooltip>
            </ButtonBase>
          </label>
        </Fragment>
      );
    } else {
      return (
        <Badge badgeContent={<CancelImageIcon className={classes.cancelImage} onClick={event => cancelImage()} />} color='primary'>
          <input
            accept='image/*'
            className={classes.inputImageStyle}
            id='outlined-button-file'
            type='file'
            onChange={event => handleChooseImage(event)}
          />
          <label htmlFor='outlined-button-file'>
            <ButtonBase focusRipple key={'Upload'} className={classes.buttonBaseStyle} component='span' disableRipple>
              <Tooltip title='Choose an image' placement='top'>
                <Avatar alt='Image' src={imageView} className={classes.bigAvatar} />
              </Tooltip>
            </ButtonBase>
          </label>
        </Badge>
      );
    }
  };

  return (
    <Fragment>
      <Grid container direction='row' justify='space-between' className={classes.cover}>
        <Grid container item lg={2} sm={2} md={2} justify='flex-start' alignItems='center'>
          <Grid item>{renderImageComponent()}</Grid>
        </Grid>
        <Grid container item lg={10} sm={10} md={10} justify='flex-start' alignItems='center'>
          <Grid item className={classes.inputArea}>
            <TextField
              required
              multiline
              rows={4}
              label='Note'
              variant='outlined'
              className={classes.inputArea}
              value={note}
              error={error.note}
              helperText={error.noteMessage}
              onFocus={() => setError({ ...error, note: false, noteMessage: '' })}
              onChange={event => setNote(event.target.value)}
            />
          </Grid>
        </Grid>
        {openEditForm ? (
          <Grid item container justify='flex-end' xs={12} sm={12} md={12} lg={12} alignItems='center'>
            <Button size='small' className={classes.button} disabled={isSubmitting} onClick={event => setOpenEditForm(!openEditForm)}>
              Cancel
            </Button>

            <Button size='small' color='primary' variant='contained' onClick={onSubmit}>
              Save
            </Button>
          </Grid>
        ) : (
          ''
        )}
      </Grid>
    </Fragment>
  );
};

export default JobNotesForm;
