import React, { FC } from 'react';
import { TableRow, makeStyles, Theme } from '@material-ui/core';
import { grey, green, red } from '@material-ui/core/colors';
import Skeleton from 'react-loading-skeleton';
import BodyCell from 'components/BodyCell';
import { format } from 'date-fns';

interface Props {
  isLoadingData: boolean;
  appLog: AppLogModel;
}

const useStyles = makeStyles((theme: Theme) => ({
  tableRow: {
    height: 64
  },
  tableCellInner: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    display: 'inline-flex',
    fontSize: '14px',
    fontWeight: 500,
    height: '36px',
    width: '36px'
  },
  nameTextCell: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2)
  },
  roleText: {
    color: grey[500]
  },
  circle: {
    height: theme.spacing(3),
    width: theme.spacing(3),
    borderRadius: '50%',
    display: 'inline-block',
    backgroundColor: green[500],
    marginRight: theme.spacing(1),
    content: "''"
  },
  redCircle: {
    backgroundColor: red[500]
  },
  actionCell: {
    '& > :nth-child(n+2)': {
      marginLeft: theme.spacing(1)
    }
  },
  wrapper: {
    position: 'relative'
  },
  fabProgress: {
    color: green[500],
    position: 'absolute',
    zIndex: 1,
    top: -4,
    left: -4
  }
}));

const BodyRow: FC<Props> = props => {
  const classes = useStyles();
  const { isLoadingData, appLog } = props;
  const { description, createdAt, User } = appLog;

  return (
    <TableRow className={classes.tableRow}>
      <BodyCell>{(User && User.displayName) || <Skeleton width={100} height={25} />}</BodyCell>
      <BodyCell>{description || <Skeleton width={100} height={25} />}</BodyCell>

      <BodyCell cellWidth={'15%'} pL='10px' pR='10px'>
        {isLoadingData ? (
          <Skeleton width={80} />
        ) : (
          `${format(new Date(createdAt === undefined ? new Date() : createdAt), 'dd/MM/yyyy | HH:mm').toString()}`
        )}
      </BodyCell>
    </TableRow>
  );
};

export default BodyRow;
