import React from 'react';
import { makeStyles, Theme, Button, Modal, Grid } from '@material-ui/core';
import default_image from 'images/default_image.svg';

interface Props {
  open: boolean;
  imageUrl: string;
  handleClose(): void;
};

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    position: 'absolute',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    outline: 'none',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: 4
  },
  controlImage: {
    display: 'contents'
  },
  cover: {
    maxHeight: 400,
    margin: '0 auto',
    border: '2px solid #ECECEE',
    borderRadius: '8px'
  },
  controlDiv: {
    '& > :nth-child(n+2)': {
      marginLeft: theme.spacing(2)
    }
  }
}));

const JobNoteDetailImage: React.FC<Props> = props => {
  const classes = useStyles();

  const { open, imageUrl, handleClose } = props;

  return (
    <Modal aria-labelledby='modal-title' open={open} disableBackdropClick={true}>
      <Grid container item xs={10} sm={10} md={8} lg={8} xl={8} spacing={2} direction='column' className={classes.paper}>
        <Grid item lg={12} md={12} sm={12} xs={12} alignItems='center' justify='center' className={classes.controlImage}>
          <img alt='' src={imageUrl ? imageUrl : default_image} className={classes.cover} />
        </Grid>

        <Grid container item justify='flex-end' xs={12} sm={12} md={12} lg={12} xl={12} className={classes.controlDiv}>
          <Button variant='contained' onClick={handleClose}>
            Close
          </Button>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default JobNoteDetailImage;