import React, { FC, useState, useContext } from 'react';
import clsx from 'clsx';
import { Container, makeStyles, Theme, Grid, Typography } from '@material-ui/core';

import CustomizedTabs from 'components/CustomizedTabs';
import { CurrentJobTabContext } from 'contexts/CurrentJobTabContext';
import UsersPage from './pages/UsersPage';
import ServiceItemTemplatePage from './pages/ServiceItemTemplatePage';
import BlockedDate from './pages/BlockedDate';
import Promotion from './pages/Promotion';
import AppLogsPage from './pages/AppLogsPage';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4)
  },
  container: {
    '& > :nth-child(n+2)': {
      marginTop: theme.spacing(2)
    }
  },
  divider: {
    marginBottom: theme.spacing(4)
  },
  paper: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(2),
    margin: 'auto'
  },
  subMenuGrid: {
    borderRight: '1px solid #dcdcdc',
    maxWidth: theme.spacing(15)
  },
  content: {
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(0.2)
  },
  headerSubMenuTitleContainer: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(0)
  },
  headerPageTitleContainer: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(2)
  },
  contentContainer: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(0),
    paddingBottom: theme.spacing(2)
  }
}));

const SettingsPage: FC = () => {
  const classes = useStyles();
  const { setCurrentJobTab } = useContext(CurrentJobTabContext);
  setCurrentJobTab(1);

  const [selectedTab, setSelectedTab] = useState<number>(0);

  const performActionAndRevertPage = (action: React.Dispatch<React.SetStateAction<any>>, actionParam: any) => {
    action(actionParam);
  };

  const SelectedPage: FC<{ page: number }> = props => {
    switch (props.page) {
      case 0:
        return <ServiceItemTemplatePage />;
      case 1:
        return <BlockedDate />;
      case 2:
        return <Promotion />;
      case 3:
        return <UsersPage />;
      case 4:
        return <AppLogsPage />;
      default:
        return <></>;
    }
  };

  return (
    <Container maxWidth='lg' className={clsx(classes.root, classes.container)}>
      <Grid container direction='row' justify='space-between'>
        <Grid container direction='row' item xs={8} sm={8} md={8} lg={8} alignItems='center' spacing={1}>
          <Grid item xs={12} sm={12} md={12} lg={12}></Grid>
        </Grid>
      </Grid>
      <CustomizedTabs
        tabs={[
          { id: 0, name: 'Services' },
          { id: 1, name: 'Blocked Date' },
          { id: 2, name: 'Promotions' },
          { id: 3, name: 'Accounts' },
          { id: 4, name: 'App Logs' }
        ]}
        selectedTabId={selectedTab}
        onSelect={(tabId: number) => performActionAndRevertPage(setSelectedTab, tabId)}
      />
      <SelectedPage page={selectedTab} />
    </Container>
  );
};

export default SettingsPage;
