import React, { FC, useState, Fragment } from 'react';
import {
  TableRow,
  TableCell,
  makeStyles,
  Theme,
  Typography,
  IconButton,
  Tooltip,
  SwitchClassKey,
  FormControlLabel,
  Checkbox
} from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import EditIcon from '@material-ui/icons/EditOutlined';
import NewIcon from '@material-ui/icons/FiberNew';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import Skeleton from 'react-loading-skeleton';
import axios from 'axios';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { AntSwitch } from 'components/CustomSwitch';

import { GET_DEACTIVATE_USER_URL, GET_ACTIVATE_USER_URL } from 'constants/url';

interface Styles extends Partial<Record<SwitchClassKey, string>> {
  focusVisible?: string;
}

interface Props {
  currentUserIndex: number;
  isLoadingData: boolean;
  user: UserDetailsModel;
  updateUser: (updatedUserProperties: Partial<UserDetailsModel>, userIndex?: number) => void;
  setOpenSnackbar: React.Dispatch<React.SetStateAction<boolean>>;
  setSnackbarVarient: React.Dispatch<React.SetStateAction<'success' | 'error'>>;
  setSelectedId: React.Dispatch<React.SetStateAction<number | undefined>>;
  onDeleteUser: React.MouseEventHandler;
  handleSetMessageSuccess: (message: string) => void;
  handleSetMessageError: (message: string) => void;
  onEditUser: React.MouseEventHandler;
  classes?: Styles;
  checked: number[];
  handleIndividualCheck: (id: number) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  tableRow: {
    height: 'unset'
  },
  tableCellInner: {
    display: 'flex',
    alignItems: 'center'
  },
  nameTextCell: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: theme.spacing(2)
  },
  circle: {
    height: theme.spacing(3),
    width: theme.spacing(3),
    borderRadius: '50%',
    display: 'inline-block',
    backgroundColor: green[500],
    marginRight: theme.spacing(1),
    content: "''"
  },
  wrapper: {
    position: 'relative'
  },
  fabProgress: {
    color: green[500],
    position: 'absolute',
    zIndex: 1,
    top: -4,
    left: -4
  },
  newIcon: {
    color: green[500]
  },
  actionIcon: {
    fontSize: 20
  },
  checkBox: {
    '&&:hover': {
      backgroundColor: 'transparent'
    }
  },
  checkBoxSize: {
    fontSize: '16px'
  }
}));

const BodyRow: FC<Props> = props => {
  const classes = useStyles();
  const {
    currentUserIndex,
    isLoadingData,
    user,
    updateUser,
    setOpenSnackbar,
    setSnackbarVarient,
    handleSetMessageSuccess,
    handleSetMessageError,
    onEditUser,
    onDeleteUser,
    handleIndividualCheck,
    checked
  } = props;
  const { id, displayName, email, contactNumber, role, active, new: isNew } = user;

  const [isProcessing, setProcessing] = useState<boolean>(false);
  const [openDeleteUserDialog, setOpenDeleteUserDialog] = useState<boolean>(false);

  const actionWrapper = async (action: () => Promise<void>) => {
    setProcessing(true);

    try {
      await action();
      setOpenSnackbar(true);
      handleSetMessageSuccess(active ? 'Successfully deactived user' : 'Successfully actived user');
      setSnackbarVarient('success');
    } catch (err) {
      setOpenSnackbar(true);
      handleSetMessageError('Failed to operation');
      setSnackbarVarient('error');
    }

    setProcessing(false);
  };

  const deactivateUser: React.ChangeEventHandler<HTMLInputElement> = async event => {
    await actionWrapper(async () => {
      await axios.put(GET_DEACTIVATE_USER_URL(id));
      updateUser({ active: false }, currentUserIndex);
    });
  };

  const activateUser: React.ChangeEventHandler<HTMLInputElement> = async event => {
    await actionWrapper(async () => {
      await axios.put(GET_ACTIVATE_USER_URL(id));
      updateUser({ active: true }, currentUserIndex);
    });
  };

  const checkedCheckbox = checked.filter(checkedValue => checkedValue === id).length;

  return (
    <TableRow className={classes.tableRow} hover>
      <TableCell>
        {isLoadingData ? (
          <Skeleton width={15} />
        ) : (
          <Checkbox
            key={id}
            icon={<CheckBoxOutlineBlankIcon className={classes.checkBoxSize} />}
            checkedIcon={<CheckBoxIcon className={classes.checkBoxSize} />}
            edge='start'
            color='primary'
            className={classes.checkBox}
            checked={checkedCheckbox === 0 ? false : true}
            tabIndex={-1}
            disableRipple
            onChange={event => handleIndividualCheck && handleIndividualCheck(id)}
          />
        )}
      </TableCell>
      <TableCell>
        <div className={classes.tableCellInner}>
          <div className={classes.nameTextCell}>
            <Typography variant='body1'>{displayName || <Skeleton width={100} />}</Typography>
          </div>
          {isNew && (
            <div>
              <NewIcon fontSize='large' className={classes.newIcon} />
            </div>
          )}
        </div>
      </TableCell>
      <TableCell>{email || <Skeleton width={100} />}</TableCell>
      <TableCell>{contactNumber || <Skeleton width={100} />}</TableCell>
      <TableCell>{role || <Skeleton width={100} />}</TableCell>
      <TableCell>
        <div className={classes.tableCellInner}>
          {isLoadingData ? (
            <Skeleton width={100} />
          ) : (
            <React.Fragment>
              <FormControlLabel
                control={
                  <AntSwitch checked={active ? true : false} onChange={active ? deactivateUser : activateUser} value='checkedB' color='primary' />
                }
                label={active ? 'Active' : 'Inactive'}
              />
            </React.Fragment>
          )}
        </div>
      </TableCell>
      <TableCell align='center'>
        {isLoadingData ? (
          <Skeleton width={100} />
        ) : (
          <Fragment>
            <Tooltip title={'Edit'} placement='top'>
              <IconButton size='small' onClick={onEditUser} disabled={isProcessing}>
                <EditIcon className={classes.actionIcon} />
              </IconButton>
            </Tooltip>
            <Tooltip title={'Delete'} placement='top'>
              <IconButton size='small' onClick={onDeleteUser}>
                <DeleteIcon className={classes.actionIcon} />
              </IconButton>
            </Tooltip>
          </Fragment>
        )}
      </TableCell>
    </TableRow>
  );
};

export default BodyRow;
