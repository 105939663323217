enum ServiceItemUnit {
    PCS = 'PCS',
    KG = 'KG',
    SITTER = 'SITTER',
    SET = 'SET',
    SQ_FT = 'SQ_FT',
    NA = 'NA'
}

export default ServiceItemUnit;
