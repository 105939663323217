import { Divider, Drawer, IconButton, List, Theme } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { isUserRoleManager } from 'selectors';
import CustomerIcon from '@material-ui/icons/PeopleOutline';
import JobIcon from '@material-ui/icons/WorkOutline';
import InvoiceIcon from '@material-ui/icons/DescriptionOutlined';
import DeliveryIcon from '@material-ui/icons/LayersOutlined';
import SettingsIcon from '@material-ui/icons/Settings';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import React, { FC, useContext, useEffect, useState } from 'react';
import logo from '../../images/loundrybutler_logo.png';
import { CurrentUserContext } from 'contexts/CurrentUserContext';
import DrawerItem from './components/DrawerItem';

interface Props {
  openDrawer: boolean;
  handleDrawerClose(): void;
}

const { REACT_APP_DRAWER_WIDTH = '240' } = process.env;

const useStyles = makeStyles((theme: Theme) => ({
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: +REACT_APP_DRAWER_WIDTH,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9)
    }
  },
  logoContainer: {
    textAlign: 'center'
  },
  logo: {
    width: '50%',
    margin: `0px ${theme.spacing(8)}px`
  }
}));

const AppDrawer: FC<Props> = props => {
  const classes = useStyles();
  const { openDrawer, handleDrawerClose } = props;
  const { currentUser } = useContext(CurrentUserContext);

  const [menuActive, setMenuActive] = useState<string>('');
  const [isManager, setIsManager] = useState<boolean>(false);

  useEffect(() => {
    if (menuActive === '') {
      const pathname = window.location.pathname;
      if (pathname.includes('/customers')) {
        setMenuActive('Customers');
      } else if (pathname.includes('/drivers')) {
        setMenuActive('Drivers');
      } else if (pathname.includes('/jobs')) {
        setMenuActive('Jobs');
      } else if (pathname.includes('/invoices')) {
        setMenuActive('Invoices');
      } else if (pathname.includes('/deliveries')) {
        setMenuActive('Delivery Orders');
      } else if (pathname.includes('/settings')) {
        setMenuActive('Settings');
      }
    }
  }, [menuActive]);

  useEffect(() => {
    if(!currentUser) {
      return;
    }

    setIsManager(isUserRoleManager(currentUser));
  }, [CurrentUserContext]);

  return (
    <Drawer
      variant='permanent'
      classes={{
        paper: clsx(classes.drawerPaper, !openDrawer && classes.drawerPaperClose)
      }}
      open={openDrawer}
    >
      <div className={classes.toolbarIcon}>
        <div className={classes.logoContainer}>
          <img src={logo} alt='' className={classes.logo} />
        </div>
        <IconButton onClick={handleDrawerClose}>
          <ChevronLeftIcon />
        </IconButton>
      </div>
      <Divider />
      <List>
        {!isManager && (<DrawerItem Icon={CustomerIcon} path='/customers' label='Customers' menuActive={menuActive} setMenuActive={setMenuActive} />)}
        <DrawerItem Icon={JobIcon} path='/jobs' label='Jobs' menuActive={menuActive} setMenuActive={setMenuActive} />
        {!isManager && (<DrawerItem Icon={InvoiceIcon} path='/invoices' label='Invoices' menuActive={menuActive} setMenuActive={setMenuActive} />)}
        <DrawerItem Icon={DeliveryIcon} path='/deliveries' label='Delivery Orders' menuActive={menuActive} setMenuActive={setMenuActive} />
        {!isManager && (<DrawerItem Icon={SettingsIcon} path='/settings' label='Settings' menuActive={menuActive} setMenuActive={setMenuActive} />)}
      </List>
    </Drawer>
  );
};

export default AppDrawer;
