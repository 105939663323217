import React, { Fragment, FC, useState, useCallback, useEffect } from 'react';
import { createStyles, makeStyles, Table, TableBody, TableCell, TableRow, TableFooter, TableHead, Paper } from '@material-ui/core';

import HeaderRow from 'components/HeaderRow';
import BodyRow from './components/BodyRow';
import TablePagination from 'components/TablePagination';
import CreateEditServiceItemTemplateForm from './components/CreateEditServiceItemTemplateForm';
import axios, { CancelTokenSource } from 'axios';
import { SERVICE_ITEM_TEMPLATE_BASE_URL, GET_EDIT_SERVICE_ITEM_TEMPLATE_URL, CATEGORY_BASE_URL } from 'constants/url';

import ItemType from 'typings/enum/ItemType';
import ServiceItemUnit from 'typings/enum/ServiceItemUnit';
import { CategoryDummy } from 'constants/DummyData';

interface Props {
  isLoadingData: boolean;
  serviceItemTemplates: ServiceItemTemplatesResponseModel[];
  count: number;
  checked: number[];
  currentPage: number;
  rowsPerPage: number;
  order: 'asc' | 'desc';
  orderBy: string;
  setOrder: React.Dispatch<React.SetStateAction<'asc' | 'desc'>>;
  setOrderBy: React.Dispatch<React.SetStateAction<string>>;
  openCreateServiceItemTemplate: boolean;
  openEditServiceItemTemplate: boolean;
  serviceItemTemplate?: ServiceItemTemplatesResponseModel;
  currentEditingServiceTemplateIndex: number;
  setOpenSnackbar: React.Dispatch<React.SetStateAction<boolean>>;
  setSnackbarVarient: React.Dispatch<React.SetStateAction<'success' | 'error'>>;
  setChecked: React.Dispatch<React.SetStateAction<number[]>>;
  addNewServiceItemTemplate(serviceItemTemplate: ServiceItemTemplatesResponseModel): void;
  updateIndividualServiceItemTemplate: (updatedServiceItemTemplateProperties: Partial<ServiceItemTemplatesResponseModel>) => void;
  deleteIndividualServiceItemTemplate: (serviceItemTemplateIndex: number) => void;
  handleSetMessageSuccess: (message: string) => void;
  handleSetMessageError: (message: string) => void;
  handleChangePage: (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => void;
  handleChangeRowsPerPage: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  handleCancelCreateServiceItemTemplate(): void;
  handleOpenEditServiceItemTemplate: (serviceItemTemplateIndex: number) => React.MouseEventHandler;
  handleCancelEditServiceItemTemplate(): void;
}

const useStyles = makeStyles(() =>
  createStyles({
    tableWrapper: {
      overflowX: 'auto'
    }
  })
);

const ServiceItemTemplateTable: FC<Props> = (props: Props) => {
  const classes = useStyles();
  let cancelTokenSource: CancelTokenSource;

  const {
    isLoadingData,
    serviceItemTemplates,
    count,
    setOpenSnackbar,
    setSnackbarVarient,
    handleSetMessageSuccess,
    handleSetMessageError,
    currentPage,
    rowsPerPage,
    order,
    setOrder,
    orderBy,
    setOrderBy,
    handleChangePage,
    handleChangeRowsPerPage,
    openCreateServiceItemTemplate,
    handleCancelCreateServiceItemTemplate,
    openEditServiceItemTemplate,
    serviceItemTemplate,
    currentEditingServiceTemplateIndex,
    handleOpenEditServiceItemTemplate,
    handleCancelEditServiceItemTemplate,
    addNewServiceItemTemplate,
    updateIndividualServiceItemTemplate,
    deleteIndividualServiceItemTemplate,
    checked,
    setChecked
  } = props;

  const dummyService: ServiceItemTemplatesResponseModel = {
    id: 0,
    name: '',
    type: '',
    unit: '',
    priceLA: 0,
    priceDC: 0,
    CategoryId: 0,
    CategoryType: '',
    Category: CategoryDummy
  };

  const dummyCategory: CategoryResponseModel = CategoryDummy;

  const dummyCategories: CategoryResponseModel[] = [dummyCategory];

  // The below logic introduces a 500ms delay for showing the skeleton
  const [showSkeleton, setShowSkeleton] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(false);

  const [name, setName] = useState<string>('');
  const [type, setType] = useState<string>(ItemType.NORMAL);
  const [unit, setUnit] = useState<string>(ServiceItemUnit.PCS);
  const [priceLA, setPriceLA] = useState<number>(0);
  const [priceDC, setPriceDC] = useState<number>(0);
  const [nameError, setNameError] = useState<string>('');
  const [CategoryId, setCategoryId] = useState<number>(0);
  const [categoryType, setCategoryType] = useState<string>('');
  const [category, setCategory] = useState<CategoryResponseModel>(dummyCategory);
  const [categories, setCategories] = useState<CategoryResponseModel[]>(dummyCategories);
  const [idItemService, setItemService] = useState<number>(0);

  const resetInputFormValues = () => {
    setName('');
    setType(ItemType.NORMAL);
    setUnit(ServiceItemUnit.PCS);
    setPriceLA(0);
    setPriceDC(0);
  };

  const resetEditFormValues = useCallback(() => {
    if (!serviceItemTemplate) {
      return;
    }

    const { id, name, type, unit, priceLA, priceDC, CategoryId, Category } = serviceItemTemplate;
    const { typeCategory } = Category;

    setItemService(id);
    setName(name);
    setType(type);
    setUnit(unit);
    setPriceLA(priceLA);
    setPriceDC(priceDC);
    setCategoryId(CategoryId);
    setCategoryType(typeCategory);
    setCategory(Category);
  }, [serviceItemTemplate]);

  useEffect(() => {
    if (!openEditServiceItemTemplate) {
      let timeout: NodeJS.Timeout;

      if (isLoadingData) {
        timeout = setTimeout(() => {
          setShowSkeleton(true);
        }, 500);
      }

      setShowSkeleton(false);
      resetInputFormValues();
      clearFormErrors();

      return () => {
        clearTimeout(timeout);
      };
    } else {
      resetEditFormValues();
      clearFormErrors();
    }
  }, [openEditServiceItemTemplate, isLoadingData, resetEditFormValues]);

  const handleCloseCreateServiceItemTemplate = () => {
    handleCancelCreateServiceItemTemplate();
    resetInputFormValues();
    clearFormErrors();
  };

  const handleCloseEditServiceItemTemplate = () => {
    handleCancelEditServiceItemTemplate();
    resetInputFormValues();
    clearFormErrors();
  };

  const clearFormErrors = () => {
    setNameError('');
  };

  const validateForm = () => {
    let ret = true;
    clearFormErrors();

    if (!name || !name.trim()) {
      setNameError('Please enter task name');
      ret = false;
    }

    return ret;
  };

  const handleOnSubmit: React.FormEventHandler = async (event: any) => {
    event.preventDefault();

    if (!validateForm()) {
      return;
    }

    setLoading(true);

    try {
      cancelTokenSource = axios.CancelToken.source();
      if (!openEditServiceItemTemplate) {
        const response = await axios.post(
          `${SERVICE_ITEM_TEMPLATE_BASE_URL}`,
          {
            name,
            type,
            unit,
            CategoryId,
            priceLA,
            priceDC
          },
          { cancelToken: cancelTokenSource.token }
        );
        addNewServiceItemTemplate(response.data);
        handleSetMessageSuccess('Successfully added new service item');
      } else {
        const response = await axios.put(
          `${GET_EDIT_SERVICE_ITEM_TEMPLATE_URL(idItemService)}`,
          {
            name,
            type,
            unit,
            CategoryId,
            priceLA,
            priceDC
          },
          { cancelToken: cancelTokenSource.token }
        );
        updateIndividualServiceItemTemplate(response.data);
        handleSetMessageSuccess('Successfully edited a service item');
      }
      setSnackbarVarient('success');
      setOpenSnackbar(true);
      !openEditServiceItemTemplate ? handleCloseCreateServiceItemTemplate() : handleCloseEditServiceItemTemplate();
    } catch (err) {
      if (!openEditServiceItemTemplate) {
        handleSetMessageError('Failed to add a new service item');
      } else {
        handleSetMessageError('Failed to edit a service item');
      }
      setSnackbarVarient('error');
      setOpenSnackbar(true);
      console.log(`err:${err}`);
      const { errorCode } = err.data;

      console.log(`errorCode:${errorCode}`);
    }
    setLoading(false);
  };

  const checkAll = () => {
    const newBulkChecked = [...checked];
    const countChecked = newBulkChecked.length;
    if (count > rowsPerPage) {
      if (countChecked !== rowsPerPage) {
        newBulkChecked.splice(0, countChecked);
        serviceItemTemplates.map(serviceItem => newBulkChecked.push(serviceItem.id));
      } else {
        newBulkChecked.splice(0, count);
      }
    } else {
      if (countChecked !== count) {
        newBulkChecked.splice(0, countChecked);
        serviceItemTemplates.map(serviceItem => newBulkChecked.push(serviceItem.id));
      } else {
        newBulkChecked.splice(0, count);
      }
    }
    setChecked(newBulkChecked);
  };

  const individualCheck = (id: number) => {
    const newChecked = [...checked];
    // count element in object selected filter for check already exist or not
    const countElement = newChecked.filter(newCheckedValue => newCheckedValue === id).length;
    if (countElement === 0) {
      newChecked.push(id);
    } else {
      // check index of element and remove object by index
      const checkedFilterIndex = newChecked.map(newCheckedValue => newCheckedValue).indexOf(id);
      newChecked.splice(checkedFilterIndex, 1);
    }
    setChecked(newChecked);
  };

  const handleSearchCategory = async (value: string) => {
    console.log('value', value);
    try {
      const params = new URLSearchParams();

      params.append('q', value);

      cancelTokenSource = axios.CancelToken.source();
      const response = await axios.get(`${CATEGORY_BASE_URL}?${params}`, { cancelToken: cancelTokenSource.token });
      setCategories(response.data.Categories);
    } catch (err) {
      console.log(err);
    }
  };

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: string ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return (
    <div className={classes.tableWrapper}>
      <Paper variant='outlined' elevation={2}>
        <Table size='small'>
          <TableHead>
            <HeaderRow
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              height={'unset'}
              headers={[
                { label: 'CheckBox', verticalAlign: 'top', isCheckBox: true, checked, rowsPerPage, handleCheckAll: checkAll },
                { id: 'nameCategory', label: 'Category', sort: true },
                { id: 'name', label: 'Service Name', sort: true },
                { id: 'type', label: 'Type', sort: true },
                { id: 'unit', label: 'Unit', sort: true },
                { id: 'price', label: 'Price' },
                { label: 'Action', align: 'center' }
              ]}
            />
          </TableHead>
          <TableBody>
            {openCreateServiceItemTemplate && (
              <CreateEditServiceItemTemplateForm
                categories={categories}
                category={category}
                name={name}
                type={type}
                unit={unit}
                priceLA={priceLA}
                priceDC={priceDC}
                nameError={nameError}
                isSubmitting={isLoading}
                setCategory={setCategory}
                setCategoryId={setCategoryId}
                categoryType={categoryType}
                setCategoryType={setCategoryType}
                setName={setName}
                setType={setType}
                setUnit={setUnit}
                setPriceLA={setPriceLA}
                setPriceDC={setPriceDC}
                onSubmit={handleOnSubmit}
                onCancel={handleCloseCreateServiceItemTemplate}
                onHandleSearchCategory={handleSearchCategory}
                openEditServiceItemTemplate={openEditServiceItemTemplate}
                primaryButtonLabel={'Save'}
                customBackground={'#F4F9FC'}
              />
            )}
            {showSkeleton ? (
              [1, 2, 3, 4, 5].map(index => (
                <BodyRow
                  index={index}
                  key={index}
                  serviceItemTemplate={dummyService}
                  setOpenSnackbar={setOpenSnackbar}
                  setSnackbarVarient={setSnackbarVarient}
                  handleSetMessageSuccess={handleSetMessageSuccess}
                  handleSetMessageError={handleSetMessageError}
                  deleteIndividualServiceItemTemplate={deleteIndividualServiceItemTemplate}
                  onEditServiceItemTemplate={handleOpenEditServiceItemTemplate(index)}
                  isLoadingData={isLoadingData}
                  checked={checked}
                  handleIndividualCheck={individualCheck}
                />
              ))
            ) : serviceItemTemplates && serviceItemTemplates.length > 0 ? (
              serviceItemTemplates.map((serviceItemTemplate, index) =>
                openEditServiceItemTemplate && currentEditingServiceTemplateIndex === index ? (
                  <CreateEditServiceItemTemplateForm
                    key={serviceItemTemplate.id}
                    category={category}
                    categories={categories}
                    name={name}
                    type={type}
                    unit={unit}
                    priceLA={priceLA}
                    priceDC={priceDC}
                    nameError={nameError}
                    isSubmitting={isLoading}
                    setCategoryId={setCategoryId}
                    setCategory={setCategory}
                    categoryType={categoryType}
                    setCategoryType={setCategoryType}
                    setName={setName}
                    setType={setType}
                    setUnit={setUnit}
                    setPriceLA={setPriceLA}
                    setPriceDC={setPriceDC}
                    onSubmit={handleOnSubmit}
                    onCancel={handleCloseEditServiceItemTemplate}
                    onHandleSearchCategory={handleSearchCategory}
                    openEditServiceItemTemplate={openEditServiceItemTemplate}
                    primaryButtonLabel={'Save'}
                    customBackground={'#F4F9FC'}
                  />
                ) : (
                  <BodyRow
                    index={index}
                    key={serviceItemTemplate.id}
                    serviceItemTemplate={serviceItemTemplate}
                    setOpenSnackbar={setOpenSnackbar}
                    setSnackbarVarient={setSnackbarVarient}
                    handleSetMessageSuccess={handleSetMessageSuccess}
                    handleSetMessageError={handleSetMessageError}
                    deleteIndividualServiceItemTemplate={deleteIndividualServiceItemTemplate}
                    onEditServiceItemTemplate={handleOpenEditServiceItemTemplate(index)}
                    isLoadingData={isLoadingData}
                    checked={checked}
                    handleIndividualCheck={individualCheck}
                  />
                )
              )
            ) : (
              <Fragment>
                <TableRow>
                  <TableCell colSpan={6} align='center'>
                    Data Not Available
                  </TableCell>
                </TableRow>
              </Fragment>
            )}
          </TableBody>
          <TableFooter>
            <TablePagination
              rowsPerPageOptions={[5, 10, 15]}
              count={count}
              rowsPerPage={rowsPerPage}
              page={currentPage}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </TableFooter>
        </Table>
      </Paper>
    </div>
  );
};

export default ServiceItemTemplateTable;
