import React, { Fragment, FC, useState } from 'react';
import {
  Grid,
  TextField,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Button,
  Typography,
  withStyles,
  makeStyles,
  Theme,
  createStyles
} from '@material-ui/core';
import axios, { CancelTokenSource } from 'axios';
import { GET_EDIT_SERVICE_ADDRESS_URL, GET_DELETE_SERVICE_ADDRESS_URL, SERVICE_ADDRESS_BASE_URL } from 'constants/url';
import AccordionActions from '@material-ui/core/AccordionActions';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import { StandardConfirmationDialog } from 'components/AppDialog';
import { Edit, Delete } from '@material-ui/icons';

interface Props {
  serviceAddress: ServiceAddressModel[];
  customerId: number;
  setServiceAddress: React.Dispatch<React.SetStateAction<ServiceAddressModel[]>>;
  handleSnackBar: (open: boolean, variant: 'success' | 'error', message: string) => void;
}

interface ErrorHandling {
  error: boolean;
  message: string;
}

interface AddressError {
  [keys: string]: ErrorHandling;
}

const defaultAddress = {
  id: 0,
  postalCode: '',
  address: '',
  floorNo: '',
  unitNo: ''
};

const addressError = {
  postalCode: { error: false, message: '' },
  address: { error: false, message: '' },
  floorNo: { error: false, message: '' },
  unitNo: { error: false, message: '' }
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: '100%'
    },
    list: {
      width: '100%'
    },
    cardContent: {
      padding: 0
    },
    accordionAction: {
      justifyContent: 'flex-start'
    },
    btnEdit: {
      color: '#388BF2'
    },
    iconEdit: {
      color: '#388BF2'
    },
    btnDelete: {
      color: '#E52A34'
    },
    iconDelete: {
      color: '#E52A34'
    },
    btnCreate: {
      color: '#388BF2'
    },
    btnCancel: {
      color: '#AAAAAA'
    },
    textField: {
      backgroundColor: '#fff'
    }
  })
);

const Accordion = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover
      },
      boxShadow: 'none',
      '&:not(:last-child)': {
        borderBottom: 0
      },
      '&:before': {
        display: 'none'
      },
      '&$expanded': {
        margin: 'auto'
      }
    },
    expanded: {}
  })
)(MuiAccordion);

const AccordionSummary = withStyles((theme: Theme) =>
  createStyles({
    root: {
      marginBottom: -1,
      minHeight: 56,
      '&$expanded': {
        minHeight: 56
      }
    },
    content: {
      '&$expanded': {
        margin: '12px 0'
      }
    },
    expanded: {}
  })
)(MuiAccordionSummary);

const AccordionDetails = withStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2)
    }
  })
)(MuiAccordionDetails);

const ServiceAddress: FC<Props> = props => {
  const classes = useStyles();

  const { serviceAddress, customerId, setServiceAddress, handleSnackBar } = props;
  const [isSubmit, setSubmit] = useState<boolean>(false);
  const [isEdit, setEdit] = useState<boolean>(false);
  const [currentIndex, setCurrentIndex] = useState<number>(-1);
  const [expanded, setExpanded] = useState<string | false>('panel1');
  const [open, setOpen] = useState<boolean>(false);
  const [isCreate, setIsCreate] = useState<boolean>(false);
  const [error, setError] = useState<AddressError>(addressError);
  const [serviceAddressTemp, setServiceAddressTemp] = useState<ServiceAddressModel>(defaultAddress);

  const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, newExpanded: boolean) => setExpanded(newExpanded ? panel : false);

  const handleOnSubmit = async () => {
    if (handleError()) {
      return;
    } else if (!isSubmit) {
      const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();
      try {
        const formData = currentIndex === 0 ? { ...serviceAddress[currentIndex], isPrimary: true } : serviceAddress[currentIndex];

        const result = await axios.put(GET_EDIT_SERVICE_ADDRESS_URL(serviceAddress[currentIndex].id), formData, {
          cancelToken: cancelTokenSource.token
        });
        setEdit(false);
        handleSnackBar(true, 'success', 'Delivery Address has been update successfully.');
        setServiceAddress(serviceAddress.map((val, i) => (i === currentIndex ? result.data : val)));
      } catch (error) {
        console.log('err : ', error);
        handleSnackBar(true, 'error', 'The Delivery Address has failed to update.');
      }
      setSubmit(false);
    }
  };

  const handleOnDelete = async () => {
    if (!isSubmit) {
      try {
        await axios.delete(GET_DELETE_SERVICE_ADDRESS_URL(serviceAddress[currentIndex].id));
        setOpen(false);
        handleSnackBar(true, 'success', 'Delivery Address has been update successfully.');
        setServiceAddress(serviceAddress.filter((val, i) => i !== currentIndex));
      } catch (error) {
        console.log('err : ', error);
        setOpen(false);
        const { errorCode } = error.data;
        if (errorCode === 20) {
          handleSnackBar(true, 'error', 'The Delivery Address is duplicated.');
        } else {
          handleSnackBar(true, 'error', 'The Delivery Address has failed to update.');
        }
      }
      setSubmit(false);
    }
  };

  const handleOnAddNew = () => {
    const index = serviceAddress.length === 0 ? 0 : serviceAddress.length;
    setError(addressError);
    setCurrentIndex(index);
    setServiceAddress([...serviceAddress, defaultAddress]);
    setIsCreate(true);
    setExpanded('panel' + (index + 1));
    handleChange('panel ' + (index + 1));
  };

  const handleOnCancelAddNew = (index: number) => {
    setServiceAddress(serviceAddress.filter((val, i) => i !== index));
    setCurrentIndex(-1);
    setIsCreate(false);
  };

  const handelOnSubmitAddNew = async (index: number) => {
    if (handleError()) return;
    if (!isSubmit) {
      const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();
      try {
        const result = await axios.post(
          SERVICE_ADDRESS_BASE_URL,
          { ...serviceAddress[index], CustomerId: customerId },
          { cancelToken: cancelTokenSource.token }
        );
        setIsCreate(false);
        handleSnackBar(true, 'success', 'Delivery Address has been added successfully.');
        setServiceAddress(serviceAddress.map((val, i) => (i === index ? result.data : val)));
      } catch (error) {
        console.log('err : ', error);
        const { errorCode } = error.data;
        if (errorCode === 20) {
          handleSnackBar(true, 'error', 'The Delivery Address is duplicated.');
        } else {
          handleSnackBar(true, 'error', 'The Delivery Address has failed to added.');
        }
      }
      setSubmit(false);
    }
  };

  const handleClickEdit = (index: number) => {
    setError(addressError);
    setServiceAddressTemp(serviceAddress[index]);
    setCurrentIndex(index);
    setEdit(!isEdit);
  };

  const handleClickDelete = (index: number) => {
    setCurrentIndex(index);
    setOpen(true);
  };

  const handleClickCancelEdit = (index: number) => {
    setServiceAddress(serviceAddress.map((val, i) => (i === index ? serviceAddressTemp : val)));
    setCurrentIndex(-1);
    setEdit(false);
  };

  const handleSetValue = (index: number, newValue: ServiceAddressModel) => {
    setServiceAddress(
      serviceAddress.map((val, idx) => {
        return idx === index! ? newValue! : val;
      })
    );
  };

  const handleError = (): boolean => {
    let i = 0;
    const newValue: { [keys: string]: ErrorHandling } = {};

    for (const [keys, value] of Object.entries(serviceAddress[currentIndex])) {
      if (keys === 'postalCode') {
        newValue[keys] = {
          error: value === '' || value.length != 6,
          message: value === '' || value.length != 6 ? 'Postal Code must be 6 digits' : ''
        };
        if (value === '' || value.length != 6) i++;
      } else {
        newValue[keys] = { error: value === '', message: value === '' ? 'Cannot be empty' : '' };
        if (value === '') i++;
      }
    }

    setError(newValue);
    return i > 0 ? true : false;
  };

  const collapseItem = () =>
    serviceAddress.map((val, index) => (
      <Accordion
        key={index + 1}
        square
        expanded={expanded === 'panel' + (index + 1)}
        onChange={handleChange('panel' + (index + 1))}
        className={classes.root}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={'panel' + (index + 1) + 'd-content'} id={'panel' + (index + 1) + 'd-header'}>
          <Typography>Delivery Address {index + 1}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {isEdit && index === currentIndex ? (
            serviceAddressForm(val, index)
          ) : isCreate && index === currentIndex ? (
            serviceAddressForm(val, index)
          ) : (
            <Fragment>
              <Typography gutterBottom>{`${val.address}, ${val.floorNo}-${val.unitNo}, Singapore, ${val.postalCode}`}</Typography>
            </Fragment>
          )}
        </AccordionDetails>
        <AccordionActions>
          {isEdit && index === currentIndex ? (
            <Fragment>
              <Button variant='text' size='small' color='primary' onClick={handleOnSubmit}>
                Save Changes
              </Button>

              <Button variant='text' size='small' onClick={event => handleClickCancelEdit(index)}>
                Cancel
              </Button>
            </Fragment>
          ) : (
            <Fragment>
              <Button
                className={classes.btnEdit}
                size='small'
                disabled={(isCreate || isEdit) && index !== currentIndex}
                onClick={event => (isCreate ? handelOnSubmitAddNew(index) : handleClickEdit(index))}
              >
                {!isCreate && <Edit fontSize='small' className={classes.iconEdit} />}
                {isCreate && index === currentIndex ? 'Save' : ' Edit Data'}
              </Button>
              {index === 0 ? (
                ''
              ) : (
                <Button
                  className={isCreate && index === currentIndex ? classes.btnCancel : classes.btnDelete}
                  size='small'
                  disabled={(isCreate || isEdit) && index !== currentIndex}
                  onClick={event => (isCreate ? handleOnCancelAddNew(index) : handleClickDelete(index))}
                >
                  {!isCreate && <Delete fontSize='small' className={classes.iconDelete} />}
                  {isCreate && index === currentIndex ? 'Cancel' : ' Delete'}
                </Button>
              )}
            </Fragment>
          )}
        </AccordionActions>
      </Accordion>
    ));

  const serviceAddressForm = (value: ServiceAddressModel, index: number) => (
    <Fragment>
      <Grid container item spacing={1} lg={12} md={12} sm={12} xs={12}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <TextField
            className={classes.textField}
            id={'collectionAddress' + index}
            key={index + 1}
            label='Delivery Address *'
            placeholder='Delivery Address'
            variant='outlined'
            margin='dense'
            fullWidth
            error={(isCreate || isEdit) && index === currentIndex && error.address.error}
            helperText={(isCreate || isEdit) && index === currentIndex ? error.address.message : ''}
            value={value.address}
            onChange={event => handleSetValue(index, { ...value, address: event.target.value })}
          />
        </Grid>

        <Grid item lg={4} md={4} sm={4} xs={4}>
          <TextField
            className={classes.textField}
            id={'floorNo' + index}
            key={index + 1}
            label='Floor No *'
            placeholder='Floor No'
            variant='outlined'
            margin='dense'
            fullWidth
            error={(isCreate || isEdit) && index === currentIndex && error.floorNo.error}
            helperText={(isCreate || isEdit) && index === currentIndex ? error.floorNo.message : ''}
            value={value.floorNo}
            onChange={event => handleSetValue(index, { ...value, floorNo: event.target.value.replace(/\D+/g,'') })}
          />
        </Grid>

        <Grid item lg={4} md={4} sm={4} xs={4}>
          <TextField
            className={classes.textField}
            id={'unitNo' + index}
            key={index + 1}
            label='Unit No *'
            placeholder='Unit No'
            variant='outlined'
            margin='dense'
            fullWidth
            error={(isCreate || isEdit) && index === currentIndex && error.unitNo.error}
            helperText={(isCreate || isEdit) && index === currentIndex ? error.unitNo.message : ''}
            value={value.unitNo}
            onChange={event => handleSetValue(index, { ...value, unitNo: event.target.value.replace(/\D+/g,'') })}
          />
        </Grid>

        <Grid item lg={4} md={4} sm={4} xs={4}>
          <TextField
            className={classes.textField}
            id={'postalCode' + index}
            key={index + 1}
            label='Postal Code *'
            placeholder='Postal Code'
            variant='outlined'
            margin='dense'
            fullWidth
            error={(isCreate || isEdit) && index === currentIndex && error.postalCode.error}
            helperText={(isCreate || isEdit) && index === currentIndex ? error.postalCode.message : ''}
            value={value.postalCode}
            onChange={event => handleSetValue(index, { ...value, postalCode: event.target.value.replace(/\D+/g,'') })}
          />
        </Grid>
      </Grid>
    </Fragment>
  );

  return (
    <Fragment>
      <Card className={classes.root}>
        <CardHeader title='Delivery Address' />
        <CardContent className={classes.cardContent}>{collapseItem()}</CardContent>
        <CardActions disableSpacing>
          <Button className={classes.btnCreate} disabled={isCreate || serviceAddress.length === 4 || isEdit} onClick={handleOnAddNew} variant='text'>
            Add New
          </Button>
        </CardActions>
      </Card>

      <StandardConfirmationDialog
        variant={'danger'}
        titleMessage={'Delete'}
        message={'Are you sure want to delete this data?'}
        open={open}
        handleClose={() => setOpen(false)}
        onConfirm={handleOnDelete}
      />
    </Fragment>
  );
};

export default ServiceAddress;
