import React, { FC, useState, Fragment, useEffect } from 'react';
import { Table, TableBody, TableHead, Paper, makeStyles, createStyles, Theme } from '@material-ui/core';

import HeaderRow from 'components/HeaderRow';
import TablePagination from 'components/TablePagination';
import BodyRow from './components/BodyRow';

interface Props {
  isLoadingData: boolean;
  count: number;
  currentPage: number;
  rowsPerPage: number;
  detailId: number;
  services: ServiceModel[];
  order: 'asc' | 'desc';
  orderBy: string;
  setOrder: React.Dispatch<React.SetStateAction<'asc' | 'desc'>>;
  setOrderBy: React.Dispatch<React.SetStateAction<string>>;
  setDetailId: React.Dispatch<React.SetStateAction<number>>;
  handleChangeRowsPerPage: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  handleChangePage: (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => void;
  handleOpenDetailDeliveryOrder: (deliveryOrderId: number) => React.MouseEventHandler;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paperWrapper: {
      overflow: 'hidden',
      overflowX: 'auto',
      width: '100%'
    },
    table: {
      width: '100%'
    }
  })
);

const dummyService: ServiceModel = {
  id: 0,
  serviceNumber: '',
  description: '',
  time: '',
  totalAmount: 0,
  CustomerId: 0,
  deliveryType: '',
  promoCode: '',
  typePromo: '',
  discountType: '',
  discountAmount: 0
};

const DeliveryOrderTable: FC<Props> = props => {
  const classes = useStyles();

  const {
    isLoadingData,
    count,
    currentPage,
    rowsPerPage,
    services,
    order,
    setOrder,
    orderBy,
    setOrderBy,
    detailId,
    setDetailId,
    handleChangePage,
    handleChangeRowsPerPage,
    handleOpenDetailDeliveryOrder
  } = props;

  // The below logic introduces a 500ms delay for showing the skeleton
  const [showSkeleton, setShowSkeleton] = useState<boolean>(false);

  useEffect(() => {
    if (isLoadingData) {
      setShowSkeleton(true);
    }

    return () => {
      setShowSkeleton(false);
    };
  }, [isLoadingData]);

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return (
    <Fragment>
      <Paper variant='outlined' elevation={2} className={classes.paperWrapper}>
        <Table size='small' className={classes.table}>
          <TableHead>
            <HeaderRow
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              headers={[
                { id: 'serviceNumber', label: 'DO ID', sort: true },
                { id: 'firstName', label: 'Customer Name', sort: true },
                { id: 'collectionDate', label: 'Collection Time (Address)', sort: true },
                { id: 'deliveryDate', label: 'Delivery Time (Address)', sort: true },
                { id: 'invoiceNumber', label: 'Receipt ID', sort: true },
                { id: 'invoiceCreated', label: 'Receipt Time', sort: true },
                { label: 'Payment Status' },
                { label: 'Action' }
              ]}
            />
          </TableHead>
          <TableBody>
            {showSkeleton
              ? [0, 1, 2, 3].map(index => (
                  <BodyRow
                    index={index}
                    key={index}
                    service={dummyService}
                    isLoadingData={isLoadingData}
                    onDetailDeliveryOrder={handleOpenDetailDeliveryOrder(index)}
                  />
                ))
              : services.map((service, index) => (
                  <BodyRow
                    index={index}
                    key={index}
                    service={service}
                    isLoadingData={isLoadingData}
                    onDetailDeliveryOrder={handleOpenDetailDeliveryOrder(service.id)}
                  />
                ))}
          </TableBody>
        </Table>

        <TablePagination
          rowsPerPageOptions={[5, 10, 15]}
          count={count}
          rowsPerPage={rowsPerPage}
          page={currentPage}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </Fragment>
  );
};

export default DeliveryOrderTable;
