import React, { FC, useState, useEffect } from 'react';
import { Container, makeStyles, Theme, Typography } from '@material-ui/core';
import axios from 'axios';
import Alert from '@material-ui/lab/Alert';

import { ACTIVATION_URL } from 'constants/url';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    margin: '10px auto'
  }
}));
const AccountVerification: FC = () => {
  const classes = useStyles();
  const params = new URLSearchParams(window.location.search);
  const email = params.get('email');
  const code = params.get('code');

  const [isSuccess, setSuccess] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    const activation = async () => {
      try {
        await axios.post(ACTIVATION_URL, { email, code });
        setSuccess(true);
      } catch (error) {
        console.log('error:', error);
      }

      setLoading(false);
    };

    activation();
  }, []);

  return (
    <Container component='main' maxWidth='sm'>
      <div className={classes.container}>
        {isLoading ? (
          <Typography>Loading...</Typography>
        ) : (
          <Alert variant='outlined' severity={isSuccess ? 'success' : 'error'}>
            {isSuccess ? 'Your email has been verified. Thanks!' : 'Your email was not found.'}
          </Alert>
        )}
      </div>
    </Container>
  );
};

export default AccountVerification;
