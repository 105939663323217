import React, { FC, useState, useEffect, useCallback, useContext } from 'react';
import clsx from 'clsx';
import { Container, makeStyles, createStyles, Theme, Typography, Grid, Button } from '@material-ui/core';
import axios, { CancelTokenSource } from 'axios';
import { JOBS_BASE_URL, GET_EXPORT_JOBS_URL, BLOCKED_DATE_BASE_URL, GET_DETAIL_JOBS_URL } from 'constants/url';
import { StandardConfirmationDialog } from 'components/AppDialog';
import useRouter from 'hooks/useRouter';
import ServiceDetail from './components/ServiceDetail';
import ServiceItemTable from './components/ServiceItemTable';
import ReturnItemTable from './components/ReturnItemTable';
import JobNotesList from './components/JobNotesList';
import { format } from 'date-fns';
import useCurrentPageTitleUpdater from 'hooks/useCurrentPageTitleUpdater';
import { CurrentJobTabContext } from '../../contexts/CurrentJobTabContext';
import StatusType from 'typings/enum/StatusType';
import AdditionalItemTable from './components/AdditionalItemTable';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4)
    },
    container: {
      '& > :nth-child(n+2)': {
        marginTop: theme.spacing(2)
      }
    },
    btnExport: {
      background: '#FFF',
      marginRight: 10
    },
    btnAddtionalItem: {
      marginTop: theme.spacing(2)
    }
  })
);

const defaultJob = {
  id: 0,
  collectionDate: new Date(),
  deliveryDate: new Date(),
  collectionTime: '',
  deliveryTime: '',
  jobStatus: '',
  clientSignature: '',
  Service: {
    id: 0,
    serviceNumber: '',
    description: '',
    time: '',
    CustomerId: 0,
    deliveryType: '',
    promoCode: '',
    typePromo: '',
    discountType: '',
    discountAmount: '',
    totalAmount: 0,
    ServiceAddress: {
      id: 0,
      postalCode: '',
      address: '',
      floorNo: '',
      unitNo: ''
    },
    CollectionAddress: {
      id: 0,
      postalCode: '',
      address: '',
      floorNo: '',
      unitNo: ''
    },
    Customer: {
      id: 0,
      firstName: '',
      lastName: '',
      contactNumber: '',
      contactPersonName: '',
      contactPersonEmail: '',
      contactPersonNumber: '',
      primaryCollectionAddress: '',
      primaryCollectionAddressDetail: '',
      primaryServiceAddress: '',
      primaryServiceAddressDetail: '',
      email: ''
    },
    ServiceItems: [
      {
        id: 0,
        serviceType: '',
        serviceItemName: '',
        unitPrice: 0,
        quantity: 0,
        quantityOriginal: 0,
        totalPrice: 0,
        remarks: '',
        itemType: '',
        unit: '',
        Category: {
          id: 0,
          name: '',
          type: '',
          image: ''
        }
      }
    ]
  },
  User: {
    id: 0,
    displayName: ''
  },
  JobNotes: [
    {
      id: 0,
      notes: '',
      image: '',
      isHide: false
    }
  ]
};

const dummyBlockedDate: BlockedDateModel = {
  id: 0,
  startDate: null,
  endDate: null,
  startTime: '',
  endTime: '',
  isHoliday: false,
  isActive: false
};

const dummyBlockedDates: BlockedDateModel[] = [dummyBlockedDate];

const JobsDetailPage: FC = () => {
  useCurrentPageTitleUpdater('JOBS');

  const classes = useStyles();
  const { location, history } = useRouter();
  // const { setCurrentJobTab } = useContext(CurrentJobTabContext);
  // setCurrentJobTab(location.state.currentTab);

  const statusAllowChangeAndEdits: string[] = ['UNASSIGNED', 'ASSIGNED_FOR_COLLECTION', 'COLLECTING', 'COLLECTED', 'CHECKING'];
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [snackbarVariant, setSnackbarVariant] = useState<'success' | 'error'>('success');
  const [message, setMessage] = useState<string>('');
  const [realJob, setRealJob] = useState<JobModel>(defaultJob);
  const [job, setJob] = useState<JobModel>(defaultJob);
  const [jobStatus, setJobStatus] = useState<string>('');
  const [serviceId, setServiceId] = useState<number>(0);
  const [serviceItems, setServiceItems] = useState<ServiceItemModel[]>(defaultJob.Service.ServiceItems);
  const [serviceDiscounts, setServiceDiscounts] = useState<ServiceDiscountModel[]>([]);
  const [returnServiceItems, setReturnServiceItems] = useState<ServiceItemModel[]>([]);
  const [additionalServiceItems, setAdditionalServiceItems] = useState<ServiceItemModel[]>([]);
  const [jobNotes, setJobNotes] = useState<JobNoteModel[]>(defaultJob.JobNotes);
  const [openCreateAdditionalItem, setOpenCreateAdditionalItem] = useState<boolean>(false);
  const [openEditAdditionalItem, setOpenEditAdditionalItem] = useState<boolean>(false);
  const [currentEditingAdditionalItemIndex, setCurrentEditingAdditionalItemIndex] = useState<number>(0);
  const [totalAmount, setTotalAmount] = useState<number>(0);
  const [isDelete, setDelete] = useState<boolean>(false);
  const [deliveryType, setDeliveryType] = useState<string>('');

  const [isEdit, setEdit] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isDriverError, setDriverError] = useState<boolean>(false);
  const [driverMessage, setDriverMessage] = useState<string>('');
  const [isCollectionDateError, setCollectionDateError] = useState<boolean>(false);
  const [collectionDateMessage, setCollectionDateMessage] = useState<string>('');
  const [isDeliveryDateError, setDeliveryDateError] = useState<boolean>(false);
  const [deliveryDateMessage, setDeliveryDateMessage] = useState<string>('');
  const [confirmCancel, setConfirmCancel] = useState<boolean>(false);
  const [confirmHoliday, setConfirmHoliday] = useState<boolean>(false);
  const [confirmBlocked, setConfirmBlocked] = useState<boolean>(false);
  const [inPublicHoliday, setInPublicHoliday] = useState<boolean>(false);
  const [inBlockedDate, setInBlockedDate] = useState<boolean>(false);

  const [blockedDates, setBlockedDates] = useState<BlockedDateModel[]>(dummyBlockedDates);
  const [publicHoliday, setPublicHoliday] = useState<BlockedDateModel[]>(dummyBlockedDates);
  const { match } = useRouter();
  const jobId = match.params.id;

  const fetchDataBlockedDate = useCallback(async () => {
    const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();

    const getQueryParams = () => {
      const params = new URLSearchParams();
      params.append('filter[isActive]', 'true');
      return params.toString();
    };

    try {
      const result = await axios.get(`${BLOCKED_DATE_BASE_URL}?${getQueryParams()}`, { cancelToken: cancelTokenSource.token });
      const getBlockedDates = result.data.BlockedDates.filter((date: BlockedDateModel) => date.isHoliday === false);
      const getPublicHoliday = result.data.BlockedDates.filter((date: BlockedDateModel) => date.isHoliday === true);
      setBlockedDates(getBlockedDates);
      setPublicHoliday(getPublicHoliday);
    } catch (err) {
      console.error('err: ', err);
    }
    return () => cancelTokenSource.cancel();
  }, [job]);

  useEffect(() => {
    fetchDataBlockedDate();
  }, [fetchDataBlockedDate]);

  useEffect(() => {
    setLoading(true);
    fetchData();
  }, [jobId, isDelete, jobStatus]);

  useEffect(() => {
    if (!job) {
      return;
    }
  }, [job]);

  const fetchData = async () => {
    try {
      if (!jobId) {
        return;
      }

      const { data } = await axios.get(`${JOBS_BASE_URL}/${jobId}`);
      const { Service, JobNotes, jobStatus, ServiceId } = data;

      const { ServiceItems, ReturnServiceItems, ServiceDiscounts, ServiceItemsOriginal, totalAmount, deliveryType } = Service;
      setJob(data);
      setRealJob(data);
      setServiceItems(ServiceItemsOriginal.filter((value: any) => value.isAdditionalItem == false && value.quantity >= 0));
      setAdditionalServiceItems(ServiceItems.filter((value: any) => value.isAdditionalItem == true));
      setJobNotes(JobNotes);
      setJobStatus(jobStatus);
      setReturnServiceItems(ReturnServiceItems);
      setServiceDiscounts(ServiceDiscounts);
      setTotalAmount(Number(totalAmount));
      setServiceId(ServiceId);
      setDeliveryType(deliveryType);
    } catch (error) {
      console.log('error:', error);
    }
    setLoading(false);
  };

  const handleSnackBar = (open: boolean, variant: 'success' | 'error', message: string) => {
    setSnackbarVariant(variant);
    setOpenSnackbar(open);
    setMessage(message);
  };

  const handleCancelledConfirm = () => {
    setConfirmCancel(false);
    handleDoSubmit();
  };

  const handleBlockedConfirm = () => {
    setConfirmBlocked(false);
    handleDoSubmit();
  };

  const handleHolidayConfirm = () => {
    setConfirmHoliday(false);
    handleDoSubmit();
  };

  const handleOnSubmit = () => {
    resetValidation();
    if (validation()) {
      return;
    }

    const { jobStatus } = job;
    setJobStatus(jobStatus);
    if (inBlockedDate) {
      setConfirmBlocked(true);
    } else if (inPublicHoliday) {
      setConfirmHoliday(true);
    } else {
      handleDoSubmit();
    }
  };

  const handleDoSubmit = async () => {
    try {
      const cancelTokenSource = axios.CancelToken.source();
      const { UserId, collectionDate, collectionTime, deliveryDate, deliveryTime, jobStatus } = job;
      await axios.put(
        `${JOBS_BASE_URL}/${job.id}`,
        { UserId, collectionDate, collectionTime, deliveryDate: deliveryDate || null, deliveryTime: deliveryTime || null, jobStatus },
        { cancelToken: cancelTokenSource.token }
      );
      setRealJob(job);
      handleSnackBar(true, 'success', 'The job has been successfully updated');
      setEdit(false);
    } catch (err) {
      handleSnackBar(true, 'error', 'Job has failed updating');
      console.log('err: ', err);
    }
  };

  const validation = (): boolean => {
    let ret = false;
    resetValidation();

    if (!job.User) {
      setDriverError(true);
      setDriverMessage('Driver can not be empty.');
      ret = true;
    } else if (!job.collectionDate || !job.collectionTime) {
      setCollectionDateError(true);
      setCollectionDateMessage('Collection Date can not be empty.');
      ret = true;
    }

    if (job.jobStatus === 'DELIVERING' || job.jobStatus === 'DELIVERED') {
      if (!job.deliveryDate || !job.deliveryTime) {
        setDeliveryDateError(true);
        setDeliveryDateMessage('Delivery Date can not be empty in delivering job.');
        ret = true;
      }
    }

    if (job.collectionDate && job.collectionTime) {
      const collectionDateTime = new Date(`${format(new Date(job.collectionDate), 'yyyy-MM-dd')} ${job.collectionTime}`);
      const isBlockDate = handleBlockedDateValidation(collectionDateTime);
      const isPublicHoliday = handlePublicHolidayValidation(collectionDateTime);
      if (isBlockDate) {
        setInBlockedDate(true);
      } else if (isPublicHoliday) {
        setInPublicHoliday(true);
      }
    }

    if (job.deliveryDate && job.deliveryTime) {
      const deliveryDateTime = new Date(`${format(new Date(job.deliveryDate), 'yyyy-MM-dd')} ${job.deliveryTime}`);
      const isBlockDate = handleBlockedDateValidation(deliveryDateTime);
      const isPublicHoliday = handlePublicHolidayValidation(deliveryDateTime);
      if (isBlockDate) {
        setInBlockedDate(true);
      } else if (isPublicHoliday) {
        setInPublicHoliday(true);
      }
    }

    return ret;
  };

  const handleOpenCreateAdditionalItem = () => {
    setOpenCreateAdditionalItem(true);
  };

  const handleCancel = () => {
    setEdit(false);
    setOpenCreateAdditionalItem(false);
    resetValidation();
    setJob({
      ...realJob,
      UserId: realJob.UserId,
      User: realJob.User,
      jobStatus: realJob.jobStatus,
      collectionDate: realJob.collectionDate,
      collectionTime: realJob.collectionTime,
      deliveryDate: realJob.deliveryDate,
      deliveryTime: realJob.deliveryTime
    });
  };

  const resetValidation = () => {
    setDriverError(false);
    setDriverMessage('');
    setCollectionDateError(false);
    setCollectionDateMessage('');
    setDeliveryDateError(false);
    setDeliveryDateMessage('');
  };

  const handleLink = (path: string, currentTab?: number) => {
    history.push(path, { currentTab });
  };

  const handleBlockedDateValidation = (date: Date | null) => {
    if (!date) {
      return false;
    }

    const isBlockDate = blockedDates.find((blockDate: BlockedDateModel) => {
      if (blockDate.startDate && blockDate.endDate) {
        const startDate = new Date(`${blockDate.startDate} ${blockDate.startTime}`);
        const endDate = new Date(`${blockDate.endDate} ${blockDate.endTime}`);
        if (date >= startDate && date <= endDate) {
          return date;
        }
      }
    });

    return isBlockDate ? true : false;
  };

  const handlePublicHolidayValidation = (date: Date | null) => {
    if (!date) {
      return false;
    }

    const isPublicHoliday = publicHoliday.find((holidayDate: BlockedDateModel) => {
      if (holidayDate.startDate && holidayDate.endDate) {
        const startDate = new Date(`${holidayDate.startDate} ${holidayDate.startTime}`);
        const endDate = new Date(`${holidayDate.endDate} ${holidayDate.endTime}`);
        if (date >= startDate && date <= endDate) {
          return date;
        }
      }
    });

    return isPublicHoliday ? true : false;
  };

  const handleExportPDF = async () => {
    if (!jobId) {
      return;
    }

    const { id, collectionDate, deliveryDate, collectionTime, deliveryTime, jobStatus, User, Service } = job;

    const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();
    const result = await axios.get(GET_DETAIL_JOBS_URL(Number(jobId)), { cancelToken: cancelTokenSource.token });
    const resultServiceItems = result.data.Service.ServiceItems;

    let deliverType = '-';
    let fullName = '-';
    let fullAddress = '-';
    let collectionFullAddress = '-';
    let collectionFullDate = collectionDate + ' ' + collectionTime;
    let deliveryFullDate = deliveryDate + ' ' + deliveryTime;
    let driverName = User ? User.displayName : '-';

    if (Service && Service !== null) {
      const { ServiceAddress, CollectionAddress, Customer, deliveryType, ServiceItems } = Service!;
      deliverType = deliveryType;

      if (ServiceAddress && ServiceAddress !== null) {
        const { postalCode, address, floorNo, unitNo } = ServiceAddress!;
        fullAddress = address + ' ' + floorNo + ' ' + unitNo + ' ' + postalCode;
      }

      if (CollectionAddress && CollectionAddress !== null) {
        const { postalCode, address, floorNo, unitNo } = CollectionAddress!;
        collectionFullAddress = address + ' ' + floorNo + ' ' + unitNo + ' ' + postalCode;
      }

      if (Customer && Customer !== null) {
        const { firstName, lastName } = Customer;
        fullName = firstName + ' ' + lastName;
      }
    }

    try {
      await axios
        .post(
          GET_EXPORT_JOBS_URL,
          {
            id,
            customerName: fullName,
            collectionAddress: collectionFullAddress,
            serviceAddress: fullAddress,
            collectionDate: collectionFullDate,
            deliveryDate: deliveryFullDate,
            driverName,
            jobStatus,
            serviceItems: resultServiceItems,
            jobNotes: jobNotes.filter(val => val.isHide !== true)
          },
          {
            headers: {
              t: localStorage.getItem('token')
            },
            responseType: 'blob'
          }
        )
        .then(response => {
          const file = new Blob([response.data && response.data], { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);

          const newwindow = window.open(fileURL, 'name', 'height=700,width=750');
          if (newwindow && window.focus) {
            newwindow.focus();
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Container maxWidth='lg' className={clsx(classes.root, classes.container)}>
      <Grid container direction='row' justify='space-between'>
        <Grid container direction='row' item xs={12} sm={12} md={8} lg={8} alignItems='center' spacing={1}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Typography variant='h4' gutterBottom>
              Job Details
            </Typography>
          </Grid>
        </Grid>
        <Grid container direction='row' item xs={12} sm={12} md={4} lg={4} alignItems='flex-end' justify='flex-end' spacing={1}>
          <Button variant='contained' size='small' onClick={() => handleLink('/jobs')}>
            Back
          </Button>
        </Grid>
      </Grid>

      <Grid container direction='row' spacing={2} justify='space-between'>
        <ServiceDetail
          isEdit={isEdit}
          isDriverError={isDriverError}
          setDriverError={setDriverError}
          driverMessage={driverMessage}
          setDriverMessage={setDriverMessage}
          isCollectionDateError={isCollectionDateError}
          setCollectionDateError={setCollectionDateError}
          collectionDateMessage={collectionDateMessage}
          setCollectionDateMessage={setCollectionDateMessage}
          isDeliveryDateError={isDeliveryDateError}
          setDeliveryDateError={setDeliveryDateError}
          deliveryDateMessage={deliveryDateMessage}
          setDeliveryDateMessage={setDeliveryDateMessage}
          setInPublicHoliday={setInPublicHoliday}
          setInBlockedDate={setInBlockedDate}
          job={job}
          setJob={setJob}
          setEdit={setEdit}
          handleBlockedDateValidation={handleBlockedDateValidation}
          handlePublicHolidayValidation={handlePublicHolidayValidation}
          handleSnackBar={handleSnackBar}
          handleCancel={handleCancel}
          handleOnSubmit={handleOnSubmit}
          handleExportPDF={handleExportPDF}
        />
      </Grid>
      <Grid container direction='row'>
        <Typography variant='h5' gutterBottom>
          Item Details
        </Typography>
        <ServiceItemTable
          serviceItems={serviceItems}
          setServiceItems={setServiceItems}
          returnServiceItems={returnServiceItems}
          setReturnServiceItems={setReturnServiceItems}
          serviceDiscounts={serviceDiscounts}
          totalAmount={totalAmount}
          deliveryType={deliveryType}
          jobStatus={jobStatus}
          isLoading={isLoading}
        />
      </Grid>

      <Grid container direction='row'>
        <Typography variant='h5' gutterBottom>
          Return Items
        </Typography>
        <ReturnItemTable serviceItems={returnServiceItems} setServiceItems={setReturnServiceItems} jobStatus={jobStatus} isLoading={isLoading} />
      </Grid>

      <Grid container direction='row'>
        <Typography variant='h5' gutterBottom>
          Additional Items
        </Typography>
        <AdditionalItemTable
          serviceItems={additionalServiceItems}
          setServiceItems={setAdditionalServiceItems}
          openCreateAdditionalItem={openCreateAdditionalItem}
          setOpenCreateAdditionalItem={setOpenCreateAdditionalItem}
          openEditAdditionalItem={openEditAdditionalItem}
          setOpenEditAdditionalItem={setOpenEditAdditionalItem}
          serviceDiscounts={serviceDiscounts}
          serviceItem={additionalServiceItems[currentEditingAdditionalItemIndex]}
          currentEditingAdditionalItemIndex={currentEditingAdditionalItemIndex}
          setCurrentEditingAdditionalItemIndex={setCurrentEditingAdditionalItemIndex}
          totalAmount={totalAmount}
          serviceId={serviceId}
          deliveryType={deliveryType}
          setDelete={setDelete}
          jobStatus={jobStatus}
          isLoading={isLoading}
        />
        <Grid container direction='row' item xs={12} sm={12} md={12} lg={12} alignItems='flex-end' justify='flex-end' spacing={1}>
          <Button
            variant='contained'
            color='primary'
            size='small'
            className={classes.btnAddtionalItem}
            onClick={handleOpenCreateAdditionalItem}
            disabled={!statusAllowChangeAndEdits.includes(jobStatus)}
          >
            Add New Items
          </Button>
        </Grid>
      </Grid>

      <Grid container direction='row'>
        <JobNotesList jobId={jobId} jobNotes={jobNotes} isLoading={isLoading} setJobNotes={setJobNotes} handleSnackBar={handleSnackBar} />
      </Grid>

      <StandardConfirmationDialog
        variant={'danger'}
        titleMessage={'Cancel Job'}
        message={'Are you sure to cancel this order? please click ‘OK’ to confirm this assignment.'}
        open={confirmCancel}
        handleClose={() => setConfirmCancel(false)}
        onConfirm={handleCancelledConfirm}
      />

      <StandardConfirmationDialog
        variant={'danger'}
        titleMessage={'Blocked Date'}
        message={'You are assigning a job on Blocked Date, please click ‘OK’ to confirm this assignment?'}
        open={confirmBlocked}
        handleClose={() => setConfirmBlocked(false)}
        onConfirm={handleBlockedConfirm}
      />

      <StandardConfirmationDialog
        variant={'danger'}
        titleMessage={'Public Holiday'}
        message={'You are assigning a job on Public Holiday, please click ‘OK’ to confirm this assignment?'}
        open={confirmHoliday}
        handleClose={() => setConfirmHoliday(false)}
        onConfirm={handleHolidayConfirm}
      />

      <StandardConfirmationDialog
        variant={snackbarVariant}
        titleMessage={snackbarVariant.charAt(0).toUpperCase() + snackbarVariant.slice(1) + '!'}
        message={message}
        open={openSnackbar}
        handleClose={() => setOpenSnackbar(false)}
        onConfirm={() => setOpenSnackbar(false)}
        noCancelButton={true}
      />
    </Container>
  );
};

export default JobsDetailPage;
