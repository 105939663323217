import React, { FC, useState, useEffect, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { IconButton, makeStyles, TableRow, Theme, Tooltip, Typography, createStyles, Menu, MenuItem } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import NewIcon from '@material-ui/icons/FiberNewOutlined';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import Skeleton from 'react-loading-skeleton';
import BodyCell from 'components/BodyCell';
import useRouter from 'hooks/useRouter';

interface Props {
  isLoadingData: boolean;
  index: number;
  customer: CustomerModel;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    newIcon: {
      color: green[500],
      fontSize: 30
    },
    actionIcon: {
      fontSize: 20
    },
    tableCellInner: {
      display: 'flex',
      alignItems: 'center'
    },
    nameTextCell: {
      display: 'flex',
      flexDirection: 'column',
      marginRight: theme.spacing(2)
    },
    checkBox: {
      '&&:hover': {
        backgroundColor: 'transparent'
      }
    },
    checkBoxSize: {
      fontSize: '16px'
    },
    contactNumber: {
      color: '#4b0a5f'
    }
  })
);

const dummyCollectionAddress: CollectionAddressModel = {
  id: 0,
  address: '',
  postalCode: '',
  floorNo: '',
  unitNo: ''
};

const dummyContactPerson: ContactPersonModel = {
  id: 0,
  name: '',
  contactNumber: '',
  contactEmail: ''
};

const BodyRow: FC<Props> = props => {
  const classes = useStyles();
  const { history } = useRouter();
  const { isLoadingData, customer } = props;

  const {
    id,
    firstName,
    lastName,
    email,
    contactNumber,
    contactPersonName,
    contactPersonEmail,
    contactPersonNumber,
    primaryCollectionAddress,
    primaryCollectionAddressDetail,
    primaryServiceAddress,
    primaryServiceAddressDetail,
    ContactPeople,
    CollectionAddresses
  } = customer;

  const [customerPostalCode, setCustomerPostalCode] = useState<string>('');
  const [customerAddress, setCustomerAddress] = useState<string>('');
  const [customerFloorNo, setCustomerFloorNo] = useState<string>('');
  const [customerUnitNo, setCustomerUnitNo] = useState<string>('');
  const [firstContactName, setFirstContactName] = useState<string>('');
  const [firstContactNumber, setFirstContactNumber] = useState<string>('');
  const [openSort, setOpenSort] = useState<null | HTMLElement>(null);

  const handleLink = (path: string) => {
    history.push(path, { id: id });
  };

  useEffect(() => {
    const { postalCode, address, floorNo, unitNo } =
      CollectionAddresses && CollectionAddresses.length > 0 ? CollectionAddresses![0] : dummyCollectionAddress;
    setCustomerPostalCode(postalCode);
    setCustomerAddress(address);
    setCustomerFloorNo(floorNo ? floorNo : '-');
    setCustomerUnitNo(unitNo ? unitNo : '-');

    const { name, contactNumber, contactEmail } = ContactPeople && ContactPeople.length > 0 ? ContactPeople![0] : dummyContactPerson;
    setFirstContactName(name);
    setFirstContactNumber(contactNumber);
  }, [CollectionAddresses, ContactPeople]);

  return (
    <Fragment>
      <TableRow>
        <BodyCell cellWidth='20%' isComponent={true}>
          <div className={classes.tableCellInner}>
            <div className={classes.nameTextCell}>
              {isLoadingData ? (
                <Skeleton width={100} />
              ) : (
                <Fragment>
                  <Typography gutterBottom>{`${firstName} ${lastName}`}</Typography>
                  <Typography gutterBottom>{email}</Typography>
                  <Typography gutterBottom className={classes.contactNumber}>
                    {contactNumber}
                  </Typography>
                </Fragment>
              )}
            </div>
            {customer!.new! && <NewIcon className={classes.newIcon} />}
          </div>
        </BodyCell>

        <BodyCell cellWidth='35%' isComponent={true}>
          {isLoadingData ? (
            <Skeleton width={100} />
          ) : (
            <Fragment>
              <Typography gutterBottom>{primaryCollectionAddress}</Typography>
              <Typography gutterBottom>{`${primaryCollectionAddressDetail}`}</Typography>
            </Fragment>
          )}
        </BodyCell>

        <BodyCell cellWidth='35%' isComponent={true}>
          {isLoadingData ? (
            <Skeleton width={100} />
          ) : (
            <Fragment>
              <Typography gutterBottom>{primaryServiceAddress}</Typography>
              <Typography gutterBottom>{`${primaryServiceAddressDetail}`}</Typography>
            </Fragment>
          )}
        </BodyCell>

        <BodyCell cellWidth='5%' isComponent={true}>
          {isLoadingData ? (
            <Skeleton width={100} />
          ) : (
            <Fragment>
              <Tooltip title='More action'>
                <IconButton
                  size='small'
                  area-label='Filter'
                  aria-controls='sort-menu'
                  aria-haspopup='true'
                  onClick={event => setOpenSort(event.currentTarget)}
                >
                  <MoreHorizIcon />
                </IconButton>
              </Tooltip>

              <Menu id='sort' anchorEl={openSort} keepMounted open={Boolean(openSort)} onClose={event => setOpenSort(null)}>
                <MenuItem onClick={() => window.open(`/customers/profile/${id}`, '_blank')}>View Profile</MenuItem>
                <MenuItem onClick={() => window.open(`/customers/job/${id}`, '_blank')}>View Jobs</MenuItem>
                <MenuItem onClick={() => window.open(`/customers/invoices/${id}`, '_blank')}>View Invoices</MenuItem>
              </Menu>
            </Fragment>
          )}
        </BodyCell>
      </TableRow>
    </Fragment>
  );
};

export default BodyRow;
