import React, { FC, useState, Fragment, useEffect } from 'react';
import { Table, TableBody, TableHead, TableRow, TableCell, Paper, makeStyles, createStyles, Theme } from '@material-ui/core';

import HeaderRow from 'components/HeaderRow';
import TablePagination from 'components/TablePagination';
import BodyRow from './components/BodyRow';

interface Props {
  isLoadingData: boolean;
  count: number;
  currentPage: number;
  rowsPerPage: number;
  customers: CustomerModel[];
  queryString?: string;
  order: 'asc' | 'desc';
  orderBy: string;
  setOrder: React.Dispatch<React.SetStateAction<'asc' | 'desc'>>;
  setOrderBy: React.Dispatch<React.SetStateAction<string>>;
  handleChangeRowsPerPage: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  handleChangePage: (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paperWrapper: {
      overflow: 'hidden',
      overflowX: 'auto',
      width: '100%'
    },
    table: {
      width: '100%'
    }
  })
);

const dummyCustomer: CustomerModel = {
  id: 0,
  firstName: '',
  lastName: '',
  email: '',
  contactNumber: '',
  contactPersonName: '',
  contactPersonEmail: '',
  contactPersonNumber: '',
  primaryCollectionAddress: '',
  primaryCollectionAddressDetail: '',
  primaryServiceAddress: '',
  primaryServiceAddressDetail: ''
};

const CustomerTable: FC<Props> = props => {
  const classes = useStyles();

  const {
    isLoadingData,
    count,
    currentPage,
    rowsPerPage,
    customers,
    order,
    orderBy,
    handleChangePage,
    handleChangeRowsPerPage,
    setOrder,
    setOrderBy,
    queryString
  } = props;

  // The below logic introduces a 500ms delay for showing the skeleton
  const [showSkeleton, setShowSkeleton] = useState<boolean>(false);

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  useEffect(() => {
    if (isLoadingData) {
      setShowSkeleton(true);
    }

    return () => {
      setShowSkeleton(false);
    };
  }, [isLoadingData]);

  return (
    <Fragment>
      <Paper variant='outlined' elevation={2} className={classes.paperWrapper}>
        <Table size='small' className={classes.table}>
          <TableHead>
            <HeaderRow
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              headers={[
                { id: 'firstName', label: 'Customer Name', sort: true },
                { label: 'Collection Address' },
                { label: 'Delivery Address' },
                { label: 'Action', align: 'center' }
              ]}
            />
          </TableHead>
          <TableBody>
            {showSkeleton ? (
              [0, 1, 2, 3].map(index => <BodyRow index={index} key={index} customer={dummyCustomer} isLoadingData={isLoadingData} />)
            ) : customers && customers.length > 0 ? (
              customers.map((value, index) => <BodyRow index={index} key={index} customer={value} isLoadingData={isLoadingData} />)
            ) : (
              <Fragment>
                <TableRow>
                  <TableCell colSpan={7} align='center'>
                    {queryString ? 'No matching result' : 'Data Not Available'}
                  </TableCell>
                </TableRow>
              </Fragment>
            )}

            <TablePagination
              rowsPerPageOptions={[5, 10, 15]}
              count={count}
              rowsPerPage={rowsPerPage}
              page={currentPage}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </TableBody>
        </Table>
      </Paper>
    </Fragment>
  );
};

export default CustomerTable;
