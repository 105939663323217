import React, { FC, useState, useCallback, useEffect, useContext } from 'react';
import clsx from 'clsx';
import { Container, makeStyles, createStyles, Theme, Typography, Grid, IconButton, Menu, MenuItem, Tooltip } from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import { Restore } from '@material-ui/icons';
import useDebounce from 'hooks/useDebounce';
import useCurrentPageTitleUpdater from 'hooks/useCurrentPageTitleUpdater';
import axios, { CancelTokenSource } from 'axios';
import SearchInput from 'components/SearchInput';
import { StandardConfirmationDialog } from 'components/AppDialog';
import DeliveryOrderTable from './components/DeliveryOrderTable';
import DetailDeliveryOrderModal from './components/DetailDeliveryOrderModal';
import { CurrentJobTabContext } from 'contexts/CurrentJobTabContext';
import { DELIVERY_ORDER_BASE_URL, GET_DELIVERY_ORDER_DETAIL_URL } from 'constants/url';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4)
    },
    container: {
      '& > :nth-child(n+2)': {
        marginTop: theme.spacing(2)
      }
    },
    divider: {
      marginBottom: theme.spacing(4)
    },
    paper: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(2),
      margin: 'auto'
    },
    subMenuGrid: {
      borderRight: '1px solid #dcdcdc',
      maxWidth: theme.spacing(15)
    },
    content: {
      paddingLeft: theme.spacing(2),
      paddingTop: theme.spacing(0.2)
    },
    headerSubMenuTitleContainer: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      paddingLeft: theme.spacing(0)
    },
    headerPageTitleContainer: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(4),
      paddingLeft: theme.spacing(2)
    },
    contentContainer: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(0),
      paddingBottom: theme.spacing(2)
    }
  })
);

const dummyService: ServiceModel[] = [
  {
    id: 0,
    serviceNumber: '',
    description: '',
    time: '',
    CustomerId: 0,
    totalAmount: 0,
    deliveryType: '',
    promoCode: '',
    typePromo: '',
    discountType: '',
    discountAmount: 0
  }
];

const DeliveryOrderPage: FC = () => {
  useCurrentPageTitleUpdater('DELIVERY ORDERS');
  const classes = useStyles();
  const { setCurrentJobTab } = useContext(CurrentJobTabContext);
  setCurrentJobTab(1);

  const [isLoadingData, setIsLoadingData] = useState<boolean>(false);
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [snackbarVariant, setSnackbarVariant] = useState<'success' | 'error'>('success');
  const [message, setMessage] = useState<string>('');
  const [messageSuccess, setMessageSuccess] = useState<string>('');
  const [messageError, setMessageError] = useState<string>('');
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [count, setCount] = useState<number>(0);
  const [detailId, setDetailId] = useState<number>(0);
  const [servicesDetail, setServicesDetail] = useState<ServiceModel>();
  const [services, setServices] = useState<ServiceModel[]>(dummyService);
  const [query, setQuery] = useState<string>('');
  const [queryString, setQueryString] = useState<string>();
  const [isReset, setReset] = useState<boolean>(false);
  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  const [orderBy, setOrderBy] = useState<string>('deliveryDate');
  const [filter, setFilter] = useState<string>();
  const [openFilter, setOpenFilter] = useState<null | HTMLElement>(null);

  const [openDetailDeliveryOrder, setOpenDetailDeliveryOrder] = useState<boolean>(false);

  const fetchData = useCallback(async () => {
    const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();
    setIsLoadingData(true);

    const getQueryParams = () => {
      const params = new URLSearchParams();
      if (queryString) {
        params.append('q', queryString);
      }

      if (orderBy) {
        params.append('orderBy', orderBy);
      }

      if (order) {
        params.append('order', order.toUpperCase());
      }

      if (filter) {
        params.append('filter', filter);
        setReset(true);
      }

      params.append('s', (currentPage * rowsPerPage).toString());
      params.append('l', rowsPerPage.toString());

      return params.toString();
    };

    try {
      const result = await axios.get(`${DELIVERY_ORDER_BASE_URL}?${getQueryParams()}`, { cancelToken: cancelTokenSource.token });
      setServices(result.data.deliveryOrders);
      setCount(result.data.count);
      setIsLoadingData(false);
    } catch (err) {
      console.error('err: ', err);
      setIsLoadingData(false);
    }
    return () => cancelTokenSource.cancel();
  }, [rowsPerPage, currentPage, queryString, order, orderBy, filter]);

  const performActionAndRevertPage = (action: React.Dispatch<React.SetStateAction<any>>, actionParam: any) => {
    setCurrentPage(0);
    action(actionParam);
  };

  const handleSearch = useCallback((searchQuery: string) => {
    performActionAndRevertPage(setQueryString, searchQuery);
  }, []);

  const debouncedSearchTerm = useDebounce(query, 500);

  const handleValueFilter = (value: string) => {
    setFilter(value);
  };

  const fetchDetailData = useCallback(async (serviceId: number) => {
    const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();

    try {
      const result = await axios.get(GET_DELIVERY_ORDER_DETAIL_URL(serviceId), { cancelToken: cancelTokenSource.token });
      setServicesDetail(result.data);
      setOpenDetailDeliveryOrder(true);
    } catch (err) {
      console.error('err: ', err);
      setMessage('Detail Error');
      setOpenSnackbar(true);
    }

    return () => cancelTokenSource.cancel();
  }, []);

  const handleOpenDetailDeliveryOrder = (serviceId: number): React.MouseEventHandler => () => {
    fetchDetailData(serviceId);
  };

  const handleCancelDetail = () => {
    setOpenDetailDeliveryOrder(false);
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleSetMessageSuccess = (message: string) => {
    setMessageSuccess(message);
  };

  const handleSetMessageError = (message: string) => {
    setMessageError(message);
  };

  const handleResetFilter = () => {
    setFilter('');
    setReset(false);
  };

  useEffect(() => {
    if (debouncedSearchTerm.length >= 3) {
      handleSearch(debouncedSearchTerm);
    } else if (debouncedSearchTerm.length === 0) {
      handleSearch(debouncedSearchTerm);
    }
  }, [debouncedSearchTerm, handleSearch]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <Container maxWidth='lg' className={clsx(classes.root, classes.container)}>
      <Grid container direction='row' justify='space-between'>
        <Grid item xs={8} sm={8} md={8} lg={8} alignItems='center'>
          <Typography variant='h6' component='h6' gutterBottom>
            List of all delivery orders.
          </Typography>
        </Grid>
      </Grid>

      <Grid container direction='row' justify='space-between'>
        <Grid container item lg={6} sm={6} md={6} justify='flex-start' alignItems='center'>
          <Grid item>
            <SearchInput
              withBorder
              withTransition={false}
              width={150}
              placeHolder='Search Delivery Order...'
              iconColor='#989898'
              tableSearchValue={query}
              setTableSearchValue={setQuery}
            />
          </Grid>
        </Grid>

        <Grid container item lg={6} sm={6} md={6} justify='flex-end' alignItems='center'>
          <Grid item>
            <Tooltip title='Filter'>
              <IconButton area-label='Filter' aria-controls='filter-menu' aria-haspopup='true' onClick={event => setOpenFilter(event.currentTarget)}>
                <FilterListIcon />
              </IconButton>
            </Tooltip>

            <Menu id='filter' anchorEl={openFilter} keepMounted open={Boolean(openFilter)} onClose={event => setOpenFilter(null)}>
              <MenuItem onClick={event => handleValueFilter('PAID')}>Status Paid</MenuItem>
              <MenuItem onClick={event => handleValueFilter('UNPAID')}>Status Unpaid</MenuItem>
              <MenuItem onClick={event => handleValueFilter('CANCELLED')}>Cancelled</MenuItem>
            </Menu>

            {isReset && (
              <Tooltip title='Reset Filter'>
                <IconButton area-label='reset' onClick={handleResetFilter}>
                  <Restore />
                </IconButton>
              </Tooltip>
            )}
          </Grid>
        </Grid>
      </Grid>

      <Grid container direction='row'>
        <DeliveryOrderTable
          isLoadingData={isLoadingData}
          count={count}
          currentPage={currentPage}
          rowsPerPage={rowsPerPage}
          services={services}
          order={order}
          orderBy={orderBy}
          setOrder={setOrder}
          setOrderBy={setOrderBy}
          detailId={detailId}
          setDetailId={setDetailId}
          handleOpenDetailDeliveryOrder={handleOpenDetailDeliveryOrder}
          handleChangePage={(event, page) => setCurrentPage(page)}
          handleChangeRowsPerPage={event => performActionAndRevertPage(setRowsPerPage, +event.target.value)}
        />
      </Grid>

      <DetailDeliveryOrderModal
        service={servicesDetail}
        open={openDetailDeliveryOrder}
        handleCancel={handleCancelDetail}
        setOpenSnackbar={setOpenSnackbar}
        setSnackbarVariant={setSnackbarVariant}
        handleSetMessageSuccess={handleSetMessageSuccess}
        handleSetMessageError={handleSetMessageError}
      />

      <StandardConfirmationDialog
        variant={snackbarVariant}
        titleMessage={snackbarVariant.charAt(0).toUpperCase() + snackbarVariant.slice(1) + '!'}
        message={message}
        open={openSnackbar}
        handleClose={() => setOpenSnackbar(false)}
        onConfirm={() => setOpenSnackbar(false)}
        noCancelButton={true}
      />
    </Container>
  );
};

export default DeliveryOrderPage;
